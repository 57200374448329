// import axios from "axios";

import { requestWithToken } from "./../utils/httpRequest";
import { getApiEndpoint } from "../utils/getApiEndpoint";
import { ON_ERROR } from "./types";

// Fetch active projects
export const getActiveProjects = (page) => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/dashboard/projects`
        );

        return response.data;
    } catch (err) {
        dispatch({
            type: ON_ERROR,
            payload: null,
        });
        return new Promise((resolve, reject) => reject(err.response.data));
    }
};

// Fetch Upcoming Milestones
export const getUpcomingMilestones = () => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/dashboard/milestones`
        );
        return new Promise((resolve, reject) => resolve(response.data));
    } catch (err) {
        dispatch({
            type: ON_ERROR,
            payload: null,
        });
        return new Promise((resolve, reject) => reject(err.response.data));
    }
};

// Fetch Plan Details
export const getPlanDetails = () => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/auth/pack-details`
        );

        return new Promise((resolve, reject) => resolve(response.data));
    } catch (err) {
        dispatch({
            type: ON_ERROR,
            payload: null,
        });
        return new Promise((resolve, reject) => reject(err.response.data));
    }
};

// Fetch Dashboard stats
export const getDashboardStats = () => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/dashboard/statistics`
        );

        return new Promise((resolve, reject) => resolve(response.data));
    } catch (err) {
        dispatch({
            type: ON_ERROR,
            payload: null,
        });
        return new Promise((resolve, reject) => reject(err.response.data));
    }
};
