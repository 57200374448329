import React, { Fragment, useContext } from "react";

import { ProfileContext } from "../../../contexts/ProfileContext";

const AboutDisplay = () => {
    const profileContext = useContext(ProfileContext);

    return (
        <Fragment>
            {profileContext.profile ? (
                <div className="section-body">
                    {profileContext.profile.bio ? (
                        <div className="profile-about">
                            {profileContext.profile.bio}
                        </div>
                    ) : (
                        <div className="profile-about text-center text-muted">
                            Please write a brief description of yourself.
                        </div>
                    )}
                </div>
            ) : (
                <Fragment></Fragment>
            )}
        </Fragment>
    );
};

export default AboutDisplay;
