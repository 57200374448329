import {
    SOCKET_CONNECTION,
    SOCKET_DISCONNECT,
    SOCKET_ERROR,
} from "../actions/types";

const initialState = {
    loading: true,
    socket: null,
};

const socket = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SOCKET_CONNECTION:
            return {
                ...state,
                loading: false,
                socket: payload,
            };
        case SOCKET_ERROR:
        case SOCKET_DISCONNECT:
            return {
                ...state,
                loading: false,
                socket: null,
            };
        default:
            return state;
    }
};

export default socket;
