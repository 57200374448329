import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getActiveGameForms } from "../../actions/gameForm";

// Styling
import styles from "./gameForms.module.css";

const GameForms = () => {
	const dispatch = useDispatch();

	const authState = useSelector((state) => state.auth);

	const [gameForms, setGameForms] = useState([]);

	useEffect(() => {
		const fetchGameForms = async () => {
			const gameForms = await dispatch(getActiveGameForms());
			setGameForms(gameForms.data);
		};

		fetchGameForms();

		// eslint-disable-next-line  react-hooks/exhaustive-deps
	}, []);

	return (
		<Fragment>
			<div className={`${styles["game-forms-container"]} container py-5`}>
				{!authState.authenticated ? (
					<div
						className="alert alert-danger text-center"
						role="alert"
					>
						You can continue but you need to login to post project
						from game customizer.
					</div>
				) : (
					<Fragment></Fragment>
				)}
				<h3 className="mb-5">Select Form To Continue With:</h3>
				{gameForms.length > 0 ? (
					<div className="row g-4">
						{gameForms.map((gameForm, index) => {
							return (
								<Fragment key={index}>
									<div className="col-md-4">
										<div
											className={`${styles["game-form-item"]} p-5`}
										>
											<h5>{gameForm.formName}</h5>
											<p
												className={`${styles["form-desc"]} text-muted`}
											>
												{gameForm.formDescription}
											</p>
											<Link
												to={`/game-customizer/${gameForm._id}`}
												className="btn btn-primary-gradient w-100"
											>
												Continue
											</Link>
										</div>
									</div>
								</Fragment>
							);
						})}
					</div>
				) : (
					<Fragment>
						<p className="text-center">Game forms not found!</p>
					</Fragment>
				)}
			</div>
		</Fragment>
	);
};

export default GameForms;
