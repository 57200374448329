import React from "react";
import { Dialog } from "@material-ui/core";
import { BiDownload } from "react-icons/bi";
import {
	CustomizedDialogContent,
	CustomizedDialogTitle,
} from "../CustomizedModal/CustomizedModal";
import styles from "./MilestoneDeliveryDetailsModal.module.css";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../actions/alert";
import fileDownload from "js-file-download";
import { downloadFile } from "../../../actions/file";

const RejectMilestoneDeliveryDetailsModal = ({ isOpen, onClose, delivery }) => {
	const dispatch = useDispatch();

	const onDownloadAttachment = async (name, url) => {
		const response = await dispatch(downloadFile({ url }));
		if (response.status === 0) {
			dispatch(setAlert(response.msg, "danger"));
		} else {
			// download(response, attachment.name);
			fileDownload(response, name);
		}
	};

	return (
		<Dialog
			onClose={() => onClose(false)}
			aria-labelledby="customized-dialog-title"
			open={isOpen}
			maxWidth="sm"
			fullWidth={true}
		>
			<CustomizedDialogTitle id="customized-dialog-title">
				<h3 className="modal-title text-accent">Delivery Details</h3>
			</CustomizedDialogTitle>
			<CustomizedDialogContent>
				<table className="table table-bordered table-dark table-striped">
					<tr>
						<td className="p-2">Title</td>
						<td className="p-2">{delivery.title}</td>
					</tr>
					<tr>
						<td className="p-2">Description</td>
						<td className="p-2">{delivery.description}</td>
					</tr>
					{delivery.workUrl && (
						<tr>
							<td className="p-2">Work URL</td>
							<td className="p-2">
								<a
									className="color-primary p-0"
									style={{ background: "transparent" }}
									href={delivery.workUrl}
									target="_blank"
									rel="noreferrer"
								>
									Open Link
								</a>
							</td>
						</tr>
					)}
					{delivery.attachments &&
						delivery.attachments.length > 0 && (
							<tr>
								<td className="p-2">Attachments</td>
								<td className="p-2">
									{delivery.attachments.map((attachment) => {
										return (
											<div
												className="d-flex align-items-center p-0"
												style={{
													background: "transparent",
												}}
											>
												<span className="flex-grow-1">
													{attachment.name}
												</span>
												<button
													className="btn p-0"
													onClick={() =>
														onDownloadAttachment(
															attachment.name,
															attachment.url
														)
													}
												>
													<BiDownload />
												</button>
											</div>
										);
									})}
								</td>
							</tr>
						)}
					<tr>
						<td className="p-2">Status</td>
						<td className="p-2">
							<p
								className={`${
									styles["status"]
								}  p-1 rounded d-inline-block ${
									styles[delivery.status]
								}`}
							>
								{delivery.status.replace("-", " ")}
							</p>
						</td>
					</tr>
					{delivery.status === "rejected" &&
						delivery.rejectReason && (
							<tr>
								<td className="p-2">Reject Reason</td>
								<td className="p-2">{delivery.rejectReason}</td>
							</tr>
						)}
				</table>
			</CustomizedDialogContent>
		</Dialog>
	);
};

export default RejectMilestoneDeliveryDetailsModal;
