import React, { Fragment } from "react";

import parse from "html-react-parser";

const Dropdown = ({
    field,
    onChangeOptions,
    sp,
    required,
    nested = false,
    formData,
}) => {
    const onOption = (e) => {
        if (sp !== null && sp !== undefined && sp !== {}) {
            onChangeOptions(e, field.dataName, sp.dataName);
        } else {
            onChangeOptions(e, field.dataName);
        }
    };

    return (
        <Fragment>
            <div className="form-group">
                {nested ? (
                    <h6 className="mt-3 mb-2">{field.fieldName}</h6>
                ) : (
                    <h3 className="my-3">{field.fieldName}</h3>
                )}
                {field.fieldDesc && field.fieldDesc !== "" ? (
                    <div className="text-muted mb-3">
                        {parse(field.fieldDesc)}
                    </div>
                ) : (
                    ""
                )}
                <select
                    className="dark-input focus-none dark-select form-control"
                    name={field.dataName}
                    onChange={(e) => onOption(e, field.dataName)}
                    required={required}
                    // value={formData[field.dataName]}
                >
                    <option
                        value={JSON.stringify({
                            _id: "",
                            optionText: "",
                            optionPrice: "0",
                            minDuration: 0,
                            maxDuration: 0,
                            optionValue: "",
                        })}
                    >
                        Select
                    </option>
                    {field.fieldOptions.length !== 0 ? (
                        <Fragment>
                            {field.fieldOptions.map((op) => {
                                return (
                                    <Fragment key={op._id}>
                                        <option value={JSON.stringify(op)}>
                                            {op.optionText}
                                        </option>
                                    </Fragment>
                                );
                            })}
                        </Fragment>
                    ) : (
                        ""
                    )}
                </select>
            </div>
        </Fragment>
    );
};

export default Dropdown;
