import useEffect from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { stripeAccountStatus } from "../../../actions/stripe";

const Refresh = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        loadData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadData = async () => {
        await dispatch(stripeAccountStatus());
        history.replace("/withdraw-funds");
        // if (response.status) {
        // } else {

        // }
    };

    return "Loading...";
};

export default Refresh;
