import React, { Fragment, useContext } from "react";
import { ProjectControlCenterContext } from "../../contexts/ProjectControlCenterContext";
import ProjectAgreementItem from "../ProjectAgreementItem/ProjectAgreementItem";

const AgreementsTab = ({ projectId }) => {
	const projectControlCenterContext = useContext(ProjectControlCenterContext);

	return (
		<Fragment>
			<h4>Project Agreements</h4>
			<hr></hr>
			{projectControlCenterContext.project && (
				<Fragment>
					{console.log(
						projectControlCenterContext.project.agreements
					)}
					{projectControlCenterContext.project.agreements.length >
					0 ? (
						<Fragment>
							<ul className={`list-type-none p-0`}>
								{projectControlCenterContext.project.agreements.map(
									(agreement) => {
										return (
											<ProjectAgreementItem
												key={agreement._id}
												agreement={agreement}
											/>
										);
									}
								)}
							</ul>
						</Fragment>
					) : (
						<h5 className="text-center text-muted mt-5">
							No Agreements Found!
						</h5>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export default AgreementsTab;
