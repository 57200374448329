import { requestWithToken } from "../utils/httpRequest";
import { getApiEndpoint } from "../utils/getApiEndpoint";

import { setAlert } from "./alert";

export const getUserContacts = () => async (dispatch) => {
  try {
    const res = await requestWithToken("GET", `${getApiEndpoint()}/contacts/`);

    if (res) {
      return res.data;
    }
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      dispatch(setAlert(errors[0].msg, "danger"));
    }
  }
};
