import { REACTTOUR_STEPS, TOGGLE_REACTTOUR } from '../actions/types';

const initialState = {
    steps: [],
    tutorials: false,
};
const reactour = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case REACTTOUR_STEPS:
            return {
                ...state,
                steps: payload,
            };
        case TOGGLE_REACTTOUR:
            return {
                ...state,
                tutorials: payload
            }
        default:
            return state;
    }
};

export default reactour;
