import React, { Fragment } from "react";

import parse from "html-react-parser";

const FileInput = ({ field, onSelectFile, fileNames, fileUpload }) => {
    return (
        <Fragment>
            <div className="form-group">
                <h3 className="my-3">{field.fieldName}</h3>

                {field.fieldDesc && field.fieldDesc !== "" ? (
                    <div className="text-muted mb-3">
                        {parse(field.fieldDesc)}
                    </div>
                ) : (
                    ""
                )}
                <div className="custom-file">
                    <Fragment>
                        <input
                            className="custom-file-input form-control"
                            autoComplete="off"
                            multiple="multiple"
                            type={field.fieldType}
                            name={field.dataName}
                            onChange={(e) => onSelectFile(e)}
                        />
                        <label
                            className="custom-file-label"
                            htmlFor="inputGroupFile02"
                        >
                            <Fragment>
                                {fileUpload[field.dataName].length === 0 ? (
                                    <Fragment>Choose file</Fragment>
                                ) : (
                                    <Fragment>
                                        {fileNames(field.dataName)}
                                    </Fragment>
                                )}
                            </Fragment>
                        </label>
                    </Fragment>
                </div>
            </div>
        </Fragment>
    );
};

export default FileInput;
