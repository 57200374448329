import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "../../actions/alert";
import {
    fetchIntegrations,
    integrateGoogleCalendar,
    removeIntegrations,
} from "../../actions/integration";

import styles from "./settings.module.css";

const Settings = () => {
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState(0);
    const [integrations, setIntegrations] = useState(null);
    const [googleCalendarData, setGoogleCalendarData] = useState(null);

    useEffect(() => {
        getIntegrations();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (integrations) {
            setGoogleCalendarData(
                integrations.calendar.find((item) => item.module === "google")
            );
        }
    }, [integrations]);

    const getIntegrations = async () => {
        const response = await dispatch(fetchIntegrations());

        if (response.status) {
            setIntegrations(response.data.integrations);
        } else {
            dispatch(setAlert(response.msg, "danger"));
        }
    };

    const onIntegration = async (module) => {
        switch (module) {
            case "google":
                const response = await integrateGoogleCalendar();

                if (response.status) {
                    window.location.href = response.data;
                } else {
                    dispatch(setAlert(response.msg, "danger"));
                }
                break;
            default:
                dispatch(setAlert("Invalid request!", "danger"));
                break;
        }
    };

    const onRemoveIntegration = async (group, module) => {
        const requestBody = {
            group: group,
            module: module,
        };

        const response = await dispatch(removeIntegrations(requestBody));

        if (response.status) {
            getIntegrations();
            dispatch(setAlert(response.msg, "success"));
        } else {
            dispatch(setAlert(response.msg, "danger"));
        }
    };

    return (
        <Fragment>
            <div id={`${styles["settings"]}`} className="container py-5">
                <div className="row">
                    <div className="col-md-3">
                        <div
                            className={`nav flex-column nav-pills ${styles["sidebar"]} me-3`}
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical"
                        >
                            <button
                                className={
                                    activeTab === 0
                                        ? `${styles["active"]} ${styles["nav-link"]}`
                                        : `${styles["nav-link"]}`
                                }
                                id="v-pills-home-tab"
                                onClick={(e) => {
                                    setActiveTab(0);
                                }}
                                data-bs-toggle="pill"
                                data-bs-target="#v-pills-home"
                                type="button"
                                role="tab"
                                aria-controls="v-pills-home"
                                aria-selected="true"
                            >
                                Integrations
                            </button>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="light-bg p-4">
                            <h5>Integrate Google Calendar</h5>
                            <p className="text-muted">
                                Integrate google calendar to get events added to
                                your calendar and view events directly in your
                                google calendar
                            </p>
                            <div className="text-end">
                                {googleCalendarData?.meta?.refreshToken ? (
                                    <button
                                        className="btn btn-primary-gradient"
                                        onClick={() =>
                                            onRemoveIntegration(
                                                "calendar",
                                                "google"
                                            )
                                        }
                                    >
                                        Remove Integration
                                    </button>
                                ) : (
                                    <button
                                        className="btn btn-primary-gradient"
                                        onClick={() => onIntegration("google")}
                                    >
                                        Integrate Now
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Settings;
