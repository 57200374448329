import React, { Fragment } from "react";
import Skeleton from "../../Skeleton/Skeleton";

const SkillsSkeleton = () => {
    return (
        <Fragment>
            <section className={`light-bg p-4 my-lg-3 mb-3 text-center`}>
                <h5>Skills</h5>
                <div className="mb-2">
                    <Skeleton height="50px" />
                </div>
                <Skeleton />
            </section>
        </Fragment>
    );
};

export default SkillsSkeleton;
