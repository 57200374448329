import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../../actions/alert";
import { startMilestone } from "../../../../actions/milestone";
import AcceptMilestoneDeliveryModal from "../../../Modals/AcceptMilestoneDeliveryModal/AcceptMilestoneDeliveryModal";
import ContinueMilestoneWorkModal from "../../../Modals/ContinueMilestoneWorkModal/ContinueMilestoneWorkModal";
import DeliverMilestoneModal from "../../../Modals/DeliverMilestoneModal/DeliverMilestoneModal";
import ExtendDeadlineModal from "../../../Modals/ExtendDeadlineModal/ExtendDeadlineModal";
import MilestoneDeliveryDetailsModal from "../../../Modals/MilestoneDeliveryDetailsModal/MilestoneDeliveryDetailsModal";
import RejectMilestoneDeliveryModal from "../../../Modals/RejectMilestoneDeliveryModal/RejectMilestoneDeliveryModal";
import LoadingButton from "../../../utils/LoadingButton/LoadingButton";
import MilestoneCounterTimer from "../../MilestoneCounterTimer/MilestoneCounterTimer";

const MilestoneDetailsActionSection = ({
    milestoneDetails,
    fetchMilestoneDetails,
}) => {
    const dispatch = useDispatch();

    const authState = useSelector((state) => state.auth);

    const [countDownDate, setCountDownDate] = useState(false);
    const [extendDeadlineModalOpen, setExtendDeadlineModalOpen] =
        useState(false);
    const [deliverMilestoneModalOpen, setDeliverMilestoneModalOpen] =
        useState(false);
    const [
        rejectMilestoneDeliveryModalOpen,
        setRejectMilestoneDeliveryModalOpan,
    ] = useState(false);
    const [
        acceptMilestoneDeliveryModalOpen,
        setAcceptMilestoneDeliveryModalOpen,
    ] = useState(false);
    const [
        milestoneDeliveryDetailsModalOpen,
        setMilestoneDeliveryDetailsModalOpen,
    ] = useState(false);
    const [continueMilestoneWorkModalOpen, setContinueMilestoneWorkModalOpen] =
        useState(false);
    const [latestMilestoneDelivery, setLatestMilestoneDelivery] =
        useState(null);

    useEffect(() => {
        // Calculating count down end date
        const calculatedCountDownDate = moment(milestoneDetails.startedAt).add(
            milestoneDetails.estimatedDuration.value,
            milestoneDetails.estimatedDuration.scope
        );
        if (milestoneDetails.deadlineExtends) {
            milestoneDetails.deadlineExtends.forEach((extend) => {
                calculatedCountDownDate.add(
                    extend.extraDuration.value,
                    extend.extraDuration.scope
                );
            });
        }
        setCountDownDate(calculatedCountDownDate);

        // Find delivery with pending status
        if (milestoneDetails?.deliveries?.length > 0) {
            setLatestMilestoneDelivery(milestoneDetails.deliveries[0]);
        }
    }, [milestoneDetails]);

    const onStartMilestone = async () => {
        const response = await dispatch(startMilestone(milestoneDetails._id));
        if (response.status) {
            fetchMilestoneDetails();
            dispatch(setAlert(response.msg, "success"));
        } else {
            dispatch(setAlert(response.msg, "danger"));
        }
    };

    return (
        <Fragment>
            {milestoneDetails && (
                <Fragment>
                    <div className="light-bg text-center p-3">
                        {/* Common block for hired developer and project owner */}
                        {(milestoneDetails.hiredUser?._id ===
                            authState.user._id ||
                            milestoneDetails.project.user ===
                                authState.user._id) && (
                            <>
                                {milestoneDetails.status === "in-progress" &&
                                    countDownDate &&
                                    countDownDate.isValid() && (
                                        <MilestoneCounterTimer
                                            targetDate={countDownDate}
                                            startDate={
                                                milestoneDetails.startedAt
                                            }
                                        />
                                    )}

                                {milestoneDetails.status === "released" && (
                                    <p className="text-muted">
                                        Milestone released! No other actions can
                                        be performed on this milestone.
                                    </p>
                                )}
                            </>
                        )}

                        {/* Block for project owner */}
                        {milestoneDetails.project.user ===
                            authState.user._id && (
                            <>
                                {milestoneDetails.status === "active" && (
                                    <p className="text-muted">
                                        Milestone not started yet! Developer can
                                        start milestone from his end when
                                        working on the milestone.
                                    </p>
                                )}

                                {milestoneDetails.status === "delivered" && (
                                    <Fragment>
                                        {/* Last milestone delivery pending status block */}
                                        {latestMilestoneDelivery?.status ===
                                            "pending" && (
                                            <Fragment>
                                                <p className="text-muted">
                                                    There is a new delivery for
                                                    review,{" "}
                                                    <span
                                                        className="text-danger pointer"
                                                        onClick={() => {
                                                            setMilestoneDeliveryDetailsModalOpen(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        review now
                                                    </span>
                                                    .
                                                </p>
                                                <LoadingButton
                                                    text="Accept Delivery"
                                                    onClick={() => {
                                                        setAcceptMilestoneDeliveryModalOpen(
                                                            true
                                                        );
                                                    }}
                                                />
                                                <LoadingButton
                                                    text="Reject Delivery"
                                                    className="btn btn-sm text-white mt-2"
                                                    onClick={() =>
                                                        setRejectMilestoneDeliveryModalOpan(
                                                            true
                                                        )
                                                    }
                                                />
                                                <AcceptMilestoneDeliveryModal
                                                    isOpen={
                                                        acceptMilestoneDeliveryModalOpen
                                                    }
                                                    onClose={() => {
                                                        fetchMilestoneDetails();
                                                        setAcceptMilestoneDeliveryModalOpen(
                                                            false
                                                        );
                                                    }}
                                                    milestoneId={
                                                        milestoneDetails._id
                                                    }
                                                    deliveryId={
                                                        latestMilestoneDelivery?._id
                                                    }
                                                />
                                                <RejectMilestoneDeliveryModal
                                                    isOpen={
                                                        rejectMilestoneDeliveryModalOpen
                                                    }
                                                    onClose={() => {
                                                        fetchMilestoneDetails();
                                                        setRejectMilestoneDeliveryModalOpan(
                                                            false
                                                        );
                                                    }}
                                                    milestoneId={
                                                        milestoneDetails._id
                                                    }
                                                    deliveryId={
                                                        latestMilestoneDelivery?._id
                                                    }
                                                />
                                                <MilestoneDeliveryDetailsModal
                                                    isOpen={
                                                        milestoneDeliveryDetailsModalOpen
                                                    }
                                                    onClose={() => {
                                                        setMilestoneDeliveryDetailsModalOpen(
                                                            false
                                                        );
                                                    }}
                                                    delivery={
                                                        latestMilestoneDelivery
                                                    }
                                                />
                                            </Fragment>
                                        )}

                                        {/* Last milestone delivery rejected status block */}
                                        {latestMilestoneDelivery?.status ===
                                            "rejected" && (
                                            <Fragment>
                                                <p className="text-muted">
                                                    Last milestone rejected
                                                    please wait for the
                                                    developer to restart work on
                                                    revisions.
                                                </p>
                                            </Fragment>
                                        )}
                                    </Fragment>
                                )}
                            </>
                        )}

                        {/* Block for hired developer in milestone */}
                        {milestoneDetails.hiredUser?._id ===
                            authState.user._id && (
                            <>
                                {milestoneDetails.status === "active" && (
                                    <>
                                        <p className="text-muted">
                                            Milestone not started yet!
                                        </p>
                                        <LoadingButton
                                            text="Start Now"
                                            onClick={() => onStartMilestone()}
                                        />
                                    </>
                                )}

                                {milestoneDetails.status === "in-progress" && (
                                    <Fragment>
                                        <LoadingButton
                                            text="Deliver Now"
                                            onClick={() =>
                                                setDeliverMilestoneModalOpen(
                                                    true
                                                )
                                            }
                                        />
                                        <LoadingButton
                                            text="Extend Deadline"
                                            className="btn btn-sm text-white mt-2"
                                            onClick={() =>
                                                setExtendDeadlineModalOpen(true)
                                            }
                                        />
                                        <ExtendDeadlineModal
                                            isOpen={extendDeadlineModalOpen}
                                            onClose={() => {
                                                fetchMilestoneDetails();
                                                setExtendDeadlineModalOpen(
                                                    false
                                                );
                                            }}
                                            milestoneId={milestoneDetails._id}
                                        />
                                        <DeliverMilestoneModal
                                            isOpen={deliverMilestoneModalOpen}
                                            onClose={() => {
                                                fetchMilestoneDetails();
                                                setDeliverMilestoneModalOpen(
                                                    false
                                                );
                                            }}
                                            milestoneId={milestoneDetails._id}
                                        />
                                    </Fragment>
                                )}

                                {/* Actions for status "delivered" */}
                                {milestoneDetails.status === "delivered" && (
                                    <Fragment>
                                        {latestMilestoneDelivery?.status ===
                                            "pending" && (
                                            <p className="text-muted">
                                                Milestone delivered to client
                                                please wait for approval or
                                                revisions from client end!
                                            </p>
                                        )}

                                        {/* Last milestone delivery rejected block */}
                                        {latestMilestoneDelivery?.status ===
                                            "rejected" && (
                                            <Fragment>
                                                <p className="text-muted">
                                                    Last milestone rejected you
                                                    can view the reject reason
                                                    on deliveries tab.
                                                </p>
                                                <LoadingButton
                                                    text="Continue Work"
                                                    onClick={() => {
                                                        setContinueMilestoneWorkModalOpen(
                                                            true
                                                        );
                                                    }}
                                                />
                                                <LoadingButton
                                                    text="View Reject Details"
                                                    className="btn btn-sm text-white mt-2"
                                                    onClick={() =>
                                                        setMilestoneDeliveryDetailsModalOpen(
                                                            true
                                                        )
                                                    }
                                                />
                                                <ContinueMilestoneWorkModal
                                                    isOpen={
                                                        continueMilestoneWorkModalOpen
                                                    }
                                                    onClose={() => {
                                                        fetchMilestoneDetails();
                                                        setContinueMilestoneWorkModalOpen(
                                                            true
                                                        );
                                                    }}
                                                    milestoneId={
                                                        milestoneDetails._id
                                                    }
                                                />
                                                <MilestoneDeliveryDetailsModal
                                                    isOpen={
                                                        milestoneDeliveryDetailsModalOpen
                                                    }
                                                    onClose={() => {
                                                        setMilestoneDeliveryDetailsModalOpen(
                                                            false
                                                        );
                                                    }}
                                                    delivery={
                                                        latestMilestoneDelivery
                                                    }
                                                />
                                            </Fragment>
                                        )}
                                    </Fragment>
                                )}
                            </>
                        )}
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

export default MilestoneDetailsActionSection;
