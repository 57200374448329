import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import download from "downloadjs";
import { setAlert } from "../../../actions/alert";
import { getApiEndpoint } from "../../../utils/getApiEndpoint";
import styles from "./ChatAttachmentItem.module.css";
import { downloadChatAttachment } from "../../../actions/chat";

const ChatAttachmentItem = ({ attachment }) => {
    const dispatch = useDispatch();

    const onDownloadAttachment = async (url) => {
        const response = await dispatch(downloadChatAttachment("123", { url }));
        if (response.status === 0) {
            dispatch(setAlert(response.msg, "danger"));
        } else {
            download(response, attachment.name);
        }
    };

    return (
        <Fragment>
            <div className={`${styles["attachment-item"]}`}>
                {attachment.name.includes(".jpg") ||
                attachment.name.includes(".png") ||
                attachment.name.includes(".jpeg") ? (
                    <div className="text-center position-relative">
                        <img
                            src={`${getApiEndpoint() + attachment.url}`}
                            alt="preview"
                            className="w-100 mb-2"
                        />
                        <p className="text-muted m-0">{attachment.name}</p>
                        <div className={`${styles["attachment-actions"]}`}>
                            <span
                                className="material-icons"
                                onClick={() =>
                                    onDownloadAttachment(attachment.url)
                                }
                            >
                                file_download
                            </span>
                        </div>
                    </div>
                ) : attachment.name.includes(".pdf") ? (
                    <div
                        className={`d-flex flex-column text-center position-relative h-100`}
                    >
                        <div
                            className={`d-flex align-items-center justify-content-center flex-grow-1 ${styles["item"]} ${styles["pdf"]} mb-2`}
                        >
                            <img src="/icons/pdf.png" alt="icon" />
                        </div>
                        <p className="text-muted m-0">{attachment.name}</p>
                        <div className={`${styles["attachment-actions"]}`}>
                            <span
                                className="material-icons"
                                onClick={() =>
                                    onDownloadAttachment(attachment.url)
                                }
                            >
                                file_download
                            </span>
                        </div>
                    </div>
                ) : attachment.name.includes(".doc") ||
                  attachment.name.includes(".docx") ? (
                    <div
                        className={`d-flex flex-column text-center position-relative h-100`}
                    >
                        <div
                            className={`d-flex align-items-center justify-content-center flex-grow-1 ${styles["item"]} mb-2`}
                        >
                            <img src="/icons/doc.png" alt="icon" />
                        </div>
                        <p className="text-muted m-0">{attachment.name}</p>
                        <div className={`${styles["attachment-actions"]}`}>
                            <span
                                className="material-icons"
                                onClick={() =>
                                    onDownloadAttachment(attachment.url)
                                }
                            >
                                file_download
                            </span>
                        </div>
                    </div>
                ) : attachment.name.includes(".xls") ||
                  attachment.name.includes(".xlsx") ? (
                    <div
                        className={`d-flex flex-column text-center position-relative h-100`}
                    >
                        <div
                            className={`d-flex align-items-center justify-content-center flex-grow-1 ${styles["item"]} mb-2`}
                        >
                            <img src="/icons/xls.png" alt="icon" />
                        </div>
                        <p className="text-muted m-0">{attachment.name}</p>
                        <div className={`${styles["attachment-actions"]}`}>
                            <span
                                className="material-icons"
                                onClick={() =>
                                    onDownloadAttachment(attachment.url)
                                }
                            >
                                file_download
                            </span>
                        </div>
                    </div>
                ) : attachment.name.includes(".ppt") ||
                  attachment.name.includes(".pptx") ? (
                    <div
                        className={`d-flex flex-column text-center position-relative h-100`}
                    >
                        <div
                            className={`d-flex align-items-center justify-content-center flex-grow-1 ${styles["item"]} mb-2`}
                        >
                            <img src="/icons/ppt.png" alt="icon" />
                        </div>
                        <p className="text-muted m-0">{attachment.name}</p>
                        <div className={`${styles["attachment-actions"]}`}>
                            <span
                                className="material-icons"
                                onClick={() =>
                                    onDownloadAttachment(attachment.url)
                                }
                            >
                                file_download
                            </span>
                        </div>
                    </div>
                ) : (
                    <div
                        className={`d-flex flex-column text-center position-relative h-100`}
                    >
                        <div
                            className={`d-flex align-items-center justify-content-center flex-grow-1 ${styles["item"]} mb-2`}
                        >
                            <img src="/icons/file.png" alt="icon" />
                        </div>
                        <p className="text-muted m-0">{attachment.name}</p>
                        <div className={`${styles["attachment-actions"]}`}>
                            <span
                                className="material-icons"
                                onClick={() =>
                                    onDownloadAttachment(attachment.url)
                                }
                            >
                                file_download
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default ChatAttachmentItem;
