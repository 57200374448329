import React, { Fragment } from "react";
import { getFrontendPublicURL } from "../../../utils/getApiEndpoint";
import styles from "./ServiceTerms.module.css";

const ServiceTerms = () => {
    return (
        <Fragment>
            <div style={{ paddingTop: "7rem", paddingBottom: "4rem" }}>
                <div className="container">
                    <h1 className={styles["heading"]}>
                        Trinity Terms of Service
                    </h1>
                    <h3 className="pb-3">Last Update: August 2021</h3>
                    <p>Welcome to Trinitycrew.com</p>
                    <p>
                        The following terms of service (these"
                        <strong>Terms of Service</strong>"), govern your access
                        to and use of the trinity website and mobile
                        application, including any content, functionality and
                        services offered on or through www.trinitycrew.com or
                        the Trinity mobile application (the"
                        <strong>Site</strong>") by NeoxEntertainment (Am
                        Jägerstuhl 10 37574 Einbeck Deutschland).
                    </p>
                    <p>
                        Trinity and its subsidiaries (NeoxEntertainment) are
                        collectively referred hereto as"<strong>Trinity</strong>
                        ""<strong>we</strong>"or"
                        <strong>us</strong>"and“<strong>you</strong>”or“
                        <strong>user</strong>”means you as an user of the
                        Site.Please read the Terms of Service carefully before
                        you start to use the Site.
                    </p>
                    <p>
                        <strong>
                            By using the Site, opening an account or by clicking
                            to accept or agree to the Terms of Service when this
                            option is made available to you, you accept and
                            agree, on behalf of yourself or on behalf of your
                            employer or any other entity (if applicable), to be
                            bound and abide by these Terms of Service, Trinity
                            Payment Terms, found{" "}
                            <a
                                className={styles["links"]}
                                href={`${getFrontendPublicURL()}/terms-of-payment`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                here
                            </a>
                            (“Payment Terms”), and our Privacy Policy, found
                            <a
                                className={styles["links"]}
                                href={`${getFrontendPublicURL()}/privacy-policy`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {" "}
                                here
                            </a>
                            , each of which is incorporated herein by reference
                        </strong>
                        .If you do not want to agree to these Terms of Service
                        or the Privacy Policy, you must not access or use the
                        Site. For more detailed policies surrounding the
                        activity and usage on the Site, please access the
                        designated articles herein.
                    </p>
                    <p>
                        This Site is offered and available to users who are 18
                        years of age or older. If you are under 18 you may not
                        use this Site or the Trinity services. By using this
                        Site, you represent and warrant that you are of legal
                        age to form a binding contract and meet all of the
                        foregoing eligibility requirements. If you do not meet
                        all of these requirements, you must not access or use
                        the Site.Our Customer Support team is available 24/7 if
                        you have any questions regarding the Site or Terms of
                        Service. Contacting our Customer Support team can be
                        performed by submitting a request{" "}
                        <a
                            className={styles["links"]}
                            href="javascript:void(0)"
                        >
                            here
                        </a>
                        .
                    </p>
                    <p>
                        The original language of these Terms of Service, as well
                        as all other texts throughout the Site, is English.
                        Trinity makes available this translation for convenience
                        only. In case of conflicts betweenthe original English
                        version and any translation, the English version shall
                        prevail.
                    </p>
                    <h3 className="py-2">Key Terms</h3>
                    <p>
                        <strong>Buyers</strong> are users who purchase services
                        on Trinity.
                    </p>
                    <p>
                        <strong>Custom Offers </strong>are exclusive proposals
                        that a S-eller can create in response to specific
                        requirements of a Buyer.
                    </p>
                    <p>
                        <strong>Custom Orders</strong>are requests made by a
                        Buyer to receive a Custom Offer from a Seller.
                    </p>
                    <p>
                        <strong>Order Page</strong> is where Buyers and Sellers
                        communicate with each other in connection with an
                        ordered Service.
                    </p>
                    <p>
                        <strong>Orders</strong> are the formal agreements
                        between a Buyer and Seller after a purchase was made
                        from the Seller’s Service Page.
                    </p>
                    <p>
                        <strong>Sellers</strong> are users who offer and perform
                        services through Services or through the Game Customizer
                        on Trinity.
                    </p>
                    <h3 className="py-2">
                        Overview (Main terms, in a nutshell)
                    </h3>
                    <ol className="m-3">
                        <li>
                            Only registered users may buy and sell on Trinity.
                            Registration is free. In registering for anaccount,
                            you agree to provide us with accurate, complete and
                            updated information and must not create an account
                            for fraudulent or misleading purposes. You are
                            solely responsible for any activity on your account
                            and for maintaining the confidentiality and security
                            of your password. We are not liable for any acts or
                            omissions by you in connection with your account.
                        </li>
                        <li>
                            Buyers pay Trinity in advance to create an order
                            (see{" "}
                            <a
                                className={styles["links"]}
                                href="/terms-of-payment"
                            >
                                Payment Terms
                            </a>
                            ).
                        </li>
                        <li>
                            Orders are purchased through the Order button found
                            on a Seller’s Service page or through a Custom
                            Offer.
                        </li>
                        <li>
                            For fees and payments please read the{" "}
                            <a
                                className={styles["links"]}
                                href="/terms-of-payment"
                            >
                                Payment Terms
                            </a>
                        </li>
                        <li>
                            Sellers must fulfill their orders, and may not
                            cancel orders on a regular basis or without cause.
                            Cancelling orders will affect Sellers’ reputation
                            and status.
                        </li>
                        <li>
                            Sellers gain account statuses (Levels) based on
                            their performance and reputation. Advanced levels
                            provide their owners with benefits, including
                            offering services for higher prices through Service
                            Extras, or selling their Service in multiples.
                        </li>
                        <li>
                            Users may not offer or accept payments using any
                            method other than placing an order through
                            Trinity.com.
                        </li>
                        <li>
                            When purchasing a Service, Buyers are granted all
                            rights for the delivered work, unless otherwise
                            specified by the Seller on their Service page. Note:
                            some Services charge additional payments (through
                            Service Extras) for Commercial Use License. See our
                            “
                            <a
                                className={styles["links"]}
                                href="javascript:void(0)"
                            >
                                Ownership
                            </a>
                            ” and “
                            <a
                                className={styles["links"]}
                                href="javascript:void(0)"
                            >
                                Commercial Use License
                            </a>
                            ” sections below for more information.
                        </li>
                        <li>
                            Trinity retains the right to use all published
                            delivered works for Trinity marketing and promotion
                            purposes.
                        </li>
                        <li>
                            We care about your privacy. You can read our Privacy
                            Policy{" "}
                            <a
                                className={styles["links"]}
                                href="javascript:void(0)"
                            >
                                here
                            </a>
                            . The Privacy Policy is a part of these Terms of
                            Service and incorporated here in by reference.
                        </li>
                        <li>
                            Users undertake to comply with Trinity’s Community
                            Standards, which are a set of behavior{" "}
                            <a
                                className={styles["links"]}
                                href="javascript:void(0)"
                            >
                                rules and guidelines
                            </a>
                            , applicable to the Trinity community and
                            marketplace in addition to these Terms of Service,
                            as updated from time to time.
                        </li>
                    </ol>
                    <h2 className="py-3">SELLERS</h2>
                    <h3 className="pb-2">Basics</h3>
                    <ol className="m-3">
                        <li>
                            Sellers create Services on Trinity to allow Buyers
                            to purchase their services.
                        </li>
                        <li>
                            Sellers may also offer Custom Offers to Buyers in
                            addition to their Services.
                        </li>
                        <li>
                            Each Service you sell and successfully complete,
                            accredits your account with a revenue equal to 90%
                            of the purchase amount.
                        </li>
                        <li>
                            Trinity accredits Sellers once an order is
                            completed. See our "
                            <a
                                className={styles["links"]}
                                href="javascript:void(0)"
                            >
                                Orders
                            </a>
                            " section below for a definition of a completed
                            order.
                        </li>
                        <li>
                            For more information about receiving payments, fees
                            and taxes see the{" "}
                            <a
                                className={styles["links"]}
                                href="/terms-of-payment"
                            >
                                Payment Terms
                            </a>
                            .
                        </li>
                        <li>
                            Sellers may not promote their Services or any
                            Trinity content via the Google Ads platform.
                        </li>
                        <li>
                            The Seller's rating is calculated based on the order
                            reviews posted by Buyers or BusinessAccount Team
                            Members. In certain cases, exceedingly low ratings
                            may lead to the suspension of the Seller’s account.
                        </li>
                        <li>
                            For security concerns, Trinity may temporarily
                            disable a Seller’s ability to withdraw revenue to
                            prevent fraudulent or illicit activity. This may
                            come as a result of security issues, improper
                            behavior reported by other users, or associating
                            multiple Trinity accounts to a single withdrawal
                            provider.
                        </li>
                        <li>
                            Sellers are responsible for obtaining a general
                            liability insurance policy with coverage amounts
                            that are sufficient to cover all risks associated
                            with the performance of their services.
                        </li>
                    </ol>
                    <h3 className="py-2">Services</h3>
                    <ol className="m-3">
                        <li>
                            Services created on Trinity are{" "}
                            <a
                                className={styles["links"]}
                                href="javascript:void(0)"
                            >
                                User Generated Content
                            </a>
                            .
                        </li>
                        <li>
                            Services and/or users may be removed by Trinity from
                            the Site for violations of these Terms of Service
                            and/or our Community Standards, which may include
                            (but are not limited to) the following violations
                            and/or materials:
                            <ol className="m-2 ml-5">
                                <li>Illegal or Fraudulent services</li>
                                <li>
                                    Copyright Infringement, Trademark
                                    Infringement, and violation of a third
                                    party's terms of service reported through
                                    our Intellectual Property Claims Policy
                                    found{" "}
                                    <a
                                        className={styles["links"]}
                                        href="javascript:void(0)"
                                    >
                                        here
                                    </a>
                                </li>
                                <li>
                                    Adult oriented services, Pornographic,
                                    Inappropriate/Obscene.
                                </li>
                                <li>Intentional copies of Services</li>
                                <li>
                                    Spam, nonsense, or violent or deceptive
                                    Services
                                </li>
                                <li>Services misleading to Buyers or others</li>
                                <li>Reselling of regulated goods</li>
                                <li>
                                    Offering to prepare academic works on behalf
                                    of Buyers
                                </li>
                                <li>Exceedingly low quality Services</li>
                                <li>
                                    Promoting Trinity and/or Trinity Services
                                    through activities that are prohibitedby any
                                    laws, regulations, and/or third parties'
                                    terms of service, as well as through any
                                    marketing activity that negatively affects
                                    our relationships with our users or
                                    partners.
                                </li>
                            </ol>
                        </li>
                        <li>
                            Services that are removed for violations mentioned
                            above, may result in the suspension of the Seller’s
                            account.
                        </li>
                        <li>
                            Services that are removed for violations are not
                            eligible to be restored or edited.
                        </li>
                        <li>
                            Services may be removed from our Search feature due
                            to poor performance and/or user misconduct.
                        </li>
                        <li>
                            Services may include pre-approved website URLs
                            contained within the Service description and
                            requirements box. Services containing websites
                            promoting content, which violates Trinity’s Terms of
                            Service and/or our Community Standards, will be
                            removed.
                        </li>
                        <li>
                            Services are required to have an appropriate Service
                            image related to the service offered.An option to
                            upload two additional Service images are available
                            to all Sellers. Sellers must deliver the same
                            quality of service as shown on their Service images.
                            Recurring deliveries that don’t match the quality
                            shown on the Service images may lead to the Seller’s
                            account losing Seller status or becoming permanently
                            disabled.
                        </li>
                        <li>
                            Services may contain an approved Service Video
                            uploaded through the Service management tools
                            available on Trinity.
                        </li>
                        <li>
                            Statements on the Service Page that undermine or
                            circumvent these Terms of Service is prohibited.
                        </li>
                        <li>
                            Eligible Services in select Categories may set up
                            Service Packages to offer their services in
                            structured formats with multiple price points for
                            the selected Service.
                        </li>
                        <li>
                            Certain categories are available only to Pro Sellers
                            to create Services. If you are not a Pro Seller,
                            creating a Service for services available to Pro
                            only may result in removal ofyour Service.
                        </li>
                    </ol>
                    <h4 className="pb-3">Service Extras</h4>
                    <ol className="m-3">
                        <li>
                            Service Extras are additional services offered on
                            top of the Seller’s Service for an additional price
                            defined by the Seller.
                        </li>
                        <li>
                            Service Extras may be removed for violations of our
                            Terms of Service and/or our Community Standards. For
                            specific terms, please see the Services section
                            above for a list of services that violate our Terms
                            of Service. Services are subject to be removed due
                            to violations found in Service Extras.
                        </li>
                        <li>
                            The amount of Service Extras, which can be offered,
                            and the price for each Service Extra,is based on
                            your Seller Level. More information surrounding your
                            accounts eligibility forService Extras can be found{" "}
                            <a
                                className={styles["links"]}
                                href="javascript:void(0)"
                            >
                                here
                            </a>
                            .
                        </li>
                        <li>
                            Services offered through Service Extras must be
                            related to the base service and part of the
                            deliverables on the Order.
                        </li>
                        <li>
                            Service Extras may cover different categories of
                            services that are components to a higherquality
                            delivered service.
                        </li>
                        <li>
                            Sellers have the option to extend the duration of an
                            Order for each Service Extra that is added to the
                            Order. This is to cover the time needed to complete
                            the extra service.
                        </li>
                    </ol>
                    <h3 className="py-2">Trinity Business Catalog</h3>
                    <p>
                        Trinity Business is a freelance platform tailor-made for
                        larger teams and businesses. Sellers on the Trinity
                        Business catalog are required to comply with certain
                        professional standards. Eligible Sellers whose Services
                        are included in the Trinity Business catalog will be
                        regularly evaluated by Trinity to ensure their
                        high-quality professionalism is kept. Trinity reserves
                        the right to remove Sellers and Services from the
                        Trinity Business catalog.
                    </p>
                    <h3 className="py-2">Seller Features</h3>
                    <p>
                        Trinity Sellers have access to several exclusive
                        features that help customize the way their services can
                        be offered.
                    </p>
                    <h4 className="pb-1">Custom Offer</h4>
                    <ol className="m-3 ml-5">
                        <li>
                            Sellers can also send Custom Offers addressing
                            specific requirements of a Buyer.
                        </li>
                        <li>
                            Custom Offers are defined by the Seller with the
                            exact description of the service, the priceand the
                            time expected to deliver the service.
                        </li>
                        <li>
                            Custom Offers are sent from the conversation page.
                        </li>
                        <li>
                            Services provided through Custom Offers may not
                            violate Trinity’s Terms of Service and/or our
                            Community Standards.
                        </li>
                        <li>
                            Project Milestones:
                            <ol className="m-3 ml-5">
                                <li>
                                    Custom Offers above $100 may include up to
                                    six project milestones, the minimum amount
                                    for each milestone is $50 and the project
                                    should include at least two milestones. Each
                                    milestone is paid and delivered separately
                                    in accordance with each Custom Offer’s
                                    description and timelines.
                                </li>
                                <li>
                                    Once a milestone is delivered and marked as
                                    completed, Buyers may choose to either
                                    continue with the Order and pay for the next
                                    milestone, or tostop the Order. If Buyer
                                    does not pay for the next milestone within
                                    10 days ofacceptance of previous milestones,
                                    the Order under the next milestones will not
                                    start. A milestone will be automatically
                                    marked as complete if no acceptance or
                                    request for modification were submitted
                                    within 8 days after marked as Delivered,
                                    however, in such case the Order will be
                                    stopped and all further milestones will not
                                    start.
                                </li>
                            </ol>
                        </li>
                    </ol>
                    <h4 className="pb-1">Subscription</h4>
                    <ol className="m-3">
                        <li>
                            Sellers may offer Buyers a fixed term subscription
                            for a specific Service (the “Subscription”). During
                            the Subscription period, Seller will deliver one
                            Order of the same Service each month. Sellers may
                            set a certain discount starting as of the second
                            month.
                        </li>
                        <li>
                            Buyers may cancel future Orders under the
                            Subscription before payment is made for suchfuture
                            Orders. In such case, the Orders under the
                            Subscription will be cancelled, as applicable. Once
                            payment is made, Trinity’s Order Cancelation policy
                            under the{" "}
                            <a
                                className={styles["links"]}
                                href="/terms-of-payment"
                            >
                                Payment Terms
                            </a>{" "}
                            will apply.
                        </li>
                        <li>
                            Seller may cancel the Subscription up to 10 days
                            before to the next consecutive Order. Otherwise,
                            Trinity’s Order Cancelation policy under the{" "}
                            <a
                                className={styles["links"]}
                                href="/terms-of-payment"
                            >
                                Payment Terms
                            </a>{" "}
                            will apply.
                        </li>
                    </ol>
                    <h4 className="pb-1">Trinity Teams</h4>
                    <ol className="m-3">
                        <li>
                            A Trinity Team allows certain Sellers to collaborate
                            with each other and offer Buyers a multi-service
                            Service (a "TeamService").
                        </li>
                        <li>
                            Team Lead: Certain selected Sellers may lead and
                            operate Trinity Teams as Team Leads. Team Leads are
                            chosen manually by Trinity editors through an
                            ongoing review process based on seniority, volume of
                            sales, high ratings, exceptional customer care, high
                            order completion rate and more. The Team Lead is
                            responsible for inviting all Team Members and
                            managing all incoming Orders, from communicating
                            with the Buyer, through handling any required
                            revisions and until the final delivery. The TeamLead
                            can also remove Team Members from Team Services at
                            any time. With respect to open Orders, however,
                            TeamMembers can be removed by Customer Support only.
                        </li>
                        <li>
                            TeamMembers: A Team Lead can invite other Sellers
                            who were also chosen by Trinity to be eligible for
                            Teams collaboration (each, a "Team Member") to join
                            their Team Serviceand be assigned to a specific
                            service. TeamMembers can ask to be removed from a
                            TeamService at any time.
                        </li>
                        <li>
                            TeamServices: A TeamService provides a bundle of
                            services (from two services and up to five) within
                            one Service. A TeamService’s minimum price is
                            US$100. As TeamService is comprised of several
                            services, every service includes its own pricing,
                            pricing factors, and a Team Member / Lead that is
                            accountable for such service, all of which is
                            defined by the TeamLead when creating the
                            TeamService. Trinity Teams may also offer Custom
                            Offers.
                        </li>
                        <li>
                            Limitations: Each Trinity Teamhas one TeamLead.
                            Similarly, a TeamLead may not operate more than one
                            Trinity Studio. A Trinity Teamcan offer up to five
                            TeamServices,each comprised of up to four
                            TeamMembers in addition to the TeamLead. TeamMembers
                            may not be assigned to more than three TeamServices.
                        </li>
                        <li>
                            TeamService Reviews: TeamService Buyers are able to
                            leave a public review on the TeamService as if it
                            were a regular Service. The public buying rating &
                            review is linked to and visible on the TeamService
                            page, the Trinity Teamitself (Teampage), as well as
                            all TeamMembers and TeamLead of that Trinity Studio.
                        </li>
                    </ol>

                    <h2 className="py-3">BUYERS</h2>
                    <h3 className="pb-2">Basics</h3>
                    <ol className="m-3">
                        <li>
                            You may not offer direct payments to Sellers using
                            payment systems outside of the Trinity platform.
                        </li>
                        <li>
                            Trinity retains the right to use all publicly
                            published delivered works for Trinity marketing and
                            promotional purposes.
                        </li>
                        <li>
                            Buyers may request a specific service from the Post
                            a Request feature. Services requested on Trinity
                            must be an allowed service on Trinity. Please
                            clickherefor guidelines on approved services. Users
                            should refrain from using the Post a Request feature
                            for any purpose other than looking for services on
                            Trinity.
                        </li>
                    </ol>
                    <h3 className="pb-2">Purchasing</h3>
                    <ol className="m-3">
                        <li>
                            Please refer to the{" "}
                            <a
                                className={styles["links"]}
                                href="/terms-of-payment"
                            >
                                Payment Terms
                            </a>{" "}
                            for making Payments through the Trinity platform and
                            to learn about fees and taxes.
                        </li>
                        <li>
                            In addition, Buyers can request a Custom Order which
                            addresses specific Buyer requirements, and receive a
                            Custom Offer from Sellers through the site or
                            through Trinity Anywhere.
                        </li>
                        <li>
                            You may not offer Sellers to pay, or make payment
                            using any method other than through the Trinity.com
                            site. In case you have been asked to use an
                            alternative payment method, please report it
                            immediately to Customer Support{" "}
                            <a
                                className={styles["links"]}
                                href="javascript:void(0)"
                            >
                                here
                            </a>
                            .
                        </li>
                        <li></li>
                    </ol>

                    <h2 className="py-3">ORDERS</h2>
                    <h3 className="pb-2">Basics</h3>
                    <ol className="m-3">
                        <li>
                            Once payment is confirmed, your order will be
                            created and given a unique Trinity order number
                            (#FO).
                        </li>
                        <li>
                            Sellers must deliver completed files and/or proof of
                            work using the Deliver Work button (located on the
                            Order page) according to the service that was
                            purchased and advertised on their Service.
                        </li>
                        <li>
                            The Deliver Work button may not be abused by Sellers
                            to circumvent Order guidelines described in these
                            Terms of Service. Using the “Deliver Work” button
                            when an Order was not fulfilled may result in a
                            cancellation of that Order after review, affect the
                            Seller’s rating and result in a warning to Seller.
                        </li>
                        <li>
                            An Order is marked as Complete after it is marked as
                            Delivered and then accepted by a Buyer. An Order
                            will be automatically marked as Complete if not
                            accepted and no request for modification was
                            submitted within 3 days after the Order was marked
                            as Delivered.
                        </li>
                        <li>
                            We encourage our Buyers and Sellers to try and
                            settle conflicts amongst themselves. If for any
                            reason this fails after using the Resolution Center
                            or if you encounter non-permitted usage on the Site,
                            users can contact Trinity's Customer Support
                            department for assistance{" "}
                            <a
                                className={styles["links"]}
                                href="javascript:void(0)"
                            >
                                here
                            </a>
                            . For more information about disputes, Order
                            cancellations and refunds please refer to the{" "}
                            <a
                                className={styles["links"]}
                                href="/terms-of-payment"
                            >
                                Payment Terms
                            </a>
                            .
                        </li>
                        <li>
                            A milestone (on an Order with milestones) is marked
                            as Complete after it is delivered by the Seller and
                            then accepted by the Buyer. A milestone will be
                            automatically marked ascomplete if no acceptance or
                            request for modification were submitted within 8
                            days after marked as Delivered, however, in such
                            case the Order will be stopped and all further
                            milestones will not start. Once a milestone is
                            delivered and accepted, Buyers may choose to either
                            continue with the Order and pay for the next
                            milestone, or to stop the Order. If Buyer does not
                            pay for the next milestone within 10 days of
                            acceptance of previous milestones, the Order under
                            the next milestones will not start. Please note that
                            if you choose to stop the future milestones, the
                            current ordered milestone will not be cancelled.
                        </li>
                        <li>
                            Buyers may purchase a fixed term Subscription to a
                            specific Service, if available on the Seller’s
                            Service page. During the Subscription period, a new
                            Order of the same Servicewill be automatically
                            created each month. By purchasing a Subscription,
                            you authorize us to automatically charge you for
                            each Order under the Subscription from your Trinity
                            Balance or saved payment method, as applicable. If
                            you do not have any Trinity Balance or a saved
                            payment method, you will be requested to approve the
                            payment prior to the next Order. In case a payment
                            is not processed or made by Buyer, for any other
                            reason, the Orders under the Subscription will be
                            cancelled. Buyer may also cancel future Orders under
                            the Subscription before payment is made for such
                            future Orders. Once payment is made, Trinity’s Order
                            Cancelation policy under the{" "}
                            <a
                                className={styles["links"]}
                                href="/terms-of-payment"
                            >
                                Payment Terms
                            </a>{" "}
                            will apply. Please note that Sellers can cancel
                            future Orders under the Subscription 10 days prior
                            to the next consecutive Order.
                        </li>
                        <li>
                            Certain Services that offer local services might
                            require Buyers and Sellers to meet in person in
                            order for the Seller to perform the service. In such
                            cases, users should note that Trinity does not
                            guarantee the behavior, conduct, safety, suitability
                            or ability of either Buyers or Sellers. Both Buyers
                            and Sellers agree that the entire risk arising out
                            of their meeting and/or their use or performance of
                            local services remains solely with them, and Trinity
                            has no responsibility or liability related to any
                            local services provided by the Sellers. In the event
                            that the service is performed on the Buyers’
                            premises, Buyers are encouraged to maintain proper
                            insurance policies to cover their liability as the
                            premise owner. Trinity’s Terms of Service and
                            Community Standards remain applicable to Orders that
                            are performed outside of the marketplace (including,
                            among others, the below restrictions on Unlawful
                            Use, Inappropriate Behavior & Language, and Targeted
                            Abuse).
                        </li>
                    </ol>
                    <h3 className="pb-2">Handling Orders</h3>
                    <ol className="m-3">
                        <li>
                            When a Buyer orders a Service, the Seller is
                            notified by email as well as notifications on the
                            site while logged into the account.
                        </li>
                        <li>
                            Sellers are required to meet the delivery time they
                            specified when creating their Services. Failing to
                            do so will allow the Buyer to cancel the Order when
                            an Order is marked as late and may harm the Seller's
                            status.
                        </li>
                        <li>
                            Sellers must send completed files and/or proof of
                            work using the Deliver Completed Workbutton (located
                            on the Order page) to mark the Order as Delivered.
                        </li>
                        <li>
                            Users are responsible for scanning all transferred
                            files for viruses and malware. Trinity willnot be
                            held responsible for any damages which might occur
                            due to site usage, use of content or files
                            transferred.
                        </li>
                        <li>
                            Buyers may use the "Request Revisions" feature
                            located on the Order Page while an Order is marked
                            as Delivered if the delivered materials do not match
                            the Seller's description on their Service page or
                            they do not match the requirements sent to the
                            Seller at the beginning of the order process.
                        </li>
                    </ol>
                    <h3 className="pb-2">Reviews</h3>
                    <ol className="m-3">
                        <li>
                            Feedback reviews provided by Buyers while completing
                            an Order are an essential part of Trinity's rating
                            system. Reviews demonstrate the Buyer's overall
                            experience with the Sellers and their service.
                            Buyers are encouraged to communicate to the Seller
                            any concerns experienced during their active order
                            in regards to the service provided by the Seller.
                        </li>
                        <li>
                            Leaving a Buyer's feedback is a basic prerogative of
                            a Buyer. Feedback reviews will not be removed unless
                            there are clear violations of our Terms of Service
                            and/or our Community Standards.
                        </li>
                        <li>
                            To prevent any misuse of our Feedback system, all
                            feedback reviews must come from legitimate sales
                            executed exclusively through the Trinity platform
                            from users within our Community. Purchases arranged,
                            determined to artificially enhance Seller ratings,
                            or toabuse the Trinity platform with purchases from
                            additional accounts, will result in a permanent
                            suspension of all related accounts.
                        </li>
                        <li>
                            Feedback comments given by Buyers are publicly
                            displayed on a Seller’s Service page.
                        </li>
                        <li>
                            Work Samples are the delivered images and videos
                            sent to a Buyer in a delivery message. Work Samples
                            are added to a Seller's Live Portfolio on their
                            Service page if the Buyer chooses to publish the
                            Work Sample while providing a public feedback
                            review.
                        </li>
                        <li>
                            Withholding the delivery of services, files, or
                            information required to complete the Service’s
                            service with the intent to gain favorable reviews or
                            additional services is prohibited.
                        </li>
                        <li>
                            Responding and posting a review: Once work is
                            delivered, the Buyer has three days to respond. If
                            no response is provided within the response period,
                            the Order will be considered completed.
                        </li>
                        <li>
                            Users are allowed to leave reviews on Orders up to
                            10 days after an Order is marked as complete. No new
                            reviews may be added to an Order after 10 days.
                        </li>
                        <li>
                            Sellers may not solicit the removal of feedback
                            reviews from their Buyers through mutual
                            cancellations.
                        </li>
                        <li>
                            Once the Buyer submits his/her review, the Seller
                            will receive a notification and will also have the
                            opportunity to leave a review about working with the
                            Buyer. Please Note: At this stage, Sellers cannot
                            see the Buyer's review.
                        </li>
                        <li>
                            Once both Seller and Buyer have completed their
                            reviews, or the 10 days have passed, all posted
                            reviews are made public.
                        </li>
                        <li>
                            Responding to a review: Once both reviews from the
                            Buyer and Seller have been published, the Seller can
                            reply to the Buyer’s review from the Order page,
                            seen on the Seller’s Service page, beneath the
                            Buyer’s feedback.
                        </li>
                    </ol>
                    <h2 className="py-3">Disputes and Cancellations</h2>
                    <p>
                        We encourage our Buyers and Sellers to try and settle
                        conflicts amongst themselves. If for any reason this
                        fails after using the Resolution Center or if you
                        encounter non-permitted usage on the Site, users can
                        contact Trinity's Customer Support department for
                        assistance{" "}
                        <a
                            className={styles["links"]}
                            href="javascript:void(0)"
                        >
                            here
                        </a>
                        . For more information about disputes, Order
                        cancellations and refunds please refer to the{" "}
                        <a className={styles["links"]} href="/terms-of-payment">
                            Payment Terms
                        </a>
                        .
                    </p>
                    <h2 className="py-3">User Conduct and Protection</h2>
                    <p>
                        Trinity enables people around the world to create,
                        share, sell and purchase nearly any service they need at
                        an unbeatable value. Services offered on Trinity reflect
                        the diversity of an expandingService economy. Members of
                        the Trinity community communicate and engage through
                        orders, social media, and on Trinity’s Community Forums.
                    </p>
                    <p>
                        Trinity maintains a friendly, community spirited, and
                        professional environment. Users should keepto that
                        spirit while participating in any activity or extensions
                        of Trinity. This section relates to the expected conduct
                        users should adhere to while interacting with each other
                        on Trinity.To report a violation of our Terms of Service
                        and/or our Community Standards, User Misconduct, or
                        inquiries regarding your account, please contact our
                        Customer Support team{" "}
                        <a
                            className={styles["links"]}
                            href="javascript:void(0)"
                        >
                            here
                        </a>
                        .
                    </p>
                    <h3 className="pb-2">Basics</h3>
                    <ol className="m-3">
                        <li>
                            To protect our users' privacy, user identities are
                            kept anonymous. Requesting or providing Email
                            addresses, Skype/IM usernames, telephone numbers or
                            any other personal contact details to communicate
                            outside of Trinity in order to circumvent or abuse
                            the Trinity messaging system or Trinity platform is
                            not permitted.
                        </li>
                        <li>
                            Any necessary exchange of personal information
                            required to continue a service may be exchanged
                            within the Order Page.
                        </li>
                        <li>
                            Trinity does not provide any guarantee of the level
                            of service offered to Buyers. You may use the
                            dispute resolution tools provided to you in the
                            Order Page.
                        </li>
                        <li>
                            Trinity does not provide protection for users who
                            interact outside of the Trinity platform.
                        </li>
                        <li>
                            All information and file exchanges must be performed
                            exclusively on Trinity's platform.
                        </li>
                        <li>
                            Rude, abusive, improper language, or violent
                            messages will not be tolerated and may result in an
                            account warning or the suspension/removal of your
                            account.
                        </li>
                        <li>
                            Trinity is open to everyone. You undertake not to
                            discriminate against any other user based on gender,
                            race, age, religious affiliation, sexual orientation
                            or otherwise and you acknowledge that such
                            discrimination may result in the suspension/removal
                            of your account.
                        </li>
                        <li>
                            Users may not submit proposals or solicit parties
                            introduced through Trinity to contract, engage with,
                            or pay outside of Trinity.
                        </li>
                    </ol>
                    <h3 className="pb-2">Orders</h3>
                    <ol className="m-3">
                        <li>
                            Users with the intention to defame competing Sellers
                            by ordering from competing serviceswill have their
                            reviews removed or further account status related
                            actions determined byreview by our Trust & Safety
                            team.
                        </li>
                        <li>
                            Users are to refrain from spamming or soliciting
                            previous Buyers or Sellers to pursue
                            removing/modifying reviews or cancelling orders that
                            do not align on Order Cancellation or Feedback
                            policies.
                        </li>
                    </ol>
                    <h3 className="pb-2">Services</h3>
                    <ol className="m-3">
                        <li>
                            Users may report Services to Customer Support that
                            may be in violation of Trinity’s Termsof Service
                            based on the reported Service’s replicated
                            similarity to pre-existing services (copycat
                            Services).
                        </li>
                        <li>
                            Sellers warrant that any content included in their
                            Services shall be original work conceived by the
                            Sellers and shall not infringe any third party
                            rights, including, without limitation, copyrights,
                            trademarks or service marks. In the event that
                            certain music or stock-footage media are
                            incorporated within the Services, Sellers represent
                            and warrant that they hold a valid license to use
                            such music and/or footage and to include them in the
                            Services.
                        </li>
                        <li>
                            Trinity will respond to clear and complete notices
                            of alleged copyright or trademark infringement. Our
                            Intellectual Property claims procedures can be
                            reviewed{" "}
                            <a
                                className={styles["links"]}
                                href="javascript:void(0)"
                            >
                                here
                            </a>
                            .
                        </li>
                    </ol>
                    <h3 className="pb-2">Reporting Violations</h3>
                    <p>
                        If you come across any content that may violate our
                        Terms of Service and/or our Community Standards, you
                        should report it to us through the appropriate channels
                        created to handle those issues as outlined in our Terms
                        of Service. All cases are reviewed by our Trust & Safety
                        team. Toprotect individual privacy, the results of the
                        investigation are not shared. You can review our Privacy
                        Policy for more information.
                    </p>
                    <h3 className="pb-2">Violations</h3>
                    <p>
                        Users may receive a warning to their account for
                        violations of our Terms of Service and/or our Community
                        Standards or any user misconduct reported to our Trust
                        and Safety team. A warning will be sent to the user's
                        email address and will be displayed for such user on the
                        Site. Warnings do not limit account activity, but can
                        lead to your account losing Seller statuses or becoming
                        permanently disabled based on the severity of the
                        violation.
                    </p>
                    <h4 className="pb-1">Non-Permitted Usage</h4>
                    <ul>
                        <li>
                            <strong>Adult Services & Pornography</strong>-
                            Trinity does not allow any exchange of adult
                            oriented or pornographic materials and services.
                        </li>
                        <li>
                            <strong>Inappropriate Behavior & Language</strong>-
                            Communication on Trinity should be friendly,
                            constructive, and professional. Trinity condemns
                            bullying, harassment, and hate speech towards
                            others. We allow users a medium for which messages
                            are exchanged between individuals, a system to rate
                            orders, and to engage on larger platforms such as
                            our Community Forum and Social Media pages.
                        </li>
                        <li>
                            <strong>Phishing and Spam</strong>- Members’
                            security is a top priority. Any attempts to publish
                            or send malicious content with the intent to
                            compromise another member’s account or computer
                            environment is strictly prohibited. Please respect
                            our members privacy by not contacting them with
                            offers, questions, suggestions or anything which is
                            not directly related to their Services or orders.
                        </li>
                        <li>
                            <strong>Privacy & Identity</strong>- You may not
                            publish or post other people's private and
                            confidential information. Any exchange of personal
                            information required for the completion of a service
                            must be provided in the Order Page. Sellers further
                            confirm that whatever information they receive
                            fromthe Buyer, which is not public domain, shall not
                            be used for any purpose whatsoever other than for
                            the delivery of the work to the Buyer. Any users who
                            engage and communicate off of Trinity will not be
                            protected by our Terms of Service.
                        </li>
                        <li>
                            <strong>Authentic Trinity Profile</strong>- You may
                            not create a false identity on Trinity, misrepresent
                            your identity, create a Trinity profile for anyone
                            other than yourself (a real person), or use or
                            attempt to use another user’s account or
                            information; Your profile information, including
                            your description, skills, location, etc., while may
                            be kept anonymous, must be accurate and complete and
                            may not be misleading, illegal, offensive or
                            otherwise harmful. Trinity reserves the right to
                            require users to go through a verification process
                            in order to use the Site (whether by using ID,
                            phone, camera, etc.).
                        </li>
                        <li>
                            <strong>Intellectual Property Claims</strong>-
                            Trinity will respond to clear and complete notices
                            of alleged copyright or trademark infringement,
                            and/or violation of third party’s terms of service.
                            Our Intellectual Property claims procedures can be
                            reviewed{" "}
                            <a
                                className={styles["links"]}
                                href="javascript:void(0)"
                            >
                                here
                            </a>
                            .
                        </li>
                        <li>
                            <strong>Fraud / Unlawful Use</strong>- You may not
                            use Trinity for any unlawful purposes or to conduct
                            illegal activities.
                        </li>
                    </ul>
                    <h4 className="py-3">Abuse and Spam</h4>
                    <ul>
                        <li>
                            <strong>Multiple Accounts</strong>- To prevent fraud
                            and abuse, users are limited to one active Trinity
                            account and one active Trinity Business account. Any
                            additional account determined to be created to
                            circumvent guidelines, promote competitive
                            advantages, or mislead the Trinity community will be
                            disabled. Mass account creation may result in
                            disabling of all related accounts. Note: any
                            violations of Trinity’s Terms of Service and/or our
                            Community Standards is a cause for
                            permanentsuspension of all accounts.
                        </li>
                        <li>
                            <strong>Targeted Abuse</strong>- We do not tolerate
                            users who engage in targeted abuse or harassment
                            towards other users on Trinity. This includes
                            creating new multiple accounts to harass members
                            through our message or ordering system.
                        </li>
                        <li>
                            <strong>Selling Accounts</strong>- You may not buy
                            or sell Trinity accounts.
                        </li>
                    </ul>
                    <h4 className="py-3">Proprietary Restrictions</h4>
                    <p>
                        The Site, including its general layout, look and feel,
                        design, information, content and other materials
                        available thereon, is exclusively owned by Trinity and
                        protected by copyright, trademark, and other
                        intellectual property laws. Trinity, Service and
                        Services are all registered trademarks owned exclusively
                        by Trinity. Users have no right, and specifically agree
                        not to do the following with respect to the Site or any
                        part, component or extension of the Site (including its
                        mobile applications): (i) copy, transfer, adapt, modify,
                        distribute, transmit, display, create derivative works,
                        publish or reproduce it, in any manner; (ii) reverse
                        assemble, decompile, reverse engineer or otherwise
                        attempt to derive its source code, underlying ideas,
                        algorithms, structure or organization; (iii) remove any
                        copyright notice, identification or any other
                        proprietary notices; (iv) use automation software
                        (bots), hacks, modifications (mods) or any other
                        unauthorized third-party software designed to modify the
                        Site; (v) attempt to gain unauthorized access to,
                        interfere with, damage or disrupt the Site or the
                        computer systems or networks connected to the Site; (vi)
                        circumvent, remove, alter, deactivate, degrade or thwart
                        any technological measure or content protections of the
                        Site; (vii) use any robot, spider, crawlers or other
                        automatic device, process, software or queries that
                        intercepts, “mines,” scrapes or otherwise accesses the
                        Site to monitor, extract, copy or collect information or
                        data from or through the Site, or engage in any manual
                        process to do the same, (viii) introduce any viruses,
                        trojan horses, worms, logic bombs or other materials
                        that are malicious or technologically harmfulinto our
                        systems, (ix) use the Site in any manner that could
                        damage, disable, overburden or impair the Site, or
                        interfere with any other users’ enjoyment of the Site or
                        (x) access or use the Site in any way not expressly
                        permitted by these Terms of Service. Users also agree
                        not to permitor authorize anyone else to do any of the
                        foregoing.Except for the limited right to use the Site
                        according to these Terms of Service, Trinity owns all
                        right, title and interest in and to the Site (including
                        any and all intellectual property rights therein) and
                        you agree not to take any action(s) inconsistent with
                        such ownership interests. We reserve allrights in
                        connection with the Site and its content (other than
                        UGC) including, without limitation, theexclusive right
                        to create derivative works.
                    </p>
                    <h4 className="py-3">Feedback Rights</h4>
                    <p>
                        To the extent that you provide Trinity with any
                        comments, suggestions or other feedback regarding the
                        Trinity platform or the Site as a whole, as well as
                        other Trinity products or services (collective, the
                        “Feedback”), you will be deemed to have granted Trinity
                        an exclusive, royalty-free, fully paid up, perpetual,
                        irrevocable, worldwide ownership rights in the Feedback.
                        Trinity is under no obligation to implement any Feedback
                        it may receive from users.
                    </p>
                    <h4 className="py-3">Confidentiality</h4>
                    <p>
                        Sellers should recognize that there might be a need for
                        Buyers to disclose certain confidential information to
                        be used by Sellers for the purpose of delivering the
                        ordered work, and to protect such confidential
                        information from unauthorized use and disclosure.
                        Therefore, Sellers agree to treat any information
                        received from Buyers as highly sensitive, top secret and
                        classified material. Without derogating from the
                        generality of the above, Sellers specifically agree to
                        (i) maintain all such information in strict confidence;
                        (ii) not disclose the information to any third parties;
                        (iii) not use the information for any purpose except for
                        delivering the ordered work; and (vi) not to copy
                        orreproduce any of the information without the Buyer’s
                        permission.
                    </p>
                    <h3 className="pb-2">General Terms</h3>
                    <ol className="m-3">
                        <li>
                            Trinity reserves the right to put any account on
                            hold or permanently disable accounts due to breach
                            of these Terms of Service and/or our Community
                            Standards or due to any illegal or inappropriate use
                            of the Site or services.
                        </li>
                        <li>
                            Violation of Trinity's Terms of Service and/or our
                            Community Standards may get your account disabled
                            permanently.
                        </li>
                        <li>
                            Users with disabled accounts will not be able to
                            sell or buy on Trinity.
                        </li>
                        <li>
                            Users who have violated our Terms of Service and/or
                            our Community Standards and had their account
                            disabled may contact our Customer Support team for
                            more information surrounding the violation and
                            status of the account.
                        </li>
                        <li>
                            Users have the option to enable account Security
                            features to protect their account from any
                            unauthorized usage.
                        </li>
                        <li>
                            Users must be able to verify their account ownership
                            through Customer Support by providing materials that
                            prove ownership of that account.
                        </li>
                        <li>
                            Disputes should be handled using Trinity's dispute
                            resolution tools ('Resolution Center' onthe order
                            page) or by contacting Trinity Customer Support.
                        </li>
                        <li>
                            Trinity may make changes to its Terms of Service
                            from time to time. When these changesare made,
                            Trinity will make a new copy of the terms of service
                            available on this page.
                        </li>
                        <li>
                            You understand and agree that if you use Trinity
                            after the date on which the Terms of Service have
                            changed, Trinity will treat your use as acceptance
                            of the updated Terms of Service.
                        </li>
                    </ol>
                    <h3 className="pb-2">User Generated Content</h3>
                    <p>
                        User Generated Content ("UGC") refers to the content
                        added by users as opposed to content created by the
                        Site. All content uploaded to Trinity by our users
                        (Buyers and Sellers) is User Generated Content. Trinity
                        does not check user uploaded/created content for
                        appropriateness, violations of copyright, trademarks,
                        other rights or violations and the user
                        uploading/creating suchcontent shall be solely
                        responsible for it and the consequences of using,
                        disclosing, storing, or transmitting it. By uploading
                        to, or creating content on, the Trinity platform, you
                        represent and warrant that you own or have obtained all
                        rights, licenses, consents, permissions, power and/or
                        authority, necessary to use and/or upload such content
                        and that such content or the use thereof in the Site
                        does not and shall not (a) infringe or violate any
                        intellectual property, proprietary or privacy, data
                        protection or publicity rights of any third party; (b)
                        violate any applicable local, state, federal and
                        international laws, regulations and conventions; and/or
                        (c) violate any of your or third party’s policies and/or
                        terms of service. We invite everyone to report
                        violations together with proofof ownership as
                        appropriate. Reported violating content may be removed
                        or disabled.Furthermore, Trinity is not responsible for
                        the content, quality or the level of service provided by
                        the Sellers (even if they are Pro Sellers, Top Rated
                        Sellers, offer Promoted Services or otherwise). We
                        provide no warranty with respect to the Services, their
                        delivery, any communications between Buyers and Sellers,
                        and the Logo Designs created through the Game
                        Customizer. We encourage users to take advantage of our
                        rating system, our community and common sense in
                        choosing appropriate services.By offering a service, the
                        Seller undertakes that he/she has sufficient
                        permissions, rights and/or licenses to provide, sell or
                        resell the service that is offered on Trinity. Sellers
                        advertising online their Service or Logo Design created
                        through the Game Customizer must comply with laws and
                        terms of service of the advertising platform or relevant
                        website used to advertise. Failing to do so may result
                        in removal of the Service or Logo Design from the Game
                        Customizer, as applicable, and may lead to the
                        suspension of Seller's account.For specific terms
                        related to Intellectual Property rights and for
                        reporting claims of copyright infringement (DMCA
                        notices) or trademark infringement - please see our{" "}
                        <a
                            className={styles["links"]}
                            href="javascript:void(0)"
                        >
                            Intellectual Property Claims Policy
                        </a>{" "}
                        which forms an integral part of these Terms of Service.
                        Note that it is our policy in appropriate circumstances
                        to disable and/or terminate the accounts of users who
                        are repeat infringers.
                    </p>
                    <h3 className="pb-2">Ownership</h3>
                    <p>
                        Ownership and limitations: When purchasing a Service on
                        Trinity, unless clearly stated otherwise on the Seller's
                        Service page/description, when the work is delivered,
                        and subject to payment, the Buyer is granted all
                        intellectual property rights, including but not limited
                        to, copyright in the work delivered from the Seller, and
                        the Seller waives any and all moral rights therein.
                        Accordingly, the Seller expressly assigns to the Buyer
                        the copyright in the delivered work. All transfer and
                        assignment of intellectual property to the Buyer shall
                        be subject to full payment for the Service, and the
                        delivery may not be used if payment is cancelled for any
                        reason. For removal of doubt, in custom created work
                        (such as art work, design work, report generation etc.),
                        the delivered work and its copyright shall be the
                        exclusive property of the Buyer and, upon delivery, the
                        Seller agrees that it thereby, pursuant to these Terms
                        of Service, assigns all right, title and interest in and
                        to the delivered work to the Buyer. Some Services
                        (including for custom created work) charge additional
                        payments (through Service Extras) for a Commercial Use
                        License. This means that if you purchase the Service for
                        personal use, you will own all rights you require for
                        such use, and will not need the Commercial Use License.
                        If you intend to use it for any charge or other
                        consideration, or for any purpose that is directly or
                        indirectly in connection with any business, or other
                        undertaking intended for profit, you will need to buy
                        the Commercial Use License through a Service Extra and
                        will have broader rights that cover your business
                        use.For Voice Over Services, when the work is delivered,
                        and subject to payment, the Buyer is purchasing basic
                        rights, (which means the Buyer is paying a one time fee
                        allowing them to use the work forever and for any
                        purpose except for commercials, radio, television and
                        internet commercial spots). If you intend to use the
                        Voice Over to promote a product and/or service (with the
                        exception of paid marketing channels), you will need to
                        purchase the Commercial Rights (Buy-Out) through Service
                        Extra. If you intend to use the Voice Over in radio,
                        television and internet commercials, you will need to
                        purchase the Full Broadcast Rights (Buy-Out) through
                        Service Extra. For further information on the type of
                        buy-outs, please read below.Furthermore, users (both
                        Buyers and Sellers) agree that unless they explicitly
                        indicate otherwise, the content users voluntarily
                        create/upload to Trinity, including Service texts,
                        photos, videos, usernames, user photos, user videos and
                        any other information, including the display of
                        deliveredwork, may be used by Trinity for no
                        consideration for marketing and/or other purposes.
                    </p>
                    <h3 className="pb-2">Service Commercial Use License</h3>
                    <p>
                        By purchasing a “Commercial Use License” with your
                        Service Order, the Seller grants you a perpetual,
                        exclusive, non-transferable, worldwide license to use
                        the purchased delivery for Permitted Commercial
                        Purposes. For the avoidance of doubt, the Seller retains
                        all ownership rights. “Permitted Commercial Purposes”
                        means any business related use, such as (by way of
                        example) advertising, promotion, creating web pages,
                        integration into product, software or other business
                        related tools etc., and strictly excludes any illegal,
                        immoral or defamatory purpose. This License is subject
                        to Trinity’s Terms of Service. There is no warranty,
                        express or implied, with the purchase of this delivery,
                        including with respect to fitness for a particular
                        purpose. Neither the Seller nor Trinity will be liable
                        for any claims, or incidental, consequential or other
                        damages arising out of this license, the delivery or
                        your use of the delivery.
                    </p>
                    <h3 className="pb-2">Disclaimer of Warranties</h3>
                    <p>
                        YOUR USE OF THE SITE, ITS CONTENT AND ANY SERVICES OR
                        ITEMS OBTAINED THROUGH THE SITE IS AT YOUR OWN RISK. THE
                        SITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED
                        THROUGH THE SITE ARE PROVIDED ON AN "AS IS" AND "AS
                        AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND,
                        EITHER EXPRESS ORIMPLIED. NEITHER TRINITY NOR ANY PERSON
                        ASSOCIATED WITH TRINITY MAKES ANY WARRANTY OR
                        REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
                        SECURITY,RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY
                        OF THE SITE.THE FOREGOING DOES NOT AFFECT ANY WARRANTIES
                        WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE
                        LAW.
                    </p>
                    <h3 className="pb-2">Machine Translation</h3>
                    <p>
                        Certain user-generated content on the Site has been
                        translated for your convenience using translation
                        software powered by Amazon Translate. Reasonable efforts
                        have been made to provide an accurate translation,
                        however, no automated translation is perfect nor is it
                        intended to replace human translators. Such translations
                        are provided as a service to users of the Site, and are
                        provided "as is". No warranty of any kind, either
                        expressed or implied, is made as to the accuracy,
                        reliability, or correctness of such translations made
                        from English into any other language. Some
                        user-generated content (such as images, videos, Flash,
                        etc.) may not be accurately translated or translated at
                        all due to the limitations of the translation
                        software.The official text is the English version of the
                        Site. Any discrepancies or differences created in the
                        translation are not binding and have no legal effect for
                        compliance or enforcement purposes. If any questions
                        arise related to the accuracy of the information
                        contained in the translated content, please refer to the
                        English version of the content which is the official
                        version.
                    </p>
                    <h3 className="pb-2">Limitation on Liability</h3>
                    <p>
                        IN NO EVENT WILL TRINITY, ITS AFFILIATES OR THEIR
                        LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS,
                        OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND,
                        UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION
                        WITH YOUR USE, OR INABILITY TO USE, THE SITE, ANY
                        WEBSITES LINKEDTO IT, ANY CONTENT ON THE SITE OR SUCH
                        OTHER WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH
                        THE SITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT,
                        INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE
                        DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY,
                        PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE,
                        LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED
                        SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA,
                        AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE),
                        BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.THE
                        FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE
                        EXCLUDED OR LIMITED UNDER APPLICABLE LAW.The term
                        “Affiliate” referred to herein, is an entity that,
                        directly or indirectly, controls, or is under the
                        control of, or is under common control with Trinity,
                        where control means having more than fiftypercent (50%)
                        voting stock or other ownership interest or the majority
                        of voting rights of such entity.
                    </p>
                </div>
            </div>
        </Fragment>
    );
};

export default ServiceTerms;
