import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Dialog } from "@material-ui/core";
// import { useHistory } from "react-router-dom";

// import { addProfileReview } from "../../../actions/profile";
// import { setAlert } from "../../../actions/alert";
import {
	CustomizedDialogContent,
	CustomizedDialogTitle,
} from "../CustomizedModal/CustomizedModal";
import LoadingButton from "../../utils/LoadingButton/LoadingButton";
import { addProfileReview } from "../../../actions/profile";
import { setAlert } from "../../../actions/alert";

const ReviewModal = ({ isOpen, onClose, projectId, reviewedTo, reviewAs }) => {
	const dispatch = useDispatch();

	const [review, setReview] = useState("");
	const [ratingComm, setRatingComm] = useState(null);
	const [ratingService, setRatingService] = useState(null);
	const [ratingRecommend, setRatingRecommend] = useState(null);
	const [hover1, setHover1] = useState(null);
	const [hover2, setHover2] = useState(null);
	const [hover3, setHover3] = useState(null);
	const [saving, setSaving] = useState(false);
	// const history = useHistory();

	useEffect(() => {
		if (isOpen) {
			console.log(projectId, reviewedTo);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	const onSubmitReview = async () => {
		setSaving(true);

		const ratings = { communication: ratingComm };

		if (reviewAs === "developer") {
			ratings["Service_as_Described"] = ratingService;
			ratings["Buy_Again_or_Recommend"] = ratingRecommend;
		}

		const response = await dispatch(
			addProfileReview(reviewedTo, projectId, review, reviewAs, ratings)
		);
		if (response.status) {
			dispatch(setAlert(response.msg, "success"));
			onClose(false);
		} else {
			dispatch(setAlert(response.msg, "danger"));
		}

		setSaving(false);
	};

	return (
		<Fragment>
			<Dialog
				onClose={() => onClose(false)}
				open={isOpen}
				maxWidth="sm"
				fullWidth={true}
			>
				<CustomizedDialogTitle>
					<h3>Submit A Review</h3>
				</CustomizedDialogTitle>
				<CustomizedDialogContent>
					<form>
						<table className="table">
							<tbody>
								<tr>
									<td className="align-middle">
										<label className="p-0">
											Communication :
										</label>
									</td>
									<td>
										{[...Array(5)].map((star, i) => {
											const ratingValue1 = i + 1;
											return (
												<label
													name="communication"
													key={i}
												>
													<input
														type="radio"
														name="ratingcomm"
														value={ratingValue1}
														onClick={() =>
															setRatingComm(
																ratingValue1
															)
														}
														style={{
															display: "none",
														}}
													/>
													<div className="mt-2">
														<span
															style={
																ratingValue1 <=
																(hover1 ||
																	ratingComm)
																	? {
																			color: "#ffc107",
																	  }
																	: {
																			color: "#e4e5e9",
																	  }
															}
															onMouseEnter={() =>
																setHover1(
																	ratingValue1
																)
															}
															onMouseLeave={() =>
																setHover1(null)
															}
															className="material-icons"
														>
															star
														</span>
													</div>
												</label>
											);
										})}
									</td>
								</tr>
								{reviewAs === "developer" && (
									<>
										<tr>
											<td className="align-middle">
												<label className="p-0">
													Service as Described :
												</label>
											</td>
											<td>
												{[...Array(5)].map(
													(star, i) => {
														const ratingValue2 =
															i + 1;
														return (
															<label
																name="service"
																key={i}
															>
																<input
																	type="radio"
																	name="ratingservice"
																	value={
																		ratingValue2
																	}
																	onClick={() =>
																		setRatingService(
																			ratingValue2
																		)
																	}
																	style={{
																		display:
																			"none",
																	}}
																/>
																<div className="mt-2">
																	<span
																		style={
																			ratingValue2 <=
																			(hover2 ||
																				ratingService)
																				? {
																						color: "#ffc107",
																				  }
																				: {
																						color: "#e4e5e9",
																				  }
																		}
																		onMouseEnter={() =>
																			setHover2(
																				ratingValue2
																			)
																		}
																		onMouseLeave={() =>
																			setHover2(
																				null
																			)
																		}
																		className="material-icons"
																	>
																		star
																	</span>
																</div>
															</label>
														);
													}
												)}
											</td>
										</tr>
										<tr>
											<td className="align-middle">
												<label className="p-0">
													Buy Again or Recommend :
												</label>
											</td>
											<td>
												{[...Array(5)].map(
													(star, i) => {
														const ratingValue3 =
															i + 1;
														return (
															<label
																name="communication"
																key={i}
															>
																<input
																	type="radio"
																	name="ratingRecommend"
																	value={
																		ratingValue3
																	}
																	onClick={() =>
																		setRatingRecommend(
																			ratingValue3
																		)
																	}
																	style={{
																		display:
																			"none",
																	}}
																/>
																<div className="mt-2">
																	<span
																		style={
																			ratingValue3 <=
																			(hover3 ||
																				ratingRecommend)
																				? {
																						color: "#ffc107",
																				  }
																				: {
																						color: "#e4e5e9",
																				  }
																		}
																		onMouseEnter={() =>
																			setHover3(
																				ratingValue3
																			)
																		}
																		onMouseLeave={() =>
																			setHover3(
																				null
																			)
																		}
																		className="material-icons"
																	>
																		star
																	</span>
																</div>
															</label>
														);
													}
												)}
											</td>
										</tr>
									</>
								)}
							</tbody>
						</table>
						<div className="mb-2">
							<label className="col-form-label">Review</label>
							<textarea
								className="dark-input"
								id="message-text"
								rows="3"
								placeholder="Write your review..."
								value={review}
								onChange={(e) => {
									setReview(e.target.value);
								}}
							></textarea>
						</div>
						<div className="mb-2 text-end">
							{reviewAs === "developer" && (
								<button
									type="button"
									className="btn btn-primary-gradient outline me-2"
									onClick={() => {
										onClose(false);
									}}
								>
									Skip
								</button>
							)}
							<LoadingButton
								text="Submit"
								className="btn btn-primary-gradient"
								onClick={onSubmitReview}
								isLoading={saving}
							/>
						</div>
					</form>
				</CustomizedDialogContent>
			</Dialog>
		</Fragment>
	);
};

export default ReviewModal;
