import { getApiEndpoint } from "../utils/getApiEndpoint";
import { requestWithToken } from "../utils/httpRequest";
import { error } from "./error";

// import { fetchCurrentUserProfile } from "./profile";

// Get Active Plans
export const getActivePlans = () => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/plan/active`
        );

        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Buy Selected Plan
export const buyPlan = (planId, paymentMode) => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "POST",
            `${getApiEndpoint()}/plan/buy`,
            { planId, paymentMode }
        );

        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Buy selected Plan by Paypal
// export const buyPlanPaypal = (packId, paymentMode) => async (dispatch) => {
//     try {
//         const config = {
//             headers: {
//                 "Content-Type": "application/json",
//             },
//         };

//         const body = JSON.stringify({
//             packageId: packId,
//             paymentMode,
//         });

//         const response = await requestWithToken(
//             "GET",
//             `${getApiEndpoint()}/payment/plan-payment`,
//             body
//         );

//         return response.data;
//     } catch (err) {
//         const errors = err.response.data.errors;

//         if (errors) {
//             errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
//         }
//     }
// };
