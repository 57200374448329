import { getApiEndpoint } from "../utils/getApiEndpoint";
import { requestWithToken } from "../utils/httpRequest";
import { setAlert } from "./alert";

import { ON_ERROR } from "./types";

// Fetch Users Transactions
export const getTransactions = () => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/transactions`
        );

        return response.data;
    } catch (err) {
        dispatch({
            type: ON_ERROR,
            payload: null,
        });
        return err.response.data;
    }
};

// Make Stripe Transaction
export const postStripe =
    (amount, redirectURL, transactionType) => async (dispatch) => {
        const body = { amount, redirectURL, transactionType };

        try {
            const response = await requestWithToken(
                "POST",
                `${getApiEndpoint()}/stripe`,
                body
            );

            return response.data;
        } catch (err) {
            dispatch({
                type: ON_ERROR,
                payload: null,
            });
            return err.response.data;
        }
    };

//Download Transactions
export const downloadTransactions = () => async (dispatch) => {
    try {
        const res = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/transactions/generate-pdf`
        );

        if (res.status === 200) {
            return res.data.result;
        }
    } catch (err) {
        const errors = err.response.data.errors;

        if (errors) {
            errors.forEach((error) => setAlert(error.msg, "danger"));
        }
    }
};
