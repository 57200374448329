import React, { Fragment } from "react";
import Skeleton from "../../Skeleton/Skeleton";

const ProfileOverviewSkeleton = () => {
    return (
        <Fragment>
            <section className={`light-bg p-4 my-lg-3 mb-3 text-center`}>
                <div className="px-5">
                    <Skeleton shape="circle" height="120px" />
                </div>
                <h5 className="text-capitalize mt-4">
                    <Skeleton />
                </h5>
                <div className={`mt-2`}>
                    <div className={`d-flex justify-content-center`}>
                        {/* {profileRating()} */}
                    </div>
                </div>
                <div className={`mt-1`}>
                    <Skeleton />
                </div>

                <div className={`mt-4`}>
                    <div className={`mt-1`}>
                        <p>
                            <Skeleton />
                        </p>
                    </div>
                    <div className={`mt-1`}>
                        <p>
                            <Skeleton />
                        </p>
                    </div>
                    <div className={`mt-1`}>
                        <p>
                            <Skeleton />
                        </p>
                    </div>
                    <div className={`mt-1`}>
                        <p>
                            <Skeleton />
                        </p>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default ProfileOverviewSkeleton;
