import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setAlert } from "../../actions/alert";
import {
    applyReferralCode,
    fetchMyActiveReferral,
    fetchMyReferrals,
} from "../../actions/referral";
import LoadingButton from "../../components/utils/LoadingButton/LoadingButton";
import styles from "../../pages/Login/login.module.css";

//import WebLoader from "./WebLoader";
const Refer = () => {
    const dispatch = useDispatch();
    const authState = useSelector((state) => state.auth);

    const [isFormLoading, setIsFormLoading] = useState(false);
    const [referrals, setReferrals] = useState([]);
    const [activeReferral, setActiveReferral] = useState(null);
    const [formData, setFormData] = useState({
        code: "",
    });

    const { code } = formData;

    useEffect(() => {
        const fetchReferrals = async () => {
            const referralsResponse = await dispatch(fetchMyReferrals());
            if (referralsResponse.status) {
                setReferrals(referralsResponse.data);
            }
        };

        fetchReferrals();
        fetchActiveReferral();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchActiveReferral = async () => {
        const response = await dispatch(fetchMyActiveReferral());
        if (response.status && response.data) {
            setActiveReferral(response.data);
        }
    };

    const handleClick = (copyText) => {
        navigator.clipboard.writeText(copyText);
        dispatch(setAlert("Code copied!", "success"));
    };

    const onSubmitReferralCode = async (e) => {
        e.preventDefault();
        setIsFormLoading(true);

        const response = await dispatch(applyReferralCode(formData));
        if (response.status) {
            fetchActiveReferral();
            dispatch(setAlert(response.msg, "success"));
        } else {
            dispatch(setAlert(response.msg, "danger"));
        }

        setIsFormLoading(false);
    };

    return (
        <Fragment>
            <div className="container form-page-layout">
                <div className="row py-5">
                    <div className="col-md-6">
                        <div
                            className="box-shadow light-bg px-3 py-2 w-100"
                            id={`${styles["login-box"]}`}
                        >
                            <div className="px-lg-2 m-0">
                                <section className="recharge-wallet light-bg p-4 my-lg-3">
                                    <h4 className=" text-center mb-2">
                                        Your Referral Code
                                    </h4>
                                    <hr></hr>
                                    <form className="d-flex">
                                        <div className="form-group flex-grow-1">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={
                                                    authState?.user?.profile
                                                        ?.referralCode
                                                }
                                                placeholder="Your Referral Code"
                                                readOnly
                                            />
                                        </div>
                                        <button
                                            type="button"
                                            className="d-flex align-items-center justify-content-center btn btn-primary-gradient ms-3"
                                            onClick={(e) =>
                                                handleClick(
                                                    authState?.user?.profile
                                                        ?.referralCode
                                                )
                                            }
                                        >
                                            <span className="material-icons text-white">
                                                content_copy
                                            </span>
                                        </button>
                                    </form>
                                </section>
                            </div>
                        </div>
                        <div
                            className="box-shadow light-bg px-3 py-2 w-100 my-5"
                            id={`${styles["login-box"]}`}
                        >
                            <div className="px-lg-2 m-0">
                                <section className="recharge-wallet light-bg p-4 my-lg-3">
                                    <ul
                                        className="d-flex nav nav-pills mb-3"
                                        id="pills-tab"
                                        role="tablist"
                                    >
                                        <li
                                            className="nav-item flex-grow-1"
                                            role="presentation"
                                        >
                                            <button
                                                className="nav-link active w-100"
                                                id="pills-benefits-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-benefits"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-benefits"
                                                aria-selected="true"
                                            >
                                                Benefits
                                            </button>
                                        </li>
                                        <li
                                            className="nav-item flex-grow-1"
                                            role="presentation"
                                        >
                                            <button
                                                className="nav-link w-100"
                                                id="pills-working-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-working"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-working"
                                                aria-selected="false"
                                            >
                                                How It Works
                                            </button>
                                        </li>
                                    </ul>
                                    <hr></hr>
                                    <div
                                        className="tab-content"
                                        id="pills-tabContent"
                                    >
                                        <div
                                            className="tab-pane fade show active"
                                            id="pills-benefits"
                                            role="tabpanel"
                                            aria-labelledby="pills-benefits-tab"
                                        >
                                            <ol>
                                                <li>
                                                    Get percentage on every
                                                    transaction for the user who
                                                    you referred to.
                                                </li>
                                                <li>
                                                    Refer as much users you can
                                                    to generate great passive
                                                    income.
                                                </li>
                                            </ol>
                                        </div>
                                        <div
                                            className="tab-pane fade"
                                            id="pills-working"
                                            role="tabpanel"
                                            aria-labelledby="pills-working-tab"
                                        >
                                            <ol>
                                                <li>Copy the referral code.</li>
                                                <li>
                                                    Share the referral code to
                                                    your friends and family by
                                                    any medium.
                                                </li>
                                                <li>
                                                    Ask them to use your
                                                    referral code on this page.
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        {!activeReferral && (
                            <div
                                className="box-shadow light-bg px-3 py-2 w-100 mb-5"
                                id={`${styles["login-box"]}`}
                            >
                                <div className="px-lg-2 m-0">
                                    <section className="recharge-wallet light-bg p-4 my-lg-3">
                                        <h4 className=" text-center mb-2">
                                            Enter Your Friend's Referral Code
                                        </h4>
                                        <hr></hr>
                                        <form
                                            onSubmit={(e) =>
                                                onSubmitReferralCode(e)
                                            }
                                            className="d-flex"
                                        >
                                            <div className="form-group flex-grow-1">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="code"
                                                    value={code}
                                                    onChange={(e) =>
                                                        setFormData({
                                                            ...formData,
                                                            [e.target.name]:
                                                                e.target.value,
                                                        })
                                                    }
                                                    placeholder="Enter Referral Code"
                                                    required
                                                />
                                            </div>
                                            <LoadingButton
                                                isLoading={isFormLoading}
                                                text={
                                                    <span className="material-icons text-white">
                                                        arrow_forward_ios
                                                    </span>
                                                }
                                                className="d-flex align-items-center justify-content-center btn btn-primary-gradient ms-3"
                                            />
                                        </form>
                                    </section>
                                </div>
                            </div>
                        )}
                        <div
                            className="box-shadow light-bg px-3 py-2 w-100 mb-5"
                            id={`${styles["login-box"]}`}
                        >
                            <div className="px-lg-2 m-0">
                                <section className="recharge-wallet light-bg p-4 my-lg-3">
                                    <h4 className=" text-center mb-2">
                                        Referred Users
                                    </h4>
                                    <hr></hr>
                                    <ul className="p-0">
                                        {referrals.length > 0 ? (
                                            referrals.map((referral, index) => {
                                                return (
                                                    <li key={index}>
                                                        {
                                                            referral.referredTo
                                                                .name
                                                        }
                                                    </li>
                                                );
                                            })
                                        ) : (
                                            <Fragment>
                                                <p className="text-center text-muted my-5">
                                                    No Referred Users Found!
                                                </p>
                                            </Fragment>
                                        )}
                                    </ul>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Refer;
