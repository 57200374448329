import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./PaymentTerms.module.css";
const PaymentTerms = () => {
  return (
    <Fragment>
      <div style={{paddingTop: "7rem", paddingBottom: "4rem" }}>
        <div
          className="container"
          style={{ color: "#fff", paddingLeft: "40px" }}
        >
          <h1 className={styles["heading"]}>Terms Of Payment</h1>
          <h3>Last Updated: August 2021</h3>
          <ol className="m-3">
            <li>Receiving Payments</li>
            <li>Purchasing</li>
            <li>Taxes</li>
            <li>Disputes and Cancellations</li>
            <li>Order Cancellations</li>
            <li>General Terms</li>
          </ol>

          <p>
            The following terms (these “Payment Terms”) govern payments you make
            or receive through www.TrinityCrew.com (the “Site”), operated by
            NeoxEntertainment. (Am Jägerstuhl 10 37574 Einbeck).
          </p>
          <p>
            Trinity and NeoxEntertainment, are collectively referred hereto as
            “Trinity” “we” or “us”.
          </p>
          <p>
            Please read these Payment Terms carefully before making or receiving
            payments through the Site. By making or receiving payments through
            the Site, you accept and agree, on behalf of yourself or on behalf
            of your employer or any other entity (if applicable), to be bound
            and abide by these Payment Terms. TheseTerms are supplemental to
            Trinity’s General Terms of Service and Trinity Business Terms of
            Service, as applicable (collectively, the “Terms of Service”).
          </p>
          <h3>Key Terms</h3>
          <h4>
            Capitalized terms used but not defined herein shall have the
            respective meanings given to them in the Terms of Service.
          </h4>

          <p>
            {" "}
            <strong>Trinity Balance</strong> is the aggregated amount of your
            Revenue as a Seller and/or returned payments from canceled orders as
            a Buyer and/or your Trinity Business Balance (as defined in the
            Trinity Business Terms of Service).
          </p>
          <p>
            {" "}
            <strong>Payment Services Provider(s)</strong> are service providers
            that provide payment services to Buyers and Sellers in connection
            with the Trinity platform, including with respect to collection of
            funds from Buyers in connection with purchases, remittance and
            withdrawal of funds to Sellers, currency exchange services in
            connection with payments and withdrawals in local currencies, and
            the holding of funds in connection with Trinity Balances.
          </p>
          <p>
            {" "}
            <strong>Revenue</strong> is the money that Sellers earn from
            completed orders and can either withdraw or use to purchase on
            Trinity, subject to these Terms
          </p>

          <h2 className="py-3">Receiving Payments</h2>
          <h3>General</h3>
          <ol className="m-3">
            <li>
              Each Service you sell and successfully complete, accredits your
              account with revenue equal to 90% of the purchase amount.
            </li>
            <li>
              Trinity accredits Sellers once an order is completed. See the
              "Orders” section in the Terms of Service for a definition of a
              completed order.
            </li>
            <li>
              If an order is canceled (for any reason), the funds paid will be
              returned to the Buyer’s Trinity Balance.
            </li>
            <li>
              Sellers are responsible for paying any direct or indirect taxes,
              including any GST, VAT or income tax, which may apply to them
              depending on residency, location or otherwise, under provisions of
              their jurisdiction. Sellers represent and warrant that they
              comply, and will comply at all times, with their obligations under
              income tax provisions in their jurisdiction. The price shown on
              the Service page or on the Game Customizer order page is inclusive
              of all such taxes and charges that may apply to the Sellers.
            </li>
            <li>
              Appointment as Limited Payment Collection Agent: Seller hereby
              appoints Trinity as Seller’s limited authorized payment collection
              agent solely for the purpose of accepting payments (via its
              Payment Services Provider, if applicable) from Buyer, and
              remitting those payments to Seller. Seller agrees that payment
              from Buyer to Trinity shall be considered the same asa made
              directly to Seller. Buyer’s payment obligation to Seller will be
              satisfied upon receipt of payment by Trinity (or its Payment
              Services Provider, as applicable), and Trinity (via its Payment
              Services Provider, as applicable) is responsible for remitting the
              funds to the Seller in the manner described in these Payment
              Terms. In the event that Trinity (via Payment Services Provider)
              does not remit any such amounts to Seller, the Seller will have
              recourse only against Trinity and not the Buyer directly. Seller
              agrees that Trinity may describe or otherwise reflect the terms
              contained herein in any terms of service, receipts, disclosures,
              or notices including, but not limited to, receipts provided to
              Buyers that Trinity may deem necessary or prudent.
            </li>
            <li>
              Trinity partners with Payment Services Providers for purposes of
              collecting payments from Buyers, transferring such payments from
              Buyers to Sellers, and holding funds in connection with Trinity
              Balances. All payments services in connection with the withdrawal
              of funds on the Trinity platform are performed by Trinity’s
              Payment Services Providers.
            </li>
          </ol>
          <h3>Withdrawing Revenues</h3>
          <ol className="m-3">
            <li>
              To withdraw your revenue, you must have an account with at least
              one of Trinity's Payment Service Providers for the withdrawal
              methods listed below. All funds eligible for Withdrawal will be
              held on your behalf at an account with Trinity’s Payment Services
              Provider. All payment services, including withdrawal services,
              will be provided by Trinity’s Payment Services Provider.
            </li>
            <li>
              Your Trinity profile can be associated with only one account from
              each Trinity withdrawal method. A Payment Service Provider
              withdrawal account can be associated with only one Trinity
              profile.
            </li>
            <li>
              Revenues are only made available for withdrawal from the Revenue
              page following a safety clearance period of 14 days after the
              order is marked as complete.
            </li>
            <li>
              Sellers may withdraw their revenues using one ofTrinity’s
              withdrawal options (see Withdrawal Section below regarding payment
              terms). To withdraw your available revenue, you must click on the
              designated withdrawal provider to initiate the withdrawal process.
            </li>
            <li>
              For security concerns, Trinity may temporarily disable a Seller’s
              ability to withdraw revenue to prevent fraudulent or illicit
              activity. This may come as a result of security issues, improper
              behavior reported by other users, or associating multiple Trinity
              accounts to a single withdrawal provider.
            </li>
            <li>
              Withdrawals can only be made in the amount available to you.
            </li>
            <li>Withdrawal fees vary depending on the withdrawal method.</li>
            <li>
              Withdrawals are final and cannot be undone. We will not be able to
              reverse this process once it has begun.
            </li>
            <li>
              In certain cases, Sellers may withdraw Revenues in several
              different currencies. All currency exchange services in connection
              with such withdrawals are performed by Trinity’s Payment Services
              Providers. Your Trinity Balance is always derived from its US$
              value and, therefore, the local currency amount of your Trinity
              Balance may change daily in accordance with exchange rate
              fluctuations and also includes conversion fees. Sellers always
              have the option to withdraw Revenues in US$.
            </li>
            <li>
              Sellers will be able to withdraw their revenues from disabled
              accounts after a safety period of 90 days following full
              verification of ownership of the account in question, from the day
              of the last cleared payment received in their account and subject
              to Trinity's approval.
            </li>
          </ol>
          <h3>Withdrawal Method</h3>
          <ol className="m-3">
            <li>
              Withdraw to your PayPal Account - $0 Fee*. For a list of PayPal
              services by country{" "}
              <a className={styles["links"]} href="">
                click here.
              </a>
            </li>
            <li>
              Local Bank Transfer (LBT) - $3 per transfer*. For more
              information, see{" "}
              <a className={styles["links"]} href="">
                Pricing and Fees{" "}
              </a>
              . Available worldwide.
            </li>
            <li>
              Direct Deposit (ACH) - $1 per transfer*. For more information, see{" "}
              <a className={styles["links"]} href="">
                Pricing and Fees
              </a>
              . Available only in the US.
            </li>
          </ol>
          <p>
            * Additional fees may apply based on your location and currency.
          </p>

          <h2 className="py-3">Purchasing</h2>
          <h3>General</h3>
          <ol className="m-3">
            <li>
              Buyers pay Trinity to create an order from a Seller’s Service page
              or Custom Offer, using the Order Now button or through the Game
              Customizer. Trinity partners with Payment Services Providers for
              purposes of collecting all payments from Buyers, transferring such
              payments from Buyers to Sellers, and holding funds in connection
              with Trinity Balances. All payments services in connection with
              the collection of funds on the Trinity platform are performed by
              Trinity’s Payment Services Providers.
            </li>
            <li>
              Trinity serves as Seller’s limited authorized payment collection
              agent solely for the purpose of accepting payments (via its
              Payment Services Provider, if applicable) from Buyer, and
              remitting those payments to Seller. Buyer’s payment obligationto
              Seller will be satisfied upon receipt of payment by Trinity (or
              its Payment Services Provider, as applicable), and Trinity (via
              its Payment Service Provider, as applicable) is responsible for
              remitting the funds to the Seller in the manner described in these
              Payment Terms. In the event that Trinity (via Payment Services
              Provider) does not remit any such amounts to Seller, the Seller
              will have recourse only against Trinity and not the Buyer
              directly.
            </li>
            <li>
              In most locations, purchases on Trinity can be made by using one
              of the following payment methods: Credit Card, PayPal, Trinity
              Credits or existing Trinity Balance. Additional payment methods
              may apply in certain locations. More information on available
              payment methods is available{" "}
              <a className={styles["links"]} href="">
                here
              </a>
              .
            </li>
            <li>
              Service fees are added at the time of purchase where you can
              review and accept the total amount requested to pay. These fees
              cover administrative fees. As of March 2021, the service fees are
              10% of the purchase amount. For purchases under $50 an additional
              $2 small order fee will be applied.
            </li>
            <li>
              Your existing Trinity Balance will be automatically applied to
              your next purchase.
            </li>
            <li>
              You may not offer Sellers to pay, or make payment using any method
              other than through the Trinity.com site. In case you have been
              asked to use an alternative payment method, please report it
              immediately to Customer Support{" "}
              <a href="" className={styles["links"]}>
                here
              </a>
              .
            </li>
            <li>
              You agree to receive invoices and/or payment receipts from Trinity
              in electronic form as PDF documents, by email or through the Site.
            </li>
            <li>
              To protect against fraud, unauthorized transactions (such as money
              laundering), claims, or other liabilities, payment information in
              connection with withdrawals is collected by either Trinity or
              Trinity’s Payment Services Providers. PaymentServices Providers
              may also collect such other information as necessary for the
              purpose of processing withdrawal payments. Trinity is not exposed
              to the payment information provided to Payment Services Providers,
              and this information is subject to the privacy policy applicable
              to the Payment Service Provider. Please see our Privacy Policy for
              more information{" "}
              <a className={styles["links"]} href="">
                here
              </a>
              .
            </li>
            <li>
              By using any payment method and/or providing payment details for
              making purchases on Trinity, you represent and warrant that: (a)
              you are legally authorized to provide such information; (b) you
              are legally authorized or have permission to make payments using
              the payment method(s); (c) if you are an employee or agent of a
              company or person that owns the payment method, you are authorized
              by that company or person to use the payment method to make
              payments on Trinity; and (d) such actions do not violate any
              applicable law.
            </li>
          </ol>
          <h3>Purchasing through Trinity Game Customizer</h3>
          <ol className="m-3">
            <li>
              All Logo Designs have a fixed US$ price and can be purchased
              either in the “Basic Logo Package” or in additional packages as
              indicated in the Logo Design order page.
            </li>
            <li>
              Before completing an order, you will be able to preview a
              watermarked version of the Logo Design embedded with your brand
              name, and to further modify the Logo Design using the editing
              tools. Once payment has been made, orders of Logo Designs may not
              be canceled.
            </li>
            <li>
              In addition to the Logo Design package you choose, you can
              purchase redesign services from the Seller who created the Logo
              Design. The price of such redesign services is determined by the
              Seller and a service fee will be charged solely for the additional
              redesign services. For cancellations of logo redesign services,
              please refer to the “Disputes and Cancellations” section below.
            </li>
          </ol>
          <h3>Local Currencies</h3>
          <ol className="m-3">
            <li>
              You can pay on Trinity in several different currencies. Unless
              stated otherwise, you will be charged in the currency displayedon
              the payment page. All currency exchange services in connection
              with payments in local currencies are performed byTrinity’s
              Payment Services Providers. In certain cases, however, when
              certain currencies are not supported by certainpayment methods,
              you will be charged in US$ even if the priceis displayed in
              another currency. In any event, the actual charged amount (in the
              actual payment currency) will be clearly disclosed to you before
              you complete the payment.
            </li>
            <li>
              Note that all prices on Trinity are derived always from their
              original US$ price and, therefore, non-US$ currency prices may
              change daily in accordance with exchange rate fluctuations and may
              also include conversion fees. Buyers always have the option to pay
              in US$ by changing their selected displayed currency.
            </li>
            <li>
              If you paid for an Order in a currency other than US$ and your
              Order was later canceled for any reason, the amount returned to
              your Trinity Balance would be based on the exchange rate as of the
              date of cancellation. Therefore, the returned amount may vary from
              the paid amount in local currency terms, while always maintaining
              the same US$ value.
            </li>
            <li>
              Your Trinity Balance is always valued in US$, even if it is
              displayed in a non-US$ currency. Therefore, should you choose to
              view your Trinity Balance in any currency other thanUS$, it may
              change daily in accordance with exchange rate fluctuations, while
              always maintaining the same US$ value.Users always have the option
              to view their Trinity Balance in US$ by changing their selected
              displayed currency.
            </li>
          </ol>
          <h3>Trinity Credits</h3>
          <p>
            In certain instances, Trinity may offer you credits (“Trinity
            Credits” or “Credits”), either for promotional purposes or
            otherwise, to be used for purchasing Services and/or other services
            offered on the Site. Your available Credit balance will appear in
            your account. Yourvalid Trinity Credits will be automatically
            applied to your next purchase and can be viewed in your order
            summary after you place the order. Unless stated otherwise, Trinity
            Credits expire following three months from their receipt. Trinity
            Credits serve only as an incentive to use Trinity, and, therefore,
            are not redeemable for cash,have no cash value and are
            nonrefundable. Once used, Trinity Credits may not be returned to
            your account even if your Order gets canceled. Trinity Credits may
            be subject to additional conditions andrestrictions as will be
            disclosed to You upon receipt of the Credits. Credits may be voided
            in the event of fraud, misuse, or violation of these Terms. Trinity
            reserves the right to modify or cancel the TrinityCredits program at
            any time. If you or Trinity deactivate or suspend your Trinity
            account, any unused Trinity Credits in your account will be
            forfeited.
          </p>
          <h3>Promo Codes</h3>
          <p>
            From time to time we may offer you promo codes for purchasing
            Services and/or other services offered on the Site, on special
            discounts. Promo Codes are valid only for purchases on Trinity and
            are subject to different time limitations and will expire after
            their predefined term. To exercise your promo code, simply enter the
            code on the checkout page. If you fail to do so, your purchase will
            not be eligible for the discount. Promo codes are limited to one use
            per user and may not be used or combined with any other promotion.
            For orders that are composed of one or more milestones, the promo
            code will apply to the first milestone only. Promo Codes do not
            apply to Trinity Credits. Promo codes may be subject to additional
            conditions and restrictions as will be disclosed to you upon receipt
            of the promo codes. Promo codes may be voided in the event of fraud,
            misuse, or violation of the Terms of Service. Trinity reserves the
            right to modify or cancel promo codes at any time.
          </p>
          <h2 className="py-3">Taxes</h2>
          <ol className="m-3">
            <li>
              Trinity may be required by applicable laws to charge users with
              indirect taxes (such as Sales Tax, VAT or GST) or to withhold
              taxes. Any amount Trinity will be required to collect will be in
              addition to the purchase amount and any other fees payable by the
              Buyer, and any amount Trinity will be required to withhold will be
              deducted from the Seller’s revenue, as required by applicable
              laws.
            </li>
            <li>
              Indirect taxes are in addition to the price shown on the site, and
              in any event, any such taxes will always be displayed to the Buyer
              before payment.
            </li>
            <li>
              Users are responsible for paying any direct or indirect taxes,
              including any GST, VAT, or income tax, which may apply to them
              depending on residency, location or otherwise, under provisions of
              their jurisdiction.
            </li>
          </ol>
          <h2 className="py-3">Disputes and Cancellations</h2>
          <p>
            We encourage our Buyers and Sellers to try and settle conflicts
            amongst themselves. If for any reason this fails after using the
            Resolution Center or if you encounter non-permitted usage on the
            Site, users can contact Trinity's Customer Support department for
            assistance{" "}
            <a className={styles["links"]} href="">
              here
            </a>
            .
          </p>
          <h3>Basics</h3>
          <ol className="m-3">
            <li>
              Order cancellations can be performed on Trinity, when eligible,by
              Customer Support or through the Resolution Center per order.
            </li>
            <li>
              Filing a transaction dispute or reversing a payment through your
              payment provider or your bank is a violation of these Payment
              Terms. Doing so may get your account temporarily orpermanently
              disabled. Note: Once you have filed a dispute with your payment
              provider, the funds will be ineligible for a refund due to our
              obligations towards the payment provider.
            </li>
            <li>
              In the event that you encounter an issue related to the service
              provided in an order, you are encouraged to use the Site's dispute
              resolution tools to attempt to resolve the matter.
            </li>
            <li>
              Trinity, through its Payment Services Provider, reserves the right
              to cancel orders or place funds on hold for any
              suspectedfraudulent transactions made on the Site.
            </li>
            <li>
              All transfer and assignment of intellectual property to the Buyer
              shall be subject to full payment and the delivery may notbe used
              if payment is canceled for any reason.
            </li>
            <li>
              If an order is canceled (for any reason), the funds paid will be
              returned to the Buyer’s Trinity Balance.
            </li>
            <li>
              Revisions to deliveries can be performed by Sellers based on the
              Seller’s Service and customer care. Sellers may determine the
              amount of revisions they wish to offer, including no revisions.
            </li>
            <li>
              Requests for revisions can be performed through the Order Page
              while the order is marked as Delivered.
            </li>
            <li>
              Requesting to gain more services from Sellers beyond the agreed
              requirements by using the Request Revisions button isnot allowed.
            </li>
          </ol>

          <h2 className="py-3">Order Cancellations</h2>
          <ol className="m-3">
            <li>
              Trinity encourages Buyers and Sellers to resolve service disputes
              mutually using the Resolution Center.
            </li>
            <li>
              Eligibility for requests to Trinity to cancel an order will be
              assessed by our Customer Support team based on a number of
              factors, including violations of our Terms of Service and/or our
              Community Standards, general misconduct, and improper usage of the
              Trinity delivery system. See below for Order specific eligibility.
            </li>
            <li>
              Completed orders (or, where applicable, completed milestones) may
              be canceled, upon review of our Customer Support team, up to 14
              days after the order (or Milestone) is marked as complete. Please
              be advised that orders cannot be partially canceled (i.e. we can
              only cancel the entire order when it is justified).
            </li>
            <li>
              In rare circumstances where we find it appropriate, our Customer
              Support team may cancel a completed order even after 14 days have
              passed from its completion. In such cases, the amounts paid for
              the canceled order will be returned to the Buyer’s Trinity Balance
              and will be deducted from the Seller’s Trinity Balance or, if
              there are no sufficient amounts in the Seller’s Trinity Balance,
              from future revenues of such Seller.
            </li>
            <li>
              For orders made through the Trinity Game Customizer, once payment
              has been made, orders of Logo Designs may not be canceled.
            </li>
            <li>
              Orders are not eligible to be canceled based on the quality of
              service/materials delivered by the Seller if the service was
              rendered as described in the Service Page. You may rate
              yourexperience with the Seller on the Order Page, including the
              overall level of service quality received.
            </li>
            <li>
              You must use Trinity Resolution Center to address your concerns
              and desired resolution related to the service provided by their
              Seller prior to contacting Customer Support. Customer Support will
              not take any action against Orders where the applicable has failed
              to inform their Seller of issues related to the Seller’s service
              and will allow Sellers to provide a resolution first. This does
              not include non-permitted usage ofTrinity.
            </li>
            <li>
              Any non-permitted usage of Trinity encountered during an Order,
              after being reviewed by our Customer Support team, may result in
              the order being canceled. This includes, but not limited to;
              harassment, unlawful behavior, or other violations ofTrinity’s
              Terms of Service.
            </li>
            <li>
              Trinity Customer Support will cancel orders based on, but not
              limited to, the following reasons:
            </li>
          </ol>
          <p>
            <strong>Active orders</strong> (after requirements were submitted to
            the Seller and before the Seller delivers on Trinity):
          </p>
          <ol className="m-3">
            <li>
              The Seller is late and unresponsive for more than 24 hours while
              the order is marked as Late.
            </li>
            <li>
              Users are abusive towards the other party through threats of low
              ratings or leveraging order materials (such as logins, personal
              information) against each other.
            </li>
            <li>
              Users supplied or included copyright/trademark infringing
              materials as part of their requirements or the Seller’s delivery.
            </li>
            <li>
              The user is no longer an active Trinity user due to Terms of
              Service violations or closure of their account.
            </li>
          </ol>
          <p>
            <strong>Delivered Orders</strong> (after the Seller clicks Deliver
            Now and before the order is marked as complete)
          </p>
          <ol className="m-3">
            <li>
              The Seller uses the Delivery system to extend the delivery duedate
              to complete the requested service without providing the final
              delivered service. Note: Multiple reported offenses will result in
              permanent suspension of your account.
            </li>
            <li>
              The Seller delivers no files and/or proof of work related to the
              agreed upon order requirements. Note: Subjectivity of the
              materials in question will be reviewed by our Customer Support
              team.
            </li>
            <li>
              The Seller requests additional payments, on or off the Trinity
              platform, by withholding the final delivery of services directly
              related to the agreed requirements.
            </li>
            <li>
              The Seller is withholding the final delivery of services for
              improved ratings.
            </li>
            <li>
              Users who abuse the Request Revisions button to gain more services
              from Sellers beyond the agreed requirements.
            </li>
            <li>
              Users who threaten to leave a damaging review to gain more
              services from the Seller not related to the agreed requirements.
            </li>
          </ol>
          <p>
            <strong>Completed Orders</strong> (after the order is marked as
            complete and before the 14-day limitation)
          </p>
          <ol className="m-3">
            <li>
              Users who have been reported to use copyright/trademark infringing
              materials after verification and with proof.
            </li>
            <li>
              Users who did not purchase commercial use rights and are reported
              to have used the materials commercially. Note: Terms of Commercial
              use are found on the Seller’s Service Page and cannot be
              retroactively included once the order is completed for over 14
              days.
            </li>
            <li>
              Trinity Customer Support will review cases of Order delivery
              manipulation that prevents users from fully utilizing our
              Resolution Center that enabled the order to be marked as complete.
            </li>
          </ol>
          <h3>Chargebacks</h3>
          <p>
            Trinity reviews cases of payment provider chargebacks and disputes
            on behalf of Sellers. Although results vary per case due to each
            chargeback reason, we work hard on resolving disputes in the
            Seller’s favor. If the chargeback case allows, Trinity (or one of
            Trinity’s Payment Services Providers) will return parts or full
            revenue back to Sellers, otherwise the chargeback amount will be
            borne by the Seller.
          </p>
          <h3>Refunds</h3>
          <ol className="m-3">
            <li>
              Trinity does not automatically refund payments made for canceled
              orders back to your payment provider. Funds from order
              cancellations are returned to the Buyer's Trinity Balance and are
              available for future purchases on Trinity. Until January1, 2019,
              funds returned to your Trinity Balance from canceled orders will
              not include service fees paid. As of January 1, 2019, Trinity
              returns service fees to your Trinity Balance once an order is
              canceled. In any event, if the service fees were not returned to
              your Trinity Balance following an order cancelation,such service
              fees’ amount will be exempted from your next purchase on Trinity.
            </li>
            <li>
              Deposit refunds (i.e. refunds directly to your payment
              provider)can be performed by our Customer Support team, based on
              the Order’s original payment amount and currency. To preventfraud
              and abuse, we limit the total amount of times users can request a
              payment provider refund, which is subject to review by our
              Customer Support team. Such refunds may be subject to an
              additional fee.
            </li>
          </ol>
          <h2 className="py-3">General Terms</h2>
          <h3>Modifications</h3>
          <ol className="m-3">
            <li>
              Trinity may make changes to these Payment Terms from time to time.
              When these changes are made, Trinity will make a new copy of the
              terms available on this page.
            </li>
            <li>
              You understand and agree that if you use Trinity after the date on
              which the Payment Terms have changed, Trinity will treat your use
              as acceptance of the updated Payment Terms.
            </li>
          </ol>
          <h3>Disclaimer of Warranties</h3>
          <p>
            YOUR USE OF THE SITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED
            THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE SITE, ITS CONTENT AND
            ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON
            AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY
            KIND, EITHER EXPRESS OR IMPLIED. NEITHER TRINITY NOR ANY PERSON
            ASSOCIATED WITH TRINITY MAKES ANY WARRANTY OR REPRESENTATION
            WITHRESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY,
            ACCURACY OR AVAILABILITY OF THE SITE.
          </p>
          <p>
            THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
            EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          </p>
          <h3>Limitation on Liability</h3>
          <p>
            IN NO EVENT WILL TRINITY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE
            PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR
            DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN
            CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SITE, ANY
            WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER
            WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE OR SUCH
            OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
            CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO,
            PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF
            REVENUE,LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS,
            LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY
            TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN
            IF FORESEEABLE.
          </p>
          <p>
            IN ACCEPTING LIABILITY AS THE LIMITED AUTHORIZED PAYMENT COLLECTION
            AGENT OF SELLER, TRINITY AND ITS AFFILIATES ASSUME NO LIABILITY FOR
            ANY ACTS OR OMISSIONS OF SELLER.
          </p>
          <p>
            THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED
            OR LIMITED UNDER APPLICABLE LAW.
          </p>
          <p>
            The term “Affiliate” referred to herein, is an entity that, directly
            or indirectly, controls, or is under the control of, or is under
            common control with Trinity, where control means having more than
            fifty percent (50%) voting stock or other ownership interest or the
            majority of voting rights of such entity.
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default PaymentTerms;
