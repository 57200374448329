import {
    CREATE_GAME_CUSTOMIZER,
    CLEAR_GAME_CUSTOMIZER,
} from "../actions/types";

const initialState = {
    description: "",
    budget: 0,
};

const gameCustomizer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case CREATE_GAME_CUSTOMIZER:
            return {
                ...state,
                description: payload.description,
                budget: payload.budget,
            };
        case CLEAR_GAME_CUSTOMIZER:
            return {
                ...state,
                description: "",
                budget: 0,
            };
        default:
            return state;
    }
};

export default gameCustomizer;
