import React, { Fragment } from "react";

// import Navbar from "./components/Navbar";
import Hero from "../../components/Landing/Hero/Hero";
import ComingSoon from "../../components/Landing/ComingSoon/ComingSoon";
import Dream from "../../components/Landing/Dream/Dream";
import Special from "../../components/Landing/Special/Special";
import CTA from "../../components/Landing/CTA/CTA";
import Projects from "../../components/Landing/Projects/Projects";
import GetWork from "../../components/Landing/GetWork/GetWork";
import FAQ from "../../components/Landing/FAQ/FAQ";
import Newsletter from "../../components/Landing/Newsletter/Newsletter";
import Gamesection from "../../components/Landing/GameSection/Gamesection";

const Landing = () => {
	return (
		<Fragment>
			<div id="landing-page">
				<Hero />
				<Gamesection />
				<Dream />
				{/* <Developers /> */}
				<ComingSoon />
				<Special />
				<CTA />
				<Projects />
				<GetWork />
				<FAQ />
				<Newsletter />
			</div>
		</Fragment>
	);
};

export default Landing;
