import { getApiEndpoint } from "../utils/getApiEndpoint";
import { requestWithToken } from "../utils/httpRequest";

import { error } from "./error";

// Save new milestone for project
export const saveNewMilestone = (formData) => async (dispatch) => {
    try {
        const res = await requestWithToken(
            "POST",
            `${getApiEndpoint()}/milestone`,
            formData
        );

        return res.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Update milestone for project
export const updateMilestone = (milestoneId, formData) => async (dispatch) => {
    try {
        const res = await requestWithToken(
            "PUT",
            `${getApiEndpoint()}/milestone/${milestoneId}`,
            formData
        );

        return res.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Get all Milestones of the project
export const getAllMilestones = (projectId) => async (dispatch) => {
    try {
        const res = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/project/${projectId}/milestones`
        );
        return res.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Get milestone by id
export const getMilestoneById = (milestoneId) => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/milestone/${milestoneId}`
        );

        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Send milestone payment request
export const sendMilestonePaymentRequest = (formData) => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "POST",
            `${getApiEndpoint()}/milestone/payment/request`,
            formData
        );

        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Submit milestone payment request action
export const sendMilestonePaymentRequestAction =
    (formData) => async (dispatch) => {
        try {
            const response = await requestWithToken(
                "POST",
                `${getApiEndpoint()}/milestone/payment/request/action`,
                formData
            );

            return response.data;
        } catch (err) {
            dispatch(error(err));
            return err.response.data;
        }
    };

// Get all milestone tasks of milestone
export const fetchMilestoneTasksByMilestoneId =
    (milestoneId) => async (dispatch) => {
        try {
            const res = await requestWithToken(
                "GET",
                `${getApiEndpoint()}/milestone/${milestoneId}/task`
            );

            return res.data;
        } catch (err) {
            dispatch(error(err));
            return err.response.data;
        }
    };

// Save new milestone task to milestone
export const saveMilestoneTask =
    (milestoneId, formData) => async (dispatch) => {
        try {
            const res = await requestWithToken(
                "POST",
                `${getApiEndpoint()}/milestone/${milestoneId}/task`,
                formData
            );

            return res.data;
        } catch (err) {
            dispatch(error(err));
            return err.response.data;
        }
    };

// Delete milestone task to milestone
export const deleteMilestoneTask =
    (milestoneId, taskId) => async (dispatch) => {
        try {
            const res = await requestWithToken(
                "DELETE",
                `${getApiEndpoint()}/milestone/${milestoneId}/task/${taskId}`
            );

            return res.data;
        } catch (err) {
            dispatch(error(err));
            return err.response.data;
        }
    };

// Update milestone task status
export const updateMilestoneTaskStatus =
    (milestoneId, taskId, status) => async (dispatch) => {
        try {
            const res = await requestWithToken(
                "PUT",
                `${getApiEndpoint()}/milestone/${milestoneId}/task/${taskId}/${status}`
            );

            return res.data;
        } catch (err) {
            dispatch(error(err));
            return err.response.data;
        }
    };

// Get all milestone tasks of milestone
export const fetchMilestoneCommentsByMilestoneId =
    (milestoneId) => async (dispatch) => {
        try {
            const res = await requestWithToken(
                "GET",
                `${getApiEndpoint()}/milestone/${milestoneId}/comment`
            );

            return res.data;
        } catch (err) {
            dispatch(error(err));
            return err.response.data;
        }
    };

// Save new milestone comment to milestone
export const saveMilestoneComment =
    (milestoneId, formData) => async (dispatch) => {
        try {
            const res = await requestWithToken(
                "POST",
                `${getApiEndpoint()}/milestone/${milestoneId}/comment`,
                formData
            );

            return res.data;
        } catch (err) {
            dispatch(error(err));
            return err.response.data;
        }
    };

// Start milestone timmer
export const startMilestone = (milestoneId) => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "POST",
            `${getApiEndpoint()}/milestone/${milestoneId}/start`
        );

        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Delete milestone
export const deleteMilestone = (milestoneId) => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "DELETE",
            `${getApiEndpoint()}/milestone/${milestoneId}`
        );

        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Extend milestone deadline
export const extendMilestoneDeadline =
    (milestoneId, formData) => async (dispatch) => {
        try {
            const response = await requestWithToken(
                "PUT",
                `${getApiEndpoint()}/milestone/${milestoneId}/extend-deadline`,
                formData
            );

            return response.data;
        } catch (err) {
            dispatch(error(err));
            return err.response.data;
        }
    };

// Deliver milestone
export const deliverMilestone = (milestoneId, formData) => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "POST",
            `${getApiEndpoint()}/milestone/${milestoneId}/deliver`,
            formData
        );

        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Milestone delivery action
export const milestoneDeliveryAction =
    (milestoneId, deliveryId, formData) => async (dispatch) => {
        try {
            const response = await requestWithToken(
                "POST",
                `${getApiEndpoint()}/milestone/${milestoneId}/deliver/${deliveryId}/action`,
                formData
            );

            return response.data;
        } catch (err) {
            dispatch(error(err));
            return err.response.data;
        }
    };

// Continue milestone work
export const startMilestoneRevision =
    (milestoneId, formData) => async (dispatch) => {
        try {
            const response = await requestWithToken(
                "POST",
                `${getApiEndpoint()}/milestone/${milestoneId}/revision/start`,
                formData
            );

            return response.data;
        } catch (err) {
            dispatch(error(err));
            return err.response.data;
        }
    };
