import moment from "moment";
import React, { Fragment, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setAlert } from "../../../actions/alert";
import { deleteMilestone, startMilestone } from "../../../actions/milestone";
import RequestPayment from "../../Modals/RequestPayment/RequestPayment";
import AddEditMilestone from "../AddEditMilestone/AddEditMilestone";
import styles from "./MilestoneListItem.module.css";
import EllipsisParagraph from "../../utils/EllipsisParagraph/EllipsisParagraph";
import { ProjectControlCenterContext } from "../../../contexts/ProjectControlCenterContext";
import MilestoneCounterTimer from "../MilestoneCounterTimer/MilestoneCounterTimer";

const MilestoneListItem = ({
	milestone,
	projectOwnerId,
	reloadMilestones,
	className,
}) => {
	const dispatch = useDispatch();
	const projectControlCenterContext = useContext(ProjectControlCenterContext);

	const authState = useSelector((state) => state.auth);

	const [editMode, setEditMode] = useState(false);
	const [countDownDate, setCountDownDate] = useState(false);
	const [showTasks, setShowTasks] = useState(false);

	useEffect(() => {
		// setCountDownDate(
		//     moment(milestone.startedAt).add(
		//         milestone.estimatedDuration.value,
		//         milestone.estimatedDuration.scope
		//     )
		// );
		// Calculating count down end date
		const calculatedCountDownDate = moment(milestone.startedAt).add(
			milestone.estimatedDuration.value,
			milestone.estimatedDuration.scope
		);
		if (milestone.deadlineExtends) {
			milestone.deadlineExtends.forEach((extend) => {
				calculatedCountDownDate.add(
					extend.extraDuration.value,
					extend.extraDuration.scope
				);
			});
		}
		setCountDownDate(calculatedCountDownDate);
	}, [milestone]);

	const onStartMilestone = async () => {
		const response = await dispatch(startMilestone(milestone._id));
		if (response.status) {
			reloadMilestones();
			dispatch(setAlert(response.msg, "success"));
		} else {
			dispatch(setAlert(response.msg, "danger"));
		}
	};

	const onDeleteMilestone = async () => {
		const response = await dispatch(deleteMilestone(milestone._id));
		if (response.status) {
			projectControlCenterContext.fetchProjectDetails();
			reloadMilestones();
			dispatch(setAlert(response.msg, "success"));
		} else {
			dispatch(setAlert(response.msg, "danger"));
		}
	};

	return (
		<Fragment>
			{authState.user !== null && (
				<Fragment>
					{editMode ? (
						<div
							className={`${
								!editMode && "d-none"
							} ${className} p-4 mb-3`}
						>
							<div className="d-flex align-items-center">
								<h5 className="flex-grow-1 mb-0">
									Edit: {milestone.title}
								</h5>
								<p
									className={`${
										styles["status"]
									}  p-1 rounded d-inline-block ${
										styles[milestone.status]
									}`}
								>
									{milestone.status.replace("-", " ")}
								</p>
							</div>
							<hr></hr>
							<AddEditMilestone
								isOpen={editMode}
								handleClose={async () => {
									reloadMilestones();
									setEditMode(false);
								}}
								milestone={milestone}
								projectId={milestone.project}
								projectOwnerId={projectOwnerId}
							/>
						</div>
					) : (
						<Fragment>
							<li
								className={`${styles["bid-one"]} ${className} p-4 mb-3`}
							>
								{milestone.status === "in-progress" &&
									countDownDate !== false && (
										<MilestoneCounterTimer
											targetDate={countDownDate}
											startDate={milestone.startedAt}
										/>
									)}
								<div className="d-flex align-items-center">
									<h6 className="flex-grow-1 d-flex align-items-center">
										<Link
											to={`?t=2&milestone=${milestone._id}`}
											className="d-inline-block me-1"
										>
											{milestone.title}
										</Link>{" "}
										($ {milestone.price.amount}){" "}
										<p
											className={`${
												styles["status"]
											}  p-1 rounded d-inline-block ${
												styles[milestone.status]
											}`}
										>
											{milestone.status.replace("-", " ")}
										</p>
									</h6>
									{authState.user._id === projectOwnerId ||
									authState.user.userType === "recruiter" ? (
										/* Action buttons for owner and recruiters */
										<div className="milestone-list-action d-flex">
											{milestone.status !==
												"released" && (
												<Fragment>
													<span
														className="material-icons pointer me-2"
														onClick={() =>
															setEditMode(true)
														}
													>
														create
													</span>
													<span
														className="material-icons pointer me-2"
														onClick={() =>
															onDeleteMilestone()
														}
													>
														delete
													</span>
												</Fragment>
											)}
											{/* <Requestpayment /> */}
										</div>
									) : (
										/* Action buttons for developers */
										<Fragment>
											{milestone.hiredUser?._id ===
												authState.user._id && (
												<div className="milestone-list-action d-flex ">
													{milestone.status !==
														"released" && (
														<Fragment>
															<span
																className="material-icons pointer me-2"
																onClick={() =>
																	setEditMode(
																		true
																	)
																}
															>
																create
															</span>
															{milestone.status ===
																"active" && (
																<span
																	className="material-icons pointer"
																	onClick={() =>
																		onStartMilestone()
																	}
																>
																	timer
																</span>
															)}
															{milestone.status ===
																"in-progress" && (
																<RequestPayment
																	milestone={
																		milestone
																	}
																/>
															)}
														</Fragment>
													)}
												</div>
											)}
										</Fragment>
									)}
								</div>
								<div className="flex-grow-1">
									<small className="d-inline text-muted">
										Tasks:{" "}
										{
											milestone.tasks.filter(
												(task) =>
													task.status === "completed"
											).length
										}{" "}
										/ {milestone.tasks.length}
									</small>
									<small className="text-muted">
										&nbsp;&nbsp;|&nbsp;&nbsp;
									</small>
									<small className="d-inline text-muted">
										Developer:{" "}
										{milestone.hiredUser
											? milestone.hiredUser.name
											: "Unassigned"}
									</small>
									<small className="text-muted">
										&nbsp;&nbsp;|&nbsp;&nbsp;
									</small>
									<small className="d-inline text-muted">
										Skill:{" "}
										{milestone.skill
											? milestone.skill.name
											: "Unassigned"}
									</small>
									<small className="text-muted">
										&nbsp;&nbsp;|&nbsp;&nbsp;
									</small>
									<small className="d-inline text-muted">
										Duration:{" "}
										{milestone.estimatedDuration.value}{" "}
										{milestone.estimatedDuration.scope}
									</small>
									<EllipsisParagraph className="mt-2">
										{milestone.description}
									</EllipsisParagraph>
									{milestone.tasks.length > 0 && (
										<Fragment>
											{showTasks ? (
												<Fragment>
													<button
														className={`btn ${styles["tasks-toggle-btn"]}`}
														onClick={() =>
															setShowTasks(false)
														}
													>
														Hide Tasks
													</button>

													<table className="table table-borderless">
														<thead>
															<tr>
																<th>Task</th>
																<th>
																	Priority
																</th>
															</tr>
														</thead>
														<tbody>
															{milestone.tasks.map(
																(task) => {
																	return (
																		<tr>
																			<td
																				className={`${
																					task.status ===
																						"completed" &&
																					styles[
																						"completed-task"
																					]
																				}`}
																			>
																				{
																					task.title
																				}
																			</td>
																			<td
																				className={`text-capitalize ${
																					task.status ===
																						"completed" &&
																					styles[
																						"completed-task"
																					]
																				}`}
																			>
																				{
																					task.priority
																				}
																			</td>
																		</tr>
																	);
																}
															)}
														</tbody>
													</table>
												</Fragment>
											) : (
												<button
													className={`btn ${styles["tasks-toggle-btn"]}`}
													onClick={() =>
														setShowTasks(true)
													}
												>
													View Tasks
												</button>
											)}
										</Fragment>
									)}
								</div>
							</li>
						</Fragment>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

MilestoneListItem.propTypes = {
	className: PropTypes.string,
};

MilestoneListItem.defaultProps = {
	className: "light-bg",
};

export default MilestoneListItem;
