import React, { useEffect, useState } from "react";
import { Dialog } from "@material-ui/core";
import {
	CustomizedDialogContent,
	CustomizedDialogTitle,
} from "../CustomizedModal/CustomizedModal";
import { useFormik } from "formik";
import { Dropdown } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { getActiveSkills } from "../../../actions/skills";
import LoadingButton from "../../utils/LoadingButton/LoadingButton";
import { updateProjectById } from "../../../actions/project";
import { setAlert } from "../../../actions/alert";

const EditProjectModal = ({ isOpen, onClose, project }) => {
	const dispatch = useDispatch();

	const [isUpdating, setIsUpdating] = useState(false);
	const [activeSkills, setActiveSkills] = useState([]);

	const editProjectFormik = useFormik({
		initialValues: {
			title: "",
			skills: [],
		},
		onSubmit: async (values) => {
			setIsUpdating(true);

			const response = await dispatch(
				updateProjectById(project._id, values)
			);
			if (response.status) {
				dispatch(setAlert(response.msg, "success"));
				onClose();
			} else {
				dispatch(setAlert(response.msg, "danger"));
			}

			setIsUpdating(false);
		},
	});

	const loadInitData = async () => {
		const response = await dispatch(getActiveSkills());
		const skillsArray = [];
		response.data.map((skill) => {
			const obj = {
				key: skill._id,
				text: skill.name,
				value: skill._id,
			};
			skillsArray.push(obj);
			return null;
		});
		setActiveSkills(skillsArray);
	};

	useEffect(() => {
		if (isOpen) {
			loadInitData();
			editProjectFormik.setValues({
				title: project.title,
				skills: project.skills.map((skill) => {
					return skill._id;
				}),
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	const onChangeDropdownValue = (e, data) => {
		editProjectFormik.setFieldValue(data.name, data.value);
	};

	return (
		<Dialog
			onClose={() => onClose(false)}
			aria-labelledby="customized-dialog-title"
			open={isOpen}
			maxWidth="sm"
			fullWidth={true}
		>
			<CustomizedDialogTitle id="customized-dialog-title">
				<h3 className="modal-title text-accent">Edit Project</h3>
			</CustomizedDialogTitle>
			<CustomizedDialogContent>
				<form onSubmit={editProjectFormik.handleSubmit}>
					<div className="mb-3">
						<label className="form-label">Project Title</label>
						<input
							type="text"
							className="dark-input"
							placeholder="Enter Your Project Title"
							name="title"
							value={editProjectFormik.values.title}
							onChange={editProjectFormik.handleChange}
							required
						/>
					</div>
					<div className="mb-3">
						<label className="form-label">
							Select required skills
						</label>
						<Dropdown
							placeholder="Select Skills"
							className="sementic-dropdown dark-input dropdown-select mt-2 mb-3"
							name="skills"
							multiple
							search
							selection
							options={activeSkills}
							onChange={onChangeDropdownValue}
							value={editProjectFormik.values.skills}
							required
						/>
					</div>
					<div className="form-group text-end">
						<LoadingButton
							isLoading={isUpdating}
							text="Update"
							className="btn btn-primary-gradient"
						/>
						{/* <button className="btn btn-primary-gradient">
							Update
						</button> */}
					</div>
				</form>
			</CustomizedDialogContent>
		</Dialog>
	);
};

export default EditProjectModal;
