import React, { useEffect, useRef, useState } from "react";
import styles from "./MilestoneCounterTimer.module.css";

const MilestoneCounterTimer = ({ targetDate, startDate }) => {
    let interval = useRef();

    const [isLate, setIsLate] = useState(false);
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        startTimer();

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            clearInterval(interval.current);
            clearInterval(interval);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetDate, startDate]);

    const startTimer = () => {
        const countdownDate = new Date(targetDate).getTime();

        interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = countdownDate - now;

            const days = Math.floor(Math.abs(distance) / (1000 * 60 * 60 * 24));
            const hours = Math.floor(
                (Math.abs(distance) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor(
                (Math.abs(distance) % (1000 * 60 * 60)) / (1000 * 60)
            );
            const seconds = Math.floor(
                (Math.abs(distance) % (1000 * 60)) / 1000
            );

            if (distance < 0) {
                // clearInterval(interval.current);
                setIsLate(true);
            } else {
                setIsLate(false);
            }

            setDays(days);
            setHours(hours);
            setMinutes(minutes);
            setSeconds(seconds);
        }, 1000);
    };

    return (
        <div className={`${styles["timer"]} mb-3`}>
            {Math.abs(days)}d : {Math.abs(hours)}h : {Math.abs(minutes)}m :{" "}
            {Math.abs(seconds)}s{" "}
            {isLate && (
                <span
                    className={`${styles["late-label"]} px-3 py-1 rounded d-inline-block`}
                >
                    Late
                </span>
            )}
        </div>
    );
};

export default MilestoneCounterTimer;
