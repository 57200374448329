import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    getChatroomMessages,
    uploadChatAttachment,
} from "../../../actions/chat";
import { getBotResponse, sendMsgBot } from "../../../actions/chatBot";
import { getApiEndpoint } from "../../../utils/getApiEndpoint";
import ScheduleMeetingModal from "../../Modals/ScheduleMeetingModal/ScheduleMeetingModal";
import MessageItem from "../MessageItem/MessageItem";
import AttachFileModal from "../../Modals/AttachFileModal/AttachFileModal";
import styles from "./chatMessages.module.css";
import { setAlert } from "../../../actions/alert";

const ChatMessages = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const authState = useSelector((state) => state.auth);
    const activeChat = useSelector((state) => state.chat.activeRoom);
    const socketState = useSelector((state) => state.socket);

    const [msg, setMsg] = useState("");
    const [meetingModalOpen, setMeetingModalOpen] = useState(false);
    const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);

    useEffect(() => {
        const fetchMessages = async () => {
            if (activeChat && activeChat._id !== "chatbot") {
                await dispatch(getChatroomMessages(activeChat._id));
            }
        };

        fetchMessages();

        // eslint-disable-next-line
    }, [activeChat?._id]);

    const onChangeMsg = (e) => {
        setMsg(e.target.value);
    };

    const onFormSubmit = async (e) => {
        e.preventDefault();
        if (!msg) {
            alert("Please type a message", "danger");
        } else {
            if (activeChat._id !== "chatbot") {
                sendMsg(
                    activeChat._id,
                    msg,
                    authState.user._id,
                    activeChat.users
                );
                setMsg("");
            } else {
                dispatch(sendMsgBot(msg, authState.user));
                dispatch(getBotResponse(msg));
                setMsg("");
            }
        }
    };

    const sendMsg = (chatId, msg, userId, users) => {
        if (socketState.socket != null) {
            socketState.socket.emit("send-message", {
                msgData: {
                    chatId: chatId,
                    msg: msg,
                    userId: userId,
                },
                msgType: {
                    type: "message",
                },
            });
        }
    };

    const onStartInstantMeeting = () => {
        const roomId = Date.now();

        if (socketState.socket != null) {
            const msgData = {
                chatId: activeChat._id,
                msg: "Instant Meeting",
                userId: authState.user._id,
                users: activeChat.users,
            };

            const msgType = {
                type: "meeting",
                action: {
                    actionType: "meeting",
                    actionData: {
                        type: "instant",
                        roomId: roomId,
                    },
                },
            };

            socketState.socket.emit("send-message", {
                msgData: msgData,
                msgType: msgType,
            });
        }

        history.push(`/video-chat/${roomId}`);
    };

    const onUploadAttachment = async (files) => {
        const formData = new FormData();
        files.forEach((file) => {
            formData.append("files", file[0]);
        });

        const response = await dispatch(
            uploadChatAttachment(activeChat._id, formData)
        );
        if (response.status) {
            response.data.forEach((attachment) => {
                if (socketState.socket != null) {
                    socketState.socket.emit("send-message", {
                        msgData: {
                            chatId: activeChat._id,
                            userId: authState.user._id,
                        },
                        msgType: {
                            type: "attachment",
                            attachment,
                        },
                    });
                }
            });

            setAttachmentModalOpen(false);
            dispatch(setAlert("Attachments sent successfully!", "success"));
        } else {
            dispatch(setAlert(response.msg, "danger"));
        }
    };

    return (
        <Fragment>
            <div className="col-md-8 p-0" style={{ height: "90vh" }}>
                <section
                    className={`${styles["chat-area"]} d-flex flex-column`}
                >
                    {activeChat ? (
                        <Fragment>
                            <nav
                                className={`${styles["chat-area-nav"]} d-flex align-items-center gap-2`}
                            >
                                {activeChat.groupName ? (
                                    <Fragment>
                                        <div className="profile-icon me-4">
                                            <img
                                                className="profile-pic-sm"
                                                src="/img/group-chat.jfif"
                                                alt="Profile"
                                            />
                                        </div>
                                        <h4 className="font-weight-bolder text-capitalize m-0">
                                            {activeChat.groupName}
                                        </h4>
                                        {/* <div className="chat-nav-option ml-auto"> */}
                                        <div
                                            // to={
                                            //     "/video-chat/" +
                                            //     activeChat._id
                                            // }
                                            className="no-color-change"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <span className="material-icons mr-3">
                                                videocam
                                            </span>
                                        </div>
                                        {/* <span className="material-icons pointer">
                                more_horiz
                            </span> */}
                                        {/* </div> */}
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        {activeChat.users ? (
                                            <Fragment>
                                                {activeChat.users.map(
                                                    (user) => {
                                                        return (
                                                            <Fragment
                                                                key={user._id}
                                                            >
                                                                {user._id !==
                                                                authState.user
                                                                    ._id ? (
                                                                    <Fragment>
                                                                        <div className="profile-icon me-4">
                                                                            <img
                                                                                className="profile-pic-sm"
                                                                                src={
                                                                                    user
                                                                                        ?.userProfile
                                                                                        ?.profileImgUrl
                                                                                        ? getApiEndpoint() +
                                                                                          user
                                                                                              .userProfile
                                                                                              .profileImgUrl
                                                                                        : "/img/profile-image.png"
                                                                                }
                                                                                alt="Profile"
                                                                            />
                                                                        </div>
                                                                        <div className="d-flex align-items-center flex-grow-1">
                                                                            <h4 className="font-weight-bolder text-capitalize m-0">
                                                                                {
                                                                                    user.name
                                                                                }
                                                                            </h4>

                                                                            {user.userType.toLowerCase() ===
                                                                            "recruiter" ? (
                                                                                <Fragment>
                                                                                    <small className="ml-2 mb-0 notification d-inline px-2">
                                                                                        Recruiter
                                                                                    </small>
                                                                                </Fragment>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </div>
                                                                        {/* <div className="chat-nav-option ml-auto"> */}
                                                                        {activeChat._id !==
                                                                        "chatbot" ? (
                                                                            <div className="dropdown">
                                                                                <a
                                                                                    className="no-color-change"
                                                                                    data-bs-toggle="dropdown"
                                                                                    aria-expanded="false"
                                                                                >
                                                                                    <span className="material-icons mr-3">
                                                                                        videocam
                                                                                    </span>
                                                                                </a>
                                                                                <ul className="dropdown-menu">
                                                                                    <li className="dropdown-item">
                                                                                        <a
                                                                                            onClick={() =>
                                                                                                onStartInstantMeeting()
                                                                                            }
                                                                                        >
                                                                                            Start
                                                                                            Instant
                                                                                            Meeting
                                                                                        </a>
                                                                                    </li>
                                                                                    <li className="dropdown-item">
                                                                                        <a
                                                                                            onClick={() =>
                                                                                                setMeetingModalOpen(
                                                                                                    true
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            Schedule
                                                                                            Meeting
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        ) : (
                                                                            ""
                                                                        )}

                                                                        <div className="d-flex">
                                                                            {/* <span className="material-icons pointer">
                                                                    more_horiz
                                                                </span> */}
                                                                        </div>
                                                                        {/* </div> */}
                                                                    </Fragment>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Fragment>
                                                        );
                                                    }
                                                )}
                                            </Fragment>
                                        ) : (
                                            ""
                                        )}
                                    </Fragment>
                                )}
                            </nav>
                            <div
                                className={`${styles["chat-container"]} d-flex flex-grow-1 flex-column-reverse`}
                            >
                                {activeChat.messages ? (
                                    <Fragment>
                                        {activeChat.messages &&
                                            activeChat.messages.map(
                                                (msg, i) => {
                                                    return (
                                                        <Fragment key={i}>
                                                            <MessageItem
                                                                msg={msg}
                                                                index={i}
                                                                activeChat={
                                                                    activeChat
                                                                }
                                                            />
                                                        </Fragment>
                                                    );
                                                }
                                            )}
                                    </Fragment>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className={`${styles["chat-msg-options"]}`}>
                                <form
                                    className="d-flex gap-3"
                                    onSubmit={(e) => onFormSubmit(e)}
                                >
                                    <button
                                        type="button"
                                        className="btn btn-secondary rounded-circle d-flex align-items-center p-2"
                                        onClick={() =>
                                            setAttachmentModalOpen(true)
                                        }
                                    >
                                        <span className="material-icons text-white">
                                            attach_file
                                        </span>
                                    </button>
                                    <AttachFileModal
                                        // chat={activeChat}
                                        // onSendMsg={sendMsg}
                                        isOpen={attachmentModalOpen}
                                        handleClose={setAttachmentModalOpen}
                                        handleSubmit={onUploadAttachment}
                                    />
                                    <input
                                        onChange={(e) => onChangeMsg(e)}
                                        type="text"
                                        className="dark-input flex-grow-1"
                                        placeholder="Type Your Message..."
                                        name="msg"
                                        autoComplete="off"
                                        value={msg}
                                    ></input>
                                    <button
                                        className={`${styles["btn-send-msg"]} p-2`}
                                        type="submit"
                                    >
                                        <span
                                            className={`${styles["send-button"]} material-icons text-white`}
                                        >
                                            send
                                        </span>
                                    </button>
                                </form>
                            </div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className="no-chat-display d-flex flex-column align-items-center justify-content-center h-100">
                                <img
                                    className="profile-pic-xl rounded-0"
                                    src="/img/chat.png"
                                    alt="Profile"
                                />
                                <h3 className="my-3">Chat Instantly</h3>
                                <p className="text-muted">
                                    Select chat to start messaging.
                                </p>
                            </div>
                        </Fragment>
                    )}
                </section>
            </div>
            <ScheduleMeetingModal
                open={meetingModalOpen}
                setOpen={setMeetingModalOpen}
                chatroom={activeChat}
            />
        </Fragment>
    );
};

export default ChatMessages;
