import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {
	CustomizedDialogContent,
	CustomizedDialogTitle,
} from "../CustomizedModal/CustomizedModal";

const MilestoneBidsTermsMismatchModal = ({
	isOpen,
	handleClose,
	handleSubmit,
	bid,
	milestone,
}) => {
	return (
		<Dialog
			onClose={handleClose}
			aria-labelledby="customized-dialog-title"
			open={isOpen}
			maxWidth="sm"
			fullWidth={true}
		>
			<CustomizedDialogTitle id="customized-dialog-title">
				<div className="alert alert-danger text-center">ALERT!</div>
			</CustomizedDialogTitle>
			<CustomizedDialogContent>
				<p className="text-center color-primary">
					Developer bid's terms doesn't match with milestone terms
					please review. By continuing you are agree with the bid's
					terms, milestone terms will automatically be updated to
					match bid's terms...
				</p>
				<table className="table">
					<thead>
						<th></th>
						<th>Milestone</th>
						<th>Bid</th>
					</thead>
					<tbody>
						<tr>
							<td>Pricing</td>
							<td>
								{milestone.price.currency}{" "}
								{milestone.price.amount}
							</td>
							<td>
								{bid.quote.currency} {bid.quote.amount}
							</td>
						</tr>
						<tr>
							<td>Duration</td>
							<td>
								{milestone.estimatedDuration.value}{" "}
								{milestone.estimatedDuration.scope}
							</td>
							<td>
								{bid.deliveredIn.value} {bid.deliveredIn.scope}
							</td>
						</tr>
					</tbody>
				</table>
				<div className="text-end">
					<button
						className="btn btn-primary-gradient outline me-2"
						onClick={handleClose}
					>
						Cancel
					</button>
					<button
						className="btn btn-primary-gradient"
						onClick={() => {
							handleClose();
							handleSubmit();
						}}
					>
						Continue
					</button>
				</div>
			</CustomizedDialogContent>
		</Dialog>
	);
};

export default MilestoneBidsTermsMismatchModal;
