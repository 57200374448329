import { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../actions/alert";
import {
    depositProjectBudget,
    fetchProjectBudgetSummary,
    withdrawProjectBudget,
} from "../../actions/project";
import { ProjectControlCenterContext } from "../../contexts/ProjectControlCenterContext";
import IncreaseBudgetModal from "../Modals/IncreaseBudgetModal/IncreaseBudgetModal";
import WithdrawBudgetModal from "../Modals/WithdrawBudgetModal/WithdrawBudgetModal";

const BudgetTab = ({ projectId }) => {
    const dispatch = useDispatch();

    const projectControlCenterContext = useContext(ProjectControlCenterContext);
    const authState = useSelector((state) => state.auth);

    const [increaseBudgetModal, setIncreaseBudgetModal] = useState(false);
    const [withdrawBudgetModal, setWithdrawBudgetModal] = useState(false);
    const [budgetSummary, setBudgetSummary] = useState(null);

    useEffect(() => {
        if (
            projectControlCenterContext.project?.user?._id ===
            authState.user._id
        ) {
            fetchSummary();
        } else {
            projectControlCenterContext.onChangeTab(0);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchSummary = async () => {
        const response = await dispatch(fetchProjectBudgetSummary(projectId));
        if (response.status) {
            setBudgetSummary(response.data);
        }
    };

    const onBudgetIncrease = async (formData) => {
        const response = await dispatch(
            depositProjectBudget(projectId, formData)
        );
        if (response.status) {
            setBudgetSummary(null);
            fetchSummary();
            dispatch(setAlert(response.msg, "success"));
            setIncreaseBudgetModal(false);
        } else {
            dispatch(setAlert(response.msg, "danger"));
        }
    };

    const onBudgetWithraw = async (formData) => {
        const response = await dispatch(
            withdrawProjectBudget(projectId, formData)
        );
        if (response.status) {
            setBudgetSummary(null);
            fetchSummary();
            dispatch(setAlert(response.msg, "success"));
            setWithdrawBudgetModal(false);
        } else {
            dispatch(setAlert(response.msg, "danger"));
        }
    };

    return (
        <Fragment>
            <h4>Budget</h4>
            <hr></hr>
            {budgetSummary ? (
                <Fragment>
                    <div className="row">
                        <div className="col-md-8">
                            <div>
                                <h6>Budget Distribution By Skill</h6>
                                <div className="light-bg p-3">
                                    <table className="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th>Skill</th>
                                                <th>Budget</th>
                                                <th>Released</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(
                                                budgetSummary.skillDistribution
                                            ).length > 0 ? (
                                                <Fragment>
                                                    {Object.keys(
                                                        budgetSummary.skillDistribution
                                                    ).map((skill) => {
                                                        return (
                                                            <tr>
                                                                <td>{skill}</td>
                                                                <td>
                                                                    ${" "}
                                                                    {
                                                                        budgetSummary
                                                                            .skillDistribution[
                                                                            skill
                                                                        ].budget
                                                                    }
                                                                </td>
                                                                <td>
                                                                    ${" "}
                                                                    {
                                                                        budgetSummary
                                                                            .skillDistribution[
                                                                            skill
                                                                        ]
                                                                            .released
                                                                    }
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </Fragment>
                                            ) : (
                                                <tr>
                                                    <td
                                                        className="text-muted text-center"
                                                        colSpan="3"
                                                    >
                                                        No Skills Found!
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6>Budget Distribution By Developer</h6>
                                <div className="light-bg p-3">
                                    <table className="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th>Developer</th>
                                                <th>Budget</th>
                                                <th>Released</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(
                                                budgetSummary.developerDistribution
                                            ).length > 0 ? (
                                                <Fragment>
                                                    {Object.keys(
                                                        budgetSummary.developerDistribution
                                                    ).map((developer) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    {developer}
                                                                </td>
                                                                <td>
                                                                    ${" "}
                                                                    {
                                                                        budgetSummary
                                                                            .developerDistribution[
                                                                            developer
                                                                        ].budget
                                                                    }
                                                                </td>
                                                                <td>
                                                                    ${" "}
                                                                    {
                                                                        budgetSummary
                                                                            .developerDistribution[
                                                                            developer
                                                                        ]
                                                                            .released
                                                                    }
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </Fragment>
                                            ) : (
                                                <tr>
                                                    <td
                                                        className="text-muted text-center"
                                                        colSpan="3"
                                                    >
                                                        No Developers Found!
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div>
                                <h6>Available Budget</h6>
                                <div className="light-bg text-center p-3">
                                    <h1>$ {budgetSummary.totalBudget}</h1>
                                    <button
                                        className="btn btn-primary-gradient w-100"
                                        onClick={() => {
                                            setIncreaseBudgetModal(true);
                                        }}
                                    >
                                        Increase Budget
                                    </button>
                                    <small
                                        className="pointer d-inline-block mt-2"
                                        onClick={() =>
                                            setWithdrawBudgetModal(true)
                                        }
                                    >
                                        Withdraw To Wallet
                                    </small>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6>Budget Summary</h6>
                                <div className="light-bg p-3">
                                    <table className="table">
                                        <tr>
                                            <td className="text-muted py-1">
                                                Planned Budget
                                            </td>
                                            <td className="text-end">
                                                $ {budgetSummary.plannedBudget}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted py-1">
                                                Unplanned Budget
                                            </td>
                                            <td className="text-end">
                                                ${" "}
                                                {budgetSummary.unplannedBudget}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted py-1">
                                                Total Available Budget
                                            </td>
                                            <td className="text-end">
                                                $ {budgetSummary.totalBudget}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Increase Budget Modal */}
                    <IncreaseBudgetModal
                        isOpen={increaseBudgetModal}
                        handleClose={setIncreaseBudgetModal}
                        handleSubmit={(formData) => onBudgetIncrease(formData)}
                    />

                    {/* Withdraw Budget Modal */}
                    <WithdrawBudgetModal
                        isOpen={withdrawBudgetModal}
                        handleClose={setWithdrawBudgetModal}
                        handleSubmit={(formData) => onBudgetWithraw(formData)}
                    />
                </Fragment>
            ) : (
                <h5 className="text-center text-muted mt-5">
                    Loading Budget...
                </h5>
            )}
        </Fragment>
    );
};

export default BudgetTab;
