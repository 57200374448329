import { Dialog } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../actions/alert";
import { markProjectComplete } from "../../../actions/project";
import LoadingButton from "../../utils/LoadingButton/LoadingButton";
import {
    CustomizedDialogContent,
    CustomizedDialogTitle,
} from "../CustomizedModal/CustomizedModal";

const MarkAsCompleteModal = ({ isOpen, onClose, projectId }) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const onMarkAsComplete = async (e) => {
        e.preventDefault();
        setLoading(true);

        const response = await dispatch(markProjectComplete(projectId));
        if (response.status) {
            dispatch(setAlert(response.msg, "success"));
            onClose();
        } else {
            dispatch(setAlert(response.msg, "danger"));
        }

        // if (props.project.user._id !== authState.user._id) {
        //     formData.hiredUser = authState.user._id;
        // }

        // formData.customerId = props.project.user._id;
        // formData.projectId = props.project._id;

        // socketState.socket.emit("send-mark-project-complete-request", formData);

        setLoading(false);
    };

    return (
        <Dialog
            onClose={() => onClose(false)}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
            maxWidth="sm"
            fullWidth={true}
        >
            <CustomizedDialogTitle id="customized-dialog-title">
                <h3 className="modal-title text-accent">
                    Mark Project As Complete
                </h3>
            </CustomizedDialogTitle>
            <CustomizedDialogContent>
                <form onSubmit={(e) => onMarkAsComplete(e)}>
                    <p className="alert alert-warning">
                        After marking project as complete you won't be able to
                        perform any actions on the project. Before marking
                        project as complete make sure all milestones are
                        completed and payment is released!
                    </p>
                    <LoadingButton
                        text="Mark Project As Complete"
                        isLoading={loading}
                    />
                </form>
            </CustomizedDialogContent>
        </Dialog>
    );
};

// const MarkAsCompleteModal = (props) => {
//     const authState = useSelector((state) => state.auth);
//     const socketState = useSelector((state) => state.socket);

//     const [formData] = useState({
//         hiredUser: "",
//         customerId: "",
//         projectId: "",
//     });

//     const onMarkAsComplete = async (e) => {
//         e.preventDefault();

//         if (props.project.user._id !== authState.user._id) {
//             formData.hiredUser = authState.user._id;
//         }

//         formData.customerId = props.project.user._id;
//         formData.projectId = props.project._id;

//         socketState.socket.emit("send-mark-project-complete-request", formData);
//     };

//     return (
//         <Fragment>
//             <button
//                 type="button"
//                 className="btn btn-primary-gradient"
//                 onClick={(e) => onMarkAsComplete(e)}
//             >
//                 Mark As Complete
//             </button>
//         </Fragment>
//     );
// };

export default MarkAsCompleteModal;
