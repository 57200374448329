import React, { Fragment, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { setNewPayout } from "../../../actions/payout";
import { useDispatch } from "react-redux";
import { createStripeAccount } from "../../../actions/stripe";
import { setAlert } from "../../../actions/alert";
import {
    CustomizedDialogContent,
    CustomizedDialogTitle,
} from "../CustomizedModal/CustomizedModal";

const PayoutMethod = ({ isOpen, onClose }) => {
    const dispatch = useDispatch();
    const [payoutmethodValue] = useState("paypal");
    const [title, setTitle] = useState("");
    const [BankformData, setBankFormData] = useState({
        accountName: "",
        branchName: "",
        ifscCode: "",
        accountNumber: "",
    });

    const [PaypalformData, setPaypalFormData] = useState({
        paypalEmailId: "",
    });

    const onNewPayoutMethod = async (e) => {
        e.preventDefault();

        if (payoutmethodValue === "bank") {
            await dispatch(
                setNewPayout(title, payoutmethodValue, BankformData)
            );
        } else {
            const response = await dispatch(
                setNewPayout(title, payoutmethodValue, PaypalformData)
            );

            if (response.status) {
                dispatch(setAlert(response.msg, "success"));
            } else {
                dispatch(setAlert(response.msg, "danger"));
            }
        }

        onClose(false);
    };

    const onChangePaypalValue = (e) => {
        setPaypalFormData({
            ...PaypalformData,
            [e.target.name]: e.target.value,
        });
    };

    const onChangeTitle = (e) => {
        setTitle(e.target.value);
    };

    const onChangeBankValue = (e) => {
        setBankFormData({ ...BankformData, [e.target.name]: e.target.value });
    };

    const onClickStripeBtn = async () => {
        const response = await dispatch(createStripeAccount());
        if (response.status) {
            window.location.replace(response.data.url);
        }
    };

    const renderForm = () => {
        switch (payoutmethodValue) {
            case "paypal":
                return (
                    <Fragment>
                        <div className="mb-2">
                            <label className="form-label">
                                Enter Your Payout Method Name
                            </label>
                            <input
                                type="text"
                                className="dark-input"
                                placeholder="Enter Your Payout Method Name"
                                name="paypalTitle"
                                onChange={(e) => onChangeTitle(e)}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">
                                Enter Your Paypal Email Id
                            </label>
                            <input
                                type="email"
                                className="dark-input"
                                placeholder="Enter Your Paypal Email Id"
                                name="paypalEmailId"
                                onChange={(e) => onChangePaypalValue(e)}
                                required
                            />
                        </div>
                        <div className="form-group text-right">
                            <button className="btn btn-primary-gradient">
                                Save Payout Method
                            </button>
                        </div>
                    </Fragment>
                );
            case "bank":
                return (
                    <Fragment>
                        <div className="row mb-2">
                            <div className="col-md-6">
                                <label className="form-label">
                                    Payout Method
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Your Payout Method Name"
                                    name="banktitle"
                                    onChange={(e) => onChangeTitle(e)}
                                />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">
                                    Account Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Your Account Name"
                                    name="accountName"
                                    onChange={(e) => onChangeBankValue(e)}
                                />
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-md-6">
                                <label className="form-label">
                                    Branch Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Your Branch Name"
                                    name="branchName"
                                    onChange={(e) => onChangeBankValue(e)}
                                />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">IFSC Code</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Your IFSC Code"
                                    name="ifscCode"
                                    onChange={(e) => onChangeBankValue(e)}
                                />
                            </div>
                        </div>
                        <div className="mb-2">
                            <label className="form-label">Account Number</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Account Number"
                                name="accountNumber"
                                onChange={(e) => onChangeBankValue(e)}
                            />
                        </div>
                        <div className="form-group text-right">
                            <button className="btn btn-primary-gradient">
                                Submit
                            </button>
                        </div>
                    </Fragment>
                );

            case "stripe":
                return (
                    <Fragment>
                        <div className="mb-2">
                            <button
                                type="button"
                                className="btn btn-primary-gradient w-100"
                                onClick={onClickStripeBtn}
                            >
                                Connect to your Stripe Account
                            </button>
                        </div>
                    </Fragment>
                );
            default:
                break;
        }
    };
    return (
        <Fragment>
            <Dialog
                onClose={() => onClose(false)}
                open={isOpen}
                maxWidth="sm"
                fullWidth={true}
            >
                <CustomizedDialogTitle>
                    <h4>Add New Payout Method</h4>
                </CustomizedDialogTitle>
                <CustomizedDialogContent>
                    <form onSubmit={(e) => onNewPayoutMethod(e)}>
                        <div className="row mb-2">
                            {/* <div className="col-md-6">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="payouttype"
                                        id="paypalRadio"
                                        checked={payoutmethodValue == "paypal"}
                                        onClick={() =>
                                            setpayoutmethodValue("paypal")
                                        }
                                    />
                                    <label
                                        className="form-check-label"
                                        for="paypalRadio"
                                    >
                                        Paypal
                                    </label>
                                </div>
                            </div> */}
                            {/* <div className="col-md-4">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="payouttype"
                                        id="bankRadio"
                                        value="bank"
                                        onClick={() =>
                                            setpayoutmethodValue("bank")
                                        }
                                    />
                                    <label
                                        className="form-check-label"
                                        for="bankRadio"
                                    >
                                        Bank
                                    </label>
                                </div>
                            </div> */}
                            {/* <div className="col-md-6">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="payouttype"
                                        id="stripeRadio"
                                        value="stripe"
                                        checked={payoutmethodValue == "stripe"}
                                        onClick={() =>
                                            setpayoutmethodValue("stripe")
                                        }
                                    />
                                    <label
                                        className="form-check-label"
                                        for="stripeRadio"
                                    >
                                        Stripe
                                    </label>
                                </div>
                            </div> */}
                        </div>

                        {renderForm()}
                    </form>
                </CustomizedDialogContent>
            </Dialog>
        </Fragment>
    );
};

export default PayoutMethod;
