import React, { Fragment, useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { getAgreements } from "../../../actions/agreement";
import { getApiEndpoint } from "../../../utils/getApiEndpoint";

const FormStep3 = ({
    selectedAddons,
    addonOptions,
    setSelectedAddons,
    selectedAddonPrice,
    ...props
}) => {
    const radios = useRef();
    const chooseFile = useRef();
    const [agreementOptions, setAgreementOptions] = useState([]);
    //const [selectedAddonPrice, setSelectedAddonPrice] = useState(0);
    const dispatch = useDispatch();

    const fileSelectHandler = (e) => {
        if (e.target.files.length === 0) {
            props.setFile(null);
        } else {
            props.setFile(e.target.files);
        }
    };
    const onChoose = () => {
        chooseFile.current.click();
    };

    useEffect(() => {
        loadInitData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadInitData = async () => {
        const agreements = await dispatch(getAgreements());
        setAgreementOptions(agreements);
    };

    return (
        <Fragment>
            <h6 className="text-center">
                Step 3: Select Agreement or Upload Your Own
            </h6>
            <form onSubmit={(e) => props.onSubmit(e)}>
                <Fragment>
                    <div className="addon-list">
                        <label className="mt-3">Predefined Agreements</label>
                        <div ref={radios}>
                            {agreementOptions.length !== 0 ? (
                                <Fragment>
                                    {agreementOptions.map((agt) => {
                                        return (
                                            <Fragment key={agt._id}>
                                                <div className="addon-item d-flex align-items-center">
                                                    <label
                                                        className="d-flex flex-grow-1 align-items-center p-3 m-0"
                                                        htmlFor={agt._id}
                                                        // onClick={() => setIsCustomAgreement(!isCustomAgreement)}
                                                    >
                                                        <input
                                                            id={agt._id}
                                                            type="radio"
                                                            name="preAgreements"
                                                            value={agt._id}
                                                            onChange={(e) =>
                                                                props.onSelectPreAgreements(
                                                                    e
                                                                )
                                                            }
                                                            disabled={
                                                                props.formData
                                                                    .isCustomAgreement
                                                            }
                                                        />
                                                        <span className="checkmark"></span>
                                                        <p className="flex-grow-1 ms-2 m-0 me-2">
                                                            {agt.agreementName}
                                                        </p>
                                                        <span className="material-icons">
                                                            <a
                                                                href={
                                                                    `${getApiEndpoint()}` +
                                                                    agt.url
                                                                }
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                visibility
                                                            </a>
                                                        </span>
                                                    </label>
                                                </div>
                                            </Fragment>
                                        );
                                    })}
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <p className="text-center text-muted mt-3">
                                        No predefined agreements found!
                                    </p>
                                </Fragment>
                            )}
                        </div>
                        <div className="addon-item d-flex align-items-center">
                            <div
                                className="d-flex flex-grow-1 align-items-center py-3"
                                // onClick={() => setIsCustomAgreement(!isCustomAgreement)}
                            >
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="isCustomAgreement"
                                        value={props.formData.isCustomAgreement}
                                        id="flexCheckDefault"
                                        onChange={(e) =>
                                            props.onChangeCheckedCallback(e)
                                        }
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="flexCheckDefault"
                                    >
                                        Custom Agreement
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {props.formData.isCustomAgreement ? (
                        <Fragment>
                            <div className="form-group my-3">
                                <label className="form-label">
                                    Agreement Name
                                </label>
                                <input
                                    type="text"
                                    className="dark-input"
                                    placeholder="Agreement"
                                    name="agreementName"
                                    value={props.formData.agreementName}
                                    onChange={(e) => props.onChangeCallback(e)}
                                    autoComplete="off"
                                    required
                                />
                            </div>
                            <div className="form-group my-3">
                                <label className="form-label">
                                    Upload Agreement
                                </label>
                                <br />
                                <div className="d-flex align-items-center">
                                    <a
                                        onClick={() => onChoose()}
                                        className="btn btn-secondary"
                                    >
                                        Upload PDF
                                    </a>
                                    <div className="ms-2">
                                        {props.file !== null ? (
                                            <p className="ml-2">
                                                {props.file[0].name}
                                            </p>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                                <input
                                    type="file"
                                    ref={chooseFile}
                                    hidden
                                    accept="application/pdf"
                                    className="form-control"
                                    name="file"
                                    onChange={fileSelectHandler}
                                    multiple
                                />
                            </div>
                        </Fragment>
                    ) : (
                        ""
                    )}
                    <div className="form-group text-end mt-3 mb-0">
                        <a
                            className="btn btn-cancel mr-3"
                            onClick={props.onBack}
                        >
                            Back
                        </a>
                        <button
                            type="submit"
                            className="btn btn-primary-gradient"
                            disabled={props.loading}
                        >
                            Post for {selectedAddonPrice} USD{" "}
                            {props.loading ? (
                                <img
                                    src="/img/loader.gif"
                                    alt="loader"
                                    width="25px"
                                />
                            ) : (
                                ""
                            )}
                        </button>
                    </div>
                </Fragment>
            </form>
        </Fragment>
    );
};

export default FormStep3;
