import { getApiEndpoint } from "../utils/getApiEndpoint";
import { requestWithToken } from "../utils/httpRequest";

import { newPanelMsg } from "./chatPanel";
import { newMsg } from "./chat";

import {
    GETTING_RESPONSE,
    UPDATE_BOT_PANEL,
    BOT_ERROR,
} from "./../actions/types";

// Get response form chatbot
export const getBotResponse = (input) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GETTING_RESPONSE,
            payload: { loading: true },
        });

        const res = await requestWithToken(
            "POST",
            `${getApiEndpoint()}/chatbot`,
            {
                queryText: input,
            }
        );

        const msg = {
            user: {
                name: "chatbot",
                userType: "chatbot",
                _id: "chatbot_id",
            },
            message: res.data.msg,
            msgType: "message",
            action: {
                actionType: "message",
                actionData: {},
                actionTakenType: "",
            },
        };

        // const messages = getState().chatBot.chat.messages;
        // messages.splice(0, 0, msg);

        dispatch({
            type: UPDATE_BOT_PANEL,
            payload: msg,
        });

        if (
            getState().chat.activeRoom &&
            getState().chat.activeRoom._id === "chatbot"
        ) {
            dispatch(newMsg(msg));
        }

        if (
            getState().chatPanel.activeChats.some((el) => el._id === "chatbot")
        ) {
            dispatch(newPanelMsg("chatbot", msg));
        }
    } catch (err) {
        const msg = {
            user: {
                name: "chatbot",
                userType: "chatbot",
                _id: "chatbot_id",
            },
            message: "Chatbot service is down",
            msgType: "message",
            action: {
                actionType: "message",
                actionData: {},
                actionTakenType: "",
            },
        };

        // const messages = getState().chatBot.chat.messages;
        // messages.splice(0, 0, msg);

        dispatch({
            type: BOT_ERROR,
            payload: msg,
        });

        if (
            getState().chat.activeRoom &&
            getState().chat.activeRoom._id === "chatbot"
        ) {
            dispatch(newMsg(msg));
        }

        if (
            getState().chatPanel.activeChats.some((el) => el._id === "chatbot")
        ) {
            dispatch(newPanelMsg("chatbot", msg));
        }
    }
};

// Send Msg to bot
export const sendMsgBot = (input, user) => async (dispatch, getState) => {
    const msg = {
        user: {
            name: user.name,
            userType: user.userType,
            _id: user._id,
        },
        message: input,
        msgType: "message",
        action: {
            actionType: "message",
            actionData: {},
            actionTakenType: "",
        },
    };

    // const messages = getState().chatBot.chat.messages;

    // messages.splice(0, 0, msg);

    dispatch({
        type: UPDATE_BOT_PANEL,
        payload: msg,
    });
    if (
        getState().chat.activeRoom &&
        getState().chat.activeRoom._id === "chatbot"
    ) {
        dispatch(newMsg(msg));
    }

    if (getState().chatPanel.activeChats.some((el) => el._id === "chatbot")) {
        dispatch(newPanelMsg("chatbot", msg));
    }
};
