import Landing from "../pages/Landing/Landing";
import Login from "../pages/Login/Login";
import Register from "../pages/Register/Register";
import VerifyUser from "../pages/VerifyUser/VerifyUser";
import ServiceTerms from "../pages/Terms/ServiceTerms/ServiceTerms";
import PaymentTerms from "../pages/Terms/PaymentTerms/PaymentTerms";
import CopyrightTerms from "../pages/Terms/CopyrightTerms/CopyrightTerms";
import Vision from "../pages/AboutCompany/Vision/Vision";
import Goals from "../pages/AboutCompany/Goals/Goals";
import Impressum from "../pages/AboutCompany/Impressum/Impressum";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import GameForms from "../pages/GameForms/GameForms";
import PostProject from "../pages/PostProject/PostProject";
import GameCustomizer from "../pages/GameCustomizer/GameCustomizer";
import PrivacyPolicy from "../pages/Terms/PrivacyPolicy/PrivacyPolicy";

const routes = [
    {
        path: "/login",
        component: Login,
    },
    { path: "/register", component: Register },
    { path: "/verify", component: VerifyUser },
    {
        path: "/game-forms",
        component: GameForms,
    },
    {
        path: "/game-customizer/:gameFormId",
        component: GameCustomizer,
    },
    {
        path: "/post-project",
        component: PostProject,
    },
    {
        path: "/terms-of-service",
        component: ServiceTerms,
    },
    {
        path: "/terms-of-payment",
        component: PaymentTerms,
    },
    {
        path: "/copyright",
        component: CopyrightTerms,
    },
    {
        path: "/privacy-policy",
        component: PrivacyPolicy,
    },
    {
        path: "/vision",
        component: Vision,
    },
    {
        path: "/impressum",
        component: Impressum,
    },
    {
        path: "/goals",
        component: Goals,
    },
    {
        path: "/forgot-password",
        component: ForgotPassword,
    },
    {
        path: "/reset-password",
        component: ResetPassword,
    },
    { path: "/", component: Landing },
];

export default routes;
