import { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllMilestones } from "../../actions/milestone";
import MilestoneListItem from "../Milestone/ListItem/MilestoneListItem";
import AddEditMilestone from "../Milestone/AddEditMilestone/AddEditMilestone";
import { useLocation } from "react-router-dom";
import MilestoneDetails from "../Milestone/MilestoneDetails/MilestoneDetails";
import { ProjectControlCenterContext } from "../../contexts/ProjectControlCenterContext";
import styles from "./ProjectControlCenter.module.css";
import {
	milestoneUpdated,
	removeSocketEventListener,
} from "../../utils/clientEvents";

const MilestonesTab = ({ projectId, projectOwnerId }) => {
	const dispatch = useDispatch();
	const milestoneQuery = new URLSearchParams(useLocation().search).get(
		"milestone"
	);
	const projectControlCenterContext = useContext(ProjectControlCenterContext);
	const socketState = useSelector((state) => state.socket);

	const [loading, setLoading] = useState(true);
	const [addMilestoneModalOpen, setAddMilestoneModalOpen] = useState(false);
	const [milestoneUpdates, setMilestoneUpdates] = useState(false);

	useEffect(() => {
		fetchMilestones();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectId]);

	useEffect(() => {
		// Realtime page events
		if (socketState.socket) {
			milestoneUpdated(socketState.socket, (data) => {
				setMilestoneUpdates(true);
			});

			return () => {
				removeSocketEventListener(
					socketState.socket,
					"milestone-updated"
				);
			};
		}
	}, [socketState.socket]);

	const fetchMilestones = async () => {
		setMilestoneUpdates(false);
		const response = await dispatch(getAllMilestones(projectId));
		if (response.status) {
			projectControlCenterContext.setMilestones(response.data);
		}

		setLoading(false);
	};

	useEffect(() => {
		projectControlCenterContext.setSelectedMilestone(
			milestoneQuery || null
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [milestoneQuery]);

	return (
		<Fragment>
			{!projectControlCenterContext.selectedMilestone ? (
				<Fragment>
					<div className="d-flex align-items-center">
						<h4 className="flex-grow-1 m-0">Milestones</h4>
						{projectControlCenterContext.project.myBid?.status !==
							"completed" && (
							<button
								type="button"
								className="btn btn-primary-gradient outline"
								onClick={() => setAddMilestoneModalOpen(true)}
							>
								Add Milestone
							</button>
						)}
					</div>
					<hr></hr>
					{milestoneUpdates && (
						<p className="alert alert-danger text-center">
							Milestones updated by developers...{" "}
							{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
							<a
								className="text-decoration-underline"
								onClick={fetchMilestones}
							>
								Refresh Now
							</a>
						</p>
					)}
					{!loading ? (
						<Fragment>
							{/* Add Milestone */}
							<div
								className={`${
									!addMilestoneModalOpen && "d-none"
								} light-bg p-4 mb-5`}
							>
								<h5>Add New Milestone</h5>
								<hr></hr>
								<AddEditMilestone
									isOpen={addMilestoneModalOpen}
									handleClose={async () => {
										projectControlCenterContext.fetchProjectDetails();
										fetchMilestones();
										setAddMilestoneModalOpen();
									}}
									projectId={projectId}
									projectOwnerId={projectOwnerId}
								/>
							</div>

							{projectControlCenterContext.milestones.length >
							0 ? (
								<ul
									className={`${styles["milestone-list"]} p-0`}
								>
									{projectControlCenterContext.milestones.map(
										(milestone, index) => {
											return (
												<MilestoneListItem
													key={index}
													milestone={milestone}
													projectOwnerId={
														projectOwnerId
													}
													reloadMilestones={
														fetchMilestones
													}
												/>
											);
										}
									)}
								</ul>
							) : (
								<h5 className="text-center text-muted mt-5">
									No Milestones Found!
								</h5>
							)}
						</Fragment>
					) : (
						<h5 className="text-center text-muted mt-5">
							Loading Milestones...
						</h5>
					)}
				</Fragment>
			) : (
				<MilestoneDetails
					milestoneId={projectControlCenterContext.selectedMilestone}
				/>
			)}
		</Fragment>
	);
};

export default MilestonesTab;
