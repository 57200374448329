import useEffect from "react";
import { useDispatch } from "react-redux";
import { refreshStripeAccount } from "../../../actions/stripe";

const Refresh = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        loadData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadData = async () => {
        const response = await dispatch(refreshStripeAccount());
        if (response.status) {
            window.location.replace(response.data.url);
        }
    };

    return "Loading...";
};

export default Refresh;
