import React, {
    Fragment,
    useEffect,
    useState,
    useRef,
    useContext,
} from "react";
import { useDispatch } from "react-redux";

import { setBio } from "../../../actions/profile";
import LoadingButton from "../../utils/LoadingButton/LoadingButton";
import { ProfileContext } from "../../../contexts/ProfileContext";

const AboutEdit = ({ toggleMode, reloadProfile }) => {
    useEffect(() => {
        if (
            profileContext.profile.bio === null ||
            profileContext.profile.bio.match(/^ *$/) !== null
        ) {
            bioInput.current.focus();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dispatch = useDispatch();
    const profileContext = useContext(ProfileContext);
    const bioInput = useRef(null);

    const [disable, setDisable] = useState(false);
    const [about, setAbout] = useState(
        profileContext.profile ? profileContext.profile.bio : ""
    );

    const onChangeValue = (e) => {
        setAbout(e.target.value);
    };

    const onFormSubmit = async (e) => {
        e.preventDefault();
        setDisable(true);
        const response = await dispatch(setBio(about));
        if (response.status) {
            profileContext.setProfile(response.data);
            reloadProfile();
            toggleMode(false);
        }
        setDisable(false);
    };

    return (
        <Fragment>
            <div className="section-body">
                <form onSubmit={(e) => onFormSubmit(e)}>
                    <textarea
                        name="about"
                        ref={bioInput}
                        className="dark-input mb-2 aboutText"
                        rows="5"
                        placeholder="Tell About Yourself here..."
                        onChange={(e) => onChangeValue(e)}
                        value={about}
                        required
                    ></textarea>
                    <div className="section-options mb-2">
                        <LoadingButton
                            className="btn-save me-2"
                            text="Save"
                            isLoading={disable}
                        />
                        <button
                            className="btn-cancel"
                            onClick={() => toggleMode(false)}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </Fragment>
    );
};

export default AboutEdit;
