import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import EllipsisParagraph from "../utils/EllipsisParagraph/EllipsisParagraph";

import { getApiEndpoint } from "./../../utils/getApiEndpoint";
import styles from "./projectListItem.module.css";

const ProjectListItem = (props) => {
    return (
        <Fragment>
            <div className="project-item">
                <article className="light-bg p-4 mb-3 ">
                    <div className="d-flex mb-3 mb-lg-0">
                        <div className="profile-pic align-self-start">
                            <img
                                className="profile-pic-lg"
                                src={
                                    props.project.projectOwner.profileImgUrl
                                        ? getApiEndpoint() +
                                          props.project.projectOwner
                                              .profileImgUrl
                                        : "/img/profile-image.png"
                                }
                                alt="Employer"
                            />
                        </div>

                        <div className="job-listed-details flex-grow-1 ms-4 d-none d-md-block">
                            <small>By: {props.project.user.name}</small>
                            <small className="d-inline ms-3">
                                Posted on:{" "}
                                {new Date(
                                    props.project.createdAt
                                ).toDateString()}
                            </small>
                            <small className="d-inline ms-3">
                                {/* <span className="material-icons">location_on</span> */}
                                {/* From: {props.project.user} */}
                            </small>
                            <Link to={"/project/" + props.project._id}>
                                <h3 className="mb-1">{props.project.title}</h3>
                            </Link>
                            {props.project.skills !== null &&
                            props.project.skills.length !== 0 ? (
                                <Fragment>
                                    <ul className="job-skills p-0">
                                        {props.project.skills.map((skill) => {
                                            return (
                                                <Fragment key={skill._id}>
                                                    <li className="badge-dark">
                                                        {skill.name}
                                                    </li>
                                                </Fragment>
                                            );
                                        })}
                                    </ul>
                                </Fragment>
                            ) : (
                                ""
                            )}
                            <EllipsisParagraph
                                className={`${styles["project-description"]} mt-3 `}
                            >
                                {props.project.description}
                            </EllipsisParagraph>
                            {/* <p
                                className={`${styles["project-description"]} mt-3 `}
                            >
                                {props.project.description}
                            </p> */}
                        </div>
                        <div className="job-listed-price align-self-start ms-auto">
                            <h3 className="job-rate">
                                ${props.project.budget.amount}
                            </h3>
                        </div>
                    </div>
                    <div className="job-listed-outside d-md-none">
                        <p className="mb-1">By: {props.project.user.name}</p>
                        <h5 className="d-inline my-3">Posted on: 12-10-2020</h5>
                        <h5 className="d-inline my-3 ms-3">
                            {/* <span className="material-icons">location_on</span> */}
                            From: India
                        </h5>
                        <h2 className="mb-3">{props.project.title}</h2>
                        {props.project.skills !== null ? (
                            <Fragment>
                                <ul className="job-skills my-2">
                                    {props.project.skills.map((skill) => {
                                        return (
                                            <Fragment key={skill._id}>
                                                <li className="badge-dark">
                                                    {skill.name}
                                                </li>
                                            </Fragment>
                                        );
                                    })}
                                </ul>
                            </Fragment>
                        ) : (
                            ""
                        )}
                        <p className={`${styles["project-description"]} mt-3 `}>
                            {props.project.description}
                        </p>
                    </div>
                </article>
            </div>
        </Fragment>
    );
};

export default ProjectListItem;
