import React, { useState } from "react";
import { Dialog } from "@material-ui/core";
import {
    CustomizedDialogContent,
    CustomizedDialogTitle,
} from "../CustomizedModal/CustomizedModal";
import { useFormik } from "formik";
import LoadingButton from "../../utils/LoadingButton/LoadingButton";
import { useDispatch } from "react-redux";
import { extendMilestoneDeadline } from "../../../actions/milestone";
import { setAlert } from "../../../actions/alert";

const ExtendDeadlineModal = ({ isOpen, onClose, milestoneId }) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const extendDeadlineFormik = useFormik({
        initialValues: {
            extraDurationValue: "",
            extraDurationScope: "days",
            extendReason: "",
        },
        onSubmit: async (values) => {
            setLoading(true);
            const response = await dispatch(
                extendMilestoneDeadline(milestoneId, values)
            );
            if (response.status) {
                dispatch(setAlert(response.msg, "success"));
                onClose();
            } else {
                dispatch(setAlert(response.msg, "danger"));
            }
            setLoading(false);
        },
    });

    return (
        <Dialog
            onClose={() => onClose(false)}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
            maxWidth="sm"
            fullWidth={true}
        >
            <CustomizedDialogTitle id="customized-dialog-title">
                <h3 className="modal-title text-accent">Extend Deadline</h3>
            </CustomizedDialogTitle>
            <CustomizedDialogContent>
                <form onSubmit={extendDeadlineFormik.handleSubmit}>
                    <div className="mb-2">
                        <label className="form-label">
                            Add Extra <small className="text-danger">*</small>
                        </label>
                        <div className="input-group">
                            <input
                                type="text"
                                name="extraDurationValue"
                                value={
                                    extendDeadlineFormik.values
                                        .extraDurationValue
                                }
                                className={`dark-input flex-grow-1 borders-right-radius-none`}
                                style={{ width: "0px" }}
                                placeholder="Estimated Time"
                                required
                                onChange={extendDeadlineFormik.handleChange}
                            />
                            <select
                                name="extraDurationScope"
                                className={`dark-input flex-grow-1 borders-left-radius-none`}
                                style={{ width: "0px" }}
                                value={
                                    extendDeadlineFormik.values
                                        .extraDurationScope
                                }
                                onChange={extendDeadlineFormik.handleChange}
                                required
                            >
                                <option value="days">Days</option>
                                <option value="weeks">Weeks</option>
                                <option value="months">Months</option>
                            </select>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">
                            Extend Reason{" "}
                            <small className="text-danger">*</small>
                        </label>
                        <textarea
                            rows="3"
                            name="extendReason"
                            className="dark-input"
                            value={extendDeadlineFormik.values.extendReason}
                            placeholder="Extend Reason..."
                            onChange={extendDeadlineFormik.handleChange}
                            required
                        ></textarea>
                    </div>
                    <div>
                        <LoadingButton
                            isLoading={loading}
                            text="Update Deadline"
                        />
                    </div>
                </form>
            </CustomizedDialogContent>
        </Dialog>
    );
};

export default ExtendDeadlineModal;
