import { getApiEndpoint } from "../utils/getApiEndpoint";
import { requestWithToken } from "../utils/httpRequest";
import { error } from "./error";

// Post new bid
export const postBid = (formData) => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "POST",
            `${getApiEndpoint()}/bid`,
            formData
        );
        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Fetch submitted bids for a project
export const fetchProjectBids = (projectId) => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/bid?projectId=${projectId}`
        );

        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Fetch submitted bids
export const fetchSubmittedBids = () => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/bid/submitted`
        );

        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Fetch hired bids on project
export const fetchHiredBidsOnProject = (projectId) => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/bid/hired?projectId=${projectId}`
        );

        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Save bid rating for a bid
export const saveBidRating = (formData) => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "POST",
            `${getApiEndpoint()}/bid/rating`,
            formData
        );

        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};
