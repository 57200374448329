import React, { Fragment } from "react";
import Skeleton from "../../Skeleton/Skeleton";

const PortfolioSkeleton = () => {
    return (
        <Fragment>
            <div className="row">
                <div className="col-md-4">
                    <div className="position-relative mb-2">
                        <Skeleton height="100px" />
                    </div>
                    <div>
                        <Skeleton />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default PortfolioSkeleton;
