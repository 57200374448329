import React, { useEffect } from "react";
import { TourProvider, useTour } from "@reactour/tour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useDispatch, useSelector } from "react-redux";

import { toggleDirectTutorial, toggleTutorial } from "../../actions/reacttour";

const CustomTourProvider = (props) => {
    const { setIsOpen, setSteps, setCurrentStep } = useTour();
    const tourState = useSelector((state) => state.reacttour);
    const authState = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const radius = 10;
    const disableBody = (target) => {
        return disableBodyScroll(target);
    };
    const beforeClose = (target) => {
        enableBodyScroll(target);
        dispatch(toggleDirectTutorial(false));
        dispatch(toggleTutorial(false));
    };

    useEffect(() => {
        setSteps(tourState.steps);
        setCurrentStep(0);
        if (authState.user && authState.user.profile) {
            setIsOpen(tourState.tutorials);
            // setIsOpen(tourState.tutorials || authState.user.tutorial);
        }

        // eslint-disable-next-line
    }, [tourState.steps, tourState.tutorials]);

    return (
        <TourProvider
            styles={{
                popover: (base) => ({
                    ...base,
                    "--reactour-accent": "#ef5a3d",
                    borderRadius: radius,
                    background: "#de3a3a",
                }),
                maskArea: (base) => ({
                    ...base,
                    rx: radius,
                }),
                maskWrapper: (base) => ({
                    ...base,
                    color: "#ef5a3d",
                }),
                badge: (base) => ({
                    ...base,
                    left: "auto",
                    right: "-0.8125em",
                    background: "#707070",
                }),
                controls: (base) => ({ ...base, marginTop: 100 }),
                close: (base) => ({ ...base, right: "auto", left: 8, top: 8 }),
                dot: (base) => ({
                    ...base,
                    animationDuration: "1s",
                    animationIterationCount: "infinite",
                    "&:nth-of-type(1)": {
                        animationDelay: ".3s",
                    },
                    "&:nth-of-type(2)": {
                        animationDelay: ".6s",
                    },
                }),
            }}
            steps={tourState.steps}
            startAt={0}
            onClickMask={({ setIsOpen }) => {
                setIsOpen(true);
            }}
            onClickClose={({ toggleTutorial }) => {
                // toggleTutorial(false);
                dispatch(toggleDirectTutorial(false));
            }}
            afterOpen={disableBody}
            beforeClose={beforeClose}
        >
            {props.children}
        </TourProvider>
    );
};

export default CustomTourProvider;
