import { HIDE_TOPLOADER, SHOW_TOPLOADER } from "../actions/types";

const initialState = {
    visible: false,
};

const topLoader = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
        case SHOW_TOPLOADER:
            return { ...state, visible: true };
        case HIDE_TOPLOADER:
            return { ...state, visible: false };
        default:
            return state;
    }
};

export default topLoader;
