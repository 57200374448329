import React, { Fragment } from "react";
import styles from "./Goals.module.css";
const Goals = () => {
    return (
        <Fragment>
            <div style={{ paddingTop: "7rem", paddingBottom: "4rem" }}>
                <div
                    className="container"
                    style={{ color: "#fff", paddingLeft: "40px" }}
                >
                    <h1 className={styles["heading"]}>Goals</h1>
                    <h3>Last Updated: August 2021</h3>

                    <p>
                        With NeoxEntertainment I am constantly trying to provide
                        my developers and customers the best possible quality
                        and Satisfaction also I want to help as many people as
                        possible to build their games so the creation of trinity
                        was the next logical Step but what's next?
                    </p>

                    <h3>RoadMap</h3>
                    <h5>Trinity GameCustomizer</h5>

                    <p>
                        {" "}
                        With the Game Customizer, you will be able to plan full
                        games in a quick and easy way Outsourcing projects and
                        game development tasks were never that easy before.
                    </p>
                    <h5>Upcoming Updates for Trinity</h5>
                    <ul className={styles["no-list-type"]}>
                        <li>Free CRM Solution</li>
                        <p>
                            {" "}
                            Trinity Developers get a Free CRM Solution as soon
                            as they got unlocked this will allow them to plan
                            and organize projects better than anyone else on the
                            market
                        </p>
                        <li>Online Video Courses</li>
                        <p>
                            {" "}
                            Later Video Courses will get created which will help
                            Clients and Developers to extend their Knowledge in
                            all Areas from Planning to Marketing. This will be
                            the first course where you not only learn to build
                            games but also learn how to coordinate teams and
                            publish your games. Also, you will learn more about
                            getting the first players directly after the launch
                            and how you can create successful Crowdfunding
                            campaigns to finance your game.
                        </p>
                    </ul>
                    <h3>Next Big Updates and Plans</h3>
                    <p>
                        {" "}
                        There are new awesome technologies get released every
                        day one of those is Pixelstreaming which allows people
                        to play high realistic games in the browser with Trinity
                        I want to go in a direction that you can build host and
                        play the games which the community-made all in one
                        platform like that you can later also easily host your
                        games with our services.
                    </p>
                    <p>
                        {" "}
                        The Next Big Step will be the Connection to Different
                        Metaverse project since I think this will be the future
                        and this will also bring a lot of potential customers to
                        our website since all the buildings which people in a
                        virtual world want to have also need to get created by
                        someone.
                    </p>
                </div>
            </div>
        </Fragment>
    );
};

export default Goals;
