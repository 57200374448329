import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { seo } from "../../utils/seo";
import { Dropdown } from "semantic-ui-react";
import Slider from "@material-ui/core/Slider";
import { fetchSkillsById } from "../../actions/project";
import ProjectListItem from "../../components/ProjectListItem/ProjectListItem";
import { fetchProjects } from "../../actions/project";
import { getActiveSkills } from "../../actions/skills";
import { toggleTopLoader } from "../../actions/topLoader";
import { useTour } from "@reactour/tour";
import Skeleton from "../../components/Skeleton/Skeleton";
import { getSteps } from "../../utils/tourSteps";
import { reacttourUpdate } from "../../actions/reacttour";

const BrowseProjects = (props) => {
    const dispatch = useDispatch();

    const isMounted = useRef(true);

    const [projects, setProjects] = useState(null);
    const [loadingProjects, setLoadingProjects] = useState(true);
    // const [recommended, setRecommended] = useState(true);
    const tourSteps = useSelector((state) => state.reacttour.steps);
    const [minBudgetRange] = useState(0);
    const [maxBudgetRange] = useState(10000);
    const [budgetRange, setBudgetRange] = useState([
        minBudgetRange,
        maxBudgetRange,
    ]);
    const { setSteps } = useTour();
    const [skills, setSkills] = useState([]);
    const [skillsArray, setSkillsArray] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [listLength, setListLength] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const user = useSelector((state) => state.auth.user);

    useEffect(() => {
        seo({ title: "Browse Projects" });
        loadData();

        return () => {
            dispatch(toggleTopLoader(false));
        };
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        dispatch(reacttourUpdate(getSteps("browseProjectSteps")));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        // setSkills([]);
        loadUserSkills();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        dispatch(toggleTopLoader(true));
        if (projects && skillsArray) {
            dispatch(toggleTopLoader(false));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projects, skillsArray]);

    // useEffect(() => {
    //     loadProjects({ skills, budgetRange }, 1, listLength);

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [skills, budgetRange]);

    useEffect(() => {
        if (isMounted.current) setSteps(tourSteps);
        // setIsOpen(authState.user ? authState.user.tutorial : false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tourSteps]);

    const loadUserSkills = async () => {
        if (user && user._id != null) {
            const res = await dispatch(fetchSkillsById(user._id));
            if (res.status) {
                res.data.map((userSkill) => {
                    if (isMounted.current)
                        setSkills((prevSkills) => [
                            ...prevSkills,
                            `${userSkill._id}`,
                        ]);
                    return null;
                });
            }
            // loadProjects({ skills, budgetRange }, 1, listLength);
        }
    };

    const loadProjects = async (formData, page, limit) => {
        const projects = await dispatch(fetchProjects(formData, page, limit));
        if (projects.data.status) {
            if (isMounted.current) {
                setLoadingProjects(false);
                setProjects(projects.data.data.projects);
                setTotalPages(projects.data.data.totalPages);
            }
        }
    };

    const loadData = async (e) => {
        loadProjects({}, currentPage, listLength);
        const skills = await dispatch(getActiveSkills());

        if (skills.status) {
            if (isMounted.current)
                setSkillsArray(
                    skills.data.map((skill) => {
                        return {
                            key: skill._id,
                            text: skill.name,
                            value: skill._id,
                        };
                    })
                );
        }
    };

    const onFilterFormSubmit = async (e) => {
        e.preventDefault();
        loadProjects({ skills, budgetRange }, 1, listLength);
    };

    const onChangeDropdownValue = (e, data) => {
        if (isMounted.current) setSkills(data.value);
    };

    const handleBudgetRange = (event, newValue) => {
        if (isMounted.current) setBudgetRange(newValue);
    };

    return (
        <Fragment>
            <div className="container">
                <div className="row w-100">
                    <div className="col-lg-3 px-lg-2 m-0">
                        <span data-tour="step-browseProject-1">
                            <section className="filter-jobs p-4 light-bg mb-3 d-none d-lg-block">
                                <h5 className="text-capitalize text-center">
                                    Filter Projects
                                </h5>

                                <form onSubmit={(e) => onFilterFormSubmit(e)}>
                                    <div className="mb-3">
                                        <label className="form-label">
                                            Skills
                                        </label>
                                        <Dropdown
                                            placeholder="Skills"
                                            className="dark-input dropdown-select sementic-dropdown"
                                            name="skills"
                                            multiple
                                            search
                                            selection
                                            options={skillsArray}
                                            onChange={onChangeDropdownValue}
                                            value={skills}
                                            required
                                        />
                                    </div>

                                    <div className="mb-0">
                                        <label className="form-label">
                                            Budget Range
                                        </label>
                                        <Slider
                                            min={minBudgetRange}
                                            max={maxBudgetRange}
                                            className="mb-3"
                                            value={budgetRange}
                                            valueLabelDisplay="auto"
                                            aria-labelledby="range-slider"
                                            onChange={handleBudgetRange}
                                        />
                                    </div>

                                    {/* <div className='mb-2'>
									<input
										type='checkbox'
										id='recommended'
										name='recommended'
										className='me-2'
										value={recommended}
										onClick={(e) => onRecommmendChange(e)}
									/>
									<label
										className='form-label'
										for='recommended'
									>
										Recommended Projects
									</label>
								</div> */}

                                    <div>
                                        <button
                                            type="submit"
                                            className="btn btn-primary-gradient w-100"
                                        >
                                            Filter
                                        </button>
                                    </div>
                                </form>
                            </section>
                        </span>
                    </div>
                    <div className="col-lg-9 px-lg-2 m-0">
                        <span data-tour="step-browseProject-2">
                            <div className="d-flex align-items-center">
                                <h5 className="text-capitalize flex-grow-1">
                                    Related Jobs
                                </h5>
                                <div className="d-flex align-items-center">
                                    Showing:{" "}
                                    <select
                                        className="dark-input ms-2"
                                        value={listLength}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setListLength(value);
                                            loadProjects(
                                                { budgetRange, skills },
                                                1,
                                                value
                                            );
                                        }}
                                    >
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="10">10</option>
                                    </select>
                                </div>
                            </div>
                        </span>
                        <section className="job-results my-lg-3 mb-3">
                            {loadingProjects ? (
                                [1, 2].map((el, index) => (
                                    <article
                                        key={index}
                                        className="light-bg p-4 mb-3 "
                                    >
                                        <div className="d-flex mb-3 mb-lg-0">
                                            <div className="profile-pic align-self-center">
                                                <Skeleton
                                                    shape="circle"
                                                    height="50px"
                                                    width="50px"
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    marginRight: "9px",
                                                }}
                                                className="job-listed-details flex-grow-1 ms-4 d-none d-md-block"
                                            >
                                                {/* <p className="mb-1">
						<Skeleton />
					  </p> */}
                                                <h5 className="d-inline my-3">
                                                    <Skeleton />
                                                </h5>
                                                {/* <h5 className="d-inline my-3 ms-3">
						<Skeleton />
					  </h5> */}
                                                {/* <h2 className="mb-3">
						<Skeleton />
					  </h2> */}

                                                <Fragment>
                                                    <ul
                                                        className="job-skills p-0"
                                                        style={{
                                                            listStyleType:
                                                                "none",
                                                            marginTop: "19px",
                                                            marginBottom:
                                                                "19px",
                                                        }}
                                                    >
                                                        <Fragment>
                                                            <Skeleton />
                                                        </Fragment>
                                                    </ul>
                                                </Fragment>

                                                <div className="mt-3">
                                                    <Skeleton />
                                                </div>
                                            </div>
                                            <div className="job-listed-price align-self-center ms-auto">
                                                <h2 className="job-rate">
                                                    <Skeleton />
                                                </h2>
                                            </div>
                                        </div>
                                    </article>
                                ))
                            ) : projects && projects.length ? (
                                projects.map((project, index) => {
                                    return (
                                        <ProjectListItem
                                            key={index}
                                            project={project}
                                        />
                                    );
                                })
                            ) : (
                                <>
                                    <h4 className="text-center py-5">
                                        No Projects Found
                                    </h4>
                                </>
                            )}
                            <div className="d-flex align-items-center justify-content-center gap-3">
                                {!loadingProjects && currentPage > 1 && (
                                    <button
                                        type="button"
                                        onClick={() => {
                                            loadProjects(
                                                { skills, budgetRange },
                                                currentPage - 1,
                                                listLength
                                            );
                                            setCurrentPage(
                                                (value) => value - 1
                                            );
                                        }}
                                        className="btn btn-primary-gradient"
                                    >
                                        Previous
                                    </button>
                                )}

                                <ul className="pagination mb-0">
                                    {totalPages > 1 &&
                                        [...Array(totalPages)].map(
                                            (number, i) => (
                                                <li
                                                    key={i}
                                                    className="page-item"
                                                >
                                                    <a
                                                        onClick={() => {
                                                            loadProjects(
                                                                {
                                                                    skills,
                                                                    budgetRange,
                                                                },
                                                                i + 1,
                                                                listLength
                                                            );
                                                            setCurrentPage(
                                                                i + 1
                                                            );
                                                        }}
                                                        className="page-link"
                                                        style={{
                                                            background: "black",
                                                        }}
                                                    >
                                                        {i + 1}
                                                    </a>
                                                </li>
                                            )
                                        )}
                                </ul>

                                {currentPage < totalPages && (
                                    <button
                                        type="button"
                                        onClick={() => {
                                            loadProjects(
                                                { skills, budgetRange },
                                                currentPage + 1,
                                                listLength
                                            );
                                            setCurrentPage(
                                                (value) => value + 1
                                            );
                                        }}
                                        className="btn btn-primary-gradient"
                                    >
                                        Next
                                    </button>
                                )}

                                {/* {pagination.next != null ? (
                                    <button
                                        type="button"
                                        onClick={() =>
                                            loadProjects(
                                                { skills, budgetRange },
                                                pagination.next.page,
                                                listLength
                                            )
                                        }
                                        className="btn btn-primary-gradient"
                                    >
                                        Next
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        className="btn btn-primary-gradient"
                                        disabled={true}
                                        style={{ opacity: 0.4 }}
                                    >
                                        Next
                                    </button>
                                )} */}
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default BrowseProjects;
