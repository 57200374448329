import { DISABLE_TUTORIAL, REACTTOUR_STEPS, TOGGLE_REACTTOUR } from "./types";
import { setAlert } from "./alert";
import { error } from "./error";
import { requestWithToken } from "../utils/httpRequest";
import { getApiEndpoint } from "../utils/getApiEndpoint";

export const reacttourUpdate = (steps) => async (dispatch) => {
    try {
        dispatch({
            type: REACTTOUR_STEPS,
            payload: steps,
        });
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const toggleTutorial = (status) => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "PUT",
            `${getApiEndpoint()}/users/tutorial/${status}`
        );

        dispatch({
            type: DISABLE_TUTORIAL,
        });

        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

export const toggleDirectTutorial = (tutorialState) => async (dispatch) => {
    if (tutorialState) {
        dispatch({
            type: TOGGLE_REACTTOUR,
            payload: tutorialState,
        });
    } else {
        dispatch({
            type: TOGGLE_REACTTOUR,
            payload: tutorialState,
        });
    }
};
