import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { setAlert } from "../../actions/alert";
import { verifyUser } from "../../actions/auth";

// Styling

const VerifyUser = (props) => {
    const history = useHistory();
    const search = useLocation().search;
    const token = new URLSearchParams(search).get("token");
    const userId = new URLSearchParams(search).get("userId");
    const dispatch = useDispatch();

    const [verifying, setVerifying] = useState(true);

    useEffect(() => {
        loadData();

        // eslint-disable-next-line
    }, []);

    const loadData = async () => {
        const res = await dispatch(verifyUser(token, userId));
        if (res.status) {
            dispatch(setAlert(res.msg, "success"));
            history.push("/login");
        } else {
            dispatch(setAlert(res.msg, "danger"));
            setVerifying(false);
        }
    };

    return (
        <Fragment>
            <div className="py-5">
                {verifying ? (
                    <div>Verifying...</div>
                ) : (
                    <div>User not verified</div>
                )}
            </div>
        </Fragment>
    );
};

export default VerifyUser;
