import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { contact } from "../../../actions/faq";

// import CustomizedAccordions from '../Accordian/Accordian';

const FAQ = () => {
    const [formData, setFormData] = useState({
        message: "",
        receiverName: "",
        receiverEmail: "",
    });
    const [submitForm, setSubmitForm] = useState(false);
    const dispatch = useDispatch();
    const { message, receiverEmail, receiverName } = formData;
    const onChangeValue = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const onSubmit = async (e) => {
        if (!submitForm) {
            setSubmitForm(true);
            e.preventDefault();
            const res = await dispatch(contact(formData));
            if (res.status === 1) setSubmitForm(false);
        }
    };
    return (
        <Fragment>
            <section id="faq-section" className="py-5 px-3">
                <div className="container">
                    <div className="row w-100">
                        <div className="col-md-6">
                            {/* <CustomizedAccordian /> */}

                            <div
                                className="accordion"
                                id="faqAccordian"
                                style={{ color: "#000" }}
                            >
                                <div
                                    className="accordion-item"
                                    style={{ border: "none" }}
                                >
                                    <h2
                                        className="accordion-header"
                                        id="headingOne"
                                    >
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            style={{
                                                color: "#fff",
                                                backgroundColor: "#e4391c",
                                            }}
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            What is a Game Configurator?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#faqAccordian"
                                        style={{
                                            color: "#fff",
                                            backgroundColor: "#373737",
                                        }}
                                    >
                                        <div className="accordion-body">
                                            In our Game Configurator we will ask
                                            you different questions which will
                                            help our developers to realize your
                                            dream of your own game more easily.
                                            In my past i often noticed that
                                            there can be problems between
                                            developers and customers because the
                                            developers dont have all the needed
                                            informations structured so that they
                                            can directly cam implement those.
                                            The game configurator structurize
                                            your answers and post those as a
                                            project here on trintiy so that our
                                            carefully selected freelancers can
                                            apply to it.
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="accordion-item"
                                    style={{ border: "none" }}
                                >
                                    <h2
                                        className="accordion-header"
                                        id="headingTwo"
                                    >
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            style={{
                                                color: "#fff",
                                                backgroundColor: "#e4391c",
                                            }}
                                            aria-controls="collapseTwo"
                                        >
                                            How does the project coordinator
                                            addon work?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTwo"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#faqAccordian"
                                        style={{
                                            color: "#fff",
                                            backgroundColor: "#373737",
                                        }}
                                    >
                                        <div className="accordion-body">
                                            I had many customers before who came
                                            from different areas and are not
                                            familar with the game development
                                            area but also I had customers who
                                            have many different projects at the
                                            same time so that they dont have all
                                            the time to coordinate the
                                            development of a game. The Project
                                            Coordinator Addon will be perfect
                                            for you because you can hire a
                                            project coordinator who will meet
                                            with you to discuss all the details
                                            and then will manage the full
                                            gamedevelopment process for you like
                                            that you only have one contact
                                            person which coordinates a big
                                            developer network.
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="accordion-item"
                                    style={{ border: "none" }}
                                >
                                    <h2
                                        className="accordion-header"
                                        id="headingThree"
                                    >
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree"
                                            style={{
                                                color: "#fff",
                                                backgroundColor: "#e4391c",
                                            }}
                                        >
                                            How the game configurator can help?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseThree"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingThree"
                                        data-bs-parent="#faqAccordian"
                                        style={{
                                            color: "#fff",
                                            backgroundColor: "#373737",
                                        }}
                                    >
                                        <div className="accordion-body">
                                            The game configurator will help you
                                            to quickly plan your game with out
                                            the need of complex Gamedesign
                                            Documents etc like that you dont
                                            only focus on planing but also can
                                            quickly start with the game
                                            development Also the Configurator
                                            helps our developers to get to know
                                            you and your projects but also your
                                            vision and requirements for the
                                            game.
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="accordion-item"
                                    style={{ border: "none" }}
                                >
                                    <h2
                                        className="accordion-header"
                                        id="headingFour"
                                    >
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour"
                                            aria-expanded="false"
                                            aria-controls="collapseFour"
                                            style={{
                                                color: "#fff",
                                                backgroundColor: "#e4391c",
                                            }}
                                        >
                                            How does the payment work?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFour"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingFour"
                                        data-bs-parent="#faqAccordian"
                                        style={{
                                            color: "#fff",
                                            backgroundColor: "#373737",
                                        }}
                                    >
                                        <div className="accordion-body">
                                            Payments work over Stripe and
                                            Paypal. Posting a project is free
                                            and you only need to pay once you
                                            found a freelancer who will help you
                                            with the project. If you select
                                            addons like a Game Coordinator there
                                            can be costs even for posting a
                                            project. Developers get their money
                                            after the sucessfull delivery of the
                                            project and after the customer is
                                            happy with the work. After 7 days
                                            the money can get paid out to your
                                            bank account. For Each project
                                            Trinity charges fees of 5% from the
                                            customer but also 5% fees from the
                                            developer.
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="accordion-item"
                                    style={{ border: "none" }}
                                >
                                    <h2
                                        className="accordion-header"
                                        id="headingFive"
                                    >
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFive"
                                            aria-expanded="false"
                                            aria-controls="collapseFive"
                                            style={{
                                                color: "#fff",
                                                backgroundColor: "#e4391c",
                                            }}
                                        >
                                            How can I earn money as a
                                            Freelancer?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFive"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingFive"
                                        data-bs-parent="#faqAccordian"
                                        style={{
                                            color: "#fff",
                                            backgroundColor: "#373737",
                                        }}
                                    >
                                        <div className="accordion-body">
                                            After the Sucessful registration you
                                            can go to your profile and fill out
                                            your portfolio etc. then you can
                                            request the access to bid to
                                            projects our team will double check
                                            your portfolio and past work. This
                                            is needed because we want to provide
                                            our customers the best possible
                                            quality but it will also help you
                                            since you will get more projects
                                            because we only have a small ammount
                                            of developers.
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="accordion-item"
                                    style={{ border: "none" }}
                                >
                                    <h2
                                        className="accordion-header"
                                        id="headingSix"
                                    >
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseSix"
                                            aria-expanded="false"
                                            aria-controls="collapseSix"
                                            style={{
                                                color: "#fff",
                                                backgroundColor: "#e4391c",
                                            }}
                                        >
                                            The Different User Types - How to
                                            work as a recruiter ?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseSix"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingSix"
                                        data-bs-parent="#faqAccordian"
                                        style={{
                                            color: "#fff",
                                            backgroundColor: "#373737",
                                        }}
                                    >
                                        <div className="accordion-body">
                                            <p>
                                                We choose the name Trinity since
                                                there are 3 different user
                                                types.
                                            </p>
                                            <p>
                                                Customers: can customize their
                                                dream game in a simple
                                                webconfigurator.
                                            </p>
                                            <p>
                                                Developers: help the customers
                                                to build their game.
                                            </p>
                                            <p>
                                                Project Coordinators: help the
                                                Customer with the planing of the
                                                game and coordinate the
                                                development process.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h3 className="text-center section-title mb-4 mt-5">
                                Still have question?
                            </h3>
                            <form onSubmit={(e) => onSubmit(e)}>
                                <table className="table table-borderless text-white">
                                    <tbody>
                                        <tr>
                                            <td>Full Name:</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Full Name"
                                                    name="receiverName"
                                                    value={receiverName}
                                                    onChange={(e) =>
                                                        onChangeValue(e)
                                                    }
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Email:</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Email"
                                                    name="receiverEmail"
                                                    value={receiverEmail}
                                                    onChange={(e) =>
                                                        onChangeValue(e)
                                                    }
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Message:</td>
                                            <td>
                                                <textarea
                                                    rows="5"
                                                    className="form-control"
                                                    placeholder="Message"
                                                    name="message"
                                                    value={message}
                                                    onChange={(e) =>
                                                        onChangeValue(e)
                                                    }
                                                ></textarea>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button
                                    type="submit"
                                    className="btn btn-light float-end"
                                    disabled={submitForm}
                                >
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default FAQ;
