import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import { resetpassword } from "../../actions/auth";

// Styling
import styles from "./ResetPassword.module.css";

const ResetPassword = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    //const { token } = useParams();

    const [disable] = useState(false);

    const [formData, setFormData] = useState({
        email: "",
        newpassword: "",
        confirmpassword: "",
    });

    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    const userId = queryParams.get("userId");

    // const loadUser = async () => {
    //   dispatch(toggleTopLoader(true));
    //   const res = await dispatch(authUser());

    //   if (res.status == 1) {
    //     //redirect login
    //     history.push("/dashboard");
    //   }

    //   dispatch(toggleTopLoader(false));
    // };

    useEffect(() => {
        //loadUser();
        if (token === "" || token === null) {
            history.replace("/");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { newpassword, confirmpassword } = formData;

    const onChangeValue = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const onPasswordFormSubmit = async (e) => {
        e.preventDefault();
        if (newpassword !== confirmpassword) {
            dispatch(setAlert("Passwords do not match", "danger"));
        } else {
            const res = await dispatch(
                resetpassword(token, userId, confirmpassword)
            );
            if (res.status === 1) {
                dispatch(setAlert(res.msg, "success"));
                history.push("/login");
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
        }
    };

    return (
        <Fragment>
            <div className="container d-flex justify-content-center align-items-center py-5">
                <div
                    className="box-shadow light-bg my-5 px-5 py-4"
                    id={`${styles["login-box"]}`}
                >
                    <h4 className="text-center">Reset Password</h4>
                    <hr></hr>
                    <form
                        onSubmit={(e) => {
                            onPasswordFormSubmit(e);
                        }}
                        method="post"
                    >
                        <div className="mb-2">
                            {/* <label className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                name="email"
                placeholder="Email"
                value={email}
                onChange={(e) => onChangeValue(e)}
                required
              ></input> */}
                            <label className="form-label">New Password</label>
                            <input
                                type="text"
                                className="form-control"
                                name="newpassword"
                                placeholder="New Password"
                                value={newpassword}
                                onChange={(e) => onChangeValue(e)}
                                required
                            ></input>
                            <label className="form-label">
                                Confirm Password
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name="confirmpassword"
                                placeholder="Confirm Password"
                                value={confirmpassword}
                                onChange={(e) => onChangeValue(e)}
                                required
                            ></input>
                        </div>
                        <div className="mb-2">
                            <button
                                disabled={disable}
                                type="submit"
                                className={
                                    "btn btn-primary-gradient form-control"
                                }
                            >
                                Reset Password{" "}
                                {disable ? (
                                    <img
                                        src="/img/loader.gif"
                                        alt="loader"
                                        width="25px"
                                    />
                                ) : (
                                    ""
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    );
};

export default ResetPassword;
