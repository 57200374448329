import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import {
	fetchProjectAttachments,
	fetchProjectById,
	uploadProjectAttachment,
} from "../../actions/project";
import CreateBidModal from "../../components/Modals/CreateBidModal/CreateBidModal";
import ReviewModal from "../../components/Modals/ReviewModal/ReviewModal";
import ProjectDetailsSkeleton from "./Skeleton/Skeleton";
import { getPortalSettingByKey } from "../../actions/portalSetting";
import { seo } from "../../utils/seo";
import { toggleTopLoader } from "../../actions/topLoader";
import { getApiEndpoint } from "../../utils/getApiEndpoint";
import { useTour } from "@reactour/tour";
import CustomTourProvider from "./../../components/CustomTourProvider/CustomTourProvider";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { fetchMyActiveReferral } from "../../actions/referral";
import { Tab, Tabs } from "@material-ui/core";
import { fetchProjectBids } from "../../actions/bid";
import { setAlert } from "../../actions/alert";
import BidListItem from "../../components/BidListItem/BidListItem";
import MilestoneListItem from "../../components/Milestone/ListItem/MilestoneListItem";
import { getAllMilestones } from "../../actions/milestone";
import ProjectAttachmentItem from "../../components/ProjectAttachmentItem/ProjectAttachmentItem";
import styles from "./projectDetails.module.css";
import AttachFileModal from "../../components/Modals/AttachFileModal/AttachFileModal";
import {
	newBidReceived,
	removeSocketEventListener,
} from "../../utils/clientEvents";
import AddEditMilestone from "../../components/Milestone/AddEditMilestone/AddEditMilestone";

const steps = [
	{
		selector: '[data-tour="step-1"]',
		content: <p>Shows the basic info for this project</p>,
	},
	{
		selector: '[data-tour="step-2"]',
		content: <p>shows the bids on this project</p>,
	},
];
const disableBody = (target) => disableBodyScroll(target);
const enableBody = (target) => enableBodyScroll(target);
const ProjectDetails = (props) => {
	return (
		<CustomTourProvider
			steps={steps}
			afterOpen={disableBody}
			beforeClose={enableBody}
		>
			<Main props={props} />
		</CustomTourProvider>
	);
};

const Main = (props) => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const location = useLocation();

	const authState = useSelector((state) => state.auth);
	const socketState = useSelector((state) => state.socket);

	const { setIsOpen } = useTour();
	const [loading, setLoading] = useState(true);
	const [activeTab, setActiveTab] = useState(0);
	const [project, setProject] = useState(null);
	const [projectAttachments, setProjectAttachments] = useState([]);
	const [projectBids, setProjectBids] = useState(null);
	const [projectMilestones, setProjectMilestones] = useState(null);
	const [customerFees, setCustomerFees] = useState(0);
	const [activeReferral, setActiveReferral] = useState(null);
	const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
	const [createBidModalOpen, setCreateBidModalOpen] = useState(false);
	const [bidSubmitted, setBidSubmitted] = useState(false);
	const [newBidCount, setNewBidCount] = useState(0);
	const [addMilestoneModalOpen, setAddMilestoneModalOpen] = useState(false);

	useEffect(() => {
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		dispatch(toggleTopLoader(true));
		if (project) {
			dispatch(toggleTopLoader(false));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [project]);

	useEffect(() => {
		// Realtime page events
		if (socketState.socket) {
			newBidReceived(socketState.socket, (data) => {
				// setNewBidCount(newBidCount + 1);
				if (location.pathname === `/project/${data.project}`) {
					setNewBidCount((prev) => prev + 1);
				}
			});

			return () => {
				removeSocketEventListener(socketState.socket, "new-bid-posted");
			};
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socketState.socket]);

	const loadData = async () => {
		seo({ title: "Project Details" });
		const projectDetails = await dispatch(fetchProjectById(id));
		if (projectDetails.status) {
			const activeReferral = await dispatch(fetchMyActiveReferral());
			setActiveReferral(activeReferral.data);
			const portalSetting = await dispatch(
				getPortalSettingByKey("customer_fees")
			);
			setCustomerFees(parseInt(portalSetting.data.data.value));
			setProject(projectDetails.data);
			setProjectAttachments(projectDetails.data.attachments);
			setLoading(false);
			setIsOpen(authState.user ? authState.user.tutorial : false);
		}
	};

	const fetchBids = async () => {
		const response = await dispatch(fetchProjectBids(id));
		if (response.status) {
			setProjectBids(response.data);
			setNewBidCount(0);
		} else {
			dispatch(setAlert(response.msg, "danger"));
		}
	};

	const fetchMilestones = async () => {
		const response = await dispatch(getAllMilestones(id));
		if (response.status) {
			setProjectMilestones(response.data);
		} else {
			dispatch(setAlert(response.msg, "danger"));
		}
	};

	const handleTabChange = async (e, selectedTab) => {
		switch (selectedTab) {
			case 1:
				setProjectBids(null);
				fetchBids();
				setNewBidCount(0);
				break;
			case 2:
				setProjectMilestones(null);
				fetchMilestones();
				break;
			default:
				break;
		}

		setActiveTab(selectedTab);
	};

	// const onIntiateChatWithUser = (e, bidUserId) => {
	//     e.preventDefault();

	//     socketState.socket.emit("send-message", {
	//         msgData: {
	//             msg: initiatingMsg,
	//             userId: authState.user._id,
	//             otherUserId: bidUserId,
	//         },
	//     });
	//     setInitiatingMsg("");
	//     // setTextAreaBidId(null);
	// };

	const reloadProjectAttachments = async () => {
		const response = await dispatch(fetchProjectAttachments(id));
		if (response.status) {
			setProjectAttachments(response.data);
		}
	};

	const onUploadAttachment = async (files) => {
		const formData = new FormData();
		files.forEach((file) => {
			formData.append("files", file.file);
		});

		const response = await dispatch(uploadProjectAttachment(id, formData));
		if (response.status) {
			setAttachmentModalOpen(false);
			dispatch(setAlert(response.msg, "success"));
			reloadProjectAttachments();
		} else {
			dispatch(setAlert(response.msg, "danger"));
			return response;
		}
	};

	// const onChangeValue = (e) => {};

	// const onFormSubmit = (e) => {
	//     e.preventDefault();
	// };

	return (
		<Fragment>
			{!loading ? (
				<div className="container" id="project-details">
					<div className="row">
						<div className="col-lg-9 px-lg-2 m-0">
							<section className="d-flex my-lg-3 mb-3">
								<div className="flex-sm-grow-1 me-3">
									<h1 className="job-title mb-3">
										{project.title}
									</h1>
									<ul className="job-skills ps-0">
										{project.skills.map((skill, index) => {
											return (
												<Fragment key={index}>
													<li className="badge-filled">
														{skill.name}
													</li>
												</Fragment>
											);
										})}
									</ul>
								</div>
								<div className="job-action align-self-center">
									<h2 className="job-rate">
										${project.budget.amount}
									</h2>
								</div>
							</section>
							<section className="job-description p-4 my-lg-3 mb-3">
								<div
									className="d-flex justify-content-between light-bg mb-3"
									style={{
										borderBottomLeftRadius: "0px",
										borderBottomRightRadius: "0px",
									}}
								>
									<Tabs
										value={activeTab}
										onChange={handleTabChange}
									>
										<Tab label="Project Details" />
										<Tab
											label={
												<span className="d-flex gap-2 flex-row align-items-center">
													Bids{" "}
													{(newBidCount &&
														activeTab !== 1) >
														0 && (
														<small
															className={`${styles["count"]}`}
														>
															{newBidCount}
														</small>
													)}
												</span>
											}
											className="flex-direction-row"
										/>
										{project.milestonesCount > 0 && (
											<Tab label="Milestones" />
										)}
									</Tabs>
									{project.user._id === authState.user._id ||
									project.bids.some(
										(bid) =>
											bid.user._id === authState.user._id
									) ? (
										<Fragment></Fragment>
									) : (
										<>
											{!bidSubmitted && (
												<button
													type="button"
													className="btn btn-primary-gradient m-2"
													onClick={() =>
														setCreateBidModalOpen(
															true
														)
													}
												>
													Create Bid
												</button>
											)}
											<CreateBidModal
												projectId={id}
												milestonesCount={
													project.milestonesCount
												}
												setBidSubmitted={
													setBidSubmitted
												}
												isOpen={createBidModalOpen}
												onClose={() =>
													setCreateBidModalOpen(false)
												}
											/>
										</>
									)}
								</div>
								<div
									className="tab-content"
									id="project-tabContent"
								>
									<div
										role="tabpanel"
										hidden={activeTab !== 0}
										id={`simple-tabpanel-${0}`}
										aria-labelledby={`simple-tab-${0}`}
										className="light-bg p-4"
									>
										{activeTab === 0 && (
											<div>
												<small className="job-date mb-0">
													Project Posted On{" "}
													{
														project.createdAt.split(
															"T"
														)[0]
													}
												</small>
												<div className="job-description-sec my-4">
													<h3 className="job-inner-head mb-4">
														Description
													</h3>
													<p className="job-inner-body">
														{project.description}
													</p>
												</div>
												<div className="job-description-sec">
													<h3 className="job-inner-head mb-4">
														Attached Files
													</h3>
													<div className="row w-100 m-0 g-3">
														{projectAttachments.length ===
															0 &&
															authState.user
																._id !==
																project.user
																	._id && (
																<p className="text-muted">
																	No
																	attachments
																	found!
																</p>
															)}
														{projectAttachments.map(
															(
																attachment,
																index
															) => {
																return (
																	<ProjectAttachmentItem
																		key={
																			index
																		}
																		project={
																			project._id
																		}
																		attachment={
																			attachment
																		}
																	/>
																);
															}
														)}
														{authState.user._id ===
															project.user
																._id && (
															<>
																<div
																	className={`${styles["add-attachment-container"]} col-md-4 text-center py-5`}
																	onClick={() => {
																		setAttachmentModalOpen(
																			true
																		);
																	}}
																>
																	<span className="material-icons text-muted">
																		add_circle
																	</span>
																	<p className="text-muted">
																		Add
																		Attachment
																	</p>
																</div>
																{/* Attach File Modal */}
																<AttachFileModal
																	isOpen={
																		attachmentModalOpen
																	}
																	handleClose={
																		setAttachmentModalOpen
																	}
																	handleSubmit={
																		onUploadAttachment
																	}
																/>
															</>
														)}
													</div>
												</div>
											</div>
										)}
									</div>
									<div
										role="tabpanel"
										hidden={activeTab !== 1}
										id={`simple-tabpanel-${1}`}
										aria-labelledby={`simple-tab-${1}`}
									>
										{activeTab === 1 && (
											<Fragment>
												{newBidCount &&
												activeTab === 1 ? (
													<p className="alert alert-danger text-center">
														New bids found...{" "}
														{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
														<a
															className="text-decoration-underline"
															onClick={fetchBids}
														>
															Refresh Now
														</a>
													</p>
												) : (
													<Fragment></Fragment>
												)}
												<ul
													className={`${styles["bid-list"]} p-0`}
												>
													{projectBids ? (
														<Fragment>
															{projectBids.length >
															0 ? (
																<Fragment>
																	{projectBids.map(
																		(
																			bid,
																			index
																		) => {
																			return (
																				<BidListItem
																					key={
																						index
																					}
																					bid={
																						bid
																					}
																					projectOwnerId={
																						project
																							.user
																							._id
																					}
																					customerFees={
																						customerFees
																					}
																					activeReferral={
																						activeReferral
																					}
																				/>
																			);
																		}
																	)}
																</Fragment>
															) : (
																<Fragment>
																	<div className="light-bg py-5">
																		<p className="text-muted text-center">
																			There
																			are
																			no
																			bids
																			on
																			this
																			job
																		</p>
																		<small className="text-muted d-flex align-items-center justify-content-center">
																			<span className="material-icons">
																				help
																			</span>
																			&nbsp;
																			Bids
																			are
																			proposals
																			or
																			quotes
																			sent
																			by
																			freelancers
																			to
																			work
																			on
																			your
																			project.
																		</small>
																	</div>
																</Fragment>
															)}
														</Fragment>
													) : (
														<Fragment>
															<p className="text-muted text-center light-bg py-5">
																Loading Bids...
															</p>
														</Fragment>
													)}
												</ul>
											</Fragment>
										)}
									</div>
									<div
										role="tabpanel"
										hidden={activeTab !== 2}
										id={`simple-tabpanel-${2}`}
										aria-labelledby={`simple-tab-${2}`}
									>
										{activeTab === 2 && (
											<Fragment>
												<ul
													className={`${styles["bid-list"]} p-0`}
												>
													{projectMilestones ? (
														<Fragment>
															{(project.user
																._id ===
																authState.user
																	._id ||
																project.hiredUsers.some(
																	(user) =>
																		user
																			.user
																			._id ===
																		authState
																			.user
																			._id
																)) && (
																<>
																	<button
																		className="btn btn-primary-gradient outline w-100 mb-3"
																		onClick={() =>
																			setAddMilestoneModalOpen(
																				true
																			)
																		}
																	>
																		Add
																		Milestone
																	</button>
																	{addMilestoneModalOpen && (
																		<div className="light-bg p-4 mb-4">
																			<AddEditMilestone
																				isOpen={
																					addMilestoneModalOpen
																				}
																				handleClose={async () => {
																					fetchMilestones();
																					setAddMilestoneModalOpen(
																						false
																					);
																				}}
																				projectId={
																					project._id
																				}
																				projectOwnerId={
																					project
																						.user
																						._id
																				}
																			/>
																		</div>
																	)}
																</>
															)}

															{projectMilestones.length >
															0 ? (
																<Fragment>
																	{projectMilestones.map(
																		(
																			milestone,
																			index
																		) => {
																			return (
																				<MilestoneListItem
																					key={
																						index
																					}
																					milestone={
																						milestone
																					}
																					projectOwnerId={
																						project
																							.user
																							._id
																					}
																					reloadMilestones={
																						fetchMilestones
																					}
																				/>
																			);
																		}
																	)}
																</Fragment>
															) : (
																<Fragment>
																	<div className="py-5">
																		<p className="text-muted text-center">
																			There
																			are
																			no
																			milestones
																			on
																			this
																			project
																		</p>
																		<small className="text-muted d-flex align-items-center justify-content-center">
																			<span className="material-icons">
																				help
																			</span>
																			&nbsp;
																			Milestones
																			are
																			dividing
																			project
																			in
																			small
																			tasks
																			and
																			assigning
																			to
																			the
																			developers.
																		</small>
																	</div>
																</Fragment>
															)}
														</Fragment>
													) : (
														<Fragment>
															<p className="text-muted text-center light-bg py-5">
																Loading
																Milestones...
															</p>
														</Fragment>
													)}
												</ul>
											</Fragment>
										)}
									</div>
								</div>
							</section>
						</div>
						<div className="col-lg-3 px-lg-2 m-0">
							{(project.user._id === authState.user._id ||
								project.hiredUsers.some(
									(user) =>
										user.user._id === authState.user._id
								)) && (
								<section className="light-bg p-4 my-lg-3 mb-3">
									<p className="text-muted text-center">
										Access project control center to modify
										project.
									</p>
									<Link
										to={
											location.pathname +
											"/control-center"
										}
										className="btn btn-primary-gradient outline w-100"
									>
										Project Control Center
									</Link>
								</section>
							)}
							<section className="light-bg p-4 my-lg-3 mb-3">
								<h5 className="sec-title text-center text-capitalize mb-4">
									Job Activity
								</h5>
								<div className={`d-flex mb-2`}>
									<p className="font-weight-bold flex-grow-1 mb-0">
										<b>Total Bids :</b>
									</p>
									<p className={`mb-0`}>
										{projectBids ? projectBids.length : 0}{" "}
										bids
									</p>
								</div>
								<div className="d-flex mb-2">
									<p className="font-weight-bold flex-grow-1 mb-0">
										<b>Avg. Bid Amount :</b>
									</p>
									<p className="mb-0">
										{projectBids ? (
											<Fragment>
												{
													project.totalBidAmount
														.currency
												}{" "}
												{isNaN(
													project.totalBidAmount
														.amount /
														(projectBids
															? projectBids.length
															: 0)
												)
													? 0
													: project.totalBidAmount
															.amount /
													  (projectBids
															? projectBids.length
															: 0)}
											</Fragment>
										) : (
											<Fragment>$ 0</Fragment>
										)}
									</p>
								</div>
								<div className="d-flex mb-2">
									<p className="font-weight-bold flex-grow-1 mb-0">
										<b>Deposited Amount :</b>
									</p>
									<p className="mb-0">
										$ {project.secureDeposit.amount}
									</p>
								</div>
							</section>
							{project.agreements && project.agreements.length ? (
								<section className="light-bg p-4 my-lg-3 mb-3">
									<h5 className="sec-title text-center text-capitalize mb-4">
										Agreements
									</h5>
									{project.agreements.map((el) => {
										return (
											<div className="d-flex mb-2">
												<p className="font-weight-bold flex-grow-1 mb-0">
													<b>{el.name}</b>
												</p>
												<p
													className="mb-0 pointer"
													onClick={() => {
														window.open(
															`${getApiEndpoint()}${
																el.url
															}`
														);
													}}
												>
													<span className="material-icons">
														visibility
													</span>
												</p>
											</div>
										);
									})}
								</section>
							) : (
								""
							)}
							{project.user._id !== authState.user._id && (
								<Fragment>
									<section className="employer-info light-bg p-4 my-lg-3 mb-3">
										<h5 className="sec-title text-center text-capitalize mb-4">
											About Employer
										</h5>
										<div className="employer-id text-center">
											<Link
												to={`/profile/${project.user._id}`}
											>
												<img
													className="profile-pic-lg mb-4"
													src={
														project.projectOwner
															.profileImgUrl
															? getApiEndpoint() +
															  project
																	.projectOwner
																	.profileImgUrl
															: "/img/profile-image.png"
													}
													alt="Employer"
												/>
												<h6 className="sec-title  text-capitalize mb-4">
													{project.user.name}
												</h6>
											</Link>
										</div>
										<div className="employer-bio text-center">
											{/* <div className="info-item mb-2">
                                                <div className="info-icon">
                                                    ??
                                                </div>
                                                <p className="info-text">
                                                    india
                                                </p>
                                            </div>
                                            <div className="info-item mb-2">
                                                <div className="info-icon">
                                                    ??
                                                </div>
                                                <p className="info-text">
                                                    india
                                                </p>
                                            </div>
                                            <div className="info-item mb-2">
                                                <div className="info-icon">
                                                    ??
                                                </div>
                                                <p className="info-text">
                                                    india
                                                </p>
                                            </div>

                                            <h5 className="sec-title text-center text-capitalize my-4">
                                                Verification
                                            </h5>
                                            <div className="info-item mb-2">
                                                <div className="info-icon">
                                                    ??
                                                </div>
                                                <p className="info-text text-capitalize">
                                                    Payment method verified
                                                </p>
                                            </div>
                                            <div className="info-item mb-2">
                                                <div className="info-icon">
                                                    ??
                                                </div>
                                                <p className="info-text text-capitalize">
                                                    Email Verified
                                                </p>
                                            </div> */}
										</div>
										{project.bids.map((bid, index) => {
											return (
												<Fragment key={index}>
													{bid.user._id ===
														authState.user._id && (
														<>
															{bid.status ===
															"completed" ? (
																<ReviewModal
																	reviewedTo={
																		project
																			.user
																			._id
																	}
																	projectId={
																		project._id
																	}
																	user="customer"
																/>
															) : (
																<></>
															)}
														</>
													)}
												</Fragment>
											);
										})}
									</section>
								</Fragment>
							)}
						</div>
					</div>
				</div>
			) : (
				<Fragment>
					<ProjectDetailsSkeleton />
				</Fragment>
			)}
		</Fragment>
	);
};

export default ProjectDetails;
