import React, { Fragment } from "react";

const LoadingButton = ({ isLoading, text, className, onClick, ...props }) => {
    return (
        <Fragment>
            <button
                type="submit"
                className={
                    className
                        ? className
                        : "btn btn-primary-gradient form-control"
                }
                onClick={onClick ? onClick : null}
                disabled={isLoading}
                {...props}
            >
                {text}{" "}
                {isLoading && (
                    <img src="/img/loader.gif" width="25px" alt="loader" />
                )}
            </button>
        </Fragment>
    );
};

export default LoadingButton;
