import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import styles from "./Transaction.module.css";
import { saveAs } from "file-saver";
import {
    getTransactions,
    postStripe,
    downloadTransactions,
} from "../../actions/transaction";
import TransactionSkeleton from "./Skeleton/Skeleton";
import { fetchCurrentUserWallet } from "../../actions/profile";
import { seo } from "../../utils/seo";
import { fetchPendingClearanceBalance } from "../../actions/pendingClearance";
import { setAlert } from "../../actions/alert";

const Transaction = (props) => {
    const dispatch = useDispatch();
    let [amt, setAmt] = useState(100);
    // const getAmt = () => {
    //     return amt;
    // };

    const [isLoading, setIsLoading] = useState(true);
    const [transactions, setTransactions] = useState([]);
    const [walletBalance, setWalletbalance] = useState(null);
    const [pendingClearanceBalance, setPendingClearanceBalance] =
        useState(null);

    useEffect(() => {
        seo({ title: "Transactions" });
        loadInit();
        // eslint-disable-next-line
    }, []);

    const onChangeAmt = (e) => {
        if (e.target.min && e.target.value < e.target.min) {
            setAmt(e.target.min);
        } else {
            setAmt(e.target.value);
        }
    };

    const loadInit = async () => {
        dispatch(fetchCurrentUserWallet())
            .then((response) => {
                if (response.status) {
                    setWalletbalance(response.data);
                }
            })
            .catch((err) => {
                dispatch(setAlert(err.msg, "danger"));
            });
        dispatch(fetchPendingClearanceBalance())
            .then((response) => {
                if (response.status) {
                    setPendingClearanceBalance(response.data);
                } else {
                    setPendingClearanceBalance({ currency: "USD", amount: 0 });
                }
            })
            .catch((err) => {
                dispatch(setAlert(err.msg, "danger"));
            });
        const result = await dispatch(getTransactions());
        setIsLoading(false);
        setTransactions(result);
    };

    // const onDownload = async () => {
    //     setIsLoading(true);
    //     const result = await downloadTransactions();
    //     const arr = new Uint8Array(result.data);
    //     const blob = new Blob([arr], { type: 'application/pdf' });

    //     saveAs(blob, 'Transactions.pdf');
    //     setIsLoading(false);
    // };

    // const onpaypalClick = async (e) => {
    //     e.preventDefault();
    //     setIsLoading(false);
    //     const result = await postTransactions(
    //         amt,
    //         'transactions',
    //         'rechargeWallet'
    //     );
    //     window.location.replace(result.orderId.result.links[1].href);
    // };

    const onstripeClick = async (e) => {
        e.preventDefault();
        setIsLoading(false);
        const response = await dispatch(
            postStripe(amt, "transactions", "rechargeWallet")
        );
        if (response.status) {
            window.location.replace(response.data.url);
        } else {
            setIsLoading(true);
        }
    };

    const onDownload = async () => {
        setIsLoading(true);
        const result = await dispatch(downloadTransactions());

        const arr = new Uint8Array(result.data);

        const blob = new Blob([arr], { type: "application/pdf" });

        saveAs(blob, "Transactions.pdf");
        setIsLoading(false);

        // const onClickRecharge = async (e) => {
        //   e.preventDefault();

        //   setIsLoading(true);

        //   const response = await rechargeWallet(rechargeAmt);
        //   setFormData({ ...formData, rechargeAmt: "" });
        //   if (response) {
        //     let redirectURL = "";
        //     for (let i = 0; i < response.links.length; i++) {
        //       if (response.links[i].rel === "approval_url") {
        //         // return res.redirect(paypal.links[i].href);
        //         redirectURL = response.links[i].href;
        //       }
        //     }

        //     if (redirectURL !== "") {
        //       window.open(redirectURL, "popup", "width=600,height=600");
        //       setIsLoading(false);
        //     }
        //   }
        // };
        // const rechargeWallet = () => {
        // };
    };

    return (
        <Fragment>
            <main className="container">
                <div className="row">
                    <div className="col-lg-3 px-lg-2 m-0">
                        <section className={`light-bg p-4 my-lg-3`}>
                            <h5 className="text-center">Summary</h5>
                            <hr></hr>
                            <table className="table table-borderless">
                                <tbody>
                                    <tr>
                                        <td>
                                            {/* <img
                                            src="img/wallet.svg"
                                            className={`w-25`}
                                            alt="recharge-icon"
                                        /> */}
                                            Wallet:
                                        </td>
                                        <td className="text-end">
                                            {walletBalance !== null
                                                ? walletBalance.wallet
                                                      .currency +
                                                  " " +
                                                  walletBalance.wallet.amount
                                                : ""}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {/* <img
                                            src="img/wallet.svg"
                                            className={`w-25`}
                                            alt="recharge-icon"
                                        /> */}
                                            Pending:
                                        </td>
                                        <td className="text-end">
                                            {pendingClearanceBalance !== null
                                                ? pendingClearanceBalance.currency +
                                                  " " +
                                                  pendingClearanceBalance.amount
                                                : ""}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {/* <img
                                            src="img/wallet.svg"
                                            className={`w-25`}
                                            alt="recharge-icon"
                                        /> */}
                                            Total:
                                        </td>
                                        <td className="text-end">
                                            USD{" "}
                                            {walletBalance &&
                                            pendingClearanceBalance
                                                ? walletBalance.wallet.amount +
                                                  pendingClearanceBalance.amount
                                                : "0"}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </section>
                        <section
                            className={` ${styles["recharge-wallet"]} light-bg p-4 my-lg-3`}
                        >
                            <h5 className="text-center">Recharge Wallet</h5>
                            <div
                                className={`${styles["recharge-icon"]} text-center py-4'}`}
                            >
                                <hr></hr>
                            </div>
                            {/* onSubmit={(e)=> onFormSubmit(e)} */}
                            <form>
                                <div className="mb-3">
                                    <label className="form-label">
                                        Recharge Amount
                                    </label>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text borders-right-radius-none d-inline-block">
                                            $
                                        </span>
                                        <input
                                            type="number"
                                            className="dark-input borders-left-radius-none flex-grow-1 w-25"
                                            placeholder="Enter Amount"
                                            value={amt}
                                            min="1"
                                            onChange={(e) => onChangeAmt(e)}
                                            autoComplete="off"
                                            required
                                        />
                                    </div>
                                </div>
                                {/* <button
                  type="button"
                  className={` ${styles["paypal-action"]} btn d-flex align-items-center justify-content-center mt-5`}
                  // onClick={(e) =>
                  //     onpaypalClick(e)
                  // }
                >
                  <img
                    src="img/paypal.svg"
                    className={` ${styles["icon"]}`}
                    alt="Paypal"
                  />
                  <div
                    className={`${styles["btn-text"]} font-weight-bolder ms-2`}
                  >
                    Paypal
                  </div>
                </button> */}
                                <button
                                    className={`btn btn-primary-gradient form-control mt-2`}
                                    onClick={(e) => onstripeClick(e)}
                                >
                                    <div className="btn-text font-weight-bolder ms-2">
                                        Pay With Stripe
                                    </div>
                                </button>
                                {/* <PayPal /> */}
                            </form>
                        </section>
                    </div>
                    <div className="col-lg-9 px-lg-2 m-0">
                        <section className="transaction-list my-lg-3 mt-3">
                            <div className="d-flex align-items-center px-3">
                                <h5 className="flex-grow-1">
                                    Transactions History
                                </h5>
                                {transactions.length !== 0 ? (
                                    <span
                                        className="material-icons pointer"
                                        onClick={() => onDownload()}
                                    >
                                        download
                                    </span>
                                ) : (
                                    <Fragment></Fragment>
                                )}
                            </div>
                            {isLoading ? (
                                <div>
                                    {/* jjj */}
                                    <TransactionSkeleton />
                                </div>
                            ) : // <h1>dds</h1>
                            transactions.length !== 0 ? (
                                <Fragment>
                                    {transactions.map((transaction, index) => {
                                        return (
                                            <Fragment key={index}>
                                                <section className="light-bg p-4 my-lg-3">
                                                    <div
                                                        className="d-flex align-items-center"
                                                        style={{
                                                            gap: "0.5rem",
                                                        }}
                                                    >
                                                        <h6 className="flex-grow-1">
                                                            {transaction.title}
                                                        </h6>
                                                        <h6>
                                                            {
                                                                transaction
                                                                    .amount
                                                                    .currency
                                                            }
                                                            &nbsp;
                                                            {
                                                                transaction
                                                                    .amount
                                                                    .amount
                                                            }
                                                        </h6>
                                                    </div>
                                                    <div className="d-flex mt-1">
                                                        <small className="text-capitalize m-0">
                                                            Status:{" "}
                                                            {transaction.status}
                                                        </small>
                                                        <small>
                                                            &nbsp;&nbsp;|&nbsp;&nbsp;
                                                        </small>
                                                        <small className="text-capitalize m-0">
                                                            Payment Mode:{" "}
                                                            {transaction.mode}
                                                        </small>
                                                    </div>
                                                </section>
                                            </Fragment>
                                        );
                                    })}
                                </Fragment>
                            ) : (
                                <h4 className="text-center py-5">
                                    No Transactions Yet
                                </h4>
                            )}
                        </section>
                    </div>
                </div>
            </main>
        </Fragment>
    );
};

export default Transaction;
