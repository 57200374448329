import { requestWithToken } from "../utils/httpRequest";
import { getApiEndpoint } from "../utils/getApiEndpoint";

import { setAlert } from "./alert";

export const getAgreements = () => async (dispatch) => {
    try {
        const res = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/agreements`
        );

        if (res) {
            return res.data.data;
        }
    } catch (err) {
        const errors = err.response.data.errors;

        if (errors) {
            dispatch(setAlert(errors[0].msg, "danger"));
        }
    }
};
