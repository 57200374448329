import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import useDynamicRefs from "use-dynamic-refs";

import styles from "./../../pages/VideoChat/VideoChat.module.css";

const OverallVideoChat = ({
    rtc,
    remoteUsers,
    toggleGridMode,
    videoEnabled,
    audioEnabled,
}) => {
    const [getRef, setRef] = useDynamicRefs();

    const authState = useSelector((state) => state.auth);

    useEffect(() => {
        if (rtc.localVideoTrack) {
            const localVideoRef = getRef("localVideo");
            rtc.localVideoTrack.play(localVideoRef.current);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rtc.localVideoTrack]);

    useEffect(() => {
        remoteUsers.map((remoteUser) => {
            const streamDiv = getRef(remoteUser.uid);
            if (streamDiv && streamDiv.current) {
                remoteUser.videoTrack?.play(streamDiv.current);
            }
            return null;
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remoteUsers]);

    return (
        <div className={`${styles["videos-container-grid-six"]} light-bg p-4`}>
            <div
                ref={setRef("localVideo")}
                className={`${styles["vid-one"]} dark-bg position-relative ${
                    !videoEnabled
                        ? "d-flex align-items-center justify-content-center"
                        : ""
                }`}
                // onClick={toggleGridMode}
            >
                {!videoEnabled && (
                    <span className="material-icons fs-1">account_circle</span>
                )}
                <div className={`${styles["video-stream-username"]}`}>
                    {!audioEnabled && (
                        <span className="material-icons text-white me-2">
                            mic_off
                        </span>
                    )}
                    {authState.user.name}
                </div>
            </div>

            {remoteUsers.map((remoteUser) => {
                return (
                    <Fragment key={remoteUser.uid}>
                        <div
                            id={remoteUser.uid}
                            ref={setRef(remoteUser.uid)}
                            className={`${
                                styles["vid-one"]
                            } dark-bg position-relative ${
                                !remoteUser.hasVideo
                                    ? "d-flex align-items-center justify-content-center"
                                    : ""
                            }`}
                            onClick={() => toggleGridMode(remoteUser.uid)}
                        >
                            {!remoteUser?.hasVideo && (
                                <span className="material-icons fs-1">
                                    account_circle
                                </span>
                            )}
                            <div
                                className={`${styles["video-stream-username"]}`}
                            >
                                {!remoteUser?.hasAudio && (
                                    <span className="material-icons text-white me-2">
                                        mic_off
                                    </span>
                                )}
                                {remoteUser.userDetails.user.name}{" "}
                                {remoteUser.isScreenShare ? " (Screen)" : ""}
                            </div>
                        </div>
                    </Fragment>
                );
            })}
        </div>
    );
};

export default OverallVideoChat;
