import { getApiEndpoint } from "../utils/getApiEndpoint";
import { requestWithoutToken } from "../utils/httpRequest";
import { error } from "./error";

//send Message
export const contact = (formData) => async (dispatch) => {
    try {
        const body = formData;
        const response = await requestWithoutToken(
            "POST",
            `${getApiEndpoint()}/contacts/`,
            body
        );
        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};
