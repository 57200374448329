import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import AppLayout from "./app/App";
import AuthLayout from "./auth/Auth";
import { authUser } from "../actions/auth";
import { useLocation } from "react-router-dom";
import authRoutes from "../routes/auth";

const MainLayout = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const authState = useSelector((state) => state.auth);

    useEffect(() => {
        if (!authState.authenticated) {
            loadUser();
        }

        // eslint-disable-next-line
    }, []);

    const loadUser = async () => {
        const res = await dispatch(authUser());

        if (res.status) {
            if (!res.data.profile) {
                history.replace("/setupprofile");
            } else {
                if (location.pathname === "/") {
                    history.replace("/dashboard");
                }
            }
        } else {
            if (!authRoutes.some((route) => route.path === location.pathname)) {
                history.replace("/login");
            }
        }
    };

    return (
        <Fragment>
            {!authState.loading ? (
                authState.authenticated ? (
                    <AppLayout />
                ) : (
                    <AuthLayout />
                )
            ) : (
                <Fragment>Loading Layout...</Fragment>
            )}
        </Fragment>
    );
};

export default MainLayout;
