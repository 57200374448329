import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { fetchSubmittedBids } from "../../actions/bid";
import ProjectListItem from "../../components/ProjectListItem/ProjectListItem";

const BidsSubmitted = () => {
    const dispatch = useDispatch();
    const [bids, setBids] = useState([]);

    useEffect(() => {
        const fetchBids = async () => {
            const response = await dispatch(fetchSubmittedBids());
            if (response.status) {
                setBids(response.data);
            }
        };
        fetchBids();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <main className="container">
                <div className="row">
                    <div className="col-lg-12 px-lg-2 m-0">
                        <div className="m-3 d-flex">
                            <h3 className="text-capitalize">Bids Submitted</h3>
                        </div>

                        <section className="job-results my-lg-3 mb-3">
                            {bids.length > 0 ? (
                                bids.map((project, index) => {
                                    return (
                                        <ProjectListItem
                                            key={index}
                                            project={project.project}
                                        />
                                    );
                                })
                            ) : (
                                <>
                                    <h4 className="text-center py-5">
                                        No Bids Found!
                                    </h4>
                                </>
                            )}
                        </section>
                    </div>
                </div>
            </main>
        </Fragment>
    );
};

export default BidsSubmitted;
