import { getApiEndpoint } from "../utils/getApiEndpoint";
import { requestWithoutToken, requestWithToken } from "../utils/httpRequest";
import { AUTH_USER, LOGIN_SUCCESS, LOGOUT, AUTH_USER_FAIL } from "./types";
import { error } from "./error";

// Authenticate User
export const authUser = () => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/auth`
        );

        dispatch({
            type: AUTH_USER,
            payload: response.data.data,
        });

        return response.data;
    } catch (err) {
        dispatch(error(err));
        dispatch({ type: AUTH_USER_FAIL });

        return err.response.data;
    }
};

// Login User
export const login = (email, password) => async (dispatch) => {
    try {
        const body = { email, password };

        const response = await requestWithoutToken(
            "POST",
            `${getApiEndpoint()}/auth`,
            body
        );

        dispatch({
            type: LOGIN_SUCCESS,
            payload: response.data.data,
        });
        return response.data;
    } catch (err) {
        if (err.response) {
            return err.response.data;
        } else {
            return { status: 0, msg: "Something went wrong" };
        }
        // dispatch(error(err));
    }
};

// Social Login
export const socialLogin = (requestBody) => async (dispatch) => {
    try {
        const response = await requestWithoutToken(
            "POST",
            `${getApiEndpoint()}/auth/social`,
            requestBody
        );

        dispatch({
            type: LOGIN_SUCCESS,
            payload: response.data.data,
        });
        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

export const verifyUser = (token, userId) => async (dispatch) => {
    try {
        const response = await requestWithoutToken(
            "GET",
            `${getApiEndpoint()}/users/verify?token=${token}&userId=${userId}`
        );

        return response.data;
    } catch (err) {
        if (err.response) {
            return err.response.data;
        } else {
            return { status: 0, msg: "Something went wrong" };
        }
    }
};

// Register User
export const register =
    (name, email, password, userType, refferalId) => async (dispatch) => {
        try {
            const body = {
                name,
                email,
                password,
                userType,
                refferalId,
            };
            const response = await requestWithoutToken(
                "POST",
                `${getApiEndpoint()}/users`,
                body
            );
            // if (userType.toLowerCase() === 'customer') {
            //     dispatch({
            //         type: REGISTER_SUCCESS,
            //         payload: res.data,
            //     });
            // } else if (userType.toLowerCase() === 'recruiter') {
            //     dispatch({
            //         type: RECRUITER_REG_SUCCESS,
            //     });
            // }
            return response.data;
        } catch (err) {
            dispatch(error(err));
            return err.response.data;
        }
    };

// Forgot Password User
export const forgotpassword = (email) => async (dispatch) => {
    try {
        const body = { email };

        const response = await requestWithoutToken(
            "POST",
            `${getApiEndpoint()}/auth/forgot-password`,
            body
        );

        return response.data;
    } catch (err) {
        if (err.response) {
            return err.response.data;
        } else {
            return { status: 0, msg: "Something went wrong" };
        }
        // dispatch(error(err));
    }
};

// Reset User Password
export const resetpassword = (token, userId, password) => async (dispatch) => {
    try {
        const body = { token, userId, password };

        const response = await requestWithoutToken(
            "POST",
            `${getApiEndpoint()}/auth/reset-password`,
            body
        );

        return response.data;
    } catch (err) {
        if (err.response) {
            return err.response.data;
        } else {
            return { status: 0, msg: "Something went wrong" };
        }
        // dispatch(error(err));
    }
};

// Logout
export const logout = () => (dispatch) => {
    // dispatch({
    //     type: SOCKET_DISCONNECT,
    // });

    // dispatch({
    //     type: CLEAR_AUTH_PROFILE,
    // });

    dispatch({
        type: LOGOUT,
    });
};
