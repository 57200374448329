import React, { Fragment, useEffect, useState, useRef } from "react";
// import Bounce from "react-reveal/Bounce";

import styles from "./ComingSoon.module.css";

const ComingSoon = () => {
    let [daysIndex, setDaysIndex] = useState("00");
    let [hoursIndex, setHoursIndex] = useState("00");
    let [minutesIndex, setMinutesIndex] = useState("00");
    let [secondsIndex, setSecondsIndex] = useState("00");

    let interval = useRef();

    useEffect(() => {
        startTimer();

        return () => {
            // eslint-disable-next-line  react-hooks/exhaustive-deps
            clearInterval(interval.current);
            clearInterval(interval);
        };
    }, []);

    const startTimer = () => {
        const countdownDate = new Date("November 30 2021 00:00:00").getTime();

        interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = countdownDate - now;

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor(
                (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor(
                (distance % (1000 * 60 * 60)) / (1000 * 60)
            );
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if (distance < 0) {
                clearInterval(interval.current);
            } else {
                setDaysIndex(days);
                setHoursIndex(hours);
                setMinutesIndex(minutes);
                setSecondsIndex(seconds);
            }
        }, 1000);
    };

    const generateCounterCells = (counter) => {
        const cells = [];
        for (let i = 0; i < 60; i++) {
            switch (counter) {
                case "days":
                    cells.push(
                        <div
                            key={i}
                            // ref={setRef(`${counter}-${i}`)}
                            className={
                                i > daysIndex
                                    ? `${styles["counter-line"]} ${styles["grey-color"]}`
                                    : styles["counter-line"]
                            }
                        ></div>
                    );
                    break;
                case "hours":
                    cells.push(
                        <div
                            key={i}
                            // ref={setRef(`${counter}-${i}`)}
                            className={
                                i > hoursIndex
                                    ? `${styles["counter-line"]} ${styles["grey-color"]}`
                                    : styles["counter-line"]
                            }
                        ></div>
                    );
                    break;
                case "minutes":
                    cells.push(
                        <div
                            key={i}
                            // ref={setRef(`${counter}-${i}`)}
                            className={
                                i > minutesIndex
                                    ? `${styles["counter-line"]} ${styles["grey-color"]}`
                                    : styles["counter-line"]
                            }
                        ></div>
                    );
                    break;
                case "seconds":
                    cells.push(
                        <div
                            key={i}
                            // ref={setRef(`${counter}-${i}`)}
                            className={
                                i > secondsIndex
                                    ? `${styles["counter-line"]} ${styles["grey-color"]}`
                                    : styles["counter-line"]
                            }
                        ></div>
                    );
                    break;

                default:
                    break;
            }
        }

        return cells;
    };

    return (
        <Fragment>
            <section
                id={styles["coming-soon-section"]}
                className="position-relative py-5 px-3"
            >
                <div
                    className={styles["background-img"]}
                    style={{ backgroundImage: "url('/img/landing/img6.jpeg')" }}
                ></div>
                <div className="container">
                    <h2 className="text-center mb-3">Coming Soon</h2>
                    <div className="hexagon-bg"></div>
                    <p className="text-center">
                        We are currently onboarding customers and developers,
                        website will be online in
                    </p>
                    <div
                        className={`${styles["countdown-container"]} text-center my-5`}
                    >
                        <div>
                            <div className="row w-100">
                                <div className="col-md-3">
                                    <div className="days-container d-inline-block position-relative">
                                        <h6 className={styles["middle-text"]}>
                                            {daysIndex}
                                        </h6>
                                        <div
                                            className={
                                                styles[
                                                    "counter-lines-container"
                                                ]
                                            }
                                        >
                                            {generateCounterCells("days")}
                                        </div>
                                        <h3 className="mt-3">Days</h3>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="hours-container d-inline-block position-relative">
                                        <h6 className={styles["middle-text"]}>
                                            {hoursIndex}
                                        </h6>
                                        <div
                                            className={
                                                styles[
                                                    "counter-lines-container"
                                                ]
                                            }
                                        >
                                            {generateCounterCells("hours")}
                                        </div>
                                        <h3 className="mt-3">Hours</h3>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="minutes-container d-inline-block position-relative">
                                        <h6 className={styles["middle-text"]}>
                                            {minutesIndex}
                                        </h6>
                                        <div
                                            className={
                                                styles[
                                                    "counter-lines-container"
                                                ]
                                            }
                                        >
                                            {generateCounterCells("minutes")}
                                        </div>
                                        <h3 className="mt-3">Minutes</h3>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="seconds-container d-inline-block position-relative">
                                        <h6 className={styles["middle-text"]}>
                                            {secondsIndex}
                                        </h6>
                                        <div
                                            className={
                                                styles[
                                                    "counter-lines-container"
                                                ]
                                            }
                                        >
                                            {generateCounterCells("seconds")}
                                        </div>
                                        <h3 className="mt-3">Seconds</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="text-center">
                        You can already use the game configuration to create
                        your game but please be patient regarding the start of
                        your order
                    </p>
                </div>
            </section>
        </Fragment>
    );
};

export default ComingSoon;
