import {
    UPDATE_CHAT,
    SET_PANEL_STATE,
    EMPTY_PANEL_CHAT,
    NEW_PANEL_MSG,
    SET_PANEL_MSG,
} from "./../actions/types";

const initialState = {
    activeChats: [],
    chatPanelOpen: false,
};

const chatPanel = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_PANEL_MSG:
            return {
                ...state,
                activeChats: state.activeChats.map((chat) => {
                    if (chat._id === payload.chatId) {
                        chat.msg = payload.msg;
                        return chat;
                    } else {
                        return chat;
                    }
                }),
            };
        case SET_PANEL_STATE:
            return {
                ...state,
                chatPanelOpen: payload,
            };
        case UPDATE_CHAT:
        case NEW_PANEL_MSG:
            return {
                ...state,
                activeChats: [...state.activeChats],
            };
        case EMPTY_PANEL_CHAT:
            return {
                ...state,
                activeChats: [],
            };
        default:
            return state;
    }
};

export default chatPanel;
