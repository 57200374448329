import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import { seo } from "../../utils/seo";
import { getReview, fetchSelectedUserProfile } from "../../actions/profile";
import AboutDisplay from "../../components/Profile/AboutDisplay/AboutDisplay";
import AboutEdit from "../../components/Profile/AboutEdit/AboutEdit";
import AddEducation from "../../components/Profile/AddEducation/AddEducation";
import AddExperience from "../../components/Profile/AddExperience/AddExperience";
import EducationList from "../../components/Profile/EducationList/EducationList";
import ExperienceList from "../../components/Profile/ExperienceList/ExperienceList";
import AddFolio from "../../components/Profile/AddFolio/AddFolio";
import FolioList from "../../components/Profile/FolioList/FolioList";
import ProfileOverview from "../../components/Profile/ProfileOverview/ProfileOverview";
import ProfileEdit from "../../components/Profile/ProfileEdit/ProfileEdit";
import SkillEdit from "../../components/Profile/SkillEdit/SkillEdit";
import SkillDisplay from "../../components/Profile/SkillDisplay/SkillDisplay";
import styles from "./Profile.module.css";
import { getSteps } from "../../utils/tourSteps";
import { reacttourUpdate } from "../../actions/reacttour";
import { ProfileContext } from "../../contexts/ProfileContext";
import { setAlert } from "../../actions/alert";
import ProfileSkeleton from "./Skeleton/Skeleton";
import {
	getApiEndpoint,
	getFrontendPublicURL,
} from "../../utils/getApiEndpoint";
import moment from "moment";
import ReviewModal from "../../components/Modals/ReviewModal/ReviewModal";
import classNames from "classnames";

const ProfileDetails = () => {
	const dispatch = useDispatch();

	const { id } = useParams();
	// const { setIsOpen, setSteps, ...rest } = useTour();

	const authState = useSelector((state) => state.auth);
	const socketState = useSelector((state) => state.socket);

	const [loading, setLoading] = useState(true);
	const [isMyProfile, setIsMyProfile] = useState(false);
	const [profile, setProfile] = useState(null);
	const [editingProfile, setEditingProfile] = useState(false);
	const [editingAbout, setEditingAbout] = useState(false);
	const [editingExperience, setEditingExperience] = useState(false);
	const [editingFolio, setEditingFolio] = useState(false);
	const [editingEducation, setEditingEducation] = useState(false);
	const [review, setGetReview] = useState([]);
	const [editingSkills, setEditingSkills] = useState(false);
	const [showReviewModal, setShowReviewModal] = useState(false);
	const [reviewProjectId, setReviewProjectId] = useState("");
	const [reviewedTo, setReviewedTo] = useState("");

	// Request Verification
	const onClickVerification = () => {
		if (profile.bio === "") {
			dispatch(
				setAlert(
					"Please provide bio before applying for verification!",
					"danger"
				)
			);
			setEditingAbout(true);
			return;
		}

		if (profile.tagline === "") {
			dispatch(
				setAlert(
					"Please fill tagline before applying for verification!",
					"danger"
				)
			);
			setEditingProfile(true);
			return;
		}

		if (profile.hourlyRate.amount === 0) {
			dispatch(
				setAlert(
					"Please fill hourly rate before applying for verification!",
					"danger"
				)
			);
			setEditingProfile(true);
			return;
		}

		if (profile.skills.length === 0) {
			dispatch(
				setAlert(
					"Please add atlease one skill before applying for verification!",
					"danger"
				)
			);
			setEditingSkills(true);
			return;
		}

		socketState.socket.emit("request-verification", {
			user: authState.user,
		});
	};

	// Load data hook
	useEffect(() => {
		seo({ title: "Profile" });
		loadData();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		fetchUserProfile();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		if (authState.user && profile?.user._id === authState.user._id) {
			setIsMyProfile(true);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profile]);

	const loadData = async () => {
		dispatch(await reacttourUpdate(getSteps("profileSteps")));
	};

	const fetchUserProfile = async () => {
		const response = await dispatch(fetchSelectedUserProfile(id));
		setProfile(response.data);
		const res = await dispatch(getReview(id));
		console.log(res);
		setGetReview(res.data);
		setLoading(false);
	};

	const profileContextData = {
		profile,
		setProfile,
	};

	return (
		<ProfileContext.Provider value={profileContextData}>
			<Fragment>
				{!loading ? (
					<div className="container">
						<div className="row">
							<div className="col-lg-3 px-lg-2 m-0">
								{isMyProfile && editingProfile ? (
									<ProfileEdit
										toggleMode={setEditingProfile}
										reloadProfile={fetchUserProfile}
									/>
								) : (
									<ProfileOverview
										toggleMode={setEditingProfile}
									/>
								)}

								{isMyProfile &&
								!profile.verificationRequested &&
								!profile.canBid ? (
									<Fragment>
										<section className="verify-section light-bg p-4 text-center mb-3 mb-lg-0">
											<h5 className="mb-3">
												Request Verification
											</h5>
											<p className="text-secondary">
												Your profile has to be verified
												before you can bid on different
												projects.
											</p>
											<div className="d-flex">
												<button
													className="btn-save flex-grow-1"
													onClick={() =>
														onClickVerification()
													}
												>
													Request
												</button>
											</div>
										</section>
									</Fragment>
								) : profile.verificationRequested &&
								  !profile.canBid ? (
									<Fragment>
										<section className="verify-section light-bg p-4 text-center mb-3 mb-lg-0">
											<h3 className="mb-3">
												Request Verification
											</h3>
											<p className="text-secondary">
												Requested
											</p>
										</section>
									</Fragment>
								) : (
									""
								)}

								{!editingSkills ? (
									<span data-tour="step-profile-2">
										<SkillDisplay
											toggleMode={setEditingSkills}
										/>
									</span>
								) : (
									<SkillEdit
										toggleMode={setEditingSkills}
										reloadProfile={fetchUserProfile}
									/>
								)}
								{/* {<div>{skillView}</div>} */}
							</div>
							<div className="col-lg-9 px-lg-2 m-0">
								<section
									className={`${styles["section-head"]} light-bg p-4 my-lg-3 mb-3`}
								>
									<div
										className={`${styles["section-title"]} mb-3 d-flex align-items-center`}
									>
										<h5 className="flex-grow-1">About</h5>
										{isMyProfile && (
											<button
												className={`${styles["heading-toggle"]} mt-1 ml-auto`}
												onClick={() =>
													!editingAbout
														? setEditingAbout(true)
														: setEditingAbout(false)
												}
											>
												<span className="material-icons">
													{editingAbout
														? "highlight_off"
														: "create"}
												</span>
											</button>
										)}
									</div>
									{isMyProfile && editingAbout ? (
										<Fragment>
											<AboutEdit
												toggleMode={setEditingAbout}
												reloadProfile={fetchUserProfile}
											/>
										</Fragment>
									) : (
										<Fragment>
											<span data-tour="step-profile-3">
												<AboutDisplay
													toggleMode={setEditingAbout}
												/>
											</span>
										</Fragment>
									)}
								</section>

								<section
									className={`${styles["section-head"]} light-bg p-4 my-lg-3 mb-3`}
								>
									<div
										className={`${styles["section-title"]} mb-3 d-flex align-items-center`}
									>
										<h5 className="flex-grow-1">
											Portfolio
										</h5>
										{isMyProfile && (
											<button
												className={`${styles["heading-toggle"]} mt-1 ml-auto`}
												onClick={() =>
													!editingFolio
														? setEditingFolio(true)
														: setEditingFolio(false)
												}
											>
												<span className="material-icons">
													{editingFolio
														? "highlight_off"
														: "add_circle_outline"}
												</span>
											</button>
										)}
									</div>
									{isMyProfile && editingFolio ? (
										<Fragment>
											<AddFolio
												toggleMode={setEditingFolio}
												reloadProfile={fetchUserProfile}
											/>
										</Fragment>
									) : (
										<Fragment>
											<span data-tour="step-profile-4">
												<FolioList
													portfolios={
														profile.portfolios
													}
												/>
											</span>
										</Fragment>
									)}
								</section>

								<section
									className={`${styles["section-head"]} light-bg p-4 my-lg-3 mb-3`}
								>
									<div
										className={`${styles["section-title"]} mb-3 d-flex align-items-center`}
									>
										<h5 className="flex-grow-1">
											Experience
										</h5>
										{isMyProfile && (
											<button
												className={`${styles["heading-toggle"]} mt-1 ml-auto`}
												onClick={() =>
													!editingExperience
														? setEditingExperience(
																true
														  )
														: setEditingExperience(
																false
														  )
												}
											>
												<span className="material-icons">
													{editingExperience
														? "highlight_off"
														: "add_circle_outline"}
												</span>
											</button>
										)}
									</div>
									{isMyProfile && editingExperience ? (
										<Fragment>
											<AddExperience
												toggleMode={
													setEditingExperience
												}
												reloadProfile={fetchUserProfile}
											/>
										</Fragment>
									) : (
										<Fragment>
											<span data-tour="step-profile-5">
												<ExperienceList
													experiences={
														profile.experiences
													}
													setExperiences={(
														experiences
													) =>
														setProfile({
															...profile,
															experiences,
														})
													}
												/>
											</span>
										</Fragment>
									)}
								</section>

								<section
									className={`${styles["section-head"]} light-bg p-4 my-lg-3 mb-3`}
								>
									<div
										className={`${styles["section-title"]} mb-3 d-flex align-items-center`}
									>
										<h5 className="flex-grow-1">
											Education
										</h5>
										{isMyProfile && (
											<button
												className={`${styles["heading-toggle"]} mt-1 ml-auto`}
												onClick={() =>
													!editingEducation
														? setEditingEducation(
																true
														  )
														: setEditingEducation(
																false
														  )
												}
											>
												<span className="material-icons">
													{editingEducation
														? "highlight_off"
														: "add_circle_outline"}
												</span>
											</button>
										)}
									</div>
									{isMyProfile && editingEducation ? (
										<Fragment>
											<AddEducation
												toggleMode={setEditingEducation}
												reloadProfile={fetchUserProfile}
											/>
										</Fragment>
									) : (
										<Fragment>
											<span data-tour="step-profile-6">
												<EducationList
													toggleMode={
														setEditingEducation
													}
												/>
											</span>
										</Fragment>
									)}
								</section>
								<section
									className={`${styles["section-head"]} light-bg p-4 my-lg-3 mb-3`}
								>
									<div
										className={`${styles["section-title"]} mb-3 d-flex align-items-center`}
									>
										<h5>Reviews</h5>
									</div>
									<Fragment>
										{review.length > 0 ? (
											<>
												{review.map((re) => (
													<div
														key={re._id}
														className={`position-relative ${styles["review-item"]}`}
													>
														<div
															className={classNames(
																"reviews-members pt-4 pb-4",
																{
																	[styles[
																		"locked"
																	]]:
																		re.status ===
																		"hidden",
																}
															)}
														>
															{re.status ===
																"hidden" && (
																<div
																	className={
																		styles[
																			"blur"
																		]
																	}
																></div>
															)}
															<div className="media">
																<div className="d-flex align-items-center">
																	<a
																		href={getFrontendPublicURL()}
																		className="me-3"
																	>
																		<img
																			className="profile-pic-lg"
																			src={
																				re
																					.project
																					.projectOwner
																					.profileImgUrl
																					? getApiEndpoint() +
																					  re
																							.project
																							.projectOwner
																							.profileImgUrl
																					: "/img/profile-image.png"
																			}
																			alt="Employer"
																		/>
																	</a>
																	<div className="d-flex flex-column">
																		<h6 className="mb-0">
																			<Link
																				to={`/profile/${re.reviewedBy._id}`}
																				style={{
																					color: "#fff",
																					textDecoration:
																						"none",
																				}}
																			>
																				{
																					re
																						.reviewedBy
																						.name
																				}
																			</Link>
																		</h6>
																		<div>
																			{[
																				...Array(
																					5
																				),
																			].map(
																				(
																					_,
																					index
																				) => {
																					const roundedRating =
																						Math.round(
																							re.overallRating
																						);

																					return (
																						<span
																							key={
																								index
																							}
																						>
																							<FaStar
																								className="star justify-content-end align-items-end"
																								size={
																									20
																								}
																								color={
																									index <
																									roundedRating
																										? "GoldenRod"
																										: "#c5c5c5"
																								}
																								fill={
																									index <
																									roundedRating
																										? "GoldenRod"
																										: "#c5c5c5"
																								}
																								style={{
																									paddingBottom:
																										"4px",
																								}}
																							/>
																						</span>
																					);
																				}
																			)}
																		</div>
																	</div>
																</div>

																<div className="media-body">
																	<div className="reviews-members-header">
																		{/* <h6 className="mt-3">
                                                                            {
                                                                                re
                                                                                    .project
                                                                                    ?.title
                                                                            }
                                                                        </h6> */}
																		<small className="d-block text-muted my-2">
																			{moment(
																				re.createdAt
																			).format(
																				"DD MMM YYYY"
																			)}
																		</small>
																	</div>
																	<div className="reviews-members-body">
																		<p>
																			{
																				re.review
																			}
																		</p>
																	</div>
																</div>
															</div>
														</div>
														{re.status ===
															"hidden" && (
															<button
																className={`btn btn-primary-gradient outline ${styles["review-btn"]}`}
																onClick={() => {
																	setReviewProjectId(
																		re.project
																	);
																	setReviewedTo(
																		re
																			.reviewedBy
																			._id
																	);
																	setShowReviewModal(
																		true
																	);
																}}
															>
																Review To
																Unblock
															</button>
														)}
													</div>
												))}

												<ReviewModal
													isOpen={showReviewModal}
													onClose={setShowReviewModal}
													projectId={reviewProjectId}
													reviewedTo={reviewedTo}
													reviewAs="customer"
												/>
											</>
										) : (
											<div className="text-center text-muted">
												No Reviews Found
											</div>
										)}
									</Fragment>
								</section>
							</div>
						</div>
					</div>
				) : (
					<ProfileSkeleton />
				)}
			</Fragment>
		</ProfileContext.Provider>
	);
};

export default ProfileDetails;
