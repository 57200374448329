import { requestWithToken } from "../utils/httpRequest";
import { getApiEndpoint } from "../utils/getApiEndpoint";
import { error } from "./error";

// Get Agora Token
export const generateToken = (callId, type) => async (dispatch) => {
    try {
        const res = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/agora/generate-token/callId/${callId}/${type}`
        );

        if (res.status === 200) {
            return res.data;
        }
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};
