import React, { Fragment, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../actions/alert";
import { deleteExperience } from "../../../actions/profile";
import { ProfileContext } from "../../../contexts/ProfileContext";
import EditExperience from "../EditExperience/EditExperience";
import styles from "./ExperienceList.module.css";
const ExperienceList = ({ experiences, setExperiences }) => {
	const dispatch = useDispatch();

	const profileContext = useContext(ProfileContext);

	const authState = useSelector((state) => state.auth);

	const [experienceview, setExperienceView] = useState();
	const [edit, setEdit] = useState(false);

	const createClick = (exp, change) => {
		setEdit(change);
		setExperienceView(
			<EditExperience exp={exp} callBackCancel={createClick} />
		);
	};

	const onDeleteExp = async (expId) => {
		const response = await dispatch(deleteExperience(expId));

		if (response.status) {
			dispatch(setAlert(response.msg, "success"));
			setExperiences(response.data.experiences);
		} else {
			dispatch(setAlert(response.msg, "danger"));
		}
	};

	return experiences.length ? (
		<Fragment>
			<ul className="list-type-none mb-0 p-0">
				{edit ? (
					<>{experienceview}</>
				) : (
					<>
						{experiences.map((exp) => {
							return (
								<Fragment key={exp._id}>
									<li className="section-el dark-bg p-3 mb-2">
										<div className="d-flex justify-content-between">
											<h3 className="mb-3">
												{exp.company}
											</h3>
											{profileContext.profile.user._id.toString() ===
												authState.user._id.toString() && (
												<div>
													<button
														className={`${styles["heading-toggle"]} me-2`}
														onClick={(e) =>
															createClick(
																exp,
																true
															)
														}
													>
														<span className="material-icons">
															create
														</span>
													</button>
													<button
														className={`${styles["heading-toggle"]}`}
														onClick={(e) =>
															onDeleteExp(exp._id)
														}
													>
														<span className="material-icons">
															delete
														</span>
													</button>
												</div>
											)}
										</div>

										<small className="text-muted">
											{exp.from.split("T")[0]} -{" "}
											{!exp.current
												? exp.to.split("T")[0]
												: "Current"}
										</small>
										<div className="el-info mt-2">
											<div className="row">
												<div className="col-sm-4 mb-1">
													<p className="font-weight-bolder mb-1 mb-sm-0">
														Job Title
													</p>
												</div>
												<div className="col-sm-8 mb-1 mb-sm-0">
													<p>{exp.title}</p>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-4 mb-1">
													<p className="font-weight-bolder mb-1 mb-sm-0">
														Description
													</p>
												</div>
												<div className="col-sm-8 mb-1 mb-sm-0">
													<p>{exp.description}</p>
												</div>
											</div>
										</div>
									</li>
									{/* <div className="row mb-1">
								<div className="col">
									<h3>{exp.company}</h3>
								</div>
							</div>
							<div className="row mb-1">
								<div className="col">
									<span className="font-weight-bold">Position: </span>
									{exp.title}
								</div>
							</div>
							<div className="row mb-1">
								<div className="col">
									{exp.from} - {!exp.current ? exp.to : "Current"}
								</div>
							</div>
							<div className="row mb-1">
								<div className="col">
									<span className="font-weight-bold">Description: </span>
									{exp.description}
								</div>
							</div> */}
								</Fragment>
							);
						})}
					</>
				)}
			</ul>
		</Fragment>
	) : (
		<Fragment>
			<div className="text-center text-muted">No Experience Added</div>
		</Fragment>
	);
};

export default ExperienceList;
