import store from "./../store";
import { setAlert } from "./../actions/alert";
import {
	intiateChatWithUser,
	newMsg,
	updateAction,
	newMsgNotification,
} from "../actions/chat";
// import { addNewProjectNotification } from "../actions/project";
import { UPDATE_UNSEEN_NOTIFICATION_COUNT } from "../actions/types";
import { newPanelMsg } from "../actions/chatPanel";

export const joinRoom = (socket, chatId) => {
	socket.emit("join-chatroom", chatId);
};

export const roomJoined = (socket) => {
	socket.on("room-joined", () => {});
};

export const leaveRoom = (socket, chatId) => {
	socket.emit("leave-chatroom", chatId);
};

export const newBidReceived = (socket, callback) => {
	socket.on("new-bid-posted", (data) => callback(data));
};

export const milestoneUpdated = (socket, callback) => {
	socket.on("milestone-updated", (data) => callback(data));
};

export const newRecommendedProject = (socket, callback) => {
	socket.on("new-project-notification", (data) => callback(data));
};

export const newMessage = (socket) => {
	socket.on("new-message", async (data) => {
		try {
			// const msgRing = new Audio("/sounds/text-msg.mp3");
			// msgRing.play();
		} catch (err) {}

		const activeRoom = store.getState().chat.activeRoom;
		const panelChats = store.getState().chatPanel.activeChats;

		if (activeRoom && data.chatId === activeRoom._id) {
			console.log(data.msg);
			store.dispatch(newMsg(data.msg));
		} else if (panelChats && panelChats.length !== 0) {
			if (panelChats.some((chat) => chat._id === data.chatId)) {
				store.dispatch(newPanelMsg(data.chatId, data.msg));
				// store.dispatch(newMsgRoom(data.chatId, data.msg));
			}
			// else {
			//     const newNotification = {
			//         user: store.getState().auth.user._id,
			//         chatId: data.chatId,
			//         sender: data.msg.user._id,
			//         msg: data.msg,
			//     };

			//     // const notifications = store.getState().chat
			//     //     .msgNotification;

			//     // notifications.push(newNotification);

			//     store.dispatch(newMsgNotification(newNotification));

			//     store.dispatch(newMsgRoom(data.chatId, data.msg));

			//     socket.emit("save-msg-notification", newNotification);
			// }
		}
	});
};

export const chatRoomEvents = (socket) => {
	socket.on("intiate-chat", (data) => {
		store.dispatch(intiateChatWithUser(data));
	});
};

export const newNotification = (socket) => {
	socket.on("new-notification", async (newNotification) => {
		// store.dispatch(newMsgRoom(newNotification.chatId, newNotification.msg));
		store.dispatch(newMsgNotification(newNotification));
	});
};

export const deleteNotification = (socket, chatId, userId) => {
	socket.emit("delete-notifications", {
		userId,
		chatId,
	});
};

export const socketAlert = async (socket) => {
	socket.on("set-alert", (data) => {
		store.dispatch(setAlert(data.msg, data.type));
	});
	socket.on("amount-deposited", (data) => {
		// store.dispatch(getCurrentProfile());
		store.dispatch(setAlert(data.alertMsg, data.alertType));
	});

	// socket.on("new-project-notification", async (data) => {
	// 	// store.dispatch(addNewProjectNotification(data));
	// });

	socket.on("update-action-taken", (data) => {
		store.dispatch(updateAction(data));
	});
};

export const newPortalNotification = (socket) => {
	socket.on("new-portal-notification", () => {
		store.dispatch({
			type: UPDATE_UNSEEN_NOTIFICATION_COUNT,
		});
	});
};

export const removeSocketEventListener = (socket, eventName) => {
	socket.removeListener(eventName);
};
