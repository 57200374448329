import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "../../actions/alert";

import { forgotpassword } from "../../actions/auth";

// Styling
import styles from "./ForgotPassword.module.css";

const ForgotPassword = (props) => {
    const dispatch = useDispatch();

    const [disable] = useState(false);

    const [formData, setFormData] = useState({
        email: "",
    });

    // const loadUser = async () => {
    //   dispatch(toggleTopLoader(true));
    //   const res = await dispatch(authUser());

    //   if (res.status == 1) {
    //     //redirect login
    //     history.push("/dashboard");
    //   }

    //   dispatch(toggleTopLoader(false));
    // };

    // useEffect(() => {
    //   //loadUser();
    // }, []);

    const { email } = formData;

    const onChangeValue = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const onPasswordFormSubmit = async (e) => {
        e.preventDefault();

        const res = await dispatch(forgotpassword(email));
        if (res.status === 1) {
            dispatch(setAlert(res.msg, "success"));
        } else {
            dispatch(setAlert(res.msg, "danger"));
        }
    };

    return (
        <Fragment>
            <div className="container d-flex justify-content-center align-items-center py-5">
                <div
                    className="box-shadow light-bg my-5 px-5 py-4"
                    id={`${styles["login-box"]}`}
                >
                    <h4 className="text-center">Forgot Password</h4>
                    <hr></hr>
                    <form
                        onSubmit={(e) => {
                            onPasswordFormSubmit(e);
                        }}
                        method="post"
                    >
                        <div className="mb-2">
                            <label className="form-label">Email</label>
                            <input
                                type="email"
                                className="form-control"
                                name="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => onChangeValue(e)}
                                required
                            ></input>
                        </div>
                        <div className="mb-2">
                            <button
                                disabled={disable}
                                type="submit"
                                className={
                                    "btn btn-primary-gradient form-control"
                                }
                            >
                                Send Password{" "}
                                {disable ? (
                                    <img
                                        src="/img/loader.gif"
                                        alt="loader"
                                        width="25px"
                                    />
                                ) : (
                                    ""
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    );
};

export default ForgotPassword;
