import { getApiEndpoint } from "../utils/getApiEndpoint";
import { requestWithToken } from "../utils/httpRequest";

import { setAlert } from "./alert";
import { error } from "./error";

import { ON_ERROR } from "./types";

// Get all countries from DB
export const getActiveCountries = () => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/country`
        );

        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Get all timezones from API
export const getTimezones = () => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/country/timezones`
        );

        return response.data;
    } catch (err) {
        dispatch({
            type: ON_ERROR,
            payload: null,
        });
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => setAlert(error.msg, "danger"));
        }
    }
};
