import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ChatList from "../../components/Messages/ChatList/ChatList";
import { seo } from "../../utils/seo";
import { clearActiveRoom, getAllChat } from "../../actions/chat";
import { newChatGroup } from "../../actions/chat";
import Typography from "@material-ui/core/Typography";
import { Dropdown } from "semantic-ui-react";
// Import Styling
import styles from "./messages.module.css";
import MessageSkeleton from "./Skeleton/Skeleton";
import { getApiEndpoint } from "../../utils/getApiEndpoint";
import { getUserContacts } from "../../actions/contact";
import ChatMessages from "../../components/Messages/ChatMessages/ChatMessages";
import { toggleTopLoader } from "../../actions/topLoader";

const Messages = () => {
    const dispatch = useDispatch();

    const authState = useSelector((state) => state.auth);
    const chatState = useSelector((state) => state.chat);
    const profileState = useSelector((state) => state.profile);
    const auth = useSelector((state) => state.auth);

    const [formData, setFormData] = useState({
        groupName: "",
        users: [],
    });
    const [contactsArr, setContactsArr] = useState([]);

    const { groupName, users } = formData;
    const onChangeDropdownValue = (e, data) => {
        setFormData({ ...formData, [data.name]: data.value });
    };

    useEffect(() => {
        seo({ title: "Message" });

        loadData();

        return () => {
            dispatch(clearActiveRoom());
            dispatch(toggleTopLoader(false));
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadData = async () => {
        dispatch(toggleTopLoader(true));
        await dispatch(getAllChat());
        dispatch(toggleTopLoader(false));
    };

    const onNewGroup = async (e) => {
        e.preventDefault();
        const response = await dispatch(newChatGroup(formData));
        if (response) {
        }
    };

    const onChangeGroupName = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const loadContacts = async () => {
        if (auth.user.userType.toLowerCase() === "customer") {
            const contacts = await dispatch(getUserContacts());
            const contactsArray = [];
            setContactsArr([]);
            contacts.map((contact) => {
                const obj = {
                    key: contact._id,
                    text: contact.name,
                    value: contact._id,
                };
                contactsArray.push(obj);
                return null;
            });
            setContactsArr(contactsArray);
        } else if (auth.user.userType.toLowerCase() === "recruiter") {
            // const profiles = dispatch(await getCustomerProfiles());
            // const contactsArray = [];
            // setContactsArr([]);
            // profiles.map((profile) => {
            //   const obj = {
            //     key: profile.user._id,
            //     text: profile.user.name,
            //     value: profile.user._id,
            //   };
            //   contactsArray.push(obj);
            // });
            // setContactsArr(contactsArray);
        }
    };

    return (
        <Fragment>
            {!chatState.loading && !authState.loading ? (
                <main className={`${styles["chat-page"]}`}>
                    <div className="container-fluid">
                        <div className="row">
                            <div
                                className="col-md-4 p-0 "
                                style={{ height: "90vh" }}
                            >
                                <div className={`${styles["chat-sidebar"]}`}>
                                    <div
                                        className={`${styles["chat-sidebar-nav"]} d-flex align-items-center gap-2`}
                                    >
                                        <div className="profile-icon me-2">
                                            {profileState.profileImgUrl ===
                                            "" ? (
                                                <img
                                                    className="profile-pic-sm"
                                                    src="/img/profile-image.png"
                                                    alt="Profile"
                                                />
                                            ) : (
                                                <img
                                                    className="profile-pic-sm"
                                                    src={
                                                        getApiEndpoint() +
                                                        profileState.profileImgUrl
                                                    }
                                                    alt="Profile"
                                                />
                                            )}
                                        </div>

                                        {authState.user !== null && (
                                            <Fragment>
                                                <h4 className="font-weight-bolder text-capitalize m-0 flex-grow-1">
                                                    {authState.user.name}
                                                    {authState.user.userType.toLowerCase() ===
                                                    "recruiter" ? (
                                                        <Fragment>
                                                            <small className="ms-2 mb-0 notification d-inline">
                                                                R
                                                            </small>
                                                        </Fragment>
                                                    ) : (
                                                        <Fragment></Fragment>
                                                    )}
                                                </h4>
                                            </Fragment>
                                        )}

                                        {/* Old GroupAdd Modal Code */}
                                        <div
                                            className="d-flex"
                                            onClick={() => loadContacts()}
                                        >
                                            <button
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                                style={{
                                                    backgroundColor:
                                                        "transparent",
                                                    border: "none",
                                                }}
                                            >
                                                <span className="material-icons pointer">
                                                    group_add
                                                </span>
                                            </button>
                                        </div>

                                        <div
                                            className="modal fade"
                                            id="exampleModal"
                                            tabIndex="-1"
                                            aria-labelledby="exampleModalLabel"
                                            aria-hidden="true"
                                        >
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content dark-bg">
                                                    <form
                                                        onSubmit={(e) =>
                                                            onNewGroup(e)
                                                        }
                                                    >
                                                        <div className="modal-body">
                                                            <h3 className="text-capitalize text-center">
                                                                Create New Group
                                                            </h3>
                                                            <Typography className="mt-4">
                                                                Group Name
                                                            </Typography>
                                                            <input
                                                                onChange={(e) =>
                                                                    onChangeGroupName(
                                                                        e
                                                                    )
                                                                }
                                                                type="text"
                                                                className="dark-input flex-grow-1 mt-2 mb-3"
                                                                placeholder="Group Name"
                                                                name="groupName"
                                                                value={
                                                                    groupName
                                                                }
                                                                autoComplete="off"
                                                                required
                                                            ></input>
                                                            <Typography className="mt-4">
                                                                Select Contacts
                                                            </Typography>
                                                            <Dropdown
                                                                placeholder="Select Contacts"
                                                                className="dark-input dropdown-select sementic-dropdown"
                                                                name="users"
                                                                multiple
                                                                search
                                                                selection
                                                                options={
                                                                    contactsArr
                                                                }
                                                                onChange={
                                                                    onChangeDropdownValue
                                                                }
                                                                value={users}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="modal-footer divider-dark">
                                                            <button
                                                                type="button"
                                                                className="me-2 btn btn-primary-gradient outline"
                                                                data-bs-dismiss="modal"
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary-gradient"
                                                                data-bs-dismiss="modal"
                                                            >
                                                                Create
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ChatList />
                                </div>
                            </div>
                            <ChatMessages />
                        </div>
                    </div>
                </main>
            ) : (
                <MessageSkeleton />
            )}
        </Fragment>
    );
};

export default Messages;
