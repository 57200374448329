import React, { Fragment, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { seo } from "../../utils/seo";
import Carousel from "react-elastic-carousel";

import Radio from "./gameForm/Radio";
import Checkbox from "./gameForm/CkeckBox";
import Dropdown from "./gameForm/Dropdown";
import FileInput from "./gameForm/FileInput";
import DefaultInput from "./gameForm/DefaultInput";
import SpecialInput from "./gameForm/SpecialInput";

import { createProject, getGameFormById } from "../../actions/gameForm";

function useForceUpdate() {
	const [value, setValue] = useState(0); // integer state
	return () => setValue(value + 1); // update the state to force render
}

const GameCustomizer = () => {
	const { gameFormId } = useParams();
	const dispatch = useDispatch();
	const history = useHistory();
	const forceUpdate = useForceUpdate();

	const authState = useSelector((state) => state.auth);

	const [fileUpload, setFileUpload] = useState({});
	const [spFileUpload] = useState({});
	const [formData, setFormData] = useState({});
	const [spFormData] = useState({});
	const [allOptions, setAllOptions] = useState([]);
	const [addedSpecials] = useState({});
	// const [isCalc, setIsCalc] = useState("change");
	const [loading, setLoading] = useState(true);
	const [totalPrice, setTotalPrice] = useState(0);
	const [totalMinDuration, setTotalMinDuration] = useState(0);
	const [totalMaxDuration, setTotalMaxDuration] = useState(0);
	const [addedEstimates] = useState([]);
	const [addedFiles] = useState([]);
	const [attachments] = useState([]);
	// const [desc, setDesc] = useState([]);
	const [gameForm, setGameForm] = useState(null);
	// const [formTitle, setFormTitle] = useState("");
	// const [formWidth, setFormWidth] = useState()

	// const submitRef = useRef();

	const setFormFields = () => {
		if (gameForm !== null && gameForm !== undefined) {
			if (
				gameForm.formFields !== null &&
				gameForm.formFields !== undefined
			) {
				gameForm.formFields.forEach((el) => {
					if (el.fieldType === "checkbox") {
						formData[el.dataName] = [];
					} else if (el.fieldType === "file") {
						fileUpload[el.dataName] = [];
					} else if (el.fieldType === "special") {
						addedSpecials[el.dataName] = [];
						formData[el.dataName] = "special_field";
						fileUpload[el.dataName] = "special_field";
						spFormData[el.dataName] = {};
						spFileUpload[el.dataName] = {};
						el.specialInputs.forEach((sp) => {
							if (sp.fieldType === "checkbox") {
								spFormData[el.dataName][sp.dataName] = [];
							} else if (sp.fieldType === "file") {
								spFileUpload[el.dataName][sp.dataName] = [];
							} else {
								spFormData[el.dataName][sp.dataName] = "";
							}
						});
					} else {
						formData[el.dataName] = "";
					}
				});
			}
		}
	};

	useEffect(() => {
		const fetchGameForm = async () => {
			const response = await dispatch(getGameFormById(gameFormId));
			if (response.status) {
				setGameForm(response.data);
				setLoading(false);
			}
		};

		fetchGameForm();
		seo({ title: "Customization" });

		// eslint-disable-next-line  react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setFormFields();

		// eslint-disable-next-line  react-hooks/exhaustive-deps
	}, [gameForm]);

	// useEffect(() => {
	// 	setEstimates();
	// });

	const setEstimates = async (recieved) => {
		let estimates;
		if (recieved) {
			estimates = await calcEstimate(recieved, addedEstimates);
		} else {
			estimates = await calcEstimate(allOptions, addedEstimates);
		}

		setTotalPrice(estimates.price);
		setTotalMinDuration(estimates.min);
		setTotalMaxDuration(estimates.max);
	};

	const onChangeOptions = (e, dataName) => {
		const option = JSON.parse(e.target.value);
		option.field = dataName;
		setFormData({ ...formData, [e.target.name]: option });

		const opIndex = allOptions.indexOf(
			allOptions.find((op) => op.field === dataName)
		);

		if (opIndex !== -1) {
			allOptions.splice(opIndex, 1, option);
		} else {
			allOptions.push(option);
		}

		setEstimates();
		forceUpdate();
	};

	const onSpChangeOptions = (e, dataName, spName) => {
		const option = JSON.parse(e.target.value);
		option.field = spName;
		spFormData[spName][e.target.name] = option;

		// forceUpdate();
	};

	const onSelectChecks = async (e, dataName) => {
		const option = JSON.parse(e.target.value);
		option.field = dataName;
		let filtered;
		if (formData[e.target.name].some((el) => el._id === option._id)) {
			filtered = allOptions.filter((el) => el._id !== option._id);

			setAllOptions(filtered);
			setFormData({
				...formData,
				[e.target.name]: formData[e.target.name].filter(
					(el) => el._id !== option._id
				),
			});
		} else {
			formData[e.target.name].push(option);
			allOptions.push(option);
		}

		onAddEstimates(dataName);

		setEstimates(filtered);
		forceUpdate();
	};

	const onSpSelectChecks = (e, dataName, spName) => {
		const option = JSON.parse(e.target.value);
		option.field = spName;
		if (
			spFormData[spName][e.target.name].some(
				(el) => el._id === option._id
			)
		) {
			spFormData[spName][e.target.name] = spFormData[spName][
				e.target.name
			].filter((el) => el._id !== option._id);
		} else {
			spFormData[spName][e.target.name].push(option);
		}
	};

	const onChangeValue = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onSpChangeValue = (e, spId) => {
		spFormData[spId][e.target.name] = e.target.value;
	};

	// const onChangeTitle = (e) => {
	//     setFormTitle(e.target.value);
	// };

	const onSelectFile = (e) => {
		if (Object.keys(e.target.files).length !== 0) {
			setFileUpload({ ...fileUpload, [e.target.name]: e.target.files });
		}
	};

	const onSelectSpFile = (e, spId) => {
		if (Object.keys(e.target.files).length !== 0) {
			spFileUpload[spId][e.target.name] = e.target.files;
		}
	};

	const fileNames = (name) => {
		let str = "";

		for (let i = 0; i < Object.keys(fileUpload[name]).length; i++) {
			if (str === "") {
				str = str + fileUpload[name][i].name;
			} else {
				str = str + ", " + fileUpload[name][i].name;
			}
		}

		if (str.length < 40) {
			return str;
		} else {
			return `${Object.keys(fileUpload[name]).length} items`;
		}
	};

	const spFileNames = (name, spName) => {
		let str = "";

		for (
			let i = 0;
			i < Object.keys(spFileUpload[spName][name]).length;
			i++
		) {
			if (str === "") {
				str = str + spFileUpload[spName][name][i].name;
			} else {
				str = str + ", " + spFileUpload[spName][name][i].name;
			}
		}

		if (str.length < 40) {
			return str;
		} else {
			return `${Object.keys(spFileUpload[spName][name]).length} items`;
		}
	};

	const addToSpecials = (sp) => {
		const keys = Object.keys(spFormData[sp.dataName]);

		let pushObj = {};
		for (let i in keys) {
			pushObj[
				sp.specialInputs.find((el) => el.dataName === keys[i]).fieldName
			] = spFormData[sp.dataName][keys[i]];
		}

		addedSpecials[sp.dataName].push(pushObj);

		sp.specialInputs.forEach((inp) => {
			if (inp.fieldType === "checkbox") {
				spFormData[sp.dataName][inp.dataName].forEach((op) => {
					op.field = sp.dataName;
					allOptions.push(op);
				});
				spFormData[sp.dataName][inp.dataName] = [];
			} else if (inp.fieldType === "file") {
				addedFiles.push(spFileUpload[sp.dataName][inp.dataName]);
				spFileUpload[sp.dataName][inp.dataName] = [];
			} else if (
				inp.fieldType === "radio" ||
				inp.fieldType === "dropdown"
			) {
				spFormData[sp.dataName][inp.dataName].field = sp.dataName;
				spFormData[sp.dataName][inp.dataName].uid = Date.now();
				allOptions.push(spFormData[sp.dataName][inp.dataName]);
				spFormData[sp.dataName][inp.dataName] = "";
			} else {
				spFormData[sp.dataName][inp.dataName] = "";
			}
		});

		setEstimates();
		forceUpdate();
	};

	const removeFromSpecials = (sp, index) => {
		let filtered = allOptions;

		Object.values(addedSpecials[sp.dataName][index]).forEach((sp) => {
			if (Array.isArray(sp)) {
				sp.forEach((el) => {
					const delIndex = filtered.findIndex(
						(x) => x._id.toString() === el._id.toString()
					);
					filtered.splice(delIndex, 1);
				});
			} else if (typeof sp !== "string" && !Array.isArray(sp)) {
				filtered = filtered.filter((el) => el.uid !== sp.uid);
			}
		});

		setAllOptions(filtered);

		setEstimates(filtered);
		addedSpecials[sp.dataName].splice(index, 1);
		forceUpdate();
	};

	const onAddEstimates = (dataName) => {
		// if (addedEstimates.some((el) => el === dataName)) {
		// 	const delIndex = addedEstimates.findIndex((el) => el === dataName);

		// 	addedEstimates.splice(delIndex, 1);
		// } else {
		// 	addedEstimates.push(dataName);
		// }

		addedEstimates.push(dataName);

		// setEstimates();
		forceUpdate();
	};

	const calcEstimate = async (options, added) => {
		let price = 0;
		let minDuration = 0;
		let maxDuration = 0;
		if (options.length !== 0) {
			for (let i = 0; i < options.length; i++) {
				if (options[i] !== undefined || options[i] !== null) {
					if (added.some((el) => el === options[i].field)) {
						price = price + options[i].optionPrice.amount;
						minDuration = minDuration + options[i].minDuration;
						maxDuration = maxDuration + options[i].maxDuration;
					}
				}
			}
		}

		const values = {
			price: price,
			min: minDuration,
			max: maxDuration,
		};
		return values;
	};

	// const create = () => {
	//     submitRef.current.click();
	// };

	const createDesc = (data) => {
		let str = "";

		const keys = Object.keys(data);
		const values = Object.values(data);

		values.forEach((el, i) => {
			if (addedEstimates.includes(keys[i])) {
				const fieldName = gameForm.formFields.find(
					(el) => el.dataName === keys[i]
				).fieldName;

				if (Array.isArray(el)) {
					let valueStr = "";
					el.forEach((el) => {
						if (valueStr === "") {
							valueStr = el.optionText;
						} else {
							valueStr = valueStr + ", " + el.optionText;
						}
					});
					str = str + fieldName + " - " + valueStr + ` ${"\n"}`;
				} else if (el === "special_field") {
					const name = keys[i];

					str = str + `${fieldName} : ${"\n"}`;

					let fullSpStr = "";

					for (let j = 0; j < addedSpecials[name].length; j++) {
						const spKeys = Object.keys(addedSpecials[name][j]);
						const spValues = Object.values(addedSpecials[name][j]);

						let spStr = "";

						spValues.forEach((spEl, spI) => {
							let oneLine = "";
							if (Array.isArray(spEl)) {
								let valueStr = "";
								spEl.forEach((x) => {
									if (valueStr === "") {
										valueStr = x.optionText;
									} else {
										valueStr =
											valueStr + ", " + x.optionText;
									}
								});
								oneLine =
									"  " +
									spKeys[spI] +
									" - " +
									valueStr +
									` ${"\n"}`;
							} else if (
								typeof spEl !== "string" &&
								!Array.isArray(spEl)
							) {
								oneLine =
									"  " +
									spKeys[spI] +
									" - " +
									spEl.optionText +
									` ${"\n"}`;
							} else {
								oneLine =
									"  " +
									spKeys[spI] +
									" - " +
									spEl +
									` ${"\n"}`;
							}

							spStr = spStr + oneLine;
						});

						fullSpStr = fullSpStr + spStr + ` ${"\n"}`;
					}
					str = str + fullSpStr;
				} else if (typeof el !== "string" && !Array.isArray(el)) {
					str = str + fieldName + " - " + el.optionText + ` ${"\n"}`;
				} else {
					str = str + fieldName + " - " + el + ` ${"\n"}`;
				}
			}
		});
		return str;
	};

	const onCreate = async (e) => {
		e.preventDefault();

		for (let i = 0; i < Object.values(fileUpload).length; i++) {
			const key = Object.keys(fileUpload)[i];
			if (fileUpload[Object.keys(fileUpload)[i]] === "special_field") {
				addedFiles.forEach((el) => {
					attachments.push(el);
				});
			} else {
				attachments.push(fileUpload[key]);
			}
		}

		const desc = createDesc(formData);

		console.log(desc);

		const projectData = {
			description: desc,
			budget: Math.ceil(totalPrice),
		};

		await dispatch(createProject(projectData));

		history.push(`/post-project`);

		// ---------------------------------------------------------------------

		// if (totalPrice !== 0 && addedEstimates.length !== 0) {
		//     const obj = {
		//         title: formTitle,
		//         description: desc,
		//         budget: Math.ceil(totalPrice),
		//     };

		//     if (attachments.length !== 0) {
		//         setLoading(true);
		//         const data = new FormData();
		//         data.append("jsonData", JSON.stringify(obj));
		//         data.append("attachments", attachments);
		//         const res = await dispatch(configureProject(data));
		//         // let res;
		//         history.push(`/project/${res._id}`);
		//     } else {
		//         setLoading(true);
		//         const data = new FormData();
		//         data.append("jsonData", JSON.stringify(obj));
		//         const res = await dispatch(configureProject(data));
		//         // let res;
		//         history.push(`/project/${res._id}`);
		//     }
		// } else {
		//     alert("You have not added any question to estimates");
		//     //   setAlert("You have not added any question to estimates", "danger");
		// }
	};

	return (
		<Fragment>
			{loading ? (
				<Fragment>
					{/* <WebLoader /> */}
					Loading ...
				</Fragment>
			) : (
				<Fragment>
					<div
						className="d-flex flex-column"
						style={{
							width: "100vw",
							height: "100vh",
							paddingTop: "10vh",
						}}
					>
						<main className="game-form flex-grow-1">
							{gameForm !== null && gameForm !== undefined ? (
								<Fragment>
									<div className="p-2">
										{gameForm.formFields !== null &&
										gameForm.formFields !== undefined &&
										gameForm.formFields.length !== 0 ? (
											<Fragment>
												{!authState.authenticated && (
													<div
														className="alert alert-danger text-center"
														role="alert"
													>
														You need to login to
														post a project.
													</div>
												)}
												<div className="container">
													<Carousel itemsToShow={1}>
														{gameForm.formFields.map(
															(field, i) => {
																return (
																	<Fragment
																		key={
																			field._id
																		}
																	>
																		{field.fieldType ===
																		"radio" ? (
																			<>
																				<div className="light-bg d-flex flex-column py-3 mx-3 w-100 px-5">
																					<Radio
																						field={
																							field
																						}
																						onChangeOptions={
																							onChangeOptions
																						}
																						formData={
																							formData
																						}
																					/>
																					{/* <div className="mt-3 mb-3">
																						<button
																							className="btn btn-primary-gradient"
																							onClick={() => {
																								onAddEstimates(
																									field.dataName
																								);
																							}}
																						>
																							{!addedEstimates.some(
																								(
																									el
																								) =>
																									el ===
																									field.dataName
																							)
																								? "Add to Estimate"
																								: "Remove from Estimates"}
																						</button>
																					</div> */}
																				</div>
																			</>
																		) : field.fieldType ===
																		  "checkbox" ? (
																			<>
																				<div className="light-bg d-flex flex-column py-3 mx-3 w-100 px-5">
																					<Checkbox
																						field={
																							field
																						}
																						onSelectChecks={
																							onSelectChecks
																						}
																						formData={
																							formData
																						}
																					/>
																					{/* <div className="mt-3 mb-3">
																						<button
																							className="btn btn-primary-gradient "
																							onClick={() => {
																								onAddEstimates(
																									field.dataName
																								);
																							}}
																						>
																							{!addedEstimates.some(
																								(
																									el
																								) =>
																									el ===
																									field.dataName
																							)
																								? "Add to Estimate"
																								: "Remove from Estimates"}
																						</button>
																					</div> */}
																				</div>
																			</>
																		) : field.fieldType ===
																		  "dropdown" ? (
																			<>
																				<div className="light-bg d-flex flex-column py-3 mx-3 w-100 px-5">
																					<Dropdown
																						field={
																							field
																						}
																						onChangeOptions={
																							onChangeOptions
																						}
																						formData={
																							formData
																						}
																					/>
																					{/* <div className="mt-3 mb-3">
																						<button
																							className="btn btn-primary-gradient "
																							onClick={() => {
																								onAddEstimates(
																									field.dataName
																								);
																							}}
																						>
																							{!addedEstimates.some(
																								(
																									el
																								) =>
																									el ===
																									field.dataName
																							)
																								? "Add to Estimate"
																								: "Remove from Estimates"}
																						</button>
																					</div> */}
																				</div>
																			</>
																		) : field.fieldType ===
																		  "file" ? (
																			<>
																				<div className="light-bg d-flex flex-column py-3 mx-3 w-100 px-5">
																					<FileInput
																						field={
																							field
																						}
																						onSelectFile={
																							onSelectFile
																						}
																						fileNames={
																							fileNames
																						}
																						fileUpload={
																							fileUpload
																						}
																					/>
																					{/* <div className="mt-3 mb-3">
																						<button
																							className="btn btn-primary-gradient"
																							onClick={() => {
																								onAddEstimates(
																									field.dataName
																								);
																							}}
																						>
																							{!addedEstimates.some(
																								(
																									el
																								) =>
																									el ===
																									field.dataName
																							)
																								? "Add to Estimate"
																								: "Remove from Estimates"}
																						</button>
																					</div> */}
																				</div>
																			</>
																		) : field.fieldType ===
																		  "special" ? (
																			<>
																				<div className="light-bg d-flex flex-column py-3 mx-3 w-100 px-5">
																					<SpecialInput
																						field={
																							field
																						}
																						onChangeOptions={
																							onSpChangeOptions
																						}
																						onSelectChecks={
																							onSpSelectChecks
																						}
																						onSelectFile={
																							onSelectSpFile
																						}
																						onChangeValue={
																							onSpChangeValue
																						}
																						fileNames={
																							spFileNames
																						}
																						fileUpload={
																							spFileUpload
																						}
																						addedSpecials={
																							addedSpecials
																						}
																						addToSpecials={
																							addToSpecials
																						}
																						removeFromSpecials={
																							removeFromSpecials
																						}
																						formData={
																							spFormData
																						}
																					/>
																					{/* <div className="mt-4 mb-3">
																						<button
																							className="btn btn-primary-gradient  "
																							onClick={() => {
																								onAddEstimates(
																									field.dataName
																								);
																							}}
																						>
																							{!addedEstimates.some(
																								(
																									el
																								) =>
																									el ===
																									field.dataName
																							)
																								? "Add to Estimate"
																								: "Remove from Estimates"}
																						</button>
																					</div> */}
																				</div>
																			</>
																		) : (
																			<>
																				<div className="light-bg d-flex flex-column py-3 mx-3 w-100 px-5">
																					<DefaultInput
																						field={
																							field
																						}
																						onChangeValue={
																							onChangeValue
																						}
																						formData={
																							formData
																						}
																					/>
																					{/* <div className="mt-3 mb-3">
																						<button
																							className="btn btn-primary-gradient "
																							onClick={() => {
																								onAddEstimates(
																									field.dataName
																								);
																							}}
																						>
																							{!addedEstimates.some(
																								(
																									el
																								) =>
																									el ===
																									field.dataName
																							)
																								? "Add to Estimate"
																								: "Remove from Estimates"}
																						</button>
																					</div> */}
																				</div>
																			</>
																		)}
																	</Fragment>
																);
															}
														)}
														{/* <form
                                                        onSubmit={(e) =>
                                                            onCreate(e)
                                                        }
                                                    >
                                                        <div
                                                            className="light-bg py-3 mx-3"
                                                            style={{
                                                                marginTop:
                                                                    "7rem",
                                                            }}
                                                        >
                                                            <div className="form-group px-5">
                                                                <h3 className="my-3">
                                                                    Project
                                                                    Title
                                                                </h3>
                                                                <p className="text-muted mb-3">
                                                                    Give your
                                                                    project a
                                                                    name
                                                                </p>
                                                                <input
                                                                    autoComplete="off"
                                                                    type="text"
                                                                    name="formTitle"
                                                                    value={
                                                                        formTitle
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        onChangeTitle(
                                                                            e
                                                                        )
                                                                    }
                                                                    placeholder="Enter Your Project Title"
                                                                    className="dark-input rounded"
                                                                    style={{
                                                                        padding:
                                                                            "7px",
                                                                        border: "0px",
                                                                    }}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        <input
                                                            ref={submitRef}
                                                            className="btn-game-form-secondary"
                                                            type="submit"
                                                            hidden
                                                        />
                                                    </form> */}
													</Carousel>
												</div>
											</Fragment>
										) : (
											<Fragment>
												<h3 className="text-center">
													This Service is currently
													not active.
												</h3>
											</Fragment>
										)}
									</div>
								</Fragment>
							) : (
								<Fragment>
									<h3 className="text-center">
										This Service is currently not active.
									</h3>
								</Fragment>
							)}
						</main>

						<section
							className="overview-section p-3 light-bg d-flex"
							style={{
								width: "100%",
								borderRadius: "0",
								zIndex: "100",
							}}
						>
							<div className="overview flex-grow-1 px-4">
								<Fragment>
									<div className="row my-2">
										<h4 className="col-8 text-left">
											Total Estimated Price :&nbsp;
										</h4>
										{totalPrice !== 0 ? (
											<h4 className="col-4 text-right">
												{totalPrice} USD
											</h4>
										) : (
											""
										)}
									</div>
								</Fragment>

								<Fragment>
									<div className="row my-2">
										<h4 className="col-8 text-left">
											Estimated min. Duration :&nbsp;
										</h4>
										{totalMinDuration !== 0 ? (
											<h4 className="col-4 text-right">
												{totalMinDuration} Week
											</h4>
										) : (
											""
										)}
									</div>
								</Fragment>
								<Fragment>
									<div className="row">
										<h4 className="col-8 text-left">
											Estimated max. Duration :&nbsp;
										</h4>
										{totalMaxDuration !== 0 ? (
											<h4 className="col-4 text-right">
												{totalMaxDuration} Week
											</h4>
										) : (
											""
										)}
									</div>
								</Fragment>
							</div>
							<div className="d-flex align-items-center px-4">
								<button
									className="btn btn-save btn-primary-gradient"
									onClick={(e) => onCreate(e)}
									disabled={
										!authState.authenticated ? true : false
									}
								>
									Create
								</button>
							</div>
						</section>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default GameCustomizer;
