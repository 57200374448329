import { getApiEndpoint } from "../utils/getApiEndpoint";
import { requestWithToken } from "../utils/httpRequest";
import { error } from "./error";
import { UPDATE_UNSEEN_NOTIFICATION_COUNT } from "./types";

export const updateUnseenNotificationCount = () => (dispatch) => {
    dispatch({
        type: UPDATE_UNSEEN_NOTIFICATION_COUNT,
    });
};

export const notificationsSeen = () => async (dispatch) => {
    try {
        await requestWithToken(
            "POST",
            `${getApiEndpoint()}/notification/customers/mark`
        );

        dispatch({
            type: UPDATE_UNSEEN_NOTIFICATION_COUNT,
            payload: 0,
        });
    } catch (err) {
        dispatch(error(err));
    }
};

export const getNewNotificationCount = () => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/notification/customers/count`
        );

        dispatch({
            type: UPDATE_UNSEEN_NOTIFICATION_COUNT,
            payload: response.data.data,
        });

        return response.data;
    } catch (err) {
        dispatch(error(err));
    }
};

export const getAllNotifications = () => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/notification/customers`
        );

        return response.data;
    } catch (err) {
        dispatch(error(err));
    }
};
