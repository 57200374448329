import { combineReducers } from "redux";

import alert from "./alert";
import auth from "./auth";
import chat from "./chat";
import chatPanel from "./chatPanel";
import socket from "./socket";
import error from "./error";
import gameCustomizer from "./gameCustomizer";
import chatBot from "./chatBot";
import notification from "./notification";
import topLoader from "./topLoader";
import project from "./project";
import reacttour from "./reacttour";
import realtimeEvents from "./realtimeEvents";
import profile from "./profile";

export default combineReducers({
    alert,
    auth,
    chat,
    chatPanel,
    socket,
    error,
    gameCustomizer,
    chatBot,
    topLoader,
    notification,
    project,
    reacttour,
    realtimeEvents,
    profile,
});
