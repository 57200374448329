import React, { Fragment, useEffect } from "react";

import styles from "./focus.module.css";
import useDynamicRefs from "use-dynamic-refs";
import { useSelector } from "react-redux";

const FocusVideoChat = ({
    rtc,
    remoteUsers,
    toggleGridMode,
    focused,
    videoEnabled,
    audioEnabled,
}) => {
    const [getRef, setRef] = useDynamicRefs();

    const authState = useSelector((state) => state.auth);

    useEffect(() => {
        if (rtc.localVideoTrack) {
            const localVideoRef = getRef("localVideo");
            if (localVideoRef.current) {
                rtc.localVideoTrack.play(localVideoRef.current);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rtc.localVideoTrack]);

    useEffect(() => {
        remoteUsers.map((remoteUser) => {
            const streamDiv = getRef(remoteUser.uid);
            if (streamDiv && streamDiv.current) {
                remoteUser.videoTrack?.play(streamDiv.current);
            }
            return null;
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remoteUsers, focused]);

    return (
        <div className={`row light-bg p-4 h-100`}>
            <div className="col-md-10">
                {remoteUsers.map((remoteUser) => {
                    if (remoteUser.uid === focused) {
                        return (
                            <Fragment>
                                <div
                                    id={remoteUser.uid}
                                    ref={setRef(remoteUser.uid)}
                                    className={`${
                                        styles["vid-one"]
                                    } dark-bg position-relative ${
                                        !remoteUser.hasVideo
                                            ? "d-flex align-items-center justify-content-center"
                                            : ""
                                    }`}
                                    onClick={() =>
                                        toggleGridMode(remoteUser.uid)
                                    }
                                >
                                    {!remoteUser.hasVideo && (
                                        <span className="material-icons fs-1">
                                            account_circle
                                        </span>
                                    )}
                                    <div
                                        className={`${styles["video-stream-username"]}`}
                                    >
                                        {!remoteUser?.hasAudio && (
                                            <span className="material-icons text-white me-2">
                                                mic_off
                                            </span>
                                        )}
                                        {remoteUser.userDetails.user.name}{" "}
                                        {remoteUser.isScreenShare
                                            ? " (Screen)"
                                            : ""}
                                    </div>
                                </div>
                            </Fragment>
                        );
                    }
                    return null;
                })}
            </div>
            <div className="col-md-2" style={{ overflowY: "auto" }}>
                <div
                    ref={setRef("localVideo")}
                    className={`${styles["vid-one"]} ${
                        styles["side-stream"]
                    } dark-bg position-relative mb-3 ${
                        !videoEnabled
                            ? "d-flex align-items-center justify-content-center"
                            : ""
                    }`}
                    // onClick={toggleGridMode}
                >
                    {!videoEnabled && (
                        <span className="material-icons fs-1">
                            account_circle
                        </span>
                    )}
                    <div className={`${styles["video-stream-username"]}`}>
                        {!audioEnabled && (
                            <span className="material-icons text-white me-2">
                                mic_off
                            </span>
                        )}
                        {authState.user.name}
                    </div>
                </div>
                <Fragment>
                    {remoteUsers.map((remoteUser) => {
                        if (remoteUser.uid !== focused) {
                            return (
                                <Fragment key={remoteUser.uid}>
                                    <div
                                        id={remoteUser.uid}
                                        ref={setRef(remoteUser.uid)}
                                        className={`${styles["vid-one"]} ${
                                            styles["side-stream"]
                                        } dark-bg position-relative mb-3 ${
                                            !remoteUser.hasVideo
                                                ? "d-flex align-items-center justify-content-center"
                                                : ""
                                        }`}
                                        onClick={() =>
                                            toggleGridMode(remoteUser.uid)
                                        }
                                    >
                                        {!remoteUser.hasVideo && (
                                            <span className="material-icons fs-1">
                                                account_circle
                                            </span>
                                        )}
                                        <div
                                            className={`${styles["video-stream-username"]}`}
                                        >
                                            {!remoteUser?.hasAudio && (
                                                <span className="material-icons text-white me-2">
                                                    mic_off
                                                </span>
                                            )}
                                            {remoteUser.userDetails.user.name}{" "}
                                            {remoteUser.isScreenShare
                                                ? " (Screen)"
                                                : ""}
                                        </div>
                                    </div>
                                </Fragment>
                            );
                        }
                        return null;
                    })}
                </Fragment>
            </div>
        </div>
    );
};

export default FocusVideoChat;
