import React, { Fragment } from "react";
import ProfileOverviewSkeleton from "../../../components/Profile/ProfileOverviewSkeleton/ProfileOverviewSkeleton";
import AboutSkeleton from "../../../components/Profile/AboutSkeleton/AboutSkeleton";
import PortfolioSkeleton from "../../../components/Profile/PortfolioSkeleton/PortfolioSkeleton";
import ExperienceSkeleton from "../../../components/Profile/ExperienceSkeleton/ExperienceSkeleton";
import EducationSkeleton from "../../../components/Profile/EducationSkeleton/EducationSkeleton";
import SkillsSkeleton from "../../../components/Profile/SkillsSkeleton/SkillsSkeleton";
import styles from "./../Profile.module.css";
import ReviewsSkeleton from "../../../components/Profile/ReviewsSkeleton/ReviewsSkeleton";

const Skeleton = () => {
    return (
        <Fragment>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 px-lg-2 m-0">
                        <ProfileOverviewSkeleton />

                        <SkillsSkeleton />
                    </div>
                    <div className="col-lg-9 px-lg-2 m-0">
                        <section
                            className={`${styles["section-head"]} light-bg p-4 my-lg-3 mb-3`}
                        >
                            <div
                                className={`${styles["section-title"]} mb-3 d-flex align-items-center`}
                            >
                                <h5 className="flex-grow-1">About</h5>
                            </div>
                            <AboutSkeleton />
                        </section>

                        <section
                            className={`${styles["section-head"]} light-bg p-4 my-lg-3 mb-3`}
                        >
                            <div
                                className={`${styles["section-title"]} mb-3 d-flex align-items-center`}
                            >
                                <h5 className="flex-grow-1">Portfolio</h5>
                            </div>
                            <PortfolioSkeleton />
                        </section>

                        <section
                            className={`${styles["section-head"]} light-bg p-4 my-lg-3 mb-3`}
                        >
                            <div
                                className={`${styles["section-title"]} mb-3 d-flex align-items-center`}
                            >
                                <h5 className="flex-grow-1">Experience</h5>
                            </div>
                            <ExperienceSkeleton />
                        </section>

                        <section
                            className={`${styles["section-head"]} light-bg p-4 my-lg-3 mb-3`}
                        >
                            <div
                                className={`${styles["section-title"]} mb-3 d-flex align-items-center`}
                            >
                                <h5 className="flex-grow-1">Education</h5>
                            </div>
                            <EducationSkeleton />
                        </section>
                        <section
                            className={`${styles["section-head"]} light-bg p-4 my-lg-3 mb-3`}
                        >
                            <div
                                className={`${styles["section-title"]} mb-3 d-flex align-items-center`}
                            >
                                <h5>Reviews</h5>
                            </div>
                            <ReviewsSkeleton />
                        </section>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Skeleton;
