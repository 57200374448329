import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { seo } from "../../utils/seo";
import ProjectListItem from "../../components/ProjectListItem/ProjectListItem";
import { fetchRecommendedProjects } from "../../actions/project";
import { getActiveSkills } from "../../actions/skills";
import { toggleTopLoader } from "../../actions/topLoader";
import Skeleton from "../../components/Skeleton/Skeleton";
import {
	newRecommendedProject,
	removeSocketEventListener,
} from "../../utils/clientEvents";

const RecommendedProjects = () => {
	const dispatch = useDispatch();

	const isMounted = useRef(true);

	const socketState = useSelector((state) => state.socket);

	const [projects, setProjects] = useState(null);
	const [loadingProjects, setLoadingProjects] = useState(true);
	// const [recommended, setRecommended] = useState(true);

	const [skillsArray, setSkillsArray] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [listLength, setListLength] = useState(10);
	const [totalPages, setTotalPages] = useState(0);
	const [newRecommendations, setNewRecommendations] = useState(false);

	useEffect(() => {
		seo({ title: "Browse Projects" });
		loadData();

		return () => {
			dispatch(toggleTopLoader(false));
		};
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		dispatch(toggleTopLoader(true));
		if (projects && skillsArray) {
			dispatch(toggleTopLoader(false));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projects, skillsArray]);

	useEffect(() => {
		// Realtime page events
		if (socketState.socket) {
			newRecommendedProject(socketState.socket, (data) => {
				setNewRecommendations(true);
			});

			return () => {
				removeSocketEventListener(
					socketState.socket,
					"new-project-notification"
				);
			};
		}
	}, [socketState.socket]);

	const loadRecommendedProjects = async (page, limit) => {
		setNewRecommendations(false);
		const projects = await dispatch(fetchRecommendedProjects(page, limit));
		if (projects.data.status) {
			if (isMounted.current) {
				setLoadingProjects(false);
				setProjects(projects.data.data.projects);
				setTotalPages(projects.data.data.totalPages);
			}
		}
	};

	const loadData = async (e) => {
		loadRecommendedProjects(currentPage, listLength);
		const skills = await dispatch(getActiveSkills());

		if (skills.status) {
			if (isMounted.current)
				setSkillsArray(
					skills.data.map((skill) => {
						return {
							key: skill._id,
							text: skill.name,
							value: skill._id,
						};
					})
				);
		}
	};

	return (
		<Fragment>
			<div className="container">
				<div className="row w-100">
					<div className="col-lg-12 px-lg-2 m-0">
						<span data-tour="step-browseProject-2">
							<div className="d-flex align-items-center">
								<h3 className="text-capitalize flex-grow-1">
									Recommended Projects
								</h3>
								<div className="d-flex align-items-center">
									Showing:{" "}
									<select
										className="dark-input ms-2"
										value={listLength}
										onChange={(e) => {
											const { value } = e.target;
											setListLength(value);
											loadRecommendedProjects(1, value);
										}}
									>
										<option value="1">1</option>
										<option value="2">2</option>
										<option value="10">10</option>
									</select>
								</div>
							</div>
						</span>
						<section className="job-results my-lg-3 mb-3">
							{newRecommendations && (
								<p className="alert alert-danger text-center">
									New recommendations found...{" "}
									{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
									<a
										className="text-decoration-underline"
										onClick={loadRecommendedProjects}
									>
										Refresh Now
									</a>
								</p>
							)}
							{loadingProjects ? (
								[1, 2].map((el, index) => (
									<article
										key={index}
										className="light-bg p-4 mb-3 "
									>
										<div className="d-flex mb-3 mb-lg-0">
											<div className="profile-pic align-self-center">
												<Skeleton
													shape="circle"
													height="50px"
													width="50px"
												/>
											</div>

											<div
												style={{
													marginRight: "9px",
												}}
												className="job-listed-details flex-grow-1 ms-4 d-none d-md-block"
											>
												{/* <p className="mb-1">
						<Skeleton />
					  </p> */}
												<h5 className="d-inline my-3">
													<Skeleton />
												</h5>
												{/* <h5 className="d-inline my-3 ms-3">
						<Skeleton />
					  </h5> */}
												{/* <h2 className="mb-3">
						<Skeleton />
					  </h2> */}

												<Fragment>
													<ul
														className="job-skills p-0"
														style={{
															listStyleType:
																"none",
															marginTop: "19px",
															marginBottom:
																"19px",
														}}
													>
														<Fragment>
															<Skeleton />
														</Fragment>
													</ul>
												</Fragment>

												<div className="mt-3">
													<Skeleton />
												</div>
											</div>
											<div className="job-listed-price align-self-center ms-auto">
												<h2 className="job-rate">
													<Skeleton />
												</h2>
											</div>
										</div>
									</article>
								))
							) : projects && projects.length ? (
								projects.map((project, index) => {
									return (
										<ProjectListItem
											key={index}
											project={project}
										/>
									);
								})
							) : (
								<>
									<h4 className="text-center py-5">
										No Projects Found
									</h4>
								</>
							)}
							<div className="d-flex align-items-center justify-content-center gap-3">
								{!loadingProjects && currentPage > 1 && (
									<button
										type="button"
										onClick={() => {
											loadRecommendedProjects(
												currentPage - 1,
												listLength
											);
											setCurrentPage(
												(value) => value - 1
											);
										}}
										className="btn btn-primary-gradient"
									>
										Previous
									</button>
								)}

								<ul className="pagination mb-0">
									{totalPages > 1 &&
										[...Array(totalPages)].map(
											(number, i) => (
												<li
													key={i}
													className="page-item"
												>
													<a
														onClick={() => {
															loadRecommendedProjects(
																i + 1,
																listLength
															);
															setCurrentPage(
																i + 1
															);
														}}
														className="page-link"
														style={{
															background: "black",
														}}
													>
														{i + 1}
													</a>
												</li>
											)
										)}
								</ul>

								{currentPage < totalPages && (
									<button
										type="button"
										onClick={() => {
											loadRecommendedProjects(
												currentPage + 1,
												listLength
											);
											setCurrentPage(
												(value) => value + 1
											);
										}}
										className="btn btn-primary-gradient"
									>
										Next
									</button>
								)}

								{/* {pagination.next != null ? (
                                    <button
                                        type="button"
                                        onClick={() =>
                                            loadProjects(
                                                { skills, budgetRange },
                                                pagination.next.page,
                                                listLength
                                            )
                                        }
                                        className="btn btn-primary-gradient"
                                    >
                                        Next
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        className="btn btn-primary-gradient"
                                        disabled={true}
                                        style={{ opacity: 0.4 }}
                                    >
                                        Next
                                    </button>
                                )} */}
							</div>
						</section>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default RecommendedProjects;
