import { Dialog } from "@material-ui/core";
import { useFormik } from "formik";
import React, { Fragment, useState } from "react";
import { FiExternalLink } from "react-icons/fi";
import { getApiEndpoint } from "../../../utils/getApiEndpoint";
import {
	CustomizedDialogContent,
	CustomizedDialogTitle,
} from "../CustomizedModal/CustomizedModal";
import LoadingButton from "../../utils/LoadingButton/LoadingButton";

const SignAgreementsModal = ({
	isOpen,
	onClose,
	projectAgreements,
	onAccept,
}) => {
	const [loading, setLoading] = useState(false);

	const agreementsFormik = useFormik({
		initialValues: { signedAgreements: [] },
	});

	const onSignAgreement = (agreementId) => {
		if (agreementsFormik.values.signedAgreements.includes(agreementId)) {
			const cloneArray = [...agreementsFormik.values.signedAgreements];

			const agreementIndex = cloneArray.findIndex(
				(agreement) => agreement === agreementId
			);

			if (agreementIndex > -1) {
				cloneArray.splice(agreementIndex, 1);
			}

			agreementsFormik.setFieldValue("signedAgreements", cloneArray);
		} else {
			const cloneArray = [...agreementsFormik.values.signedAgreements];
			cloneArray.push(agreementId);

			agreementsFormik.setFieldValue("signedAgreements", cloneArray);
		}
	};

	const handleAccept = async () => {
		setLoading(true);
		await onAccept(agreementsFormik.values.signedAgreements);
		onClose(false);
		setLoading(false);
	};

	return (
		<Fragment>
			<Dialog
				onClose={() => onClose(false)}
				open={isOpen}
				maxWidth="sm"
				fullWidth={true}
			>
				<CustomizedDialogTitle>
					<h3>Sign Project Agreements</h3>
					<p className="text-muted">
						Please agree with all the project agreements first to
						accept the project.
					</p>
				</CustomizedDialogTitle>
				<CustomizedDialogContent>
					<table className="table table-borderless">
						<thead>
							<tr>
								<th>#</th>
								<th>Agreement</th>
								<th>Sign</th>
							</tr>
						</thead>
						<tbody>
							{projectAgreements.map((agreement, index) => {
								return (
									<tr>
										<td>{index + 1}</td>
										<td>
											<a
												href={`${
													getApiEndpoint() +
													agreement.url
												}`}
												target="_blank"
												rel="noreferrer"
											>
												{agreement.name}{" "}
												<FiExternalLink />
											</a>
										</td>
										<td>
											<input
												type="checkbox"
												onChange={() =>
													onSignAgreement(
														agreement._id
													)
												}
											/>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
					<div className="text-end">
						<button
							className="btn btn-secondary me-2"
							onClick={() => onClose(false)}
						>
							Cancel
						</button>
						<LoadingButton
							text="Accept"
							isLoading={loading}
							className="btn btn-primary-gradient"
							disabled={
								agreementsFormik.values.signedAgreements
									.length !== projectAgreements.length
							}
							onClick={handleAccept}
						/>
					</div>
				</CustomizedDialogContent>
			</Dialog>
		</Fragment>
	);
};

export default SignAgreementsModal;
