import React, { Fragment, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../actions/alert";
import { editFolio } from "../../../actions/profile";
import { ProfileContext } from "../../../contexts/ProfileContext";

const EditFolio = ({ folio, callBackCancel }) => {
    const dispatch = useDispatch();
    const updateCallback = () => {
        callBackCancel(false);
    };
    const context = useContext(ProfileContext);

    const [disable, setDisable] = useState(false);
    const [Folio] = useState(folio ? folio : "");
    const [formData, setFormData] = useState({
        title: Folio.title,
        link: Folio.link,
        description: Folio.description,
        images: [],
    });
    const { title, link, description, images } = formData;

    const onChangeValue = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const fileSelectHandler = (e) => {
        setFormData({
            ...formData,
            images: [...images, e.target.files[0]],
        });
    };

    const onFormSubmit = async (e) => {
        e.preventDefault();
        setDisable(true);
        const response = await dispatch(editFolio(Folio._id, formData));
        if (response.status === 1) {
            await dispatch(setAlert(response.msg, "success"));

            callBackCancel(false);
            context.loadData();
        } else await dispatch(setAlert(response.msg, "danger"));
        setDisable(true);
    };
    return (
        <Fragment>
            <div className="section-body px-3">
                <form onSubmit={(e) => onFormSubmit(e)}>
                    <div className="form-group">
                        <div className="row mb-2">
                            <div className="col-md-6 mb-2 mb-md-0">
                                <label className="form-label">Title</label>
                                <input
                                    type="text"
                                    className="dark-input"
                                    name="title"
                                    placeholder="Title"
                                    value={title}
                                    onChange={(e) => onChangeValue(e)}
                                    required
                                />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">
                                    Add relevant URL
                                </label>
                                <input
                                    type="text"
                                    className="dark-input"
                                    name="link"
                                    placeholder="URL"
                                    value={link}
                                    onChange={(e) => onChangeValue(e)}
                                    //   required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-2">
                        <label for="formFile" className="form-label">
                            Add Project Image
                        </label>
                        <input
                            className="dark-input"
                            type="file"
                            name="images"
                            id="formFile"
                            accept=".jpg,.jpeg,.png"
                            onChange={(e) => fileSelectHandler(e)}
                        />
                    </div>
                    <div className="form-group mb-2">
                        <label className="form-label">Description</label>
                        <textarea
                            className="dark-input"
                            name="description"
                            rows="5"
                            placeholder="Describe your work experience"
                            value={description}
                            onChange={(e) => onChangeValue(e)}
                        ></textarea>
                    </div>
                    <div className="section-options mb-2">
                        <button
                            disabled={disable}
                            type="submit"
                            className="btn-save me-2"
                        >
                            Save{" "}
                            {disable ? (
                                <img
                                    src="/img/loader.gif"
                                    alt="loader"
                                    width="25px"
                                />
                            ) : (
                                ""
                            )}
                        </button>
                        <button
                            className="btn-cancel"
                            onClick={(e) => updateCallback()}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </Fragment>
    );
};

export default EditFolio;
