import React, { Fragment } from "react";
import styles from "./Gamesection.module.css";
const Gamesection = () => {
    return (
        <Fragment>
            <h2 className="text-center py-5 px-3">
                CREATE A VIRTUAL EXPERIENCE FOR THESE AREAS
            </h2>
            <div className="container ">
                <div className="row w-100">
                    <div className="col-md-4 text-center mb-5">
                        <h4 className={`mb-4 ${styles["underline"]}`}>GAMES</h4>
                        <div className="ratio ratio-16x9">
                            <iframe
                                height="auto"
                                width="auto"
                                src="https://youtube.com/embed/KF8J6ySCzco"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </div>
                    </div>
                    <div className="col-md-4 text-center mb-5">
                        <h4 className={`mb-4 ${styles["underline"]}`}>
                            BUSINESS
                        </h4>
                        <div className="ratio ratio-16x9">
                            <iframe
                                height="auto"
                                width="auto"
                                src="https://youtube.com/embed/EqXVF3ovwmM"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </div>
                    </div>
                    <div className="col-md-4 text-center mb-5">
                        <h4 className={`mb-4 ${styles["underline"]}`}>
                            EDUCATION
                        </h4>
                        <div className="ratio ratio-16x9">
                            <iframe
                                height="auto"
                                width="auto"
                                src="https://youtube.com/embed/flquxG0V36w"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Gamesection;
