import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { seo } from "../../utils/seo";
import { getActivePlans } from "../../actions/plan";
import { fetchCurrentUserProfile } from "../../actions/profile";
import GetPlan from "../../components/Modals/GetPlan/GetPlan";

import styles from "./upgradePlan.module.css";
import { toggleTopLoader } from "../../actions/topLoader";
import moment from "moment";

const UpgradePlan = () => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const [activePlan, setActivePlan] = useState(null);
    const [isExpired, setIsExpired] = useState(false);
    const [profile, setProfile] = useState(null);
    const [packs, setPacks] = useState([]);

    useEffect(() => {
        dispatch(toggleTopLoader(true));
        loadPacks();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        seo({ title: "Plan" });
        if (profile) {
            if (profile.currentPlan) {
                const daysDiff = moment(profile.currentPlan.endDate).diff(
                    moment(),
                    "days"
                );

                if (daysDiff < 0) {
                    setActivePlan(profile.defaultPlan);
                    setIsExpired(false);
                } else {
                    setActivePlan(profile.currentPlan.plan);
                }
            } else {
                setActivePlan(profile.defaultPlan);
                setIsExpired(false);
            }
        }
    }, [profile]);

    const loadPacks = async () => {
        const profileRes = await dispatch(fetchCurrentUserProfile());
        if (profileRes.status) setProfile(profileRes.data);
        const packsRes = await dispatch(getActivePlans());
        if (packsRes.status) setPacks(packsRes.data);

        dispatch(toggleTopLoader(false));
        setLoading(false);
    };

    return (
        <Fragment>
            <main className="container py-5">
                {profile && profile.defaultPlan && (
                    <Fragment>
                        <section className="light-bg p-4 d-flex align-items-center position-relative mb-3">
                            {activePlan &&
                                activePlan._id === profile.defaultPlan._id && (
                                    <p
                                        className={`${styles["current-plan-tag"]}`}
                                    >
                                        Current Plan
                                    </p>
                                )}
                            <div className="flex-grow-1">
                                <h5 className="text-muted">Free Plan:</h5>
                                <h3>
                                    {profile.defaultPlan.name}
                                    {isExpired && (
                                        <Fragment>&nbsp;(Expired)</Fragment>
                                    )}
                                </h3>
                            </div>
                            <div className="plan-perks">
                                <div className="d-flex justify-content-end my-1">
                                    <p className="m-0">
                                        Active Projects:&nbsp;
                                    </p>
                                    <p className="ml-2">
                                        {profile.defaultPlan.projectsCount}
                                    </p>
                                </div>
                                <div className="d-flex justify-content-end my-1">
                                    <p className="m-0">Bids:&nbsp;</p>
                                    <p className="ml-2">
                                        {profile.defaultPlan.bidsCount}
                                    </p>
                                </div>
                                <div className="d-flex justify-content-end my-1">
                                    <p className="m-0">Validity:&nbsp;</p>
                                    <p className="ml-2">Forever</p>
                                </div>
                            </div>
                        </section>
                    </Fragment>
                )}

                {!loading ? (
                    <Fragment>
                        {packs.length > 0 ? (
                            <Fragment>
                                <div
                                    className={`${styles["plan-container"]} pb-3`}
                                >
                                    <div className="row">
                                        {packs.map((pack, index) => {
                                            return (
                                                <Fragment key={index}>
                                                    <div className="col-md-4">
                                                        <div className="plan-item light-bg p-4 d-flex flex-column justify-content-between position-relative">
                                                            {activePlan._id ===
                                                                pack._id && (
                                                                <p
                                                                    className={`${styles["current-plan-tag"]}`}
                                                                >
                                                                    Current Plan
                                                                </p>
                                                            )}
                                                            <h3>{pack.name}</h3>
                                                            <div className="plan-perks">
                                                                <table className="table table-borderless">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <p className="mb-0">
                                                                                    Active
                                                                                    Projects:
                                                                                </p>
                                                                            </td>
                                                                            <td>
                                                                                <p className="text-end mb-0">
                                                                                    {
                                                                                        pack.projectsCount
                                                                                    }
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <p className="mb-0">
                                                                                    Bids:
                                                                                </p>
                                                                            </td>
                                                                            <td>
                                                                                <p className="text-end mb-0">
                                                                                    {
                                                                                        pack.bidsCount
                                                                                    }
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <p className="mb-0">
                                                                                    Validity:
                                                                                </p>
                                                                            </td>
                                                                            <td>
                                                                                <p className="text-end mb-0">
                                                                                    {
                                                                                        pack
                                                                                            .duration
                                                                                            .value
                                                                                    }{" "}
                                                                                    {
                                                                                        pack
                                                                                            .duration
                                                                                            .scope
                                                                                    }
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="d-flex justify-content-center w-100">
                                                                <GetPlan
                                                                    profile={
                                                                        profile
                                                                    }
                                                                    plan={pack}
                                                                    refreshPlanPage={
                                                                        loadPacks
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            );
                                        })}
                                    </div>
                                </div>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div className="light-bg p-4 text-center">
                                    <h3>No Active Plans to Buy</h3>
                                </div>
                            </Fragment>
                        )}
                    </Fragment>
                ) : (
                    "Loading..."
                )}
            </main>
        </Fragment>
    );
};

export default UpgradePlan;
