import React, { Fragment } from 'react';
import styles from './Impressum.module.css';

const Impressum = () => {
    return (
        <div style={{ paddingTop: '7rem', paddingBottom: '4rem' }}>
            <div
                className='container'
                style={{ color: '#fff', paddingLeft: '40px' }}
            >
                <Fragment>
                    <h1 className={styles['heading']}>Impressum</h1>
                    <h4>Legal Disclosure</h4>
                    <p>Information in accordance with Section 5 TMG</p>
                    <p>
                        NeoxEntertainment Fabian Grundmann Am Jägerstuhl 10
                        37574 Einbeck
                    </p>

                    <h4>CONTACT INFORMATION</h4>
                    <p>
                        E-Mail: neoxdev.info@gmail.com Internet address:
                        https://neoxentertainment.com
                    </p>

                    <h4>VAT NUMBER</h4>
                    <p>
                        VAT indentification number in accorance with Section 27
                        a of the German VAT act 12/115/01512
                    </p>
                    <h4>DISCLAIMER</h4>
                    <p>
                        Accountability for content The contents of our pages
                        have been created with the utmost care. However, we
                        cannot guarantee the contents' accuracy, completeness or
                        topicality. According to statutory provisions, we are
                        furthermore responsible for our own content on these web
                        pages. In this matter, please note that we are not
                        obliged to monitor the transmitted or saved information
                        of third parties, or investigate circumstances pointing
                        to illegal activity. Our obligations to remove or block
                        the use of information under generally applicable laws
                        remain unaffected by this as per §§ 8 to 10 of the
                        Telemedia Act (TMG).
                    </p>

                    <h4>Accountability for links</h4>

                    <p>
                        Responsibility for the content of external links (to web
                        pages of third parties) lies solely with the operators
                        of the linked pages. No violations were evident to us at
                        the time of linking. Should any legal infringement
                        become known to us, we will remove the respective link
                        immediately.
                    </p>

                    <h4>Copyright</h4>
                    <p>
                        Our web pages and their contents are subject to German
                        copyright law. Unless expressly permitted by law, every
                        form of utilizing, reproducing or processing works
                        subject to copyright protection on our web pages
                        requires the prior consent of the respective owner of
                        the rights. Individual reproductions of a work are only
                        allowed for private use. The materials from these pages
                        are copyrighted and any unauthorized use may violate
                        copyright laws.
                    </p>
                </Fragment>
            </div>
        </div>
    );
};
export default Impressum;
