import { ADD_REALTIME_EVENT, CLEAR_REALTIME_EVENT } from "./../actions/types";

const initialState = { type: null, data: null };

const realtimeEvents = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case ADD_REALTIME_EVENT:
            return { ...state, type: payload.type, data: payload.data };
        case CLEAR_REALTIME_EVENT:
            return { ...state, type: null, data: null };
        default:
            return state;
    }
};

export default realtimeEvents;
