import React, { Fragment, useRef, useState } from "react";

import parse from "html-react-parser";

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value + 1); // update the state to force render
}

const Checkbox = ({
    field,
    onSelectChecks,
    sp,
    required,
    nested = false,
    formData,
}) => {
    const forceUpdate = useForceUpdate();

    const checks = useRef();

    const [oneChecked, setOneChecked] = useState(false);

    const onSelect = (e) => {
        if (sp !== null && sp !== undefined && sp !== {}) {
            const inputs = checks.current.querySelectorAll(
                "input[type='checkbox']"
            );

            for (let i = 0; i < inputs.length; i++) {
                if (inputs[i].checked === true) {
                    setOneChecked(true);
                }
            }
            onSelectChecks(e, field.dataName, sp.dataName);
        } else {
            onSelectChecks(e, field.dataName);
        }

        forceUpdate();
    };

    return (
        <Fragment>
            <div className="form-group">
                {nested ? (
                    <h6 className="mt-3 mb-2">{field.fieldName}</h6>
                ) : (
                    <h3 className="my-3">{field.fieldName}</h3>
                )}
                {field.fieldDesc && field.fieldDesc !== "" ? (
                    <div className="text-muted mb-3">
                        {parse(field.fieldDesc)}
                    </div>
                ) : (
                    ""
                )}
                {field.fieldOptions.length !== 0 ? (
                    <Fragment>
                        <div ref={checks}>
                            {field.fieldOptions.map((op) => {
                                return (
                                    <Fragment key={op._id}>
                                        <div className="checkbox d-flex">
                                            <label
                                                className="checkbox-one d-flex align-items-center p-2 m-0"
                                                htmlFor={op._id}
                                            >
                                                <input
                                                    id={op._id}
                                                    type="checkbox"
                                                    name={field.dataName}
                                                    value={JSON.stringify(op)}
                                                    onChange={(e) =>
                                                        onSelect(e)
                                                    }
                                                    required={
                                                        oneChecked && required
                                                            ? false
                                                            : true
                                                    }
                                                    checked={formData[
                                                        field.dataName
                                                    ].some(
                                                        (el) =>
                                                            el._id.toString() ===
                                                            op._id.toString()
                                                    )}
                                                />
                                                <span className="checker me-2"></span>
                                                <p className="ml-3 mb-0">
                                                    {op.optionText}
                                                </p>
                                            </label>
                                        </div>
                                    </Fragment>
                                );
                            })}
                        </div>
                    </Fragment>
                ) : (
                    ""
                )}
            </div>
        </Fragment>
    );
};

export default Checkbox;
