import React, { useState, useRef, Fragment, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import LoadingButton from "../../utils/LoadingButton/LoadingButton";
import { bytesToSize } from "../../../utils/bytesToSize";
import {
	CustomizedDialogTitle,
	CustomizedDialogContent,
} from "../CustomizedModal/CustomizedModal";
import AttachmentPreviewIcon from "../../AttachmentPreviewIcon/AttachmentPreviewIcon";

function AttachFileModal({
	isOpen,
	handleClose,
	handleSubmit,
	isModal = true,
}) {
	const inputFileRef = useRef();

	const [getFile, setGetFile] = useState([]);
	const [uploadResposne, setUploadResponse] = useState(null);
	const [isUploading, setIsUploading] = useState(false);

	useEffect(() => {
		if (uploadResposne) {
			const updatedgetFile = [];
			uploadResposne.data?.forEach((item) => {
				updatedgetFile.push(getFile[item.index]);
				updatedgetFile[item.index].status = item.msg;
			});
			setGetFile(updatedgetFile);

			setIsUploading(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uploadResposne]);

	const handleChange = (e) => {
		const filesArray = Array.from(e.target.files);

		const uploadArray = [];
		filesArray.forEach((file) => {
			const uploadObject = {
				file,
				preview: URL.createObjectURL(file),
				status: "Ready to upload",
			};
			uploadArray.push(uploadObject);
		});

		setGetFile((prev) => [...prev, ...uploadArray]);

		return () => {
			getFile.forEach((file) => {
				URL.revokeObjectURL(file.preview);
			});
		};
	};

	const onOpenWindow = () => {
		inputFileRef.current.click();
	};

	return (
		<Dialog
			onClose={() => handleClose(false)}
			aria-labelledby="customized-dialog-title"
			open={isOpen}
			maxWidth="sm"
			fullWidth={true}
		>
			<CustomizedDialogTitle id="customized-dialog-title">
				<h3 className="modal-title text-accent">Attach Files</h3>
			</CustomizedDialogTitle>
			<CustomizedDialogContent>
				<input
					className="form-control mb-3"
					type="file"
					ref={inputFileRef}
					onChange={(e) => handleChange(e)}
					multiple
					hidden
				/>
				<div>
					<input
						type="button"
						className="btn btn-secondary mb-3 w-100"
						value="Click to Select Files"
						onClick={() => onOpenWindow()}
					></input>
				</div>
				{getFile.length !== 0 && (
					<form
						onSubmit={async (e) => {
							e.preventDefault();
							setIsUploading(true);
							setUploadResponse(await handleSubmit(getFile));
						}}
					>
						<table className="table">
							<thead>
								<tr>
									<th scope="col">Preview</th>
									<th scope="col">Name</th>
									<th scope="col">Size</th>
									<th scope="col">Status / Error</th>
								</tr>
							</thead>
							<tbody
								style={{
									color: "#fff",
								}}
							>
								{getFile.map((file, index) => {
									return (
										<Fragment key={index}>
											<tr>
												<td
													className="text-primary-color py-1 pl-6"
													style={{
														textAlign: "left",
													}}
												>
													<AttachmentPreviewIcon
														name={file.file.name}
														url={file.preview}
														width="50"
													/>
												</td>
												<td className="text-white py-1 pr-4">
													{file.file.name}
												</td>
												<td className="text-white py-1 pr-4">
													{bytesToSize(
														file.file.size
													)}
												</td>
												<td className="text-white py-1">
													{file.status}
												</td>
											</tr>
										</Fragment>
									);
								})}
							</tbody>
						</table>

						<LoadingButton text="Upload" isLoading={isUploading} />
					</form>
				)}
			</CustomizedDialogContent>
		</Dialog>
	);
}

export default AttachFileModal;
