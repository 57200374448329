import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import Navbar from "./../../components/LoggedOutNavbar/LoggedOutNavbar";
import AlertsContainer from "../../components/AlertsContainer/AlertsContainer";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import Footer from "../../components/Landing/Footer/Footer";
import TopLoader from "../../components/TopLoader/TopLoader";
import { Switch } from "react-router-dom";
import authRoutes from "../../routes/auth";
import { getRoutes } from "../../utils/getRoutes";

const Auth = (props) => {
    const errorState = useSelector((state) => state.error);

    return (
        <Fragment>
            <TopLoader />
            <AlertsContainer />
            <Navbar />
            {!errorState.isError ? props.component : <ErrorComponent />}

            {/* Routes */}
            <Switch>{getRoutes(authRoutes)}</Switch>

            <Footer />
        </Fragment>
    );
};

export default Auth;
