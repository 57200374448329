import React, { useState, Fragment, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../actions/alert";
import { deletePortfolio } from "../../../actions/profile";
import { ProfileContext } from "../../../contexts/ProfileContext";
import { getApiEndpoint } from "../../../utils/getApiEndpoint";
import ViewPortfolioItemModal from "../../Modals/ViewPortfolioItemModal/ViewPortfolioItemModal";
import EditFolio from "../EditFolio/EditFolio";
import styles from "./FolioList.module.css";
const FolioList = ({ portfolios }) => {
    const dispatch = useDispatch();

    const profileContext = useContext(ProfileContext);

    const authState = useSelector((state) => state.auth);

    const [folioview, setFolioView] = useState();
    const [edit, setEdit] = useState(false);
    const [folios] = useState(portfolios ? portfolios : []);
    const [showPortfolioDetailsModal, setShowPortfolioDetailsModal] =
        useState(false);
    const [selectPortfolio, setSelectedPortfolio] = useState(null);

    const createClick = (folio, change) => {
        setEdit(change);
        setFolioView(<EditFolio folio={folio} callBackCancel={createClick} />);
    };

    const onDeleteFolio = async (folioId) => {
        const response = await dispatch(deletePortfolio(folioId));

        if (response.status) {
            dispatch(setAlert(response.msg, "success"));
            profileContext.setProfile({
                ...profileContext.profile,
                portfolios: response.data.portfolios,
            });
        } else {
            dispatch(setAlert(response.msg, "danger"));
        }
    };

    return folios.length ? (
        <Fragment>
            <div className="row">
                {edit ? (
                    <>{folioview}</>
                ) : (
                    <>
                        {profileContext.profile.portfolios.map((folio) => {
                            return (
                                <div
                                    className="col-md-3 text-center"
                                    key={folio._id}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setSelectedPortfolio(folio);
                                        setShowPortfolioDetailsModal(true);
                                    }}
                                >
                                    <div className="position-relative">
                                        <img
                                            src={
                                                getApiEndpoint() +
                                                folio.files[0]
                                            }
                                            className="w-100 rounded"
                                            alt="Portfolio"
                                        ></img>

                                        {profileContext.profile.user._id.toString() ===
                                            authState.user._id.toString() && (
                                            <>
                                                <button
                                                    className={`${styles["heading-toggle"]}`}
                                                    style={{ right: "25px" }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        onDeleteFolio(
                                                            folio,
                                                            true
                                                        );
                                                    }}
                                                >
                                                    <span className="material-icons">
                                                        delete
                                                    </span>
                                                </button>
                                                <button
                                                    className={`${styles["heading-toggle"]}`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        createClick(
                                                            folio,
                                                            true
                                                        );
                                                    }}
                                                >
                                                    <span className="material-icons">
                                                        create
                                                    </span>
                                                </button>
                                            </>
                                        )}
                                    </div>
                                    <div className="d-block my-2 fw-bold">
                                        {folio.title}
                                    </div>
                                </div>
                            );
                        })}
                        {selectPortfolio && (
                            <ViewPortfolioItemModal
                                isOpen={showPortfolioDetailsModal}
                                onClose={() =>
                                    setShowPortfolioDetailsModal(false)
                                }
                                portfolio={selectPortfolio}
                            />
                        )}
                    </>
                )}
            </div>
        </Fragment>
    ) : (
        <Fragment>
            <div className="text-center text-muted">No Portfolios Added</div>
        </Fragment>
    );
};
export default FolioList;
