import io from "socket.io-client";
import {
    newMessage,
    chatRoomEvents,
    newNotification,
    roomJoined,
    socketAlert,
    newPortalNotification,
} from "../utils/clientEvents";
import { getApiEndpoint } from "../utils/getApiEndpoint";

import { SOCKET_CONNECTION, SOCKET_ERROR } from "./types";

// Open main socket connection
export const openSocketConnection = (token) => async (dispatch) => {
    try {
        const socket = io.connect(`${getApiEndpoint()}`, {
            query: { token: token },
        });

        dispatch({
            type: SOCKET_CONNECTION,
            payload: socket,
        });

        roomJoined(socket);
        chatRoomEvents(socket);
        newMessage(socket);
        newNotification(socket);
        socketAlert(socket);
        newPortalNotification(socket);
    } catch (err) {
        dispatch({
            type: SOCKET_ERROR,
        });
    }
};
