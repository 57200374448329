import React from "react";
import { useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { sendMilestonePaymentRequest } from "../../../actions/milestone";
import { setAlert } from "../../../actions/alert";

// import { Tooltip } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
    button: {
        color: "#c5c5c5",
        textTransform: "Capitalize",
        fontSize: "12px",
        "&:hover": {
            background: "#0c0c0c",
            cursor: "pointer",
        },
    },
    dialog: {
        // "overflow-y": "none",
    },
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        background: "#0c0c0c",
        color: "#c5c5c5",
        borderBottom: "1px solid #000000",
        borderTop: "1px solig #000000",
    },
    actions: {
        borderTop: "1px solid #000000",
    },
    // typography: {
    // 	paddingRight: theme.spacing(7),
    // },
    content: {
        borderTop: "1px solid #000000",
        paddingBottom: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            {children}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        background: "#0c0c0c",
        color: "#c5c5c5",
    },
}))(MuiDialogContent);

function CustomizedDialogs({ auth, milestone, socket }) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const toggleDialog = () => {
        if (open) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    };

    const onSendRequest = async (milestoneId, projectId, hiredUserId) => {
        const requestBody = {
            milestoneId,
            projectId,
            hiredUserId,
        };
        const response = await dispatch(
            sendMilestonePaymentRequest(requestBody)
        );

        if (response.status) {
            dispatch(setAlert(response.msg, "success"));
        } else {
            dispatch(setAlert(response.msg, "danger"));
        }

        toggleDialog();
    };

    return (
        <div className="d-inline">
            <Tooltip title="Request Payment">
                <span
                    className="material-icons ml-3 pointer"
                    onClick={toggleDialog}
                >
                    request_page
                </span>
            </Tooltip>
            <Dialog
                onClose={toggleDialog}
                aria-labelledby="customized-dialog-title"
                open={open}
                className={classes.dialog}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={toggleDialog}
                >
                    <h3 className="modal-title text-accent text-center">
                        Request for Payment
                    </h3>
                </DialogTitle>
                <DialogContent>
                    <div className="text-center">
                        <p>
                            Requesting payment will request to the project owner
                            to release the request after checking if Milestone
                            is completed. Do you want to send request ?
                        </p>
                    </div>
                    <div className="modal-actions d-flex justify-content-center mt-3">
                        <button
                            className="btn btn-primary-gradient flex-grow-1 me-3"
                            onClick={() =>
                                onSendRequest(
                                    milestone._id,
                                    milestone.project,
                                    milestone.hiredUser
                                )
                            }
                        >
                            Continue...
                        </button>
                        <button
                            type="submit"
                            onClick={() => toggleDialog()}
                            className="btn btn-primary-gradient outline flex-grow-1"
                        >
                            Cancel
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default CustomizedDialogs;
