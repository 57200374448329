import React, { Fragment } from "react";
import { Link } from "react-router-dom";
// import Fade from 'react-reveal/Fade';

import styles from "./Hero.module.css";

const Hero = () => {
    return (
        <Fragment>
            <section
                id={styles["hero-section"]}
                style={{ backgroundImage: "url('/img/landing/img1.png')" }}
            >
                <div className={styles["overlay"]}></div>
                <div className="container">
                    <div
                        className={`${styles["hero-title"]} position-relative py-3`}
                    >
                        {/* <div className={styles['hero-title-border']}></div> */}
                        <>
                            <h1>
                                Outsource the full process
                                <br />
                                of game development
                            </h1>
                        </>
                        <br />
                        <p className="position-relative">
                            Configure your game and hire a project coordinator
                            <br />
                            who will help you with planning and hiring of the
                            freelancers
                        </p>
                    </div>
                    <div className="actions py-4">
                        <Link to="/game-forms">
                            <button className={`${styles.pushable} me-3 mb-3`}>
                                <span className={styles.shadow}></span>
                                <span className={styles.edge}></span>
                                <span className={styles.front}>
                                    Configurate Experience
                                </span>
                            </button>
                            {/* <button className="btn btn-primary-gradient px-4 mr-3">
								Configure Game
							</button> */}
                        </Link>
                        <Link to="/register">
                            <button
                                className={`${styles.pushable} ${styles.transparent}`}
                            >
                                <span className={styles.shadow}></span>
                                <span className={styles.edge}></span>
                                <span className={styles.front}>
                                    Earn Money As Freelancer
                                </span>
                            </button>
                            {/* <button className="btn btn-second px-4">
								Earn Money As Freelancer
							</button> */}
                        </Link>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default Hero;
