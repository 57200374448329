import { setAlert } from "./alert";
import { requestWithToken } from "../utils/httpRequest";
import { getApiEndpoint } from "../utils/getApiEndpoint";

// Get All Active Addons
export const getActiveAddons = () => async (dispatch) => {
  try {
    const res = await requestWithToken(
      "GET",
      `${getApiEndpoint()}/addons/active`
    );

    if (res) {
      return res.data;
    }
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      dispatch(setAlert(errors[0].msg, "danger"));
    }
  }
};
