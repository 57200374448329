import React, { useRef, useState } from "react";
import { Dialog } from "@material-ui/core";
import {
	CustomizedDialogContent,
	CustomizedDialogTitle,
} from "../CustomizedModal/CustomizedModal";
import LoadingButton from "../../utils/LoadingButton/LoadingButton";
import { useFormik } from "formik";
import { deliverMilestone } from "../../../actions/milestone";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../actions/alert";

const DeliverMilestoneModal = ({ isOpen, onClose, milestoneId }) => {
	const dispatch = useDispatch();

	const attachFileRef = useRef();

	const [loading, setLoading] = useState(false);
	const [attachedFiles, setAttachedFiles] = useState([]);

	const deliverMilestoneFormik = useFormik({
		initialValues: {
			title: "",
			description: "",
			url: "",
		},
		onSubmit: async (values) => {
			setLoading(true);

			const formData = new FormData();
			formData.append("title", values.title);
			formData.append("description", values.description);
			formData.append("url", values.url);
			Array.from(attachedFiles).forEach((attachedfile) => {
				formData.append("attachments", attachedfile);
			});

			const response = await dispatch(
				deliverMilestone(milestoneId, formData)
			);
			if (response.status) {
				dispatch(setAlert(response.msg, "success"));
				onClose();
			} else {
				dispatch(setAlert(response.msg, "danger"));
			}
			setLoading(false);
		},
	});

	const onFileAttached = (e) => {
		setAttachedFiles(e.target.files);
	};

	return (
		<Dialog
			onClose={() => onClose(false)}
			aria-labelledby="customized-dialog-title"
			open={isOpen}
			maxWidth="sm"
			fullWidth={true}
		>
			<CustomizedDialogTitle id="customized-dialog-title">
				<h3 className="modal-title text-accent">Deliver Milestone</h3>
			</CustomizedDialogTitle>
			<CustomizedDialogContent>
				<form onSubmit={deliverMilestoneFormik.handleSubmit}>
					<div className="mb-2">
						<label className="form-label">
							Delivery Title{" "}
							<small className="text-danger">*</small>
						</label>
						<input
							name="title"
							value={deliverMilestoneFormik.values.title}
							onChange={deliverMilestoneFormik.handleChange}
							className="dark-input"
							placeholder="Delivery Title"
						/>
					</div>
					<div className="mb-2">
						<label className="form-label">
							Delivery Description{" "}
							<small className="text-danger">*</small>
						</label>
						<textarea
							name="description"
							value={deliverMilestoneFormik.values.description}
							onChange={deliverMilestoneFormik.handleChange}
							className="dark-input"
							placeholder="Delivery Description"
							rows="3"
							required
						></textarea>
					</div>
					<div className="row mb-3">
						<div className="col-md-6">
							<label className="form-label">
								Attach Work URL
							</label>
							<input
								name="url"
								value={deliverMilestoneFormik.values.url}
								onChange={deliverMilestoneFormik.handleChange}
								className="dark-input"
								placeholder="Work URL"
							/>
						</div>
						<div className="col-md-6">
							<label className="form-label">
								Attach Work Files
							</label>
							<div>
								<a
									onClick={() => {
										attachFileRef.current.click();
									}}
									className="btn btn-secondary w-100"
								>
									Attach Files{" "}
									{attachedFiles.length > 0 && (
										<>({attachedFiles.length})</>
									)}
								</a>
							</div>
							<input
								type="file"
								ref={attachFileRef}
								hidden
								className="form-control"
								name="file"
								onChange={onFileAttached}
								multiple
							/>
						</div>
					</div>
					<div>
						<LoadingButton isLoading={loading} text="Send" />
					</div>
				</form>
			</CustomizedDialogContent>
		</Dialog>
	);
};

export default DeliverMilestoneModal;
