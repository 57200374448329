import React, { Fragment, useLayoutEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./EllipsisParagraph.module.css";

const EllipsisParagraph = ({ children, className }) => {
    const contentContainerRef = useRef();
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [fullHeight, setFullHeight] = useState(false);

    useLayoutEffect(() => {
        if (
            contentContainerRef.current.clientHeight <
            contentContainerRef.current.scrollHeight
        ) {
            setIsOverflowing(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, contentContainerRef);

    return (
        <Fragment>
            <div className={`${styles["wrapper"]}`}>
                <div
                    ref={contentContainerRef}
                    className={`${className} ${styles["container"]} ${
                        fullHeight && styles["full-height"]
                    }`}
                >
                    {children}
                </div>
                {isOverflowing && (
                    <Fragment>
                        {!fullHeight ? (
                            <button
                                className={`btn ${styles["more-btn"]}`}
                                onClick={() => setFullHeight(true)}
                            >
                                ...show more
                            </button>
                        ) : (
                            <button
                                className={`btn ${styles["less-btn"]}`}
                                onClick={() => setFullHeight(false)}
                            >
                                ...show less
                            </button>
                        )}
                    </Fragment>
                )}
            </div>
        </Fragment>
    );
};

EllipsisParagraph.propTypes = {
    children: PropTypes.node.isRequired,
};

EllipsisParagraph.defaultProps = {
    className: "",
};

export default EllipsisParagraph;
