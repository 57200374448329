import { error } from "./error";
import { HIDE_TOPLOADER, SHOW_TOPLOADER } from "./types";

export const toggleTopLoader = (show) => (dispatch) => {
    try {
        if (show) {
            dispatch({
                type: SHOW_TOPLOADER,
            });
        } else {
            dispatch({
                type: HIDE_TOPLOADER,
            });
        }
    } catch (err) {
        error();
    }
};
