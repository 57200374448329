import { getApiEndpoint } from "../utils/getApiEndpoint";
import { requestWithToken } from "../utils/httpRequest";

import { error } from "./error";

// Get all skills from DB
export const getActiveSkills = () => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/skills`
        );
        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// // Save skill in DB
// export const saveSkill = (formData) => async (dispatch) => {
//   try {
//     const config = {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     };

//     const body = JSON.stringify({ name: formData.name });

//     const res = await axios.post(
//       `${process.env.REACT_APP_DEV_API_URL}/skills`,
//       body,
//       config
//     );
//     if (res) {
//       dispatch(setAlert("Skill Added Successfully!", "success"));
//     }
//   } catch (err) {
//     const errors = err.response.data.errors;

//     if (errors) {
//       errors.forEach((error) => setAlert(error.msg, "danger"));
//     }
//   }
// };
