import { getApiEndpoint } from "../utils/getApiEndpoint";
import { requestWithToken } from "../utils/httpRequest";
import { error } from "./error";

// Fetch pending clearance balance for user
export const fetchPendingClearanceBalance = () => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/pending-clearance/total`
        );

        return new Promise((resolve, reject) => resolve(response.data));
    } catch (err) {
        dispatch(error(err));
        return new Promise((resolve, reject) => reject(err.response.data));
    }
};
