import React, { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjectBids } from "../../actions/bid";
import { getPortalSettingByKey } from "../../actions/portalSetting";
import { ProjectControlCenterContext } from "../../contexts/ProjectControlCenterContext";
import {
    newBidReceived,
    removeSocketEventListener,
} from "../../utils/clientEvents";
import BidListItem from "../BidListItem/BidListItem";
import styles from "./ProjectControlCenter.module.css";

const BidsTab = ({ projectId, projectOwnerId }) => {
    const dispatch = useDispatch();

    const projectControlCenterContext = useContext(ProjectControlCenterContext);
    const authState = useSelector((state) => state.auth);
    const socketState = useSelector((state) => state.socket);

    const [loading, setLoading] = useState(true);
    const [customerFees, setCustomerFees] = useState(0);
    const [bids, setBids] = useState([]);
    const [newBids, setNewBids] = useState(false);

    useEffect(() => {
        const fetchCustomerFees = async () => {
            const portalSetting = await dispatch(
                getPortalSettingByKey("customer_fees")
            );
            setCustomerFees(parseInt(portalSetting.data.data.value));
        };

        if (
            projectControlCenterContext.project?.user?._id ===
            authState.user._id
        ) {
            fetchCustomerFees();
            fetchBids();
        } else {
            projectControlCenterContext.onChangeTab(0);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);

    useEffect(() => {
        // Realtime page events
        if (socketState.socket) {
            newBidReceived(socketState.socket, (data) => {
                setNewBids(true);
            });

            return () => {
                removeSocketEventListener(socketState.socket, "new-bid-posted");
            };
        }
    }, [socketState.socket]);

    const fetchBids = async () => {
        setNewBids(false);
        const response = await dispatch(fetchProjectBids(projectId));
        if (response.status) {
            setBids(response.data);
        }

        setLoading(false);
    };

    return (
        <Fragment>
            <h4 className="flex-grow-1 m-0">Bids</h4>
            <hr></hr>
            {newBids && (
                <p className="alert alert-danger text-center">
                    New bids found...{" "}
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                        className="text-decoration-underline"
                        onClick={fetchBids}
                    >
                        Refresh Now
                    </a>
                </p>
            )}
            {!loading ? (
                <Fragment>
                    {bids.length ? (
                        <ul className={`${styles["bid-list"]} p-0`}>
                            {bids.map((bid, index) => {
                                return (
                                    <BidListItem
                                        key={index}
                                        bid={bid}
                                        projectOwnerId={projectOwnerId}
                                        customerFees={customerFees}
                                    />
                                );
                            })}
                        </ul>
                    ) : (
                        <h5 className="text-center text-muted mt-5">
                            No Bids Found!
                        </h5>
                    )}
                </Fragment>
            ) : (
                <h5 className="text-center text-muted mt-5">Loading Bids...</h5>
            )}
        </Fragment>
    );
};

export default BidsTab;
