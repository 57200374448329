import React, { Fragment } from "react";
// import Bounce from 'react-reveal/Bounce';

import styles from "./Special.module.css";

const Special = () => {
    return (
        <Fragment>
            <section
                id={styles["makes-us-special-section"]}
                className="position-relative py-5 px-3"
            >
                <div className={styles["background-img"]}></div>
                <div className="container">
                    <h2 className="section-title">What Makes Us Special</h2>
                    <hr />
                    <div className="row w-100 mt-5">
                        <div className="col-md-4 mb-5">
                            <div className={`${styles["special-item"]}`}>
                                <div className={styles["overlay"]}></div>
                                <img
                                    alt=""
                                    src="/img/landing/game_configurator.png"
                                    className={styles["icon"]}
                                />
                                <img
                                    alt=""
                                    src="/img/landing/game_configurator.png"
                                    className={styles["bg_icon"]}
                                />
                                <div className={styles["content"]}>
                                    <h5 className={styles.title}>
                                        Game Configurator
                                    </h5>
                                    <hr
                                        className="m-0"
                                        style={{
                                            background: "white",
                                        }}
                                    ></hr>
                                    <p className={styles["description"]}>
                                        Use the Gameconfigurator to easily build
                                        your game in our unique web customizer.
                                        Once your done you can post the projects
                                        and our developers will apply for the
                                        postet project.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-5">
                            <div className={styles["special-item"]}>
                                <div className={styles["overlay"]}></div>
                                <img
                                    alt=""
                                    src="/img/landing/freelancer.png"
                                    className={styles["icon"]}
                                />
                                <img
                                    alt=""
                                    src="/img/landing/freelancer.png"
                                    className={styles["bg_icon"]}
                                />
                                <div className={styles["content"]}>
                                    <h5 className={styles.title}>
                                        Only Selected Freelancer
                                    </h5>
                                    <hr
                                        className="m-0"
                                        style={{
                                            background: "white",
                                        }}
                                    ></hr>
                                    <p className={styles["description"]}>
                                        On many Pages Freelancers can just Sign
                                        up here you need to get verifyed before
                                        you can bid to projects this makes sure
                                        that you can Build up your personal team
                                        of Professionals.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-5">
                            <div className={styles["special-item"]}>
                                <div className={styles["overlay"]}></div>
                                <img
                                    alt=""
                                    src="/img/landing/settings.png"
                                    className={styles["icon"]}
                                />
                                <img
                                    alt=""
                                    src="/img/landing/settings.png"
                                    className={styles["bg_icon"]}
                                />
                                <div className={styles["content"]}>
                                    <h5 className={styles.title}>
                                        CRM and other Tools
                                    </h5>
                                    <hr
                                        className="m-0"
                                        style={{
                                            background: "white",
                                        }}
                                    ></hr>
                                    <p className={styles["description"]}>
                                        We provide our developers free CRM tools
                                        and more that they can keep track about
                                        their projects. Because of that the
                                        developers can work more focussed and
                                        are able to provide a better Service for
                                        our customers as well
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row w-100 mt-5">
                        <div className="col-md-4 mb-5">
                            <div className={styles["special-item"]}>
                                <div className={styles["overlay"]}></div>
                                <img
                                    alt=""
                                    src="/img/landing/coordinator.png"
                                    className={styles["icon"]}
                                />
                                <img
                                    alt=""
                                    src="/img/landing/coordinator.png"
                                    className={styles["bg_icon"]}
                                />
                                <div className={styles["content"]}>
                                    <h5 className={styles.title}>
                                        Project Coordinator
                                    </h5>
                                    <hr
                                        className="m-0"
                                        style={{
                                            background: "white",
                                        }}
                                    ></hr>
                                    <p className={styles["description"]}>
                                        If you are new in the Game Indistry or
                                        you plan to build up a huge team for a
                                        bigger project we recomment to hire a
                                        project coordinator who will be your
                                        mentor and partner who will select the
                                        best possible freelancers for your
                                        project.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-5">
                            <div className={styles["special-item"]}>
                                <div className={styles["overlay"]}></div>
                                <img
                                    alt=""
                                    src="/img/landing/phone-call.png"
                                    className={styles["icon"]}
                                />
                                <img
                                    alt=""
                                    src="/img/landing/phone-call.png"
                                    className={styles["bg_icon"]}
                                />
                                <div className={styles["content"]}>
                                    <h5 className={styles.title}>
                                        Voice and Video Chat
                                    </h5>
                                    <hr
                                        className="m-0"
                                        style={{
                                            background: "white",
                                        }}
                                    ></hr>
                                    <p className={styles["description"]}>
                                        Big Projects like games cant get planed
                                        by chat so we are offering free voice
                                        and video chat to plan your projects and
                                        get work done much faster with better
                                        quality.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-5">
                            <div className={styles["special-item"]}>
                                <div className={styles["overlay"]}></div>
                                <img
                                    alt=""
                                    src="/img/landing/online-course.png"
                                    className={styles["icon"]}
                                />
                                <img
                                    alt=""
                                    src="/img/landing/online-course.png"
                                    className={styles["bg_icon"]}
                                />
                                <div className={styles["content"]}>
                                    <h5 className={styles.title}>
                                        Courses About Game Development
                                    </h5>
                                    <hr
                                        className="m-0"
                                        style={{
                                            background: "white",
                                        }}
                                    ></hr>
                                    <p className={styles["description"]}>
                                        Soon we will offer Video courses for our
                                        developers and customers to learn more
                                        about game psychologie and project
                                        management like that you can improof
                                        your marketing, hook players to your
                                        game and earn money with your games even
                                        in the development process.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default Special;
