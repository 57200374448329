import React, { Fragment, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useTour } from "@reactour/tour";
import {
	getPlanDetails,
	getActiveProjects,
	getUpcomingMilestones,
	getDashboardStats,
} from "../../actions/dashboard";
import { reacttourUpdate } from "../../actions/reacttour";
import { toggleTopLoader } from "../../actions/topLoader";
import Skeleton from "../../components/Skeleton/Skeleton";
import { seo } from "../../utils/seo";
import styles from "./dashboard.module.css";
import { getSteps } from "../../utils/tourSteps";
import { postStripe } from "../../actions/transaction";
import WelcomeModal from "../../components/Modals/WelcomeModal/WelcomeModal";
import moment from "moment";

const Dashboard = () => {
	const dispatch = useDispatch();

	const isMounted = useRef(true);

	const tourState = useSelector((state) => state.reacttour);
	const authState = useSelector((state) => state.auth);

	const { setSteps } = useTour();

	const [depositAmt, setDepositAmt] = useState(100);
	const [planDetails, setPlanDetails] = useState(null);
	const [activeProjects, setActiveProjects] = useState(null);
	const [activeMilestones, setActiveMilestones] = useState(null);
	const [headerdata, setHeaderData] = useState(null);

	useEffect(() => {
		seo({ title: "Dashboad" });

		const loadInit = async () => {
			dispatch(toggleTopLoader(true));
			dispatch(getPlanDetails()).then((response) => {
				if (isMounted.current) setPlanDetails(response);
			});
			dispatch(getActiveProjects("dashboard")).then((response) => {
				if (isMounted.current) setActiveProjects(response);
			});
			dispatch(getUpcomingMilestones()).then((response) => {
				if (isMounted.current) setActiveMilestones(response);
			});
			dispatch(getDashboardStats()).then((response) => {
				if (isMounted.current) setHeaderData(response.data);
			});
		};

		loadInit();

		return () => {
			isMounted.current = false;
			dispatch(toggleTopLoader(false));
		};
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (planDetails && activeProjects && activeMilestones && headerdata) {
			dispatch(toggleTopLoader(false));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [planDetails, activeProjects, activeMilestones, headerdata]);

	useEffect(() => {
		dispatch(reacttourUpdate(getSteps("dashboard")));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setSteps(tourState.steps);
		// if (authState?.user) {
		//     setIsOpen(authState.user.tutorial);
		// }

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tourState.steps, authState]);

	const onChangeAmt = (e) => {
		setDepositAmt(e.target.value);
	};

	const onstripeClick = async (e) => {
		e.preventDefault();
		const response = await dispatch(
			postStripe(depositAmt, "transactions", "rechargeWallet")
		);
		if (response.status) {
			window.location.replace(response.data.url);
		}
	};

	return (
		<Fragment>
			<WelcomeModal />
			<main className="container">
				<div className="row">
					<div className="col-md-9">
						<div className="row">
							<span data-tour="step-dashboard-1">
								<div className={`d-flex gap-3 my-3 flex-wrap`}>
									<div
										className={`${styles["highlight-container"]} flex-grow-1 `}
									>
										<div className="text-center">
											<h2>
												{headerdata != null ? (
													<>
														${" "}
														{
															headerdata.wallet
																.amount
														}
													</>
												) : (
													<Skeleton />
												)}
											</h2>
											<p
												className={`${styles["red-text"]} mb-0`}
											>
												Wallet Ballance
											</p>
										</div>
									</div>
									<div
										className={`${styles["highlight-container"]} flex-grow-1 `}
									>
										<div className="text-center">
											<h2>
												{headerdata != null ? (
													<>
														${" "}
														{
															headerdata.earningThisMonth
														}
													</>
												) : (
													<Skeleton />
												)}
											</h2>
											<p
												className={`${styles["red-text"]} mb-0`}
											>
												Earning This Month
											</p>
										</div>
									</div>
									<div
										className={`${styles["highlight-container"]} flex-grow-1 `}
									>
										<div className="text-center">
											<h2>
												{headerdata != null ? (
													<>
														${" "}
														{
															headerdata.pendingClearence
														}
													</>
												) : (
													<Skeleton />
												)}
											</h2>
											<p
												className={`${styles["red-text"]} mb-0`}
											>
												Pending Clearance
											</p>
										</div>
									</div>
									<div
										className={`${styles["highlight-container"]} flex-grow-1 `}
									>
										<div className="text-center">
											<Link to={"/bids-submitted"}>
												<h2>
													{headerdata != null ? (
														headerdata.totalBids
													) : (
														<Skeleton />
													)}
												</h2>
												<p
													className={`${styles["red-text"]} mb-0`}
												>
													Bids Submitted
												</p>
											</Link>
										</div>
									</div>
								</div>
							</span>
							<div>
								<h5 className="my-3">Active Projects</h5>
								<span data-tour="step-dashboard-2">
									<table
										className={`table ${styles["table-dark"]}`}
									>
										<thead>
											<tr>
												<th
													className={
														styles["red-text"]
													}
													scope="col"
												>
													Title
												</th>
												<th
													className={
														styles["red-text"]
													}
													scope="col"
												>
													No. of Milestones
												</th>
												<th
													className={
														styles["red-text"]
													}
													scope="col"
												>
													Actions
												</th>
											</tr>
										</thead>

										<tbody>
											{activeProjects == null ? (
												[1, 2].map((el, index) => (
													<tr key={index}>
														<td>
															<Skeleton></Skeleton>
														</td>
														<td>
															<Skeleton></Skeleton>
														</td>
														<td>
															<Skeleton></Skeleton>
														</td>
														<td>
															<Skeleton></Skeleton>
														</td>
													</tr>
												))
											) : activeProjects.data &&
											  activeProjects.data.length ? (
												activeProjects.data.map(
													(project, i) => {
														let completedprojects = 0;
														let totalprojects = 0;
														return (
															<tr key={i}>
																<td>
																	{
																		project
																			.project
																			.title
																	}
																</td>
																<td>
																	{project.milestones !=
																		null &&
																		project.milestones.map(
																			(
																				milestone
																			) => {
																				if (
																					milestone.status ===
																					"released"
																				) {
																					completedprojects++;
																				}
																				totalprojects++;
																				return null;
																			}
																		)}
																	{
																		completedprojects
																	}
																	/
																	{
																		totalprojects
																	}
																</td>
																<td>
																	<Link
																		to={`/project/${project.project._id}`}
																	>
																		{
																			<div className="d-flex justify-content-center">
																				<span className="material-icons">
																					visibility
																				</span>
																			</div>
																		}
																	</Link>
																</td>
															</tr>
														);
													}
												)
											) : (
												<tr>
													<td colSpan="4">
														No Active Projects
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</span>
							</div>

							<div>
								<h5 className="my-3">Upcoming Milestones</h5>
								<table
									className={`table ${styles["table-dark"]}`}
								>
									<thead>
										<tr>
											<th
												className={styles["red-text"]}
												scope="col"
											>
												Milestone Title
											</th>
											<th
												className={styles["red-text"]}
												scope="col"
											>
												Milestone Amount
											</th>
											<th
												className={styles["red-text"]}
												scope="col"
											>
												Actions
											</th>
										</tr>
									</thead>
									<tbody>
										{activeMilestones == null ? (
											[1, 2].map((el, index) => (
												<tr key={index}>
													<td>
														<Skeleton></Skeleton>
													</td>
													<td>
														<Skeleton></Skeleton>
													</td>
													<td>
														<Skeleton></Skeleton>
													</td>
													<td>
														<Skeleton></Skeleton>
													</td>
												</tr>
											))
										) : activeMilestones.data &&
										  activeMilestones.data.length ? (
											activeMilestones.data.map(
												(pendingmilestones, index) => {
													return (
														<tr key={index}>
															<td>
																{
																	pendingmilestones.title
																}
															</td>
															<td>
																${" "}
																{
																	pendingmilestones
																		.price
																		.amount
																}
															</td>
															<td>
																<Link
																	to={`/project/${pendingmilestones.project}`}
																>
																	<div className="d-flex justify-content-center">
																		<span className="material-icons">
																			visibility
																		</span>
																	</div>
																</Link>
															</td>
														</tr>
													);
												}
											)
										) : (
											<tr>
												<td colSpan="4">
													No Upcoming Milestones
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div className="col-md-3  m-0">
						<div>
							<span data-tour="step-dashboard-3">
								<h5 className="my-3">Active Plan</h5>

								<div className={styles["active-plan-box"]}>
									<div
										className={
											styles["active-plan-element"]
										}
									>
										<span>Plan Name</span>
										{planDetails &&
											planDetails.data &&
											planDetails.data.plan && (
												<Fragment>
													<span>
														{
															planDetails.data
																.plan.name
														}
													</span>
												</Fragment>
											)}
									</div>
									<div
										className={
											styles["active-plan-element"]
										}
									>
										<span>Expiring On</span>
										{planDetails &&
										planDetails.data &&
										planDetails.data.startDate &&
										planDetails.data.endDate ? (
											<span>
												{moment(
													planDetails.data.endDate
												).format("DD-MMM-YYYY")}
											</span>
										) : (
											<span>Never</span>
										)}
									</div>
									<div>
										<div
											className={
												styles["active-plan-element"]
											}
										>
											<span>Active Bids</span>
											{planDetails &&
											planDetails.data &&
											planDetails.data.plan ? (
												<Fragment>
													<span>
														{
															planDetails.data
																.bidCount
														}
														/
														{
															planDetails.data
																.plan.bidsCount
														}
													</span>
												</Fragment>
											) : (
												""
											)}
										</div>

										{/* <div className="progress">
											<div
												className={
													styles["progress-bar"]
												}
												role="progressbar"
												style={
													planDetails &&
													planDetails.data &&
													planDetails.data.plan
														? {
																width: `${
																	(planDetails
																		.data
																		.bidCount /
																		planDetails
																			.data
																			.plan
																			.bidsCount) *
																	100
																}%`,
														  }
														: {}
												}
												aria-valuenow="25"
												aria-valuemin="0"
												aria-valuemax="100"
											></div>
										</div> */}
									</div>
									<div>
										<div
											className={
												styles["active-plan-element"]
											}
										>
											<span>Available Projects</span>
											{planDetails &&
											planDetails.data &&
											planDetails.data.plan ? (
												<Fragment>
													<span>
														{
															planDetails.data
																.projectCount
														}
														/
														{
															planDetails.data
																.plan
																.projectsCount
														}
													</span>
												</Fragment>
											) : (
												""
											)}
										</div>
										{/* <div className="progress">
											<div
												className={
													styles["progress-bar"]
												}
												role="progressbar"
												style={
													planDetails &&
													planDetails.data &&
													planDetails.data.plan
														? {
																width: `${
																	(planDetails
																		.data
																		.projectCount /
																		planDetails
																			.data
																			.plan
																			.projectsCount) *
																	100
																}%`,
														  }
														: {}
												}
												aria-valuenow="40"
												aria-valuemin="0"
												aria-valuemax="100"
											></div>
										</div> */}
									</div>
									<div>
										<Link
											to="/upgrade-plan"
											className={styles["Upgrade-plan"]}
										>
											Upgrade Plan
										</Link>
									</div>
								</div>
							</span>
						</div>
						<div>
							{/* <Projects projects={projects} /> */}
							<span data-tour="step-dashboard-4">
								<h5 className="my-3">Deposit Money</h5>

								<div className={styles["active-plan-box"]}>
									<div>
										<form>
											<div className="form-group">
												<input
													type="number"
													className="dark-input"
													placeholder="Enter Amount"
													value={depositAmt}
													onChange={(e) =>
														onChangeAmt(e)
													}
												/>
											</div>
											<button
												type="submit"
												className={
													styles["Deposit-money"]
												}
												onClick={(e) =>
													onstripeClick(e)
												}
											>
												Pay With Stripe
											</button>
										</form>
									</div>
								</div>
							</span>
						</div>
					</div>
				</div>
			</main>
		</Fragment>
	);
};

export default Dashboard;
