import { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import {
	getMilestoneById,
	fetchMilestoneTasksByMilestoneId,
	fetchMilestoneCommentsByMilestoneId,
	saveMilestoneComment,
	saveMilestoneTask,
	updateMilestoneTaskStatus,
	deleteMilestoneTask,
} from "../../../actions/milestone";
import WYSIWYGEditor from "../../WYSIWYGEditor/WYSIWYGEditor";
import LoadingButton from "../../utils/LoadingButton/LoadingButton";
import styles from "./MilestoneDetails.module.css";
import { setAlert } from "../../../actions/alert";
import { Tab, Tabs } from "@material-ui/core";
import { fetchProjectActivitiesByType } from "../../../actions/project";
import { ProjectControlCenterContext } from "../../../contexts/ProjectControlCenterContext";
import { convertToRaw } from "draft-js";
import draftjsToHtml from "draftjs-to-html";
import MilestoneDeliveryListItem from "../MilestoneDeliveryListItem/MilestoneDeliveryListItem";
import MilestoneDetailsActionSection from "./DetailsActionSection/DetailsActionSection";
import { formatTime } from "../../../utils/formatTime";
import {
	milestoneUpdated,
	removeSocketEventListener,
} from "../../../utils/clientEvents";

const MilestoneDetails = ({ milestoneId }) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const projectControlCenterContext = useContext(ProjectControlCenterContext);

	const authState = useSelector((state) => state.auth);
	const socketState = useSelector((state) => state.socket);

	const [isEditable, setIsEditable] = useState(false);
	const [selectedTab, setSelectedTab] = useState("0");
	const [milestoneDetails, setMilestoneDetails] = useState(null);
	const [milestoneTasks, setMilestoneTasks] = useState([]);
	const [milestoneComments, setMilestoneComments] = useState([]);
	const [milestoneActivities, setMilestoneActivities] = useState([]);
	const [savingMilestone, setSavingMilestone] = useState(false);
	const [milestoneUpdates, setMilestoneUpdates] = useState(false);

	const [previousMilestoneId, setPreviousMilestoneId] = useState(null);
	const [nextMilestoneId, setNextMilestoneId] = useState(null);

	const taskFormik = useFormik({
		initialValues: {
			title: "",
			priority: "low",
		},
		onSubmit: async (values) => {
			const response = await dispatch(
				saveMilestoneTask(milestoneId, values)
			);
			if (response.status) {
				dispatch(setAlert(response.msg, "success"));
				milestoneTasks.push(response.data);
				taskFormik.resetForm();
			} else {
				dispatch(setAlert(response.msg, "danger"));
			}
		},
	});

	const commentFormik = useFormik({
		initialValues: {
			comment: "",
		},
		onSubmit: async (values) => {
			setSavingMilestone(true);
			const response = await dispatch(
				saveMilestoneComment(milestoneId, {
					comment: draftjsToHtml(
						convertToRaw(values.comment.getCurrentContent())
					),
				})
			);
			if (response.status) {
				dispatch(setAlert(response.msg, "success"));
				milestoneComments.push(response.data);
				commentFormik.resetForm();
			} else {
				dispatch(setAlert(response.msg, "danger"));
			}

			setSavingMilestone(false);
		},
	});

	useEffect(() => {
		// Realtime page events
		if (socketState.socket) {
			milestoneUpdated(socketState.socket, (data) => {
				setMilestoneUpdates(true);
			});

			return () => {
				removeSocketEventListener(
					socketState.socket,
					"milestone-updated"
				);
			};
		}
	}, [socketState.socket]);

	useEffect(() => {
		fetchMilestoneDetails();
		fetchMilestoneTasks();
		fetchMilestoneComments();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [milestoneId]);

	useEffect(() => {
		setIsEditable(
			(projectControlCenterContext.project.user._id ===
				authState.user._id ||
				milestoneDetails?.hiredUser?._id === authState.user._id) &&
				(milestoneDetails?.status === "active" ||
					milestoneDetails?.status === "in-progress")
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectControlCenterContext.project, milestoneDetails]);

	useEffect(() => {
		if (
			projectControlCenterContext.milestones.length > 0 &&
			projectControlCenterContext.selectedMilestone
		) {
			// Calculate next and previous milestone ids
			const selectedMilestoneIndex =
				projectControlCenterContext.milestones.findIndex(
					(milestone) =>
						milestone._id ===
						projectControlCenterContext.selectedMilestone
				);
			if (
				selectedMilestoneIndex <
				projectControlCenterContext.milestones.length - 1
			) {
				setNextMilestoneId(
					projectControlCenterContext.milestones[
						selectedMilestoneIndex + 1
					]._id
				);
			} else {
				setNextMilestoneId(null);
			}
			if (selectedMilestoneIndex > 0) {
				setPreviousMilestoneId(
					projectControlCenterContext.milestones[
						selectedMilestoneIndex - 1
					]._id
				);
			} else {
				setPreviousMilestoneId(null);
			}
		}
	}, [
		projectControlCenterContext.milestones,
		projectControlCenterContext.selectedMilestone,
	]);

	const fetchMilestoneDetails = async () => {
		setMilestoneUpdates(false);
		const response = await dispatch(getMilestoneById(milestoneId));
		if (response.status) {
			response.data.deliveries?.reverse();
			setMilestoneDetails(response.data);
		}
	};

	const fetchMilestoneTasks = async () => {
		const response = await dispatch(
			fetchMilestoneTasksByMilestoneId(milestoneId)
		);
		if (response.data) {
			setMilestoneTasks(response.data);
		}
	};

	const fetchMilestoneComments = async () => {
		const response = await dispatch(
			fetchMilestoneCommentsByMilestoneId(milestoneId)
		);
		if (response.status) {
			setMilestoneComments(response.data);
		}
	};

	const fetchMilestoneActivities = async () => {
		const response = await dispatch(
			fetchProjectActivitiesByType(
				milestoneDetails.project._id,
				milestoneDetails._id,
				"milestone"
			)
		);
		if (response.status) {
			setMilestoneActivities(response.data);
		}
	};

	const handleTabChange = (newValue) => {
		if (Number(newValue) === 2) {
			fetchMilestoneActivities();
		}
		setSelectedTab(newValue);
	};

	const handleTaskStatusChange = async (taskId, status) => {
		if (status === "active") {
			status = "completed";
		} else if (status === "completed") {
			status = "active";
		}

		const response = await dispatch(
			updateMilestoneTaskStatus(milestoneId, taskId, status)
		);
		if (response.status) {
			fetchMilestoneTasks();
		}
	};

	const handleDeleteTask = async (milestoneId, taskId) => {
		const response = await dispatch(
			deleteMilestoneTask(milestoneId, taskId)
		);
		if (response.status) {
			fetchMilestoneTasks();
		}
	};

	return (
		<Fragment>
			{milestoneDetails && (
				<Fragment>
					<div className="d-flex align-items-center">
						{previousMilestoneId && (
							<span
								className="material-icons pointer"
								onClick={() => {
									history.push(
										`/project/${projectControlCenterContext.project._id}/control-center?t=2&milestone=${previousMilestoneId}`
									);
								}}
							>
								arrow_back_ios
							</span>
						)}
						<h4 className="flex-grow-1 text-center m-0">
							{milestoneDetails.title}
						</h4>
						{nextMilestoneId && (
							<span
								className="material-icons pointer"
								onClick={() => {
									history.push(
										`/project/${projectControlCenterContext.project._id}/control-center?t=2&milestone=${nextMilestoneId}`
									);
								}}
							>
								arrow_forward_ios
							</span>
						)}
					</div>
					<hr></hr>
					{milestoneUpdates && (
						<p className="alert alert-danger text-center">
							Milestones updated by developers...{" "}
							{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
							<a
								className="text-decoration-underline"
								onClick={fetchMilestoneDetails}
							>
								Refresh Now
							</a>
						</p>
					)}
					<Tabs
						value={selectedTab}
						onChange={(event, newValue) =>
							handleTabChange(newValue)
						}
					>
						<Tab label="Details" value="0" />
						{milestoneDetails?.deliveries?.length > 0 && (
							<Tab label="Deliveries" value="1" />
						)}
						<Tab label="Activity" value="2" />
					</Tabs>
					<div
						role="tabpanel"
						hidden={Number(selectedTab) !== 0}
						id={`simple-tabpanel-${0}`}
						aria-labelledby={`simple-tab-${0}`}
					>
						{Number(selectedTab) === 0 && (
							<div className="row pt-4">
								<div className="col-md-8">
									<div>
										<h6>Tasks</h6>
										<div className="light-bg p-3">
											<table className="table table-borderless">
												<thead>
													<tr>
														{isEditable && (
															<th>
																<input type="checkbox" />
															</th>
														)}
														<th>Task</th>
														<th>Priority</th>
														{isEditable && (
															<th>Actions</th>
														)}
													</tr>
												</thead>
												<tbody>
													{milestoneTasks.length >
													0 ? (
														<Fragment>
															{milestoneTasks.map(
																(
																	task,
																	index
																) => {
																	return (
																		<tr
																			key={
																				index
																			}
																		>
																			{isEditable && (
																				<td>
																					<input
																						type="checkbox"
																						checked={
																							task.status ===
																							"completed"
																						}
																						onChange={() =>
																							handleTaskStatusChange(
																								task._id,
																								task.status
																							)
																						}
																					/>
																				</td>
																			)}

																			<td
																				className={`${
																					task.status ===
																						"completed" &&
																					styles[
																						"completed-task"
																					]
																				}`}
																			>
																				{
																					task.title
																				}
																			</td>
																			<td
																				className={`text-capitalize ${
																					task.status ===
																						"completed" &&
																					styles[
																						"completed-task"
																					]
																				}`}
																			>
																				{
																					task.priority
																				}
																			</td>
																			{isEditable && (
																				<td>
																					<span
																						className="material-icons pointer"
																						onClick={() =>
																							handleDeleteTask(
																								task.milestone,
																								task._id
																							)
																						}
																					>
																						delete
																					</span>
																				</td>
																			)}
																		</tr>
																	);
																}
															)}
														</Fragment>
													) : (
														<tr>
															<td
																colSpan="4"
																className="text-center text-muted py-4"
															>
																No Tasks Found!
															</td>
														</tr>
													)}

													{isEditable && (
														<tr>
															<td colSpan="2">
																<input
																	className="dark-input"
																	placeholder="Create new task..."
																	name="title"
																	value={
																		taskFormik
																			.values
																			.title
																	}
																	onChange={
																		taskFormik.handleChange
																	}
																/>
															</td>
															<td>
																<select
																	className="dark-input"
																	name="priority"
																	value={
																		taskFormik
																			.values
																			.priority
																	}
																	onChange={
																		taskFormik.handleChange
																	}
																>
																	<option value="low">
																		Low
																	</option>
																	<option value="medium">
																		Medium
																	</option>
																	<option value="high">
																		High
																	</option>
																</select>
															</td>
															<td>
																<LoadingButton
																	text="Save"
																	className="btn btn-primary-gradient outline w-100"
																	onClick={
																		taskFormik.handleSubmit
																	}
																/>
															</td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
									</div>
									<div className="mt-3">
										<h6>Comments</h6>
										<div className="milestone-comments">
											{milestoneComments.length > 0 ? (
												<div
													className={`${styles["milestone-comments"]}`}
												>
													{milestoneComments.map(
														(comment, index) => {
															return (
																<div
																	key={index}
																	className={`${styles["item"]} light-bg p-3 mb-1`}
																>
																	<div className="d-flex align-items-center mb-3">
																		<img
																			src="/img/profile-image.png"
																			className="profile-pic-sm"
																			alt="profile"
																		/>
																		<p className="flex-grow-1 m-0 ms-2">
																			{
																				comment
																					.user
																					.name
																			}
																			<br></br>
																			<small className="text-muted">
																				{formatTime(
																					comment.createdAt,
																					authState
																						.user
																						.profile
																						.timezone,
																					"DD MMM, YYYY hh:mm A"
																				)}
																			</small>
																		</p>
																	</div>
																	<div
																		dangerouslySetInnerHTML={{
																			__html: comment.content,
																		}}
																	></div>
																</div>
															);
														}
													)}
												</div>
											) : (
												<div
													className="light-bg p-3 my-1"
													style={{
														borderBottomLeftRadius:
															"0px",
														borderBottomRightRadius:
															"0px",
													}}
												>
													<p className="text-muted text-center m-0">
														No comments found!
													</p>
												</div>
											)}
											{isEditable && (
												<div
													className={`${styles["comment-editor-container"]} light-bg p-3`}
												>
													<form
														onSubmit={
															commentFormik.handleSubmit
														}
													>
														<WYSIWYGEditor
															onChange={(
																data
															) => {
																commentFormik.setFieldValue(
																	"comment",
																	data
																);
															}}
														/>
														<div className="text-end mt-2">
															<LoadingButton
																isLoading={
																	savingMilestone
																}
																text="Submit"
																className="btn btn-primary-gradient outline "
															/>
														</div>
													</form>
												</div>
											)}
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div
										className="position-sticky"
										style={{ top: "1.5rem" }}
									>
										<MilestoneDetailsActionSection
											milestoneDetails={milestoneDetails}
											fetchMilestoneDetails={
												fetchMilestoneDetails
											}
										/>
										<div className="mt-3">
											<h6>Milestone Details</h6>
											<div className="light-bg p-3">
												<table className="w-100">
													<tbody>
														<tr>
															<td className="py-2">
																{
																	milestoneDetails.title
																}
															</td>
															<td className="text-end">
																<p
																	className={`${
																		styles[
																			"status"
																		]
																	}  p-1 rounded d-inline-block ${
																		styles[
																			milestoneDetails
																				.status
																		]
																	}`}
																>
																	{milestoneDetails.status.replace(
																		"-",
																		" "
																	)}
																</p>
															</td>
														</tr>
														<tr>
															<td className="text-muted py-2">
																Delivery Date
															</td>
															<td className="text-end">
																{`${milestoneDetails.estimatedDuration.value} ${milestoneDetails.estimatedDuration.scope}`}
																{/* {moment(
                                                                    milestoneDetails.estimatedDate
                                                                ).format(
                                                                    "DD MMM YYYY"
                                                                )} */}
															</td>
														</tr>
														<tr>
															<td className="text-muted py-2">
																Developer
															</td>
															<td className="text-end">
																{milestoneDetails.hiredUser
																	? milestoneDetails
																			.hiredUser
																			.name
																	: "Unassigned"}
															</td>
														</tr>

														<tr>
															<td className="text-muted py-2">
																Milestone Price
															</td>
															<td className="text-end">
																${" "}
																{
																	milestoneDetails
																		.price
																		.amount
																}
															</td>
														</tr>
														{/* <tr>
                                                        <td className="text-muted py-2">
                                                            Skill
                                                        </td>
                                                        <td className="text-end">
                                                            {milestoneDetails.skill
                                                                ? milestoneDetails
                                                                      .skill
                                                                      .name
                                                                : "Unassigned"}
                                                        </td>
                                                    </tr> */}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
					<div
						role="tabpanel"
						hidden={Number(selectedTab) !== 1}
						id={`simple-tabpanel-${1}`}
						aria-labelledby={`simple-tab-${1}`}
					>
						{Number(selectedTab) === 1 && (
							<div className="pt-4">
								{milestoneDetails.deliveries.map(
									(delivery, index) => {
										return (
											<MilestoneDeliveryListItem
												key={index}
												delivery={delivery}
											/>
										);
									}
								)}
							</div>
						)}
					</div>
					<div
						role="tabpanel"
						hidden={Number(selectedTab) !== 2}
						id={`simple-tabpanel-${2}`}
						aria-labelledby={`simple-tab-${2}`}
					>
						{Number(selectedTab) === 2 && (
							<div className="pt-4">
								{milestoneActivities.length > 0 ? (
									<Fragment>
										{milestoneActivities.map(
											(activity, index) => {
												return (
													<div
														key={index}
														className={`${styles["milestone-activity"]} light-bg p-3 mb-3`}
													>
														<h6 className="mb-0">
															{activity.title} -{" "}
															<Link className="color-primary">
																{
																	activity
																		.user
																		.name
																}
															</Link>
														</h6>
														<small className="text-muted">
															@{" "}
															{formatTime(
																activity.createdAt,
																authState.user
																	.profile
																	.timezone,
																"DD MMM YYYY, hh:mm A"
															)}
														</small>
														<div
															className={`${styles["description"]} text-muted mt-2`}
															dangerouslySetInnerHTML={{
																__html: activity.description,
															}}
														></div>
													</div>
												);
											}
										)}
									</Fragment>
								) : (
									<h5 className="text-center text-muted mt-4">
										No Recent Activities Found!
									</h5>
								)}
							</div>
						)}
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default MilestoneDetails;
