import {
    ADD_NEW_MESSAGE,
    GET_ACTIVE_CHAT,
    GET_ALL_CHAT,
    SET_ACTIVE_CHAT,
    NEW_MESSAGE_ROOMS,
    UPDATE_ACTION_TAKEN,
    NEW_CHAT_GROUP_CREATED,
    INITIATE_CHAT_WITH_USER,
    GET_ALL_MSG_NOTIFICATION,
    NEW_MSG_NOTIFICATION,
    REMOVE_MSG_NOTIFICATION,
    CLEAR_ACTIVE_ROOM,
    LOAD_CHATROOM_MESSAGES,
} from "../actions/types";

const initialState = {
    loading: true,
    activeRoom: null,
    chats: [],
    msgNotification: [],
};

const chat = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_ALL_CHAT:
            return {
                ...state,
                loading: false,
                chats: payload,
            };
        case GET_ACTIVE_CHAT:
        case SET_ACTIVE_CHAT:
            return {
                ...state,
                activeRoom: payload,
                loading: false,
            };
        case LOAD_CHATROOM_MESSAGES:
            return {
                ...state,
                activeRoom: {
                    ...state.activeRoom,
                    messages: payload,
                },
            };
        case ADD_NEW_MESSAGE:
            return {
                ...state,
                activeRoom: {
                    ...state.activeRoom,
                    messages: [payload, ...state.activeRoom.messages],
                },
            };
        case UPDATE_ACTION_TAKEN:
            return {
                ...state,
                activeRoom: {
                    ...state.activeRoom,
                },
            };
        case GET_ALL_MSG_NOTIFICATION:
            return {
                ...state,
                msgNotification: payload,
            };
        case NEW_MSG_NOTIFICATION:
            return {
                ...state,
                msgNotification: [payload, ...state.msgNotification],
            };
        case REMOVE_MSG_NOTIFICATION:
            return {
                ...state,
                msgNotification: state.msgNotification.filter(
                    (el) => el.chatId !== payload
                ),
            };
        case NEW_CHAT_GROUP_CREATED:
            return {
                ...state,
                activeRoom: payload,
                chats: [...state.chats, payload],
            };
        case INITIATE_CHAT_WITH_USER:
            return {
                ...state,
                chats: [...state.chats, payload],
            };
        case NEW_MESSAGE_ROOMS:
            if (state.chats.some((chat) => chat._id === payload.chatId)) {
                state.chats
                    .find((chat) => chat._id === payload.chatId)
                    .messages.unshift(payload.msg);
            }
            return state;
        case CLEAR_ACTIVE_ROOM:
            return {
                ...state,
                activeRoom: null,
            };
        default:
            return state;
    }
};

export default chat;
