import { Dialog } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useState } from "react";
import LoadingButton from "../../utils/LoadingButton/LoadingButton";
import {
    CustomizedDialogContent,
    CustomizedDialogTitle,
} from "../CustomizedModal/CustomizedModal";

const IncreaseBudgetModal = ({ isOpen, handleClose, handleSubmit }) => {
    const [submitting, setSubmitting] = useState(false);

    const formik = useFormik({
        initialValues: {
            amount: "",
        },
    });

    return (
        <Dialog
            onClose={() => handleClose(false)}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
            maxWidth="sm"
            fullWidth={true}
        >
            <CustomizedDialogTitle id="customized-dialog-title">
                <h3 className="modal-title text-accent">Increase Budget</h3>
            </CustomizedDialogTitle>
            <CustomizedDialogContent>
                <form
                    onSubmit={async (e) => {
                        e.preventDefault();
                        setSubmitting(true);
                        await handleSubmit(formik.values);
                        setSubmitting(false);
                    }}
                >
                    <div className="form-group mb-3">
                        <label className="form-label">Amount to add</label>
                        <input
                            type="number"
                            autoComplete="off"
                            className="dark-input"
                            placeholder="Enter Amount"
                            name="amount"
                            onChange={formik.handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <LoadingButton
                            text="Add From Wallet"
                            isLoading={submitting}
                        />
                    </div>
                </form>
            </CustomizedDialogContent>
        </Dialog>
    );
};

export default IncreaseBudgetModal;
