import React, { Fragment } from "react";

import styles from "./GetWork.module.css";

const GetWork = () => {
    return (
        <Fragment>
            <section id={styles["work-categories-section"]} className="py-5">
                <h2 className="text-center section-title mb-4">
                    Get Work Done In These Categories
                </h2>
                <div
                    style={{ background: "#df371c" }}
                    className="extra-padding"
                >
                    <div className="container">
                        <div className={`row ${styles.holder}`}>
                            <div className="col-md-4">
                                <ul>
                                    <li>Game development</li>
                                    <li>Level Design</li>
                                    <li>3D Modelling</li>
                                </ul>
                            </div>
                            <div className="col-md-4">
                                <ul>
                                    <li>Rigging and Animations</li>
                                    <li>Textures and Materials</li>
                                    <li>Sfx and Music</li>
                                </ul>
                            </div>
                            <div className="col-md-4">
                                <ul>
                                    <li>UI / UX Design</li>
                                    <li>Visual Effects / Particles</li>
                                    <li>Webdevelopment / Server</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default GetWork;
