export const getApiEndpoint = () => {
    switch (process.env.REACT_APP_ENVIRONMENT_MODE) {
        case "local":
            return process.env.REACT_APP_LOCAL_API_URL;
        case "development":
            return process.env.REACT_APP_DEV_API_URL;
        case "staging":
            return process.env.REACT_APP_STAGE_API_URL;
        case "production":
            return process.env.REACT_APP_PROD_API_URL;
        default:
            break;
    }
};

export const getFrontendPublicURL = () => {
    switch (process.env.REACT_APP_ENVIRONMENT_MODE) {
        case "local":
            return process.env.REACT_APP_LOCAL_PUBLIC_URL;
        case "development":
            return process.env.REACT_APP_DEV_PUBLIC_URL;
        case "staging":
            return process.env.REACT_APP_STAGE_PUBLIC_URL;
        case "production":
            return process.env.REACT_APP_PROD_PUBLIC_URL;
        default:
            break;
    }
};
