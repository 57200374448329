import { setAlert } from "./alert";
import { requestWithToken } from "../utils/httpRequest";
import { getApiEndpoint } from "../utils/getApiEndpoint";

// Get portal setting using a key
export const getPortalSettingByKey = (key) => async (dispatch) => {
  try {
    const res = await requestWithToken(
      "GET",
      `${getApiEndpoint()}/portalsetting/${key}`
    );

    return res;
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      dispatch(setAlert(errors[0].msg, "danger"));
    }
  }
};
