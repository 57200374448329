import { getApiEndpoint } from "../utils/getApiEndpoint";
import { requestWithoutToken } from "../utils/httpRequest";
import { error } from "./error";
import { CREATE_GAME_CUSTOMIZER, CLEAR_GAME_CUSTOMIZER } from "./types";

export const getGameForms = async () => {
    try {
        // const res = await axios.get(
        //   `${process.env.REACT_APP_DEV_API_URL}/gameform`
        // );
        const res = await requestWithoutToken(
            "GET",
            `${getApiEndpoint()}/gameform`
        );

        if (res.status === 200) {
            return res.data;
        }
    } catch (err) {
        const errors = err.response.data;
        if (errors) {
            //   dispatch(setAlert(errors[0].msg, "danger"));
        }
    }
};

export const getActiveGameForms = () => async (dispatch) => {
    try {
        const res = await requestWithoutToken(
            "GET",
            `${getApiEndpoint()}/gameform/active`
        );

        return res.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

export const getGameFormById = (formId) => async (dispatch) => {
    try {
        const response = await requestWithoutToken(
            "GET",
            `${getApiEndpoint()}/gameform/form/${formId}`
        );

        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Create project from game form
export const createProject = (data) => async (dispatch) => {
    try {
        dispatch({
            type: CREATE_GAME_CUSTOMIZER,
            payload: data,
        });
    } catch (err) {
        return err.response.data;
    }
};

export const clearGame = () => async (dispatch) => {
    try {
        dispatch({
            type: CLEAR_GAME_CUSTOMIZER,
        });
    } catch (err) {
        return err.response.data;
    }
};
