import React, { Fragment } from "react";
import Skeleton from "../../../components/Skeleton/Skeleton";

const TransactionSkeleton = () => {
    return (
        <Fragment>
            {[1, 2].map((el, index) => (
                <section key={index} className="light-bg p-4 my-lg-3">
                    <div
                        className="d-flex align-items-center"
                        style={{
                            gap: "0.5rem",
                        }}
                    >
                        <h3 className="flex-grow-1">
                            <Skeleton />
                        </h3>
                        <h3>
                            <Skeleton />
                        </h3>
                    </div>
                    <div className="d-flex mt-3">
                        <div className="text-capitalize d-flex m-0">
                            <span className="me-2">Status:</span>
                            <Skeleton />
                        </div>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        <div className="text-capitalize d-flex m-0">
                            <span style={{ width: "100px" }} className="me-2">
                                Payment:
                            </span>
                            <Skeleton />
                        </div>
                    </div>
                </section>
            ))}
        </Fragment>
    );
};

export default TransactionSkeleton;
