import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import styles from "./topLoader.module.css";

const TopLoader = () => {
    const topLoaderState = useSelector((state) => state.topLoader);

    return (
        <Fragment>
            {topLoaderState.visible ? (
                <div className={`${styles["loader-container"]}`}>
                    <div className={`${styles["loader"]}`}></div>
                </div>
            ) : (
                <Fragment></Fragment>
            )}
        </Fragment>
    );
};

export default TopLoader;
