import React, { Fragment } from "react";
import { FiExternalLink } from "react-icons/fi";
import { getApiEndpoint } from "../../utils/getApiEndpoint";

const ProjectAgreementItem = ({ agreement }) => {
	return (
		<Fragment>
			<article className="light-bg p-4 mb-3">
				<div className="row align-items-center">
					<div className="col-md-8">
						<h4>{agreement.name}</h4>
					</div>
					<div className="col-md-4 text-end">
						<a
							href={`${getApiEndpoint() + agreement.url}`}
							target="_blank"
							rel="noreferrer"
							className="btn btn-primary-gradient outline"
						>
							View Agreement <FiExternalLink />
						</a>
					</div>
				</div>
				<div className="mb-3">
					<small className="d-block text-muted">
						Signed By ({agreement?.signedBy?.length}):
					</small>
					{agreement?.signedBy?.length > 0 ? (
						<Fragment>
							<div className="d-flex flex-wrap">
								{agreement.signedBy.map((signedByUser) => {
									console.log(signedByUser);
									return (
										<div
											key={signedByUser._id}
											className="d-inline-block text-center m-2"
										>
											<img
												className="profile-pic-sm"
												src={
													signedByUser.userProfile
														.profileImgUrl
														? getApiEndpoint() +
														  signedByUser
																.userProfile
																.profileImgUrl
														: "/img/profile-image.png"
												}
												alt="Employer"
											/>
											<small className={`d-block`}>
												{signedByUser.name}
											</small>
										</div>
									);
								})}
							</div>
						</Fragment>
					) : (
						<Fragment>
							<small>Not Signed By Anyone!</small>
						</Fragment>
					)}
				</div>
			</article>
		</Fragment>
	);
};

export default ProjectAgreementItem;
