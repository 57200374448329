import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./CopyrightTerms.module.css";
const CopyrightTerms = () => {
    return (
        <Fragment>
            <div style={{  paddingTop: "7rem", paddingBottom: "4rem"}}>
                <div
                    className="container"
                    style={{ color: "#fff", paddingLeft: "40px" }}
                >
                    <h1 className={styles["heading"]}>
                        Intellectual Property Claims (Copyright (DMCA),
                        Trademark Infringement)
                    </h1>
                    <h3>Last update: July 2021</h3>
                    <h2 className="mb-3">
                        REPORTING CLAIMS OF COPYRIGHT INFRINGEMENT
                    </h2>
                    <p>
                        Trinty.com's content is based on User Generated Content
                        (UGC). Trinty does not check user uploaded/created
                        content for violations of copyright or other rights.
                        However, if you believe any ofthe uploaded content
                        violates your copyright or a related exclusive right,
                        you should follow the process below. Trinty looks into
                        reported violations and removes or disables content
                        shown to be violating third party rights.
                    </p>
                    <p>
                        In order to allow us to review your report promptly and
                        effectively, a copyright infringement notice ("Notice")
                        should include the following:
                    </p>
                    <ol className="m-3">
                        <li>
                            Identification of your copyrighted work and what is
                            protected under the copyright(s) that you are
                            referring to
                        </li>
                        <li>
                            Your copyright certificate(s)/designation(s) and the
                            type, e.g., registered or unregistered
                        </li>
                        <li>
                            Proof of your copyrights ownership, such as the
                            registration number or a copy of the registration
                            certificate
                        </li>
                        <li>
                            A short description of how our user(s) allegedly
                            infringe(s) your copyright(s)
                        </li>
                        <li>
                            Clear reference to the materials you allege are
                            infringing and which you are requesting to be
                            removed, for example, the GIG® url, a link to the
                            deliverable provided to a user, etc.
                        </li>
                        <li>
                            Your complete name, address, email address, and
                            telephone number.
                        </li>
                        <li>
                            A statement that you have a good faith belief that
                            use of the material in the manner complained of is
                            not authorized by the copyright owner, its agent, or
                            the law.
                        </li>
                        <li>
                            A statement made under penalty of perjury that the
                            information provided in the notice is accurate and
                            that you are the copyright owner or the owner of an
                            exclusive right that is being infringed, or are
                            authorized to make the complaint on behalf of the
                            copyright owner or the owner of an exclusive right
                            that is being infringed.
                        </li>
                        <li>Your electronic or physical signature.</li>
                    </ol>
                    <p className="mb-2">
                        You can send your Notice to our designated DMCA /
                        Copyright Claims Agent at:
                        <br />
                        Trinty / NeoxEntertainment
                        <br />
                        Attention: DMCA / Copyright Claims Agent
                        <br />
                        Am Jägerstuhl 10
                        <br />
                        37574 Einbeck Germany
                    </p>
                    <p>
                        Alternatively you can submit the Notice electronically
                        to{" "}
                        <a className={styles["links"]} href="">
                            neoxdev.info@gmail.com
                        </a>
                        .
                    </p>
                    <p>
                        Note that we will provide the user who is allegedly
                        infringing your copyright with information aboutthe
                        Notice and allow them to respond. In cases where
                        sufficient proof of infringement is provided, we may
                        remove or suspend the reported materials prior to
                        receiving the user's response. In cases where the
                        allegedly infringing user provides us with a proper
                        counter-notification indicating that it is permitted to
                        post the allegedly infringing material, we may notify
                        you and then replace theremoved or disabled material. In
                        all such cases, we will act in accordance with 17 U.S.C
                        Section 512 and other applicable laws. If you fail to
                        comply with allof the requirements of Section 512(c)(3)
                        of the DMCA, your DMCA Notice may not be effective.
                        Please be aware that if you knowingly materially
                        misrepresent that material or activity on the Website is
                        infringing your copyright, you may be held liable for
                        damages (including costs and attorneys' fees) under
                        Section 512(f) of the DMCA.
                    </p>
                    <h2 className="mb-3">COUNTER-NOTIFICATION PROCEDURES</h2>
                    <p>
                        If you believe that material you posted on the site was
                        removed or access to it was disabled by mistake or
                        misidentification, you may file a counter-notification
                        with us (a "Counter-Notice") by submitting written
                        notification to our DMCA / Copyright Claims agent
                        (identified above). Pursuant to the DMCA, the
                        Counter-Notice must include substantially the following:
                    </p>
                    <ol className="m-3">
                        <li>Your physical or electronic signature.</li>
                        <li>
                            An identification of the material that has been
                            removed or to which access has been disabled and the
                            location at which the material appeared before it
                            was removed or access disabled.
                        </li>
                        <li>
                            Adequate information by which we can contact you
                            (including your name, postal address, telephone
                            number and, if available, e-mail address).
                        </li>
                        <li>
                            A statement under penalty of perjury by you that you
                            have a good faith belief that the material
                            identified above was removed or disabled as a result
                            of a mistake or misidentification of the material to
                            be removed or disabled.
                        </li>
                        <li>
                            A statement that you will consent to the
                            jurisdiction of the Federal District Court for the
                            judicial district in which your address is located
                            (or if you reside outside the United States for any
                            judicial district in which the Website may be found)
                            and that you will accept service from the person (or
                            an agent of that person) who provided the Website
                            with the complaint at issue.
                        </li>
                    </ol>
                    <p>
                        The DMCA allows us to restore the removed content if the
                        party filing the original DMCA Notice does not file a
                        court action against you within ten business days of
                        receiving the copy of your{" "}
                        <strong>Counter-Notice</strong>. Please be aware that if
                        you knowingly materially misrepresent that materialor
                        activity on the Website was removed or disabled by
                        mistake or misidentification, you may be held liable for
                        damages (including costs and attorneys' fees) under
                        Section 512(f) of the DMCA.]
                    </p>
                    <h2 className="mb-3">TRADEMARK INFRINGEMENT</h2>
                    <p>
                        Trinty.com's content is based on User Generated Content
                        (UGC). Trinty does not check user uploaded/created
                        content for violations of trademark or other rights.
                        However, if you believe any of the uploaded content
                        violates your trademark, you should follow the process
                        below. Trinty looks into reported violations and removes
                        or disables content shown to be violating third party
                        trademark rights.
                    </p>
                    <p>
                        In order to allow us to review your report promptly and
                        effectively, a trademark infringement notice ("TM
                        Notice") should include the following:
                    </p>
                    <ol className="m-3">
                        <li>
                            Identification of your trademark and the
                            goods/services for which you claim trademark rights
                        </li>
                        <li>
                            Your trademark registration certificate and a
                            printout from the pertinent country's trademark
                            office records showing current status and title of
                            the registration. Alternatively, a statement that
                            your mark is unregistered, together with a court
                            ruling confirming your rights
                        </li>
                        <li>
                            A short description of how our user(s) allegedly
                            infringe(s) your trademark(s)
                        </li>
                        <li>
                            Clear reference to the materials you allege are
                            infringing and which you are requesting to be
                            removed, for example, the GIG® url, a link to the
                            deliverable provided to a user, etc.
                        </li>
                        <li>
                            Your complete name, address, email address, and
                            telephone number.
                        </li>
                        <li>
                            A statement that you have a good faith belief that
                            use of the material in the manner complained of is
                            not authorized by the trademark owner, its agent, or
                            the law.
                        </li>
                        <li>
                            A statement made under penalty of perjury that the
                            information provided in the notice is accurate and
                            that you are the trademark or are authorized to make
                            the complaint on behalf of the trademark owner.
                        </li>
                        <li>Your electronic or physical signature</li>
                    </ol>
                    <p className="mb-2">
                        You can send your Notice to our designated DMCA /
                        Copyright Claims Agent at:
                        <br />
                        Trinty / NeoxEntertainment
                        <br />
                        Attention: DMCA / Copyright Claims Agent
                        <br />
                        Am Jägerstuhl 10
                        <br />
                        37574 Einbeck Germany
                    </p>
                    <p>
                        Alternatively you can submit the Notice electronically
                        to{" "}
                        <a href="" className={styles["links"]}>
                            neoxdev.info@gmail.com
                        </a>
                        .
                    </p>
                    <p>
                        Note that we will provide the user who is allegedly
                        infringing your trademark with information about the TM
                        Notice and allow them to respond. In cases where
                        sufficient proof of infringement isprovided, we may
                        remove or suspend the reported materials prior to
                        receiving the user's response. In cases where the
                        allegedly infringing user provides us with information
                        indicating thatit is permitted to post the allegedly
                        infringing material, we may notify you and then replace
                        the removed or disabled material. In all such cases, we
                        will act in accordance with applicable law.
                    </p>
                    <h2 className="mb-3">REPEAT INFRINGERS</h2>
                    <p>
                        It is our policy in appropriate circumstances to disable
                        and/or terminate the accounts of users whoare repeat
                        infringers.{" "}
                        <a className={styles["links"]} href="">
                            privacy
                        </a>
                    </p>
                </div>
            </div>
        </Fragment>
    );
};
export default CopyrightTerms;
