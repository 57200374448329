import { Fragment, useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import DetailsTab from "./../../components/ProjectControlCenter/DetailsTab";
import BudgetTab from "./../../components/ProjectControlCenter/BudgetTab";
import MilestonesTab from "./../../components/ProjectControlCenter/MilestonesTab";
import DevelopersTab from "./../../components/ProjectControlCenter/DevelopersTab";
import BidsTab from "./../../components/ProjectControlCenter/BidsTab";
import styles from "./projectControlCenter.module.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjectById } from "../../actions/project";
import { setAlert } from "../../actions/alert";
import { ProjectControlCenterContext } from "../../contexts/ProjectControlCenterContext";
import {
	newBidReceived,
	removeSocketEventListener,
} from "../../utils/clientEvents";
import AgreementsTab from "../../components/ProjectControlCenter/AgreementsTab";

const ProjectControlCenter = (props) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const history = useHistory();
	const search = useLocation().search;

	const socketState = useSelector((state) => state.socket);
	const authState = useSelector((state) => state.auth);

	const [newBidCount, setNewBidCount] = useState(0);
	const [activeTab, setActiveTab] = useState(
		parseInt(new URLSearchParams(search).get("t")) || 0
	);
	const [project, setProject] = useState(null);
	const [projectAttachments, setProjectAttachments] = useState([]);
	const [milestones, setMilestones] = useState([]);
	const [selectedMilestone, setSelectedMilestone] = useState(null);
	const [todos, setTodos] = useState([
		{
			title: "Upload Attachments",
			description:
				"Upload some attachments to make it for informative to freelancers",
			completed: false,
		},
		{
			title: "Add Milestones",
			description:
				"Add some milestones to divide big project into smaller chunks according to the skills",
			completed: false,
		},
		{
			title: "Change Project Status",
			description:
				"Change project status to active so that freelancers can see it in listing",
			completed: false,
		},
	]);

	useEffect(() => {
		fetchProjectDetails();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		// Realtime page events
		if (socketState.socket && activeTab !== 4) {
			newBidReceived(socketState.socket, (data) => {
				setNewBidCount(newBidCount + 1);
			});

			return () => {
				removeSocketEventListener(socketState.socket, "new-bid-posted");
			};
		} else if (socketState.socket && activeTab === 4) {
			return () => {
				removeSocketEventListener(socketState.socket, "new-bid-posted");
			};
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socketState.socket, activeTab]);

	useEffect(() => {
		if (project) {
			if (project.attachments.length > 0) {
				const updatedTodos = [...todos];
				updatedTodos[0].completed = true;
				setTodos(updatedTodos);
			} else {
				const updatedTodos = [...todos];
				updatedTodos[0].completed = false;
				setTodos(updatedTodos);
			}

			if (project.milestonesCount > 0) {
				const updatedTodos = [...todos];
				updatedTodos[1].completed = true;
				setTodos(updatedTodos);
			} else {
				const updatedTodos = [...todos];
				updatedTodos[1].completed = false;
				setTodos(updatedTodos);
			}

			if (project.status === "active") {
				const updatedTodos = [...todos];
				updatedTodos[2].completed = true;
				setTodos(updatedTodos);
			} else {
				const updatedTodos = [...todos];
				updatedTodos[2].completed = false;
				setTodos(updatedTodos);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [project]);

	const fetchProjectDetails = async () => {
		const response = await dispatch(fetchProjectById(id));
		if (response.status) {
			setProject(response.data);
		} else {
			dispatch(setAlert(response.msg, "danger"));
		}
	};

	const onChangeTab = (tab) => {
		setActiveTab(tab);
		history.push(`/project/${id}/control-center?t=${tab}`);
	};

	const renderView = () => {
		if (project) {
			switch (activeTab) {
				case 0:
					return <DetailsTab projectId={id} project={project} />;
				case 1:
					return <AgreementsTab projectId={id} />;
				case 2:
					return <BudgetTab projectId={id} />;
				case 3:
					return (
						<MilestonesTab
							projectId={id}
							projectOwnerId={project.user._id}
						/>
					);
				case 4:
					return <DevelopersTab projectId={id} />;
				case 5:
					return (
						<BidsTab
							projectId={id}
							projectOwnerId={project.user._id}
						/>
					);
				default:
					return <DetailsTab projectId={id} project={project} />;
			}
		} else {
			return <h5 className="text-center text-muted mt-5">Loading...</h5>;
		}
	};

	const projectControlCenterContextData = {
		project,
		projectAttachments,
		milestones,
		selectedMilestone,
		setProject,
		setProjectAttachments,
		setMilestones,
		setSelectedMilestone,
		onChangeTab,
		fetchProjectDetails,
	};

	return (
		<ProjectControlCenterContext.Provider
			value={projectControlCenterContextData}
		>
			<Fragment>
				<div
					className="container py-5"
					id={`${styles["project-control-center"]}`}
				>
					<div className="row">
						<div className="col-md-3">
							<div
								className="position-sticky"
								style={{ top: "1.5rem" }}
							>
								<div
									className={`nav flex-column nav-pills ${styles["sidebar"]}`}
									id="v-pills-tab"
									role="tablist"
									aria-orientation="vertical"
								>
									<button
										className={
											activeTab === 0
												? `${styles["active"]} ${styles["nav-link"]}`
												: `${styles["nav-link"]}`
										}
										id="v-pills-home-tab"
										onClick={(e) => {
											onChangeTab(0);
										}}
										data-bs-toggle="pill"
										data-bs-target="#v-pills-home"
										type="button"
										role="tab"
										aria-controls="v-pills-home"
										aria-selected="true"
									>
										Details & Activity
									</button>
									<button
										className={
											activeTab === 1
												? `${styles["active"]} ${styles["nav-link"]}`
												: `${styles["nav-link"]}`
										}
										id="v-pills-home-tab"
										onClick={(e) => {
											onChangeTab(1);
										}}
										data-bs-toggle="pill"
										data-bs-target="#v-pills-home"
										type="button"
										role="tab"
										aria-controls="v-pills-home"
										aria-selected="true"
									>
										Project Agreements
									</button>
									{project?.user?._id ===
										authState.user._id && (
										<button
											className={
												activeTab === 2
													? `${styles["active"]} ${styles["nav-link"]}`
													: `${styles["nav-link"]}`
											}
											id="v-pills-profile-tab"
											onClick={(e) => {
												onChangeTab(2);
											}}
											data-bs-toggle="pill"
											data-bs-target="#v-pills-profile"
											type="button"
											role="tab"
											aria-controls="v-pills-profile"
											aria-selected="false"
										>
											Budget
										</button>
									)}
									<button
										className={
											activeTab === 3
												? `${styles["active"]} ${styles["nav-link"]}`
												: `${styles["nav-link"]}`
										}
										id="v-pills-profile-tab"
										onClick={(e) => {
											onChangeTab(3);
										}}
										data-bs-toggle="pill"
										data-bs-target="#v-pills-profile"
										type="button"
										role="tab"
										aria-controls="v-pills-profile"
										aria-selected="false"
									>
										Milestones
									</button>
									{project?.user?._id ===
										authState.user._id && (
										<button
											className={
												activeTab === 4
													? `${styles["active"]} ${styles["nav-link"]}`
													: `${styles["nav-link"]}`
											}
											id="v-pills-profile-tab"
											onClick={(e) => {
												onChangeTab(4);
											}}
											data-bs-toggle="pill"
											data-bs-target="#v-pills-profile"
											type="button"
											role="tab"
											aria-controls="v-pills-profile"
											aria-selected="false"
										>
											Developers
										</button>
									)}
									{project?.user?._id ===
										authState.user._id && (
										<button
											className={
												activeTab === 5
													? `${styles["active"]} ${styles["nav-link"]}`
													: `${styles["nav-link"]}`
											}
											id="v-pills-profile-tab"
											onClick={(e) => {
												setNewBidCount(0);
												onChangeTab(5);
											}}
											data-bs-toggle="pill"
											data-bs-target="#v-pills-profile"
											type="button"
											role="tab"
											aria-controls="v-pills-profile"
											aria-selected="false"
										>
											<span
												className={`${styles["text"]}`}
											>
												Bids
											</span>
											{newBidCount > 0 && (
												<small
													className={`${styles["count"]}`}
												>
													{newBidCount}
												</small>
											)}
										</button>
									)}
								</div>

								{project &&
									project?.user?._id === authState.user._id &&
									todos.some(
										(todo) => todo.completed === false
									) && (
										<div
											className={`${styles["to-do-section"]} light-bg my-4 p-3`}
										>
											<h5>To Do's</h5>
											<small className="text-muted">
												Follow these optional todos to
												get most out of projects.
											</small>
											<ul className="px-3 pt-2">
												{todos.map((todo, index) => {
													return (
														<li
															key={index}
															className={`${
																todo.completed &&
																styles[
																	"completed"
																]
															} mb-2`}
														>
															<p className="color-primary mb-1">
																{todo.title}
															</p>
															<small>
																{
																	todo.description
																}
															</small>
														</li>
													);
												})}
											</ul>
										</div>
									)}
							</div>
						</div>
						<div className="col-md-9">
							{project?.status === "draft" && (
								<small className="d-block alert alert-warning">
									Your project is currently in draft mode.
									Publish your project once done with setting
									up all the details.
								</small>
							)}
							{renderView()}
						</div>
					</div>
				</div>
			</Fragment>
		</ProjectControlCenterContext.Provider>
	);
};

export default ProjectControlCenter;
