import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setAlert } from "../../actions/alert";
import {
    addMeetingToGoogleCalendar,
    updateMeetingInvitationAction,
} from "../../actions/scheduledMeeting";
import { formatTime } from "../../utils/formatTime";
import { getApiEndpoint } from "../../utils/getApiEndpoint";
import LoadingButton from "../utils/LoadingButton/LoadingButton";

const ScheduledMeetingListItem = ({ meeting, reloadMeetingsData }) => {
    const dispatch = useDispatch();

    const authState = useSelector((state) => state.auth);

    const [loading, setLoading] = useState(false);
    const [invitedUser, setInvitedUser] = useState(undefined);

    useEffect(() => {
        setInvitedUser(
            meeting.invitedUsers.find(
                (invitedUser) => invitedUser.user._id === authState.user._id
            )
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meeting]);

    const onMeetingActionTaken = async (meetingId, action) => {
        const requestBody = {
            meetingId,
            action,
        };

        const response = await dispatch(
            updateMeetingInvitationAction(requestBody)
        );
        if (response.status) {
            reloadMeetingsData();
            dispatch(setAlert(response.msg, "success"));
        } else {
            dispatch(setAlert(response.msg, "danger"));
        }
    };

    const onAddToGoogleCalendar = async (meetingId) => {
        setLoading(true);

        const requestBody = {
            meetingId,
        };
        const response = await dispatch(
            addMeetingToGoogleCalendar(requestBody)
        );
        if (response.status) {
            reloadMeetingsData();
            dispatch(setAlert(response.msg, "success"));
        } else {
            dispatch(setAlert(response.msg, "danger"));
        }

        setLoading(false);
    };

    return (
        <Fragment>
            <article className="light-bg p-4 mb-3">
                <div className="row align-items-center">
                    <div className="col-md-8">
                        <h4>{meeting.title}</h4>
                    </div>
                    <div className="col-md-4 text-end">
                        <small className="text-muted">Meeting Date</small>
                        <p>
                            {formatTime(
                                meeting.date,
                                authState?.user?.profile?.timezone,
                                "DD/MM/YYYY HH:mm"
                            )}
                        </p>
                    </div>
                </div>
                <div className="mb-3">
                    <small className="d-block text-muted">
                        Meeting Members ({meeting.invitedUsers.length}):
                    </small>
                    {meeting.invitedUsers.length > 0 ? (
                        <Fragment>
                            <div className="d-flex flex-wrap">
                                {meeting.invitedUsers.map(
                                    (invitedUser, index) => (
                                        <div
                                            key={index}
                                            className="d-inline-block text-center m-2"
                                        >
                                            <img
                                                className="profile-pic-sm"
                                                src={
                                                    invitedUser.user.userProfile
                                                        .profileImgUrl
                                                        ? getApiEndpoint() +
                                                          invitedUser.user
                                                              .userProfile
                                                              .profileImgUrl
                                                        : "/img/profile-image.png"
                                                }
                                                alt="Employer"
                                            />
                                            <small
                                                className={`d-block ${
                                                    invitedUser.action ===
                                                    "accepted"
                                                        ? "text-success"
                                                        : invitedUser.action ===
                                                          "rejected"
                                                        ? "text-danger"
                                                        : "text-warning"
                                                }`}
                                            >
                                                {invitedUser.user.name}
                                            </small>
                                        </div>
                                    )
                                )}
                            </div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <small>No Members!</small>
                        </Fragment>
                    )}
                </div>
                <div className="row">
                    {invitedUser && (
                        <Fragment>
                            {invitedUser.action === "pending" ? (
                                <Fragment>
                                    <div className="col-md-6">
                                        <button
                                            className="btn btn-primary-gradient w-100"
                                            onClick={() =>
                                                onMeetingActionTaken(
                                                    meeting._id,
                                                    "accepted"
                                                )
                                            }
                                        >
                                            Accept
                                        </button>
                                    </div>
                                    <div className="col-md-6">
                                        <button
                                            className="btn btn-primary-gradient outline w-100"
                                            onClick={() =>
                                                onMeetingActionTaken(
                                                    meeting._id,
                                                    "rejected"
                                                )
                                            }
                                        >
                                            Reject
                                        </button>
                                    </div>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <div className="col-md-8"></div>
                                    <div className="col-md-4 text-end">
                                        {invitedUser &&
                                            !invitedUser.isAddedToCalendar && (
                                                <LoadingButton
                                                    text="Add To Google Calendar"
                                                    className="btn btn-primary-gradient"
                                                    onClick={() =>
                                                        onAddToGoogleCalendar(
                                                            meeting._id
                                                        )
                                                    }
                                                    isLoading={loading}
                                                />
                                            )}
                                    </div>
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </div>
            </article>
        </Fragment>
    );
};

export default ScheduledMeetingListItem;
