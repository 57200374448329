import {
    NEW_PROJECT_NOTIFICATION,
    REMOVE_PROJECT_NOTIFICATION,
} from "../actions/types";

const initialState = {
    loading: false,
    newProjects: [],
};

const projects = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case NEW_PROJECT_NOTIFICATION:
            return {
                ...state,
                loading: false,
                newProjects: [...state.newProjects, payload],
            };
        case REMOVE_PROJECT_NOTIFICATION:
            const newProjects = state.newProjects.filter(
                (project) => project.id !== payload
            );
            return {
                ...state,
                newProjects: newProjects,
            };
        default:
            return state;
    }
};

export default projects;
