import { getApiEndpoint } from "../utils/getApiEndpoint";
import { requestWithToken } from "../utils/httpRequest";
import { error } from "./error";

// Create Stripe Account
export const createStripeAccount = () => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "POST",
            `${getApiEndpoint()}/stripe/account`
        );
        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Refresh Stripe Account URL
export const refreshStripeAccount = () => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "POST",
            `${getApiEndpoint()}/stripe/account/refresh`
        );
        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Refresh Stripe Account URL
export const stripeAccountStatus = () => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "POST",
            `${getApiEndpoint()}/stripe/account/return`
        );
        
        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};
