import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { postProject } from "../../actions/project";
import InitialForm from "../../components/PostProject/InitialForm/InitialForm";
import FormStep2 from "../../components/PostProject/FormStep2/FormStep2";
import FormStep3 from "../../components/PostProject/FormStep3/FormStep3";
import { setAlert } from "../../actions/alert";
import { useHistory } from "react-router-dom";

import { clearGame } from "../../actions/gameForm";
import styles from "./PostProject.module.css";
import { getActiveAddons } from "../../actions/addon";
import { getSteps } from "../../utils/tourSteps";
import { reacttourUpdate } from "../../actions/reacttour";
import { PostProjectContext } from "../../contexts/PostProjectContext";

const PostProject = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    // const tourSteps = useSelector((state) => state.reacttour.steps);
    const authState = useSelector((state) => state.auth);
    const gameCustomizerState = useSelector((state) => state.gameCustomizer);

    const [addonOptions, setAddonOptions] = useState([]);
    const [selectedAddons, setSelectedAddons] = useState([]);
    const [selectedAddonPrice, setSelectedAddonPrice] = useState(0);
    const [loading, setLoading] = useState(false);
    const [step, setstep] = useState("step1");

    // const { setIsOpen, setSteps, setCurrentStep } = useTour();

    useEffect(() => {
        (async () => {
            const addons = await dispatch(getActiveAddons());
            setAddonOptions(addons);
        })();

        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(reacttourUpdate(getSteps("postProjectSteps1")));

        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, []);

    const getAddonPrice = () => {
        let totalPrice = 0;
        if (selectedAddons.length !== 0) {
            for (let i = 0; i < selectedAddons.length; i++) {
                totalPrice = totalPrice + selectedAddons[i].price.amount;
            }
        }
        return totalPrice;
    };

    useEffect(() => {
        const price = getAddonPrice();
        setSelectedAddonPrice(price);

        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [selectedAddons]);

    const [formData, setFormData] = useState({
        title: "",
        description: gameCustomizerState.description,
        skills: [],
        currency: "USD",
        amount: gameCustomizerState.budget,
        isAgreementAddon: false,
        isRecruiterAddon: false,
        isFeaturedAddon: false,
        isCustomAgreement: false,
        agreementName: "",
        preAgreements: "",
        paymentMode: "wallet",
    });
    const [file, setFile] = useState(null);

    const onChangeValue = (e) => {
        if (e.target.min && e.target.value < e.target.min) {
            setFormData({ ...formData, [e.target.name]: e.target.min });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const onChangeChecked = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.checked });
    };

    const onSelectPreAgreements = (e) => {
        console.log(e.target);
        setFormData({
            ...formData,
            [e.target.name]: [e.target.value],
        });
    };

    useEffect(() => {
        (async () => {
            await dispatch(clearGame());
        })();

        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, []);

    const onProjectFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const data = new FormData();
        if (formData.isCustomAgreement && file !== null) {
            formData.preAgreements = [];
            Object.values(file).forEach((el) => {
                data.append("agreements", el);
            });
        }

        data.append("title", formData.title);
        data.append("description", formData.description);
        data.append("skills", JSON.stringify(formData.skills));
        data.append("currency", formData.currency);
        data.append("amount", formData.amount);
        data.append("isAgreementAddon", formData.isAgreementAddon);
        data.append("isRecruiterAddon", formData.isRecruiterAddon);
        data.append("isFeaturedAddon", formData.isFeaturedAddon);
        data.append("isCustomAgreement", formData.isCustomAgreement);
        data.append("preAgreements", formData.preAgreements);
        data.append("agreementName", formData.agreementName);
        data.append("paymentMode", formData.paymentMode);

        const res = await dispatch(postProject(data));
        if (res.status === 1) {
            dispatch(setAlert(res.msg, "success"));
            history.push(`/project/${res.data._id}/control-center`);
        } else {
            dispatch(setAlert(res.msg, "danger"));
        }

        setLoading(false);
    };

    const postProjectContextData = {
        step,
        addonOptions,
        setstep,
        onProjectFormSubmit,
    };

    return (
        <PostProjectContext.Provider value={postProjectContextData}>
            <Fragment>
                <div className="container form-page-layout py-5">
                    <div className="row py-5">
                        {/* <div className="form-img"></div> */}
                        <div className="col-md-5 d-none d-md-block ">
                            <section className="form-img-section p-4 my-md-3">
                                <img
                                    className={`${styles["form-img"]}`}
                                    src="/img/form-svg-2.svg"
                                    alt="side"
                                ></img>
                            </section>
                        </div>
                        <div className="col-md-7">
                            <section
                                className={`${styles["new-project"]} light-bg p-4 mb-3`}
                            >
                                <h4 className="project-form-title text-center mb-2">
                                    Post Your Project
                                </h4>
                                {!authState.authenticated && (
                                    <div
                                        className="alert alert-danger text-center"
                                        role="alert"
                                    >
                                        You need to login to post a project.
                                    </div>
                                )}

                                <div className="project-form">
                                    <Fragment>
                                        {step === "step1" ? (
                                            <InitialForm
                                                loading={loading}
                                                formData={formData}
                                                setFormData={setFormData}
                                                onChangeCallback={onChangeValue}
                                                onNext={() => setstep("step2")}
                                                onSubmit={onProjectFormSubmit}
                                                addonOptions={addonOptions}
                                            />
                                        ) : step === "step2" ? (
                                            <FormStep2
                                                loading={loading}
                                                formData={formData}
                                                setFormData={setFormData}
                                                onChangeCallback={onChangeValue}
                                                onBack={() => setstep("step1")}
                                                onNext={() => setstep("step3")}
                                                onSubmit={onProjectFormSubmit}
                                                onChangeCheckedCallback={
                                                    onChangeChecked
                                                }
                                                addonOptions={addonOptions}
                                                selectedAddons={selectedAddons}
                                                setSelectedAddons={
                                                    setSelectedAddons
                                                }
                                                selectedAddonPrice={
                                                    selectedAddonPrice
                                                }
                                            />
                                        ) : step === "step3" ? (
                                            <FormStep3
                                                loading={loading}
                                                formData={formData}
                                                setFormData={setFormData}
                                                onChangeCallback={onChangeValue}
                                                onBack={() => setstep("step2")}
                                                onSubmit={onProjectFormSubmit}
                                                onChangeCheckedCallback={
                                                    onChangeChecked
                                                }
                                                file={file}
                                                setFile={setFile}
                                                addonOptions={addonOptions}
                                                selectedAddons={selectedAddons}
                                                setSelectedAddons={
                                                    setSelectedAddons
                                                }
                                                selectedAddonPrice={
                                                    selectedAddonPrice
                                                }
                                                onSelectPreAgreements={
                                                    onSelectPreAgreements
                                                }
                                            />
                                        ) : (
                                            <Fragment></Fragment>
                                        )}
                                    </Fragment>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </Fragment>
        </PostProjectContext.Provider>
    );
};

export default PostProject;
