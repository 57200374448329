import { useTour } from "@reactour/tour";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    toggleDirectTutorial,
    toggleTutorial,
} from "../../../actions/reacttour";

const WelcomeModal = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { setIsOpen } = useTour();
    const authState = useSelector((state) => state.auth);

    const [showModal, setShowModal] = useState(
        authState.user?.tutorial || false
    );

    useEffect(() => {
        setShowModal(authState?.user?.tutorial);
    }, [authState]);

    const loadTutorial = async () => {
        setShowModal(false);
        await dispatch(toggleTutorial(false));
        history.replace("/dashboard");
        await dispatch(toggleDirectTutorial(true));
        setIsOpen(true);
    };

    const onCancelTutorial = () => {
        setShowModal(false);
        dispatch(toggleTutorial(false));
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            contentClassName="dark-bg"
            show={showModal}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <img
                        src="/img/landing/logo.png"
                        alt="logo"
                        style={{ width: "50px", marginRight: "10px" }}
                    />
                    Welcome To Trinity !
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="text-center p-3">
                    Welcome to trinity if your new here you can take a look at
                    this tutorial to easily understand all the possibilities
                    here...
                </p>
                <h4>Do you want a platform tutorial ? </h4>
                <div className="d-flex">
                    <button
                        className="btn btn-secondary flex-grow-1 me-1"
                        onClick={onCancelTutorial}
                    >
                        No
                    </button>
                    <button
                        className="btn btn-primary-gradient flex-grow-1"
                        onClick={loadTutorial}
                    >
                        Yes
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default WelcomeModal;
