import { requestWithToken } from "./../utils/httpRequest";
import { getApiEndpoint } from "../utils/getApiEndpoint";
import {
	NEW_PROJECT_NOTIFICATION,
	ON_ERROR,
	REMOVE_PROJECT_NOTIFICATION,
} from "./types";
import { error } from "./error";

// Fetch My Projects
export const fetchMyProjects = () => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"GET",
			`${getApiEndpoint()}/project/my-projects`
		);

		return response.data;
	} catch (err) {
		dispatch({
			type: ON_ERROR,
			payload: null,
		});
		return err.response.data;
	}
};

// Fetch All Projects
export const fetchProjects = (formData, page, limit) => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"POST",
			`${getApiEndpoint()}/project/fetch?page=${page}&limit=${limit}`,
			formData
		);
		return response;
	} catch (err) {
		dispatch({
			type: ON_ERROR,
			payload: null,
		});
		return err.response.data;
	}
};

// Fetch Recommended Projects
export const fetchRecommendedProjects = (page, limit) => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"GET",
			`${getApiEndpoint()}/project/recommended?page=${page}&limit=${limit}`
		);
		return response;
	} catch (err) {
		dispatch({
			type: ON_ERROR,
			payload: null,
		});
		return err.response.data;
	}
};

//Fetch User Skills
export const fetchSkillsById = (user_id) => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"GET",
			`${getApiEndpoint()}/skills/${user_id}`
		);
		return response.data;
	} catch (err) {
		dispatch({
			type: ON_ERROR,
			payload: null,
		});
		return err.response.data;
	}
};

// Fetch Project Detail With Id
export const fetchProjectById = (projectId) => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"GET",
			`${getApiEndpoint()}/project/${projectId}`
		);
		return response.data;
	} catch (err) {
		dispatch({
			type: ON_ERROR,
			payload: null,
		});
		return err.response.data;
	}
};

// Update Project Detail With Id
export const updateProjectById = (projectId, formData) => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"PUT",
			`${getApiEndpoint()}/project/${projectId}/update`,
			formData
		);
		return response.data;
	} catch (err) {
		dispatch({
			type: ON_ERROR,
			payload: null,
		});
		return err.response.data;
	}
};

// Post New Project
export const postProject = (formData) => async (dispatch) => {
	try {
		const resposne = await requestWithToken(
			"POST",
			`${getApiEndpoint()}/project`,
			formData
		);
		return resposne.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};

// Create project for Game configrator
export const configureProject = (data) => async (dispatch) => {
	try {
		const res = await requestWithToken(
			"POST",
			`${getApiEndpoint()}/project/game-customiser`,
			data
		);

		// dispatch({
		// 	type: GET_PROJECT,
		// 	payload: res.data,
		// });
		if (res.status === 200) {
			return res.data;
		}
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};

export const addNewProjectNotification = (project) => (dispatch) => {
	let payload = {
		id: project._id,
		title: project.title,
		description: project.description,
		budget: project.budget,
	};

	dispatch({
		type: NEW_PROJECT_NOTIFICATION,
		payload,
	});

	setTimeout(
		() =>
			dispatch({
				type: REMOVE_PROJECT_NOTIFICATION,
				payload: project._id,
			}),
		10000
	);
};

// Hire developer for a project
export const hireDeveloper = (formData) => async (dispatch) => {
	try {
		const resposne = await requestWithToken(
			"POST",
			`${getApiEndpoint()}/developer/hire`,
			formData
		);
		return resposne.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};

// Developer action on hire request
export const hireDeveloperAction = (formData) => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"POST",
			`${getApiEndpoint()}/developer/hire/action`,
			formData
		);
		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};

// Fetch project activities by type
export const fetchProjectActivitiesByType =
	(projectId, milestoneId, type) => async (dispatch) => {
		try {
			let endpoint = `${getApiEndpoint()}/project-activity?projectId=${projectId}`;

			if (milestoneId) {
				endpoint += `&milestoneId=${milestoneId}`;
			}
			if (type) {
				endpoint += `&type=${type}`;
			}

			const response = await requestWithToken("GET", endpoint);

			return response.data;
		} catch (err) {
			dispatch(error(err));
			return err.response.data;
		}
	};

// Fetch project attachments
export const fetchProjectAgreements = (projectId) => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"GET",
			`${getApiEndpoint()}/project/${projectId}/agreements`
		);

		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};

// Upload project attachment
export const uploadProjectAttachment =
	(projectId, formData) => async (dispatch) => {
		try {
			const response = await requestWithToken(
				"POST",
				`${getApiEndpoint()}/project/${projectId}/attachment`,
				formData
			);

			return response.data;
		} catch (err) {
			dispatch(error(err));
			return err.response.data;
		}
	};

// Fetch project attachments
export const fetchProjectAttachments = (projectId) => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"GET",
			`${getApiEndpoint()}/project/${projectId}/attachment`
		);

		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};

// Delete project attachment
export const deleteProjectAttachment =
	(projectId, formData) => async (dispatch) => {
		try {
			const response = await requestWithToken(
				"DELETE",
				`${getApiEndpoint()}/project/${projectId}/attachment`,
				formData
			);

			return response.data;
		} catch (err) {
			dispatch(error(err));
			return err.response.data;
		}
	};

// Download project attachment
export const downloadProjectAttachment =
	(projectId, formData) => async (dispatch) => {
		try {
			// const response = await axios.post(
			//     `${getApiEndpoint()}/project/${projectId}/attachment/download`,
			//     formData,
			//     {
			//         headers: {
			//             "x-auth-token": localStorage.getItem("auth-token"),
			//         },
			//         responseType: "blob",
			//     }
			// );
			const response = await requestWithToken(
				"POST",
				`${getApiEndpoint()}/project/${projectId}/attachment/download`,
				formData,
				{ responseType: "blob" }
			);

			return response.data;
		} catch (err) {
			dispatch(error(err));
			return err.response.data;
		}
	};

// Fetch project budget summary
export const fetchProjectBudgetSummary = (projectId) => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"GET",
			`${getApiEndpoint()}/project/${projectId}/budget/summary`
		);

		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};

// Increase project budget
export const depositProjectBudget =
	(projectId, formData) => async (dispatch) => {
		try {
			const response = await requestWithToken(
				"POST",
				`${getApiEndpoint()}/project/${projectId}/budget/deposit`,
				formData
			);

			return response.data;
		} catch (err) {
			dispatch(error(err));
			return err.response.data;
		}
	};

// Withdraw project budget
export const withdrawProjectBudget =
	(projectId, formData) => async (dispatch) => {
		try {
			const response = await requestWithToken(
				"POST",
				`${getApiEndpoint()}/project/${projectId}/budget/withdraw`,
				formData
			);

			return response.data;
		} catch (err) {
			dispatch(error(err));
			return err.response.data;
		}
	};

// Change project status
export const changeProjectStatus = (projectId, status) => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"POST",
			`${getApiEndpoint()}/project/${projectId}/status/${status}`
		);

		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};

// Mark project as complete
export const markProjectComplete = (projectId) => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"POST",
			`${getApiEndpoint()}/project/${projectId}/mark-complete`
		);

		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};

// Mark project as complete action
export const markProjectCompleteAction =
	(projectId, formData) => async (dispatch) => {
		try {
			const response = await requestWithToken(
				"POST",
				`${getApiEndpoint()}/project/${projectId}/mark-complete/action`,
				formData
			);

			return response.data;
		} catch (err) {
			dispatch(error(err));
			return err.response.data;
		}
	};
