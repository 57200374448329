import moment from "moment";
import { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import {
	fetchProjectActivitiesByType,
	fetchProjectAttachments,
	uploadProjectAttachment,
} from "../../actions/project";
import AttachFileModal from "../Modals/AttachFileModal/AttachFileModal";
import styles from "./ProjectControlCenter.module.css";
import ProjectAttachmentItem from "../ProjectAttachmentItem/ProjectAttachmentItem";
import { setAlert } from "../../actions/alert";
import ChangeProjectStatusModal from "../Modals/ChangeProjectStatusModal/ChangeProjectStatusModal";
import { ProjectControlCenterContext } from "../../contexts/ProjectControlCenterContext";
import MarkAsCompleteModal from "../Modals/MarkAsCompleteModal/MarkAsCompleteModal";
import EditProjectModal from "../Modals/EditProjectModal/EditProjectModal";

const DetailsTab = ({ projectId }) => {
	const dispatch = useDispatch();
	const projectControlCenterContext = useContext(ProjectControlCenterContext);

	const authState = useSelector((state) => state.auth);

	const [loadingActivities, setLoadingActivities] = useState(true);
	const [editProjectModalOpen, setEditProjectModalOpen] = useState(false);
	const [projectStatusModalOpen, setProjectStatusModalOpen] = useState(false);
	const [markCompleteModalOpen, setMarkCompleteModalOpen] = useState(false);
	const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
	const [activities, setActivities] = useState([]);

	useEffect(() => {
		const fetchRecentActivities = async () => {
			const response = await dispatch(
				fetchProjectActivitiesByType(projectId)
			);
			if (response.status) {
				setActivities(response.data);
			}

			setLoadingActivities(false);
		};

		//

		projectControlCenterContext.setProjectAttachments(
			projectControlCenterContext.project.attachments
		);
		fetchRecentActivities();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectId]);

	const reloadProjectAttachments = async () => {
		const response = await dispatch(fetchProjectAttachments(projectId));
		if (response.status) {
			projectControlCenterContext.setProjectAttachments(response.data);
		}
	};

	const onUploadAttachment = async (files) => {
		const formData = new FormData();
		files.forEach((file) => {
			formData.append("files", file.file);
		});

		const response = await dispatch(
			uploadProjectAttachment(projectId, formData)
		);
		if (response.status) {
			setAttachmentModalOpen(false);
			dispatch(setAlert(response.msg, "success"));
			// projectControlCenterContext.fetchProjectDetails();
			reloadProjectAttachments();
		} else {
			dispatch(setAlert(response.msg, "danger"));
			return response;
		}
	};

	const handleCompleteActionBtnClick = (status) => {
		if (status === "completed") {
			dispatch(
				setAlert("Project is already marked as complete!", "success")
			);
		} else {
			setMarkCompleteModalOpen(true);
		}
	};

	return (
		<Fragment>
			{projectControlCenterContext.project && (
				<Fragment>
					<div className="d-flex my-lg-3 mb-3">
						<div className="flex-sm-grow-1">
							<h1 className="job-title mb-3">
								{projectControlCenterContext.project.title}
							</h1>
							<ul className="job-skills ps-0">
								{projectControlCenterContext.project.skills.map(
									(skill, index) => {
										return (
											<Fragment key={index}>
												<li className="badge-filled">
													{skill.name}
												</li>
											</Fragment>
										);
									}
								)}
							</ul>
						</div>
						<div className="job-action align-self-center">
							{projectControlCenterContext.project.user._id ===
							authState.user._id ? (
								<div className="d-flex align-items-start gap-2">
									<p
										className="btn-primary-gradient outline px-3 rounded-pill pointer d-flex align-items-center"
										onClick={() =>
											setEditProjectModalOpen(true)
										}
									>
										<FiEdit className="me-1" size={14} />
										Edit
									</p>
									<p
										className={`${
											styles["project-status"]
										} ${
											styles[
												projectControlCenterContext
													.project.status
											]
										} d-flex text-capitalize mb-2 px-3`}
										onClick={() =>
											setProjectStatusModalOpen(true)
										}
									>
										<span className="flex-grow-1">
											{
												projectControlCenterContext
													.project.status
											}
										</span>
										<span className="material-icons">
											arrow_drop_down
										</span>
									</p>

									{/* Edit Project Modal */}
									<EditProjectModal
										isOpen={editProjectModalOpen}
										onClose={() => {
											projectControlCenterContext.fetchProjectDetails();
											setEditProjectModalOpen(false);
										}}
										project={
											projectControlCenterContext.project
										}
									/>

									{/* Project Status Modal */}
									<ChangeProjectStatusModal
										isOpen={projectStatusModalOpen}
										onClose={() => {
											projectControlCenterContext.fetchProjectDetails();
											setProjectStatusModalOpen(false);
										}}
										projectId={projectId}
										currentStatus={
											projectControlCenterContext.project
												.status
										}
									/>
								</div>
							) : (
								<>
									<p
										className={`${
											styles["project-status"]
										} ${
											styles[
												projectControlCenterContext
													.project.myBid?.status
											]
										} d-flex text-capitalize mb-2 px-3`}
										onClick={() =>
											handleCompleteActionBtnClick(
												projectControlCenterContext
													.project.myBid?.status
											)
										}
									>
										<span className="flex-grow-1">
											{projectControlCenterContext.project
												.myBid?.status === "completed"
												? "Completed"
												: "Mark as Complete"}
										</span>
										{projectControlCenterContext.project
											.myBid?.status !== "completed" && (
											<span className="material-icons">
												arrow_drop_down
											</span>
										)}
									</p>

									{/* Mark As Complete Modal */}
									<MarkAsCompleteModal
										isOpen={markCompleteModalOpen}
										onClose={() => {
											setMarkCompleteModalOpen(false);
										}}
										projectId={projectId}
									/>
								</>
							)}

							<h2 className="job-rate text-end">
								${" "}
								{
									projectControlCenterContext.project.budget
										.amount
								}
							</h2>
						</div>
					</div>
					<p className="job-inner-body mb-5">
						{projectControlCenterContext.project.description}
					</p>
					<div className="mb-5">
						<h4>Attachments</h4>
						<hr></hr>
						<div className="row w-100 m-0 g-3">
							<Fragment>
								{projectControlCenterContext.projectAttachments.map(
									(attachment, index) => {
										return (
											<ProjectAttachmentItem
												key={index}
												projectId={projectId}
												attachment={attachment}
												refreshAttachments={
													reloadProjectAttachments
												}
											/>
										);
									}
								)}
								{projectControlCenterContext.project.user
									._id === authState.user._id && (
									<div
										className={`${styles["add-attachment-container"]} col-md-4 text-center py-5`}
										onClick={() => {
											setAttachmentModalOpen(true);
										}}
									>
										<span className="material-icons text-muted">
											add_circle
										</span>
										<p className="text-muted">
											Add Attachment
										</p>
									</div>
								)}
							</Fragment>
						</div>
					</div>
					<div>
						<h4>Recent Activities</h4>
						<hr></hr>
						<div>
							{!loadingActivities ? (
								<Fragment>
									{activities.length > 0 ? (
										<Fragment>
											{activities.map(
												(activity, index) => {
													return (
														<div
															key={index}
															className={`${styles["project-activity"]} light-bg p-3 mb-3`}
														>
															<h6 className="mb-0">
																{activity.title}{" "}
																by{" "}
																<Link className="color-primary">
																	{
																		activity
																			.user
																			.name
																	}
																</Link>
															</h6>
															<small className="text-muted">
																@{" "}
																{moment(
																	activity.createdAt
																).format(
																	"DD MMM YYYY, hh:mm A"
																)}
															</small>
															<div
																className={`${styles["description"]} text-muted mt-2`}
																dangerouslySetInnerHTML={{
																	__html: activity.description,
																}}
															></div>
														</div>
													);
												}
											)}
										</Fragment>
									) : (
										<h5 className="text-center text-muted mt-5">
											No Recent Activities Found!
										</h5>
									)}
								</Fragment>
							) : (
								<h5 className="text-center text-muted mt-5">
									Loading Activities...
								</h5>
							)}
						</div>
					</div>
				</Fragment>
			)}
			{/* Attach File Modal */}
			<AttachFileModal
				isOpen={attachmentModalOpen}
				handleClose={setAttachmentModalOpen}
				handleSubmit={onUploadAttachment}
			/>
		</Fragment>
	);
};

export default DetailsTab;
