import { Tab, Tabs } from "@material-ui/core";
import React, { Fragment } from "react";
import Skeleton from "../../../components/Skeleton/Skeleton";

const skeleton = () => {
    return (
        <Fragment>
            <div className="container" id="project-details">
                <div className="row">
                    <div className="col-lg-9 px-lg-2 m-0">
                        <section
                            style={{
                                height: "59px",
                                margin: "16px 0",
                                alignItems: "center",
                            }}
                            className="d-flex my-lg-3 mb-3"
                        >
                            <div className="flex-sm-grow-1">
                                <h1
                                    style={{ width: "65%" }}
                                    className="job-title mb-3"
                                >
                                    <Skeleton />
                                </h1>
                            </div>
                            <div className="job-action align-self-center">
                                <h2 className="job-rate">
                                    <Skeleton />
                                </h2>
                            </div>
                        </section>
                        <section
                            style={{ height: "324px" }}
                            className="job-description p-4 light-bg my-lg-3 mb-3"
                        >
                            <div className="d-flex justify-content-between mb-3">
                                <Tabs value={0}>
                                    <Tab label="Project Details" />
                                    <Tab label="Bids" />
                                </Tabs>
                            </div>
                            <div
                                className="tab-content"
                                id="project-tabContent"
                            >
                                <div className="tab-pane fade show active">
                                    <div className="job-description-sec my-4">
                                        <h3
                                            style={{
                                                margin: "24px 0",
                                                width: "65%",
                                            }}
                                            className="job-inner-head mb-4"
                                        >
                                            <Skeleton />
                                        </h3>
                                        <div className="job-inner-body">
                                            <Skeleton />
                                        </div>
                                    </div>
                                    <div className="job-description-sec">
                                        <Fragment>
                                            <div className="job-inner-body">
                                                <small className="job-inner-body text-muted">
                                                    <Skeleton height="60px" />
                                                </small>
                                            </div>
                                        </Fragment>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="col-lg-3 px-lg-2 m-0">
                        <section className="light-bg p-4 my-lg-3 mb-3">
                            <h5 className="sec-title text-center text-capitalize mb-4">
                                Job Activity
                            </h5>
                            <div className={`d-flex mb-2`}>
                                <p className="font-weight-bold flex-grow-1 mb-0">
                                    <b>Total Bids:</b>
                                </p>
                                <div
                                    className={`mb-0`}
                                    style={{ width: `40px ` }}
                                >
                                    <Skeleton />
                                </div>
                            </div>
                            <div className="d-flex mb-2">
                                <p className="font-weight-bold flex-grow-1 mb-0">
                                    <b>Avg. Bid Amount:</b>
                                </p>
                                <div
                                    className="mb-0"
                                    style={{ width: `40px ` }}
                                >
                                    <Skeleton />
                                </div>
                            </div>
                            <div className="d-flex mb-2">
                                <b className="font-weight-bold flex-grow-1 mb-0">
                                    Deposited Amount:
                                </b>
                                <div
                                    className="mb-0"
                                    style={{ width: `40px ` }}
                                >
                                    <Skeleton />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default skeleton;
