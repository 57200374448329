import React, { Fragment } from "react";

import styles from "./Projects.module.css";

const Projects = () => {
    return (
        <Fragment>
            <div className="py-5">
                <h2 className="text-center section-title mb-4">
                    Our Previous Works
                </h2>
                <ul
                    className={`nav nav-tabs border-bottom-0 text-center container ${styles["tabs"]}`}
                    id="projectsTab"
                    role="tablist"
                >
                    <li
                        className={`${styles["list-items"]} mx-auto nav-items`}
                        role="presentation"
                    >
                        <a
                            href="#"
                            className="p-2  pl-3 nav-link active current"
                            id="projects-tab "
                            data-bs-toggle="tab"
                            data-bs-target="#projects"
                            type="button"
                            role="tab"
                            aria-controls="projects"
                            aria-selected="false"
                        >
                            Projects
                        </a>
                    </li>
                    <li
                        className={`${styles["list-items"]} mx-auto nav-items`}
                        role="presentation"
                    >
                        <a
                            className=" p-2  nav-link current"
                            id="testimonials-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#testimonials"
                            type="button"
                            role="tab"
                            aria-controls="testimonials"
                            aria-selected="false"
                        >
                            Testimonials
                        </a>
                    </li>
                </ul>
                <div className="tab-content" id="projectsTabContent">
                    <div
                        className="tab-pane fade show active"
                        id="projects"
                        role="tabpanel"
                        aria-labelledby="projects-tab"
                    >
                        {/* <section
                        id={styles['our-projects-section']}
                        className='extra-padding pt-5'
                    >
                        <div className='container'>
                            <div className='row'>
                                <div
                                    className='col-6'
                                    // style={{
                                    // 	backgroundImage: "url(" + projectsData[activeProject].image + ")",
                                    // }}
                                    id='carouselExampleControl'
                                    name='carousel slide'
                                    data-bs-ride='carousel'
                                >
                                    {projectsData[activeProject].media.length >
                                    1 ? (
                                        <>
                                            <div className='carousel-inner'>
                                                <div className='carousel-item active'>
                                                    <img
                                                        src={
                                                            projectsData[
                                                                activeProject
                                                            ].media[0].url
                                                        }
                                                        className='d-block w-100'
                                                        alt='...'
                                                    />
                                                </div>
                                                {projectsData[
                                                    activeProject
                                                ].media.map((media, index) => {
                                                    if (index > 0) {
                                                        return (
                                                            <>
                                                                <div
                                                                    key={index}
                                                                    className='carousel-item'
                                                                >
                                                                    <img
                                                                        src={
                                                                            media.url
                                                                        }
                                                                        className='d-block w-100'
                                                                        alt='...'
                                                                    />
                                                                </div>
                                                            </>
                                                        );
                                                    }
                                                })}
                                            </div>
                                            <a
                                                className='carousel-control-prev'
                                                href='#carouselExampleControl'
                                                role='button'
                                                data-bs-slide='prev'
                                            >
                                                <span
                                                    className='carousel-control-prev-icon'
                                                    aria-hidden='true'
                                                ></span>
                                                <span className='sr-only'>
                                                    Previous
                                                </span>
                                            </a>
                                            <a
                                                className='carousel-control-next'
                                                href='#carouselExampleControl'
                                                role='button'
                                                data-bs-slide='next'
                                            >
                                                <span
                                                    className='carousel-control-next-icon'
                                                    aria-hidden='true'
                                                ></span>
                                                <span className='sr-only'>
                                                    Next
                                                </span>
                                            </a>
                                        </>
                                    ) : (
                                        <>
                                            <img
                                                src={
                                                    projectsData[activeProject]
                                                        .media[0].url
                                                }
                                                alt=''
                                                width='100%'
                                                height='100%'
                                            ></img>
                                        </>
                                    )}
                                </div>

                                <div
                                    className={`${styles['content-container']} ${styles.transparent} ${styles['curved-border']} p-5 col-6`}
                                >
                                    <h4 className='mb-4'>
                                        {projectsData[activeProject].title}
                                    </h4>
                                    <p className='mb-3'>
                                        {
                                            projectsData[activeProject]
                                                .description
                                        }
                                    </p>
                                </div>
                            </div>
                            <div
                                id='projects-carousel'
                                className='owl-carousel owl-theme py-4 container'
                            >
                                <Carousel itemsToShow={5}>
                                    {projectsData.map((project, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={
                                                    Number(index) ===
                                                    Number(activeProject)
                                                        ? `item ${styles['active-carousel-item']} p-2`
                                                        : 'item p-2'
                                                }
                                                onClick={() =>
                                                    changeActiveProject(index)
                                                }
                                            >
                                                <img
                                                    src={project.media[0].url}
                                                    alt=''
                                                    height='100%'
                                                />
                                            </div>
                                        );
                                    })}
                                </Carousel>
                            </div>
                        </div> 
                    </section>*/}

                        <section
                            id={styles["our-projects-section"]}
                            className="pt-5"
                        >
                            <div
                                id="Project-carousel"
                                className="carousel slide"
                                data-bs-ride="carousel"
                            >
                                <div className="carousel-inner">
                                    <div
                                        className={`carousel-item active ${styles["c-items"]}`}
                                        // ${styles['c-items']}
                                    >
                                        <div className="container ">
                                            <div className="row w-100">
                                                <div className="col-md-6 ps-5">
                                                    <img
                                                        src="https://cdn.discordapp.com/attachments/707923727001583657/906607329640857620/84861187_575525643178077_7370437266950650660_n.jpg"
                                                        style={{
                                                            objectFit: "cover",
                                                            height: "90%",
                                                            width: "90%",
                                                        }}
                                                        alt="profile"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <p>
                                                        Meltdown is a post
                                                        apocalyptic Third-Person
                                                        Shooter which takes
                                                        place in a nuclear
                                                        winter. Outside, it is
                                                        so cold that humanity
                                                        can only survive in old
                                                        mines, vaults or domes
                                                        which were built by the
                                                        roboteers. The heat from
                                                        within the Earth has
                                                        kept those places warm.
                                                        Your girlfriend is
                                                        outside and you are
                                                        tasked at saving her.
                                                        But in order to do this,
                                                        you have to unite the
                                                        people in the only city
                                                        left to fight of the
                                                        enemy Russian faction
                                                        who takes those alive as
                                                        slaves.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    className="carousel-control-prev pe-0"
                                    type="button"
                                    data-bs-slide="prev"
                                    data-bs-target="#Project-carousel"
                                >
                                    <span
                                        className="carousel-control-prev-icon"
                                        aria-hidden="true"
                                    ></span>
                                    <span className="visually-hidden">
                                        Previous
                                    </span>
                                </button>
                                <button
                                    className="carousel-control-next"
                                    data-bs-target="#Project-carousel"
                                    data-bs-slide="next"
                                >
                                    <span
                                        className="carousel-control-next-icon"
                                        aria-hidden="true"
                                    ></span>
                                    <span className="visually-hidden">
                                        Next
                                    </span>
                                </button>
                            </div>
                        </section>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="testimonials"
                        role="tabpanel"
                        aria-labelledby="testimonials-tab"
                    >
                        <section
                            id={styles["testimonials"]}
                            className="container my-5"
                        >
                            <div
                                id="testimonial-carousel"
                                className="carousel slide"
                                data-bs-ride="carousel"
                                data-bs-interval="10000"
                            >
                                <div className="carousel-inner">
                                    <div
                                        className={`carousel-item active ${styles["c-items"]}`}
                                    >
                                        <div className="d-flex">
                                            <iframe
                                                style={{ width: "100%" }}
                                                height="315"
                                                src="https://www.youtube.com/embed/BN8R8mnFwzU"
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            ></iframe>
                                        </div>
                                        <img
                                            src="/img/landing/user.png"
                                            className={`${styles["testimonial-image"]}`}
                                            alt="profile"
                                        />
                                        <em>
                                            Tobias Leißner Senior Consultant SAP
                                            (CProConlog)
                                        </em>
                                    </div>

                                    <div
                                        className={`carousel-item ${styles["c-items"]}`}
                                    >
                                        <div className="d-flex">
                                            <p className="testimonial-text">
                                                I would like to extend my
                                                appreciation for the creation of
                                                a virtual environement (created
                                                with Unreal Engine 4) for
                                                training purposes. The map will
                                                later be used as part of one of
                                                our training simulation on the
                                                law of armed conflict (IHL) I
                                                found Fabian / NeoxEntertainment
                                                on the internet and got a very
                                                positive impression when we
                                                talked on the phone for the
                                                first time. Fabian also had many
                                                positive reviews and also came
                                                up with an impressive portfolio.
                                                The service helped me a lot,
                                                because I was able to focus on
                                                more important things with my
                                                team of game developers. At the
                                                end, I got a professional well
                                                optimized level quickly and
                                                stress free. I am looking
                                                forward to continue working with
                                                NeoxEntertainment and Trinity in
                                                the future. I really like the
                                                Idea and approach that there are
                                                only selected developers and
                                                artists on Trinity.This way,
                                                quality can be guaranteed and
                                                you only work with
                                                professionals.Also the Project
                                                Coordinators could help people
                                                who are new to game development
                                                or who want to realize bigger
                                                projects. Even though
                                                NeoxEntertainment's services
                                                tend to be in the upper price
                                                range, they are highly
                                                recommended because the quality
                                                and I was always kept up to date
                                                with the creative work. I had
                                                the possibility to directly give
                                                my feedback and ask for
                                                modification that Fabian
                                                implemented the next days.
                                                Fabian has a good eye for detail
                                                and an excellent work ethic. I
                                                recommend NeoxEntertainment to
                                                anyone who needs level design
                                                because he takes the time to
                                                listen to what the customer
                                                wants and does its best to
                                                assist its clients reaching
                                                their goals.
                                            </p>
                                        </div>
                                        <img
                                            src="/img/landing/user.png"
                                            className={`${styles["testimonial-image"]}`}
                                            alt="profile"
                                        />
                                        <em>
                                            Christian head of Virtual Reality
                                            department (ICRC)
                                        </em>
                                    </div>
                                </div>
                                <button
                                    className="carousel-control-prev"
                                    type="button"
                                    data-bs-slide="prev"
                                    data-bs-target="#testimonial-carousel"
                                >
                                    <span
                                        className="carousel-control-prev-icon"
                                        aria-hidden="true"
                                    ></span>
                                    <span className="visually-hidden">
                                        Previous
                                    </span>
                                </button>
                                <button
                                    className="carousel-control-next"
                                    data-bs-target="#testimonial-carousel"
                                    data-bs-slide="next"
                                >
                                    <span
                                        className="carousel-control-next-icon"
                                        aria-hidden="true"
                                    ></span>
                                    <span className="visually-hidden">
                                        Next
                                    </span>
                                </button>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Projects;
