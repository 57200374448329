import React, { Fragment, useState } from "react";

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value + 1); // update the state to force render
}

const SpFileInput = ({
    field,
    onSelectFile,
    fileNames,
    fileUpload,
    sp,
    formName,
    nested = false,
}) => {
    const forceUpdate = useForceUpdate();

    const onChangeFile = (e) => {
        onSelectFile(e, sp.dataName);
        forceUpdate();
    };

    return (
        <Fragment>
            <div className="form-group">
                {nested ? (
                    <h6 className="mt-3 mb-2">{field.fieldName}</h6>
                ) : (
                    <h3 className="my-3">{field.fieldName}</h3>
                )}
                <div className="custom-file">
                    <input
                        className="custom-file-input"
                        autoComplete="off"
                        // multiple="multiple"
                        type={field.fieldType}
                        name={field.dataName}
                        onChange={(e) => onChangeFile(e)}
                        form={formName}
                    />
                    <label
                        className="custom-file-label"
                        htmlFor="inputGroupFile02"
                    >
                        <Fragment>
                            {fileUpload[sp.dataName][field.dataName].length ===
                            0 ? (
                                <Fragment>Choose File</Fragment>
                            ) : (
                                <Fragment>
                                    <Fragment>
                                        {fileNames(field.dataName, sp.dataName)}
                                    </Fragment>
                                </Fragment>
                            )}
                        </Fragment>
                    </label>
                </div>
            </div>
        </Fragment>
    );
};

export default SpFileInput;
