import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog";

import { scheduleNewMeeting } from "../../../actions/scheduledMeeting";
import { setAlert } from "../../../actions/alert";
import {
    CustomizedDialogContent,
    CustomizedDialogTitle,
} from "../CustomizedModal/CustomizedModal";
import LoadingButton from "../../utils/LoadingButton/LoadingButton";

const ScheduleMeetingModal = (props) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        chatroomId: "",
        title: "",
        date: "",
    });

    useEffect(() => {
        setFormData({ ...formData, chatroomId: props.chatroom?._id });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.chatroom]);

    const onChangeValue = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onScheduleMeetingFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const response = await dispatch(scheduleNewMeeting(formData));
        if (response.status) {
            dispatch(setAlert(response.msg, "success"));
            props.setOpen(false);
        } else {
            dispatch(setAlert(response.msg, "danger"));
        }

        setLoading(false);
    };

    return (
        <Fragment>
            <Dialog
                open={props.open}
                onClose={() => props.setOpen(false)}
                maxWidth="sm"
                fullWidth={true}
            >
                <CustomizedDialogTitle id="customized-dialog-title">
                    <h3 className="modal-title text-accent">
                        Schedule New Meeting
                    </h3>
                </CustomizedDialogTitle>
                <CustomizedDialogContent>
                    <form onSubmit={(e) => onScheduleMeetingFormSubmit(e)}>
                        <div className="mb-2">
                            <label className="form-label">Meeting Title</label>
                            <input
                                type="text"
                                className="dark-input"
                                placeholder="Meeting Title"
                                name="title"
                                onChange={(e) => onChangeValue(e)}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Meeting Date</label>
                            <input
                                type="datetime-local"
                                className="dark-input"
                                placeholder="Meeting Date"
                                name="date"
                                onChange={(e) => onChangeValue(e)}
                            />
                        </div>
                        <div className="mb-2 text-end">
                            <LoadingButton
                                text="Schedule Meeting"
                                className="btn btn-primary-gradient"
                                isLoading={loading}
                            />
                        </div>
                    </form>
                </CustomizedDialogContent>
            </Dialog>
        </Fragment>
    );
};

export default ScheduleMeetingModal;
