import React, { Fragment, useState, useEffect } from "react";
import Tilt from "react-tilt";
import styles from "./Dream.module.css";

const Dream = () => {
    const [allCards] = useState([
        {
            title: "Configure Game",
            body: "Our Unique and Powerful game configurator will make the processof Game development simpler then never before. there is no need of any knowledge in the area of game development coding or project management !",
        },
        {
            title: "Hire Coordinator",
            body: "When you also want to outsource the planing process of your game we recoment to also hire a project coordinator who will support you by finding freelancers and he will take over the project planing so you only have one contact person who will help you with everything",
        },
        {
            title: "Watch Process",
            body: "While our Network of Proffessionals will build your game together the developers or project coordinators will provide you videos or pictures of the process every 1-2 days when ever they made some progress. so you can give them direct feedback",
        },
        {
            title: "Get Your Game",
            body: "At the end you will have a final Game which could get released on Steam for example to generate a passive income or bring a smile in other players faces. Maybe you can also make the world to a better place by Connecting Players world wide with your game",
        },
    ]);

    const [currentCardIndex, setCurrentCardIndex] = useState(0);

    const [isHovered, setIsHovered] = useState(false);

    const onPrevious = () => {
        if (currentCardIndex === 0) {
            setCurrentCardIndex(allCards.length - 1);
        } else {
            setCurrentCardIndex(currentCardIndex - 1);
        }
    };

    const onNext = () => {
        if (currentCardIndex === allCards.length - 1) {
            setCurrentCardIndex(0);
        } else {
            setCurrentCardIndex(currentCardIndex + 1);
        }
    };

    // let interval = useRef();

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isHovered) {
                if (currentCardIndex === allCards.length - 1) {
                    setCurrentCardIndex(0);
                } else {
                    setCurrentCardIndex(currentCardIndex + 1);
                }
            }
        }, 10000);

        return () => clearInterval(interval);

        // eslint-disable-next-line
    }, [currentCardIndex, isHovered]);

    return (
        <Fragment>
            <section
                id={styles["realize-dream-section"]}
                className="position-relative py-5 px-3"
            >
                <div className="hexagon-bg"></div>
                <div className="container">
                    <h2 className="section-title">How We Realize Your Dream</h2>
                    <hr />
                    <div className="row w-100">
                        <div className="col-md-4">
                            <div>
                                <ul className={styles["process-steps-list"]}>
                                    {allCards.map((card, i) => {
                                        return (
                                            <li
                                                key={i}
                                                className={
                                                    currentCardIndex === i
                                                        ? `${styles.selected}`
                                                        : ""
                                                }
                                                onClick={() =>
                                                    setCurrentCardIndex(i)
                                                }
                                            >
                                                {/* <div className={styles.overlay}></div> */}
                                                <div className={styles.text}>
                                                    {card.title}
                                                </div>
                                            </li>
                                        );
                                    })}
                                    {/* <li>
                                        <div className={styles.overlay}></div>
                                        Hire Coordinator
                                    </li>
                                    <li>
                                        <div className={styles.overlay}></div>
                                        Watch Process
                                    </li>
                                    <li>
                                        <div className={styles.overlay}></div>
                                        Get Your Game
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-md-8 carousel slide"
                            // data-bs-ride="carousel"
                            // id="carouselExampleInterval"
                        >
                            <div className="carousel-inner">
                                {allCards.map((card, i) => {
                                    return (
                                        <div
                                            key={i}
                                            className={
                                                currentCardIndex === i
                                                    ? `${styles["carousel-item"]} ${styles.active}`
                                                    : `${styles["carousel-item"]}`
                                            }
                                            onMouseEnter={() =>
                                                setIsHovered(true)
                                            }
                                            onMouseLeave={() =>
                                                setIsHovered(false)
                                            }
                                        >
                                            <Tilt
                                                className={`Tilt ${styles.transparent} ${styles["curved-border"]} w-100 p-5`}
                                                options={{
                                                    perspective: 1000,
                                                    scale: 1,
                                                }}
                                            >
                                                <h4 className="Tilt-inner mb-4">
                                                    {card.title}
                                                </h4>
                                                <p className="mb-3">
                                                    {card.body}
                                                </p>
                                            </Tilt>
                                        </div>
                                    );
                                })}
                                {/* <div
                                    className="carousel-item active"
                                    data-bs-interval="10000"
                                >
                                    <Tilt
                                        className={`Tilt ${styles.transparent} ${styles["curved-border"]} w-100 p-5`}
                                        options={{
                                            perspective: 1000,
                                            scale: 1,
                                        }}
                                    >
                                        <h4 className="Tilt-inner mb-4">
                                            Configure Game
                                        </h4>
                                        <p className="mb-3">
                                            Our Unique and Powerful game
                                            configurator will make the process
                                            of Game development simpler then
                                            never before. there is no need of
                                            any knowledge in the area of game
                                            development coding or project
                                            management !
                                        </p>
                                    </Tilt>
                                </div>
                                <div
                                    className="carousel-item "
                                    data-bs-interval="10000"
                                >
                                    <Tilt
                                        className={`Tilt ${styles.transparent} ${styles["curved-border"]} w-100 p-5`}
                                        options={{
                                            perspective: 1000,
                                            scale: 1,
                                        }}
                                    >
                                        <h4 className="Tilt-inner mb-4">
                                            Hire Coordinator
                                        </h4>
                                        <p className="mb-3">
                                            When you also want to outsource the
                                            planing process of your game we
                                            recoment to also hire a project
                                            coordinator who will support you by
                                            finding freelancers and he will take
                                            over the project planing so you only
                                            have one contact person who will
                                            help you with everything
                                        </p>
                                    </Tilt>
                                </div>
                                <div
                                    className="carousel-item"
                                    data-bs-interval="10000"
                                >
                                    <Tilt
                                        className={`Tilt ${styles.transparent} ${styles["curved-border"]} w-100 p-5`}
                                        options={{
                                            perspective: 1000,
                                            scale: 1,
                                        }}
                                    >
                                        <h4 className="Tilt-inner mb-4">
                                            Watch Process
                                        </h4>
                                        <p className="mb-3">
                                            While our Network of Proffessionals
                                            will build your game together the
                                            developers or project coordinators
                                            will provide you videos or pictures
                                            of the process every 1-2 days when
                                            ever they made some progress. so you
                                            can give them direct feedback
                                        </p>
                                    </Tilt>
                                </div>
                                <div
                                    className="carousel-item"
                                    data-bs-interval="10000"
                                >
                                    <Tilt
                                        className={`Tilt ${styles.transparent} ${styles["curved-border"]} w-100 p-5`}
                                        options={{
                                            perspective: 1000,
                                            scale: 1,
                                        }}
                                    >
                                        <h4 className="Tilt-inner mb-4">
                                            Get your Game
                                        </h4>
                                        <p className="mb-3">
                                            At the end you will have a final
                                            Game which could get released on
                                            Steam for example to generate a
                                            passive income or bring a smile in
                                            other players faces. Maybe you can
                                            also make the world to a better
                                            place by Connecting Players world
                                            wide with your game
                                        </p>
                                    </Tilt>
                                </div> */}
                            </div>
                            <button
                                className="carousel-control-prev pe-5"
                                onClick={() => onPrevious()}
                                // type="button"
                                // data-bs-target="#carouselExampleInterval"
                                // data-bs-slide="prev"
                            >
                                <span
                                    className="carousel-control-prev-icon"
                                    aria-hidden="true"
                                ></span>
                                <span className="visually-hidden">
                                    Previous
                                </span>
                            </button>
                            <button
                                className="carousel-control-next ps-3"
                                onClick={() => onNext()}
                                // type="button"
                                // data-bs-target="#carouselExampleInterval"
                                // data-bs-slide="next"
                            >
                                <span
                                    className="carousel-control-next-icon "
                                    aria-hidden="true"
                                ></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default Dream;
