import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../actions/alert";
import { editEducation } from "../../../actions/profile";

const EditEducation = ({ edu, callBackCancel }) => {
    const dispatch = useDispatch();
    const updateCallback = () => {
        callBackCancel(false);
    };
    const [disable, setDisable] = useState(false);
    const [Education] = useState(edu ? edu : "");
    const [formData, setFormData] = useState({
        location: Education.location,
        school: Education.school,
        degree: Education.degree,
        from: Education.from.split("T")[0],
        to: Education.current ? Education.To : Education.to.split("T")[0],
        current: Education.current,
        description: Education.description,
    });
    const { location, school, degree, from, to, current, description } =
        formData;

    const onChangeValue = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onChangeCheckbox = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.checked });
    };

    const onFormSubmit = async (e) => {
        e.preventDefault();
        setDisable(true);
        const response = await dispatch(editEducation(Education._id, formData));
        if (response.status === 1) {
            await dispatch(setAlert(response.msg, "success"));
            callBackCancel(false);
        } else await dispatch(setAlert(response.msg, "danger"));
        setDisable(false);
    };
    return (
        <Fragment>
            <div className="section-body px-3">
                <form onSubmit={(e) => onFormSubmit(e)}>
                    <div className="mb-2">
                        <label className="form-label">Degree</label>
                        <input
                            type="text"
                            className="dark-input"
                            name="degree"
                            placeholder="Degree"
                            value={degree}
                            onChange={(e) => onChangeValue(e)}
                            required
                        />
                    </div>
                    <div className="mb-2">
                        <div className="row">
                            <div className="col-md-6 mb-2 mb-md-0">
                                <label className="form-label">Location</label>
                                <input
                                    type="text"
                                    className="dark-input"
                                    name="location"
                                    placeholder="Location"
                                    value={location}
                                    onChange={(e) => onChangeValue(e)}
                                    required
                                />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">School</label>
                                <input
                                    type="text"
                                    className="dark-input"
                                    name="school"
                                    placeholder="School"
                                    value={school}
                                    onChange={(e) => onChangeValue(e)}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mb-2">
                        <div className="row">
                            <div className="col-md-6 mb-2 mb-md-0">
                                <label className="form-label">From Date</label>
                                <input
                                    type="date"
                                    className="dark-input"
                                    name="from"
                                    value={from}
                                    onChange={(e) => onChangeValue(e)}
                                    required
                                />
                            </div>
                            {formData.current ? (
                                <div className="col-md-6"></div>
                            ) : (
                                <div className="col-md-6">
                                    <label className="form-label">
                                        To Date
                                    </label>
                                    <input
                                        type="date"
                                        className="dark-input"
                                        name="to"
                                        value={to}
                                        onChange={(e) => onChangeValue(e)}
                                        required
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="mb-2">
                        <label className="m-0">
                            <input
                                type="checkbox"
                                name="current"
                                checked={current}
                                onChange={(e) => onChangeCheckbox(e)}
                            />{" "}
                            I am pursuing it
                        </label>
                    </div>
                    <div className="mb-2">
                        <label className="form-label">Description</label>
                        <textarea
                            className="dark-input"
                            name="description"
                            rows="5"
                            placeholder="Describe your work experience"
                            value={description}
                            onChange={(e) => onChangeValue(e)}
                        ></textarea>
                    </div>

                    <div className="section-options mb-3">
                        <button
                            disabled={disable}
                            type="submit"
                            className="btn-save me-2"
                        >
                            Save{" "}
                            {disable ? (
                                <img
                                    src="/img/loader.gif"
                                    alt="loader"
                                    width="25px"
                                />
                            ) : (
                                ""
                            )}
                        </button>
                        <button
                            className="btn-cancel"
                            onClick={(e) => updateCallback()}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </Fragment>
    );
};

export default EditEducation;
