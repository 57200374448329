import React, { Fragment, useEffect, useState } from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { seo } from "../../utils/seo";
import WithdrawRequest from "../../components/Modals/WithdrawRequest/WithdrawRequest";
import PayoutMethod from "../../components/Modals/PayoutMethod/PayoutMethod";
import { useDispatch } from "react-redux";
import { getPayoutMethods } from "../../actions/payout";
import styles from "./WithdrawFunds.module.css";
import { deletePayoutMethod } from "../../actions/payout";
import { fetchWithdrawalRequests } from "../../actions/withdrawRequest";
import { setAlert } from "../../actions/alert";

const WithdrawFunds = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [withdrawalRequests, setWithdrawalRequests] = useState([]);
    const [withdrawalRequestModalOpen, setWithdrawalRequestModalOpen] =
        useState(false);
    const [payoutMethodModalOpen, setPayoutMethodModalOpen] = useState(false);

    useEffect(() => {
        seo({ title: "Withdraw" });
    }, []);

    const [payoutMethodDetails, setpayoutMethodDetails] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        fetchPayoutMethods();

        dispatch(fetchWithdrawalRequests()).then((response) => {
            setWithdrawalRequests(response.data);
        });
        // eslint-disable-next-line
    }, []);

    async function fetchPayoutMethods() {
        const response = await dispatch(getPayoutMethods());
        setpayoutMethodDetails(response.data);
    }

    const deletePayout = async (id) => {
        const deleteResponse = await dispatch(deletePayoutMethod(id));
        if (deleteResponse.status) {
            dispatch(setAlert(deleteResponse.msg, "success"));
            fetchPayoutMethods();
        } else {
            dispatch(setAlert(deleteResponse.msg, "danger"));
        }
    };

    return (
        <Fragment>
            {/* <Navbar /> */}
            <div id={`${styles["withdraw-funds"]}`} className="container py-5">
                <div className="row">
                    <div className="col-md-3">
                        <div
                            className={`nav flex-column nav-pills ${styles["sidebar"]} me-3`}
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical"
                        >
                            <button
                                className={
                                    activeTab === 0
                                        ? `${styles["active"]} ${styles["nav-link"]}`
                                        : `${styles["nav-link"]}`
                                }
                                id="v-pills-home-tab"
                                onClick={(e) => {
                                    setActiveTab(0);
                                }}
                                data-bs-toggle="pill"
                                data-bs-target="#v-pills-home"
                                type="button"
                                role="tab"
                                aria-controls="v-pills-home"
                                aria-selected="true"
                            >
                                Withdraw Requests
                            </button>
                            <button
                                className={
                                    activeTab === 1
                                        ? `${styles["active"]} ${styles["nav-link"]}`
                                        : `${styles["nav-link"]}`
                                }
                                id="v-pills-profile-tab"
                                onClick={(e) => {
                                    setActiveTab(1);
                                }}
                                data-bs-toggle="pill"
                                data-bs-target="#v-pills-profile"
                                type="button"
                                role="tab"
                                aria-controls="v-pills-profile"
                                aria-selected="false"
                            >
                                Payout Method
                            </button>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div
                                className={
                                    activeTab === 0
                                        ? "tab-pane fade show active"
                                        : "tab-pane fade"
                                }
                                id="v-pills-home"
                                role="tabpanel"
                                aria-labelledby="v-pills-home-tab"
                            >
                                <div className="row d-flex align-items-center">
                                    <div className="col-md-9">
                                        <h5>Withdraw Request Details</h5>
                                    </div>
                                    <div className="col-md-3">
                                        <button
                                            className="btn btn-primary-gradient"
                                            onClick={() =>
                                                setWithdrawalRequestModalOpen(
                                                    true
                                                )
                                            }
                                        >
                                            Add New Request
                                        </button>
                                        <WithdrawRequest
                                            isOpen={withdrawalRequestModalOpen}
                                            onClose={() => {
                                                fetchWithdrawalRequests();
                                                setWithdrawalRequestModalOpen(
                                                    false
                                                );
                                            }}
                                        ></WithdrawRequest>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    {withdrawalRequests.length > 0 ? (
                                        <Fragment>
                                            {withdrawalRequests.map(
                                                (request) => {
                                                    return (
                                                        <Fragment>
                                                            <section className="light-bg p-4 my-lg-3">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="d-inline-block me-3">
                                                                            Payout
                                                                            Method:{" "}
                                                                            {
                                                                                request
                                                                                    .payoutMethod
                                                                                    .title
                                                                            }
                                                                        </h6>
                                                                        <p
                                                                            className={`${
                                                                                styles[
                                                                                    "status"
                                                                                ]
                                                                            }  p-1 rounded d-inline-block ${
                                                                                styles[
                                                                                    request
                                                                                        .status
                                                                                ]
                                                                            }`}
                                                                        >
                                                                            {
                                                                                request.status
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                    <p>
                                                                        {request
                                                                            .withdrawalAmount
                                                                            .currency +
                                                                            " " +
                                                                            request
                                                                                .withdrawalAmount
                                                                                .amount}
                                                                    </p>
                                                                </div>
                                                                <div className="d-flex mt-1">
                                                                    <small className="text-capitalize m-0">
                                                                        Created
                                                                        at:{" "}
                                                                        <Moment format="DD/MM/YYYY">
                                                                            {
                                                                                request.createdAt
                                                                            }
                                                                        </Moment>
                                                                    </small>
                                                                </div>
                                                            </section>
                                                        </Fragment>
                                                    );
                                                }
                                            )}
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <p className="text-center text-muted py-4">
                                                No Withdrawal Request!
                                            </p>
                                        </Fragment>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    activeTab === 1
                                        ? "tab-pane fade show active"
                                        : "tab-pane fade"
                                }
                                id="v-pills-profile"
                                role="tabpanel"
                                aria-labelledby="v-pills-profile-tab"
                            >
                                <div>
                                    <div className="row d-flex align-items-center">
                                        <div className="col-md-9">
                                            <h5>Payout Method Details</h5>
                                        </div>
                                        <div className="col-md-3">
                                            <button
                                                className="btn btn-primary-gradient"
                                                onClick={() =>
                                                    setPayoutMethodModalOpen(
                                                        true
                                                    )
                                                }
                                            >
                                                Add Payout Method
                                            </button>
                                            <PayoutMethod
                                                isOpen={payoutMethodModalOpen}
                                                onClose={() => {
                                                    fetchPayoutMethods();
                                                    setPayoutMethodModalOpen(
                                                        false
                                                    );
                                                }}
                                            ></PayoutMethod>
                                        </div>
                                    </div>
                                    <hr />

                                    {payoutMethodDetails.length > 0 ? (
                                        <Fragment>
                                            {payoutMethodDetails.map(
                                                (info, i) => {
                                                    return (
                                                        <Fragment>
                                                            <section className="light-bg p-4 my-lg-3">
                                                                <div
                                                                    className="d-flex align-items-center"
                                                                    style={{
                                                                        gap: "0.5rem",
                                                                    }}
                                                                >
                                                                    <h6 className="flex-grow-1">
                                                                        {
                                                                            info.title
                                                                        }{" "}
                                                                        (
                                                                        {
                                                                            info.payoutType
                                                                        }
                                                                        )
                                                                    </h6>
                                                                    <h6>
                                                                        <div
                                                                            className="d-flex justify-content-center"
                                                                            onClick={() =>
                                                                                deletePayout(
                                                                                    info._id
                                                                                )
                                                                            }
                                                                        >
                                                                            <span className="material-icons">
                                                                                delete
                                                                            </span>
                                                                        </div>
                                                                    </h6>
                                                                </div>
                                                                <div className="d-flex mt-1">
                                                                    {info.payoutType ===
                                                                    "paypal" ? (
                                                                        <Fragment>
                                                                            <div className="d-flex mt-1">
                                                                                <small className="text-capitalize m-0">
                                                                                    Paypal
                                                                                    Email
                                                                                    ID:{" "}
                                                                                    {
                                                                                        info
                                                                                            .payoutDetails
                                                                                            .paypalEmailId
                                                                                    }
                                                                                </small>
                                                                            </div>
                                                                        </Fragment>
                                                                    ) : (
                                                                        <Fragment>
                                                                            <div className="d-flex mt-1">
                                                                                <small className="text-capitalize m-0">
                                                                                    Account
                                                                                    Name:{" "}
                                                                                    {
                                                                                        info
                                                                                            .payoutDetails
                                                                                            .accountName
                                                                                    }
                                                                                </small>
                                                                                <small>
                                                                                    &nbsp;&nbsp;|&nbsp;&nbsp;
                                                                                </small>
                                                                                <small className="text-capitalize m-0">
                                                                                    Account
                                                                                    Number:{" "}
                                                                                    {
                                                                                        info
                                                                                            .payoutDetails
                                                                                            .accountNumber
                                                                                    }
                                                                                </small>
                                                                                <small>
                                                                                    &nbsp;&nbsp;|&nbsp;&nbsp;
                                                                                </small>
                                                                                <small className="text-capitalize m-0">
                                                                                    Branch
                                                                                    Name:{" "}
                                                                                    {
                                                                                        info
                                                                                            .payoutDetails
                                                                                            .branchName
                                                                                    }
                                                                                </small>
                                                                                <small>
                                                                                    &nbsp;&nbsp;|&nbsp;&nbsp;
                                                                                </small>
                                                                                <small className="text-capitalize m-0">
                                                                                    IFSC
                                                                                    Code:{" "}
                                                                                    {
                                                                                        info
                                                                                            .payoutDetails
                                                                                            .ifscCode
                                                                                    }
                                                                                </small>
                                                                            </div>
                                                                        </Fragment>
                                                                    )}
                                                                </div>
                                                            </section>
                                                        </Fragment>
                                                    );
                                                }
                                            )}
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <p className="text-center text-muted py-4">
                                                No Payout Methods Found!
                                            </p>
                                        </Fragment>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default connect(null, {})(WithdrawFunds);
