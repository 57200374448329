import { getApiEndpoint } from "../utils/getApiEndpoint";
import { requestWithToken } from "../utils/httpRequest";
import { error } from "./error";

// Fetch user integrations
export const fetchIntegrations = () => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/integration`
        );

        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Integrate google calendar
export const integrateGoogleCalendar = async () => {
    try {
        const response = await requestWithToken(
            "POST",
            `${getApiEndpoint()}/integration/google/calendar`
        );

        return response.data;
    } catch (err) {
        return err.response.data;
    }
};

// Remove integration
export const removeIntegrations = (formData) => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "DELETE",
            `${getApiEndpoint()}/integration`,
            formData
        );

        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};
