import { Dialog } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import { IoIosHelpCircle } from "react-icons/io";
import { setAlert } from "../../../actions/alert";
import { postBid } from "../../../actions/bid";
import { getAllMilestones } from "../../../actions/milestone";
import LoadingButton from "../../utils/LoadingButton/LoadingButton";
import {
    CustomizedDialogContent,
    CustomizedDialogTitle,
} from "../CustomizedModal/CustomizedModal";
import { IconButton, Tooltip } from "@mui/material";
import { SelectMilestoneTooltip } from "../../Tooltips/Tooltips";

const CreateBidModal = (props) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [milestones, setMilestones] = useState([]);
    const [formData, setFormData] = useState({
        currency: "USD",
        amount: "",
        duration: "",
        scope: "weeks",
        selectedMilestones: [],
        description: "",
    });

    useEffect(() => {
        fetchMilestones();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        currency,
        amount,
        duration,
        scope,
        selectedMilestones,
        description,
    } = formData;

    const onChangeValue = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onChangeDropdownValue = (e, data) => {
        if (data.value.some((item) => item === "all")) {
            const selected = [];
            milestones.forEach((milestone) => {
                if (milestone.value !== "all") {
                    selected.push(milestone.value);
                }
            });
            setFormData({ ...formData, [data.name]: selected });
        } else {
            setFormData({ ...formData, [data.name]: data.value });
        }
    };

    const fetchMilestones = async () => {
        const response = await dispatch(getAllMilestones(props.projectId));
        if (response.status) {
            const milestonesArray = [
                { key: "101", text: "Select All", value: "all" },
            ];

            response.data.map((milestone) => {
                const obj = {
                    key: milestone._id,
                    text: milestone.title,
                    value: milestone._id,
                };
                milestonesArray.push(obj);
                return null;
            });

            setMilestones(milestonesArray);
        }
    };

    const onBidFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const bidData = {
            project: props.projectId,
            currency,
            amount,
            duration,
            scope,
            selectedMilestones,
            description,
        };
        console.log(bidData);

        if (milestones.length > 0 && selectedMilestones.length === 0) {
            dispatch(
                setAlert("Please select atleast one milestone!", "danger")
            );
        }

        const response = await dispatch(postBid(bidData));
        if (response.status) {
            dispatch(setAlert(response.msg, "success"));
            props.setBidSubmitted(true);
            props.onClose();
        } else {
            dispatch(setAlert(response.msg, "danger"));
        }

        setLoading(false);
    };

    return (
        <Fragment>
            <Dialog
                onClose={() => props.onClose(false)}
                aria-labelledby="customized-dialog-title"
                open={props.isOpen}
                maxWidth="sm"
                fullWidth={true}
            >
                <CustomizedDialogTitle id="customized-dialog-title">
                    <h3 className="modal-title text-accent">Create Bid</h3>
                </CustomizedDialogTitle>
                <CustomizedDialogContent>
                    <form onSubmit={(e) => onBidFormSubmit(e)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">
                                        Bid Budget
                                    </label>
                                    <div className="input-group">
                                        <span className="input-group-text borders-right-radius-none d-inline-block">
                                            $
                                        </span>
                                        <input
                                            type="number"
                                            name="amount"
                                            className="dark-input borders-left-radius-none flex-grow-1 w-auto"
                                            placeholder="Bid Budget"
                                            required
                                            onChange={(e) => onChangeValue(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">
                                        Estimated Time
                                    </label>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            name="duration"
                                            className="dark-input borders-right-radius-none flex-grow-1 w-50"
                                            placeholder="Estimated Time"
                                            required
                                            onChange={(e) => onChangeValue(e)}
                                        />
                                        <select
                                            name="scope"
                                            className="form-select dark-input borders-left-radius-none flex-grow-1 w-50"
                                            defaultValue="weeks"
                                            onChange={(e) => onChangeValue(e)}
                                        >
                                            <option value="days">Days</option>
                                            <option value="weeks">Weeks</option>
                                            <option value="months">
                                                Months
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {milestones.length > 0 && (
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">
                                            Select Milestone{" "}
                                            <Tooltip
                                                title={
                                                    <SelectMilestoneTooltip />
                                                }
                                            >
                                                <IconButton className="ps-0">
                                                    <IoIosHelpCircle
                                                        size={18}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </label>
                                        <Dropdown
                                            placeholder="Select Milestones"
                                            className="sementic-dropdown dark-input dropdown-select"
                                            name="selectedMilestones"
                                            multiple
                                            search
                                            selection
                                            options={milestones}
                                            onChange={onChangeDropdownValue}
                                            value={selectedMilestones}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label className="form-label">
                                        Describe Your Proposal
                                    </label>
                                    <textarea
                                        className="dark-input"
                                        name="description"
                                        rows="5"
                                        placeholder="Describe your proposal"
                                        onChange={(e) => onChangeValue(e)}
                                        required
                                    ></textarea>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="text-end mb-3">
                                    <LoadingButton
                                        text="Post Bid"
                                        className="btn btn-primary-gradient"
                                        isLoading={loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </CustomizedDialogContent>
            </Dialog>
        </Fragment>
    );
};

export default CreateBidModal;
