import React, { Fragment, useState } from "react";

import parse from "html-react-parser";

import Radio from "./Radio";
import Checkbox from "./CkeckBox";
import Dropdown from "./Dropdown";
import SpFileInput from "./SpFileInput";
import DefaultInput from "./DefaultInput";

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value + 1); // update the state to force render
}

const SpecialInput = ({
    field,
    onChangeOptions,
    onSelectChecks,
    onSelectFile,
    onChangeValue,
    fileNames,
    fileUpload,
    addedSpecials,
    addToSpecials,
    removeFromSpecials,
    formData,
}) => {
    const showEntered = (inp) => {
        const result = [];

        const keys = Object.keys(inp);
        const values = Object.values(inp);

        // if (values.some((el) => el === "" )) {
        // 	return setAlert("Please file the required Input", "danger");
        // }

        values.forEach((el, i) => {
            let str = "";
            if (Array.isArray(el)) {
                let valueStr = "";
                el.forEach((el) => {
                    // str = str + keys[i] + " - " + values[i]. + "\n"
                    if (valueStr === "") {
                        valueStr = el.optionText;
                    } else {
                        valueStr = valueStr + ", " + el.optionText;
                    }
                });
                str = str + keys[i] + " - " + valueStr;
                result.push(str);
            } else if (typeof el !== "string" && !Array.isArray(el)) {
                str = str + keys[i] + " - " + el.optionText;
                result.push(str);
            } else {
                str = str + keys[i] + " - " + el;
                result.push(str);
            }
        });

        return result;
    };

    const forceUpdate = useForceUpdate();

    const onChangeOptionsForce = (e, dataName, spName) => {
        onChangeOptions(e, dataName, spName);
        forceUpdate();
    };

    const onAdd = (e) => {
        e.preventDefault();
        addToSpecials(field);
        forceUpdate();
    };

    const onRemove = (i) => {
        removeFromSpecials(field, i);
        forceUpdate();
    };

    return (
        <Fragment>
            <div className="form-group">
                <h3 className="my-3">{field.fieldName}</h3>
                {field.fieldDesc !== "" ? (
                    <div className="text-muted mb-3">
                        {parse(field.fieldDesc)}
                    </div>
                ) : (
                    ""
                )}
                <div className=" my-3">
                    <form
                        className="special-input-form"
                        onSubmit={(e) => onAdd(e)}
                    >
                        {field.specialInputs.map((sp) => {
                            return (
                                <Fragment key={sp._id}>
                                    {sp.fieldType === "radio" ? (
                                        <>
                                            <Radio
                                                field={sp}
                                                onChangeOptions={
                                                    onChangeOptionsForce
                                                }
                                                sp={field}
                                                required={true}
                                                nested={true}
                                                formData={
                                                    formData[field.dataName]
                                                }
                                            />
                                        </>
                                    ) : sp.fieldType === "checkbox" ? (
                                        <>
                                            <Checkbox
                                                field={sp}
                                                onSelectChecks={onSelectChecks}
                                                sp={field}
                                                required={true}
                                                nested={true}
                                                formData={
                                                    formData[field.dataName]
                                                }
                                            />
                                        </>
                                    ) : sp.fieldType === "dropdown" ? (
                                        <>
                                            <Dropdown
                                                field={sp}
                                                onChangeOptions={
                                                    onChangeOptions
                                                }
                                                sp={field}
                                                required={true}
                                                nested={true}
                                                formData={
                                                    formData[field.dataName]
                                                }
                                            />
                                        </>
                                    ) : sp.fieldType === "file" ? (
                                        <>
                                            <SpFileInput
                                                sp={field}
                                                field={sp}
                                                onSelectFile={onSelectFile}
                                                fileNames={fileNames}
                                                fileUpload={fileUpload}
                                                nested={true}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <DefaultInput
                                                field={sp}
                                                onChangeValue={onChangeValue}
                                                sp={field}
                                                required={true}
                                                nested={true}
                                                formData={
                                                    formData[field.dataName]
                                                }
                                            />
                                        </>
                                    )}
                                </Fragment>
                            );
                        })}

                        <div>
                            <input
                                className="btn btn-primary-gradient outline mt-3"
                                value="Add this option"
                                type="submit"
                            />
                        </div>
                    </form>
                    <div className="row pt-4 px-2">
                        {addedSpecials[field.dataName].length !== 0 ? (
                            <Fragment>
                                {addedSpecials[field.dataName].map((inp, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <div className="col-md-4 p-1">
                                                <div className="dark-bg p-3">
                                                    <div className="d-flex">
                                                        <h4 className="flex-grow-1">
                                                            {i + 1}
                                                        </h4>
                                                        <div>
                                                            <span
                                                                className="material-icons"
                                                                style={{
                                                                    fontSize:
                                                                        "16px",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() =>
                                                                    onRemove(i)
                                                                }
                                                            >
                                                                close
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="mt-3">
                                                        <ul
                                                            className="px-3"
                                                            style={{
                                                                listStyle:
                                                                    "disc",
                                                            }}
                                                        >
                                                            {showEntered(
                                                                inp
                                                            ).map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <li
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {item}
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    );
                                })}
                            </Fragment>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SpecialInput;
