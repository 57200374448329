import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons/lib";
import { getApiEndpoint } from "../../utils/getApiEndpoint";
import { FaRegStar, FaStar, FaStarHalfAlt } from "react-icons/fa";

const HiredDeveloperListItem = ({ developer }) => {
    const [rating] = useState(0);

    return (
        <li className="light-bg bid-one p-4 mb-3">
            <div className="bid-user-info d-flex align-items-center">
                <img
                    className="profile-pic-md me-3"
                    src={
                        developer.bidOwner.profileImgUrl
                            ? getApiEndpoint() +
                              developer.bidOwner.profileImgUrl
                            : "/img/profile-image.png"
                    }
                    alt="Bid User"
                />
                <div className="bid-user me-3 flex-grow-1">
                    <Link to={`/profile/${developer.user._id}`}>
                        <h5>{developer.user.name}</h5>
                    </Link>
                    <small className="text-muted">
                        {[...Array(Math.floor(rating))].map((rat, index) => {
                            return (
                                <Fragment key={index}>
                                    <IconContext.Provider
                                        value={{
                                            color: "red",
                                        }}
                                    >
                                        <FaStar
                                            className="star justify-content-end align-items-end"
                                            size={20}
                                            style={{
                                                paddingBottom: "4px",
                                            }}
                                        />
                                    </IconContext.Provider>
                                </Fragment>
                            );
                        })}
                        {rating - Math.floor(rating) > 0 ? (
                            <>
                                <FaStarHalfAlt
                                    className="star justify-content-end align-items-end"
                                    size={20}
                                    style={{
                                        paddingBottom: "4px",
                                    }}
                                />
                            </>
                        ) : (
                            ""
                        )}
                        {[...Array(Math.floor(5 - rating))].map(
                            (rat, index) => {
                                return (
                                    <Fragment key={index}>
                                        <FaRegStar
                                            className="star justify-content-end align-items-end"
                                            size={20}
                                            style={{
                                                paddingBottom: "4px",
                                            }}
                                        />
                                    </Fragment>
                                );
                            }
                        )}{" "}
                        ({rating} reviews)
                    </small>
                </div>
            </div>
        </li>
    );
};

export default HiredDeveloperListItem;
