import React, { Fragment, useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import { useDispatch } from "react-redux";
import { getPayoutMethods } from "../../../actions/payout";
import { createWithdrawRequest } from "../../../actions/withdrawRequest";
import { setAlert } from "../../../actions/alert";
import {
    CustomizedDialogContent,
    CustomizedDialogTitle,
} from "../CustomizedModal/CustomizedModal";

const WithdrawRequest = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        amount: "",
        payoutMethodId: "",
    });

    const [payoutMethodDetails, setpayoutMethodDetails] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        async function fetchPayoutMethods() {
            const response = await dispatch(getPayoutMethods());
            if (response.status) {
                setpayoutMethodDetails(response.data);
            }
        }
        fetchPayoutMethods();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeValue = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onWithdrawRequestSubmit = async (e) => {
        e.preventDefault();

        const response = await dispatch(createWithdrawRequest(formData));
        if (response.status) {
            dispatch(setAlert(response.msg, "success"));
            onClose(false);
        } else {
            dispatch(setAlert(response.msg, "danger"));
        }
    };

    return (
        <Fragment>
            <Dialog
                onClose={() => onClose(false)}
                open={isOpen}
                maxWidth="sm"
                fullWidth={true}
            >
                <CustomizedDialogTitle>
                    <h3>Create New Withdrawal Request</h3>
                </CustomizedDialogTitle>
                <CustomizedDialogContent>
                    <form onSubmit={(e) => onWithdrawRequestSubmit(e)}>
                        <div className="mb-1">
                            <label className="form-label">
                                Withdraw Amount
                            </label>
                            <div className="input-group mb-3">
                                <span className="input-group-text borders-right-radius-none d-inline-block">
                                    $
                                </span>
                                <input
                                    type="text"
                                    className="dark-input borders-left-radius-none flex-grow-1 w-auto"
                                    placeholder="Withdraw Amount"
                                    name="amount"
                                    onChange={(e) => onChangeValue(e)}
                                />
                            </div>
                        </div>
                        <div className="mb-2">
                            <label className="form-label">Withdraw To</label>
                            <select
                                className="dark-input"
                                name="payoutMethodId"
                                onChange={(e) => onChangeValue(e)}
                            >
                                <option>Select Payout Method</option>
                                {payoutMethodDetails.map(
                                    (payoutMethod, index) => {
                                        return (
                                            <option
                                                key={index}
                                                value={payoutMethod._id}
                                            >
                                                {payoutMethod.title}
                                            </option>
                                        );
                                    }
                                )}
                            </select>
                        </div>
                        <div className="mb-2 text-end">
                            <button
                                type="submit"
                                className="btn btn-primary-gradient"
                            >
                                Create Request
                            </button>
                        </div>
                    </form>
                </CustomizedDialogContent>
            </Dialog>
        </Fragment>
    );
};

export default WithdrawRequest;
