import moment from "moment";
import { UPDATE_BOT_PANEL, BOT_ERROR } from "../actions/types";

const initialState = {
    loading: false,
    chat: {
        _id: "chatbot",
        users: [
            {
                name: "chatbot",
                userType: "chatbot",
                _id: "chatbot_id",
            },
        ],
        messages: [
            {
                user: {
                    name: "chatbot",
                    userType: "chatbot",
                    _id: "chatbot_id",
                },
                message: "How can i help you?",
                msgType: "message",
                action: {
                    actionType: "message",
                    actionData: {},
                    actionTakenType: "",
                },
                createdAt: moment(),
            },
        ],
    },
};

const chatBot = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_BOT_PANEL:
        case BOT_ERROR:
            return {
                ...state,
                chat: {
                    ...state.chat,
                    messages: [payload, ...state.chat.messages],
                },
            };
        default:
            return state;
    }
};

export default chatBot;
