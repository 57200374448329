import { getApiEndpoint } from "../utils/getApiEndpoint";
import { requestWithToken } from "../utils/httpRequest";
import { error } from "./error";

// Create New Withdrawal Request
export const createWithdrawRequest = (formData) => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "POST",
            `${getApiEndpoint()}/withdraw-requests`,
            formData
        );

        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Fetch User Withdrawal Request
export const fetchWithdrawalRequests = () => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/withdraw-requests`
        );

        return new Promise((resolve, reject) => resolve(response.data));
    } catch (err) {
        dispatch(error(err));
        return new Promise((resolve, reject) => reject(err.response.data));
    }
};
