import React from "react";
import { useSelector } from "react-redux";

// Import Styling
import styles from "./ErrorComponent.module.css";

const ErrorComponent = () => {
    const errorState = useSelector((state) => state.error);

    const getErrorMessage = () => {
        if (errorState.isForbidden) {
            return "Your account is blocked! Please contact customer support!";
        }

        return "Something Went Wrong. Please try again.";
    };

    const reload = () => {
        window.location.reload(false);
    };
    return (
        <div className="position-relative py-5">
            <div className={`${styles["container-box"]} py-5`}>
                <img
                    className="something-wrong"
                    src="/img/owl_blackeye.png"
                    alt="Profile"
                />
                <h1>{getErrorMessage()}</h1>
                <button
                    className="btn btn-primary-gradient"
                    onClick={(e) => reload()}
                >
                    Reload
                </button>
            </div>
        </div>
    );
};

export default ErrorComponent;
