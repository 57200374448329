import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { reacttourUpdate } from "../../../actions/reacttour";
import { getSteps } from "../../../utils/tourSteps";

const FormStep2 = ({
    selectedAddons,
    addonOptions,
    setSelectedAddons,
    selectedAddonPrice,
    ...props
}) => {
    const dispatch = useDispatch();
    // const { setIsOpen, ...rest } = useTour();

    useEffect(() => {}, []);

    useEffect(() => {
        dispatch(reacttourUpdate(getSteps("postProjectSteps2")));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSelectAddon = (e, addon) => {
        if (selectedAddons.some((el) => el._id === addon._id)) {
            const filtered = selectedAddons.filter(
                (el) => el._id !== addon._id
            );
            setSelectedAddons(filtered);
            props.onChangeCheckedCallback(e);
        } else {
            setSelectedAddons([...selectedAddons, addon]);
            props.onChangeCheckedCallback(e);
        }
    };

    return (
        <Fragment>
            <form onSubmit={(e) => props.onSubmit(e)}>
                <span data-tour="step-postproject-1">
                    <h6 className="text-center">Step 2: Select Your Addons</h6>
                    <div className="form-group py-3">
                        <div className="addon-list">
                            {addonOptions.some(
                                (addon) => addon.addonType === "agreement"
                            ) ? (
                                <div className="form-check m-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="isAgreementAddon"
                                        checked={
                                            props.formData.isAgreementAddon
                                        }
                                        id="flexCheckDefault1"
                                        onChange={(e) =>
                                            onSelectAddon(
                                                e,
                                                addonOptions.find(
                                                    (addon) =>
                                                        addon.addonType ===
                                                        "agreement"
                                                )
                                            )
                                        }
                                    />

                                    <label
                                        className="form-check-label"
                                        htmlFor="flexCheckDefault1"
                                        style={{
                                            fontWeight: 600,
                                        }}
                                    >
                                        Agreements
                                    </label>
                                    {addonOptions.map((item, index) => {
                                        if (item.addonType === "agreement") {
                                            return (
                                                <p
                                                    key={index}
                                                    className="text-muted"
                                                >
                                                    {item.description}
                                                </p>
                                            );
                                        } else {
                                            return "";
                                        }
                                    })}
                                </div>
                            ) : (
                                ""
                            )}
                            {addonOptions.some(
                                (addon) => addon.addonType === "recruiter"
                            ) ? (
                                <div className="form-check m-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="isRecruiterAddon"
                                        checked={
                                            props.formData.isRecruiterAddon
                                        }
                                        id="flexCheckDefault2"
                                        onChange={(e) =>
                                            onSelectAddon(
                                                e,
                                                addonOptions.find(
                                                    (addon) =>
                                                        addon.addonType ===
                                                        "recruiter"
                                                )
                                            )
                                        }
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="flexCheckDefault2"
                                        style={{
                                            fontWeight: 600,
                                        }}
                                    >
                                        Recruiter checkbox
                                    </label>
                                    {addonOptions.map((item, index) => {
                                        if (item.addonType === "recruiter") {
                                            return (
                                                <p
                                                    key={index}
                                                    className="text-muted"
                                                >
                                                    {item.description}
                                                </p>
                                            );
                                        } else {
                                            return "";
                                        }
                                    })}
                                </div>
                            ) : (
                                ""
                            )}
                            {addonOptions.some(
                                (addon) => addon.addonType === "featured"
                            ) ? (
                                <div className="form-check m-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={props.formData.isFeaturedAddon}
                                        name="isFeaturedAddon"
                                        id="flexCheckDefault3"
                                        onChange={(e) =>
                                            onSelectAddon(
                                                e,
                                                addonOptions.find(
                                                    (addon) =>
                                                        addon.addonType ===
                                                        "featured"
                                                )
                                            )
                                        }
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="flexCheckDefault3"
                                    >
                                        Custom Agreement checkbox
                                    </label>
                                </div>
                            ) : (
                                ""
                            )}

                            {/* {addonOptions !== null ? (
              <Fragment>
                {addonOptions.map((addon) => {
                  return (
                    <Fragment></Fragment>
                    // <Fragment key={addon._id}>
                    //   <div className="addon-item d-flex align-items-center">
                    //     <div
                    //       className="d-flex flex-grow-1 align-items-center p-3"
                    //       onClick={() => onSelectAddon(addon)}
                    //     >
                    //       {selectedAddons.length !== 0 ? (
                    //         <Fragment>
                    //           {selectedAddons.some(
                    //             (el) => el._id === addon._id
                    //           ) ? (
                    //             <div className="checked">
                    //               <span className="material-icons">done</span>
                    //             </div>
                    //           ) : (
                    //             <div className="unchecked"></div>
                    //           )}
                    //         </Fragment>
                    //       ) : (
                    //         <div className="unchecked"></div>
                    //       )}
                    //       <p className="ml-2 m-0">{addon.title}</p>
                    //     </div>
                    //     <div
                    //       className="info-option mt-1 pr-3"
                    //     >
                    //       <span className="material-icons">info</span>
                    //     </div>
                    //   </div>
                    // </Fragment>
                  );
                })}
              </Fragment>
            ) : (
              ""
            )} */}
                        </div>
                    </div>
                </span>
                <div className="form-group text-end mt-3 mb-0">
                    <span data-tour="step-postproject-3">
                        <a
                            className="btn btn-cancel mr-3"
                            onClick={props.onBack}
                        >
                            Back
                        </a>
                    </span>
                    {selectedAddons.some(
                        (el) => el.addonType.toLowerCase() === "agreement"
                    ) ? (
                        <Fragment>
                            <a
                                className="btn btn-primary-gradient"
                                onClick={props.onNext}
                            >
                                Next Step
                            </a>
                        </Fragment>
                    ) : selectedAddons.length !== 0 ? (
                        <span data-tour="step-2">
                            <button
                                type="submit"
                                className="btn btn-primary-gradient"
                                disabled={props.loading}
                            >
                                Post for {selectedAddonPrice} USD{" "}
                                {props.loading ? (
                                    <img
                                        src="/img/loader.gif"
                                        alt="loader"
                                        width="25px"
                                    />
                                ) : (
                                    ""
                                )}
                            </button>
                        </span>
                    ) : (
                        <span data-tour="step-postproject-2">
                            <button
                                type="submit"
                                className="btn btn-primary-gradient"
                                disabled={props.loading}
                            >
                                Skip and Post Free{" "}
                                {props.loading ? (
                                    <img
                                        src="/img/loader.gif"
                                        alt="loader"
                                        width="25px"
                                    />
                                ) : (
                                    ""
                                )}
                            </button>
                        </span>
                    )}
                </div>
            </form>
        </Fragment>
    );
};

export default FormStep2;
