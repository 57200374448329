import React, { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { setAlert } from "../../actions/alert";
// import GoogleLogin from "react-google-login";

import { login, socialLogin } from "../../actions/auth";

// Styling
import styles from "./login.module.css";
import LoadingButton from "../../components/utils/LoadingButton/LoadingButton";
import jwtDecode from "jwt-decode";

const Login = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const googleLoginRef = useRef(null);
    const isMounted = useRef(true);

    const [disable, setDisable] = useState(false);
    const [showPasswordField, setShowPasswordField] = useState(false);

    useEffect(() => {
        // eslint-disable-next-line no-undef
        google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            callback: async (response) => {
                const userObj = jwtDecode(response.credential);

                const requestBody = {
                    email: "",
                    fullName: "",
                    password: "",
                    userType: "customer",
                };

                requestBody["email"] = userObj.email;
                requestBody["fullName"] = userObj.name;

                const res = await dispatch(socialLogin(requestBody));
                if (res.status) {
                    if (res.data.user.profile !== null) {
                        history.replace("/dashboard");
                    } else {
                        history.replace("/setupprofile");
                    }
                } else {
                    if (res.msg === "PASSWORD_REQUIRED") {
                        setShowPasswordField(true);
                    } else {
                        dispatch(setAlert(res.msg, "danger"));
                    }
                }

                if (isMounted.current) setDisable(false);
            },
        });

        // eslint-disable-next-line no-undef
        google.accounts.id.renderButton(googleLoginRef.current, {
            theme: "outline",
            size: "large",
            width: "100%",
        });

        return () => {
            isMounted.current = false;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [formData, setFormData] = useState({
        email: "",
        fullName: "",
        password: "",
        confirmPassword: "",
    });

    const { email, password, confirmPassword, fullName } = formData;

    const onChangeValue = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const responseGoogle = async (response, resetForm) => {
        try {
            response.preventDefault();
        } catch (err) {
            console.log(err);
        }

        setDisable(true);

        const requestBody = {
            email: email,
            fullName: fullName,
            password: password,
            userType: "customer",
        };

        if (resetForm) {
            setFormData({
                ...formData,
                email: response.profileObj.email,
                fullName: response.profileObj.name,
            });

            requestBody["email"] = response.profileObj.email;
            requestBody["fullName"] = response.profileObj.name;
        }

        const res = await dispatch(socialLogin(requestBody));
        if (res.status) {
            if (res.data.user.profile !== null) {
                history.replace("/dashboard");
            } else {
                history.replace("/setupprofile");
            }
        } else {
            if (res.msg === "PASSWORD_REQUIRED") {
                setShowPasswordField(true);
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
        }

        if (isMounted.current) setDisable(false);
    };

    const onLoginFormSubmit = async (e) => {
        e.preventDefault();
        setDisable(true);

        const res = await dispatch(login(email, password));

        if (res.status) {
            console.log(res.data);
            if (res.data.user.profile !== null) {
                history.replace("/dashboard");
            } else {
                history.replace("/setupprofile");
            }
        } else {
            dispatch(setAlert(res.msg, "danger"));
        }

        setDisable(false);
    };

    return (
        <Fragment>
            <div className="container d-flex justify-content-center align-items-center py-5">
                <div
                    className="box-shadow light-bg my-5 px-5 py-4"
                    id={`${styles["login-box"]}`}
                >
                    {!showPasswordField ? (
                        <Fragment>
                            <h4 className="text-center">
                                Login To Your Account
                            </h4>
                            <hr></hr>
                            <form
                                onSubmit={(e) => {
                                    onLoginFormSubmit(e);
                                }}
                            >
                                <div className="mb-3">
                                    <input
                                        type="email"
                                        className="dark-input"
                                        name="email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) => onChangeValue(e)}
                                        required
                                    ></input>
                                </div>
                                <div className="mb-4">
                                    <input
                                        type="password"
                                        className="dark-input"
                                        name="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => onChangeValue(e)}
                                        required
                                    ></input>
                                </div>
                                <div className="mb-2">
                                    <LoadingButton
                                        text="Login"
                                        isLoading={disable}
                                    />
                                </div>
                            </form>
                            <div className="row">
                                <div className="col-md-6">
                                    <a
                                        className={styles["footerLink"]}
                                        href="/forgot-password"
                                    >
                                        Forgot Password
                                    </a>
                                </div>
                                <div className="col-md-6">
                                    <Link
                                        className={styles["footerLink"]}
                                        to="/register"
                                        style={{
                                            float: "right",
                                        }}
                                    >
                                        Create a new Account
                                    </Link>
                                </div>
                            </div>
                            <div className={styles["hr-sect"]}>OR</div>
                            <div className="row">
                                <div className="col-md-12">
                                    {/* <button
                                type="button"
                                className="btn btn-light w-100"
                            >
                                <p style={{ color: "#000" }}>
                                    <img
                                        src="/img/google.png"
                                        width="20px"
                                        height="12%"
                                        style={{ marginRight: "15px" }}
                                    />
                                    Google
                                </p>
                            </button> */}
                                    {/* <GoogleLogin
                                        clientId={
                                            process.env
                                                .REACT_APP_GOOGLE_CLIENT_ID
                                        }
                                        buttonText="Login With Google"
                                        onSuccess={(e) =>
                                            responseGoogle(e, true)
                                        }
                                        className="w-100 text-muted"
                                    /> */}

                                    <div className="d-flex justify-content-center">
                                        <div
                                            ref={googleLoginRef}
                                            id="google-login-btn"
                                        ></div>
                                    </div>
                                </div>
                                {/* <div className="col-6 text-center">
                            <button
                                type="button"
                                className="btn btn-light w-100"
                            >
                                <p style={{ color: "#000" }}>
                                    <img
                                        src="/img/facebook.png"
                                        width="20px"
                                        style={{
                                            marginRight: "15px",
                                        }}
                                    />
                                    Facebook
                                </p>
                            </button>
                        </div> */}
                            </div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <h4 className="text-center">
                                Set Password For Your Account
                            </h4>
                            <hr></hr>
                            <form
                                onSubmit={(e) => {
                                    if (password === confirmPassword) {
                                        responseGoogle(e, false);
                                    } else {
                                        e.preventDefault();
                                        dispatch(
                                            setAlert(
                                                "Password doesn't match!",
                                                "danger"
                                            )
                                        );
                                    }
                                }}
                            >
                                <div className="mb-3">
                                    <input
                                        type="password"
                                        className="dark-input"
                                        name="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => onChangeValue(e)}
                                        required
                                    ></input>
                                </div>
                                <div className="mb-4">
                                    <input
                                        type="password"
                                        className="dark-input"
                                        name="confirmPassword"
                                        placeholder="Confirm Password"
                                        value={confirmPassword}
                                        onChange={(e) => onChangeValue(e)}
                                        required
                                    ></input>
                                </div>
                                <div className="mb-2">
                                    <LoadingButton
                                        text="Save"
                                        isLoading={disable}
                                    />
                                </div>
                            </form>
                        </Fragment>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default Login;
