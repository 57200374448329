import React, { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Dropdown } from "semantic-ui-react";

import { setAlert } from "../../../actions/alert";
import { getActiveSkills } from "../../../actions/skills";
import { ProfileContext } from "../../../contexts/ProfileContext";
import LoadingButton from "../../utils/LoadingButton/LoadingButton";
import { setSkills } from "./../../../actions/profile";

import styles from "./skillEdit.module.css";

const SkillEdit = ({ toggleMode, reloadProfile }) => {
	const profileContext = useContext(ProfileContext);
	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = useState(false);
	const [formData, setFormData] = useState({
		skills: [],
	});
	const [allSkills, setAllSkills] = useState([]);

	useEffect(() => {
		const formatSkills = () => {
			const selectedSkills = [];
			profileContext.profile.skills.map((skill) => {
				selectedSkills.push(skill._id);
				return null;
			});
			setFormData({ ...formData, skills: selectedSkills });
		};

		const fetchAllSkills = async () => {
			const response = await dispatch(getActiveSkills());
			if (response.status) {
				const activeSkills = [];
				response.data.map((skill) => {
					const skillObj = {
						key: skill._id,
						text: skill.name,
						value: skill._id,
					};
					activeSkills.push(skillObj);
					return null;
				});
				setAllSkills(activeSkills);
			}
		};

		fetchAllSkills();
		formatSkills();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChangeDropdownValue = (e, data) => {
		setFormData({ ...formData, [data.name]: data.value });
	};

	const onFormSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);

		const response = await dispatch(setSkills(formData));
		if (response.status) {
			// profileContext.setProfile(response.data);
			reloadProfile();
			toggleMode(false);
		} else {
			dispatch(setAlert(response.msg, "danger"));
		}

		setIsLoading(false);
	};

	return (
		<Fragment>
			<section
				className={`${styles["skills-section"]} light-bg p-4 my-lg-3 mb-3`}
			>
				<h3 className="text-center">Add Skills</h3>
				<form className="mt-3" onSubmit={(e) => onFormSubmit(e)}>
					<div className="skill-dropdown">
						<Dropdown
							className="sementic-dropdown dark-input dropdown-select"
							placeholder="Skills"
							name="skills"
							fluid
							multiple
							search
							selection
							options={allSkills}
							onChange={onChangeDropdownValue}
							value={formData.skills}
						/>
					</div>
					<div className="mt-3">
						<LoadingButton
							text="Save"
							className="btn-save"
							isLoading={isLoading}
						/>
						<input
							type="button"
							className="btn-cancel ms-2"
							value="Cancel"
							onClick={() => toggleMode(false)}
						></input>
					</div>
				</form>
			</section>
		</Fragment>
	);
};

export default SkillEdit;
