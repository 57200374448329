import { UPDATE_UNSEEN_NOTIFICATION_COUNT } from "../actions/types";

const initialState = {
    loading: true,
    unseenNotificationCount: 0,
};

const notification = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_UNSEEN_NOTIFICATION_COUNT:
            return {
                ...state,
                loading: false,
                unseenNotificationCount:
                    payload !== null && payload !== undefined
                        ? payload
                        : state.unseenNotificationCount + 1,
            };
        default:
            return state;
    }
};

export default notification;
