import Dashboard from "../pages/Dashboard/Dashboard";
import BrowseProjects from "../pages/BrowseProjects/BrowseProjects";
import ProjectDetails from "../pages/ProjectDetails/ProjectDetails";
import Profile from "../pages/Profile/Profile";
import PostProject from "../pages/PostProject/PostProject";
import Messages from "../pages/Messages/Messages";
import Transaction from "../pages/Transaction/Transaction";
import MyProjects from "../pages/MyProjects/MyProjects";
import GameCustomizer from "../pages/GameCustomizer/GameCustomizer";
import WithdrawFunds from "../pages/WithdrawFunds/WithdrawFunds";
import UpgradePlan from "../pages/UpgradePlan/UpgradePlan";
import SetupProfile from "../pages/SetupProfile/SetupProfile";
import VideoChat from "../pages/VideoChat/VideoChat";
import Refer from "../pages/Refer/Refer";
import StripeRefresh from "../pages/Stripe/Refresh/Refresh";
import StripeReturn from "../pages/Stripe/Return/Return";
import BidsSubmitted from "../pages/BidsSubmitted/BidsSubmitted";
import GameForms from "../pages/GameForms/GameForms";
import ScheduledMeetings from "../pages/ScheduledMeetings/ScheduledMeetings";
import Settings from "../pages/Settings/Settings";
import ProjectControlCenter from "../pages/ProjectControlCenter/ProjectControlCenter";
import RecommendedProjects from "../pages/RecommendedProjects/RecommendedProjects";

const routes = [
	{ path: "/setupprofile", component: SetupProfile },
	{ path: "/profile/:id", component: Profile },
	{ path: "/dashboard", component: Dashboard },
	{
		path: "/browse-projects",
		component: BrowseProjects,
	},
	{
		path: "/recommended-projects",
		component: RecommendedProjects,
	},
	{ path: "/project/:id/control-center", component: ProjectControlCenter },
	{ path: "/project/:id", component: ProjectDetails },
	{
		path: "/post-project",
		component: PostProject,
	},
	{ path: "/messages", component: Messages },
	{
		path: "/scheduled-meetings",
		component: ScheduledMeetings,
	},
	{ path: "/transactions", component: Transaction },
	{ path: "/my-projects", component: MyProjects },
	{
		path: "/bids-submitted",
		component: BidsSubmitted,
	},
	{
		path: "/game-forms",
		component: GameForms,
	},
	{
		path: "/game-customizer/:gameFormId",
		component: GameCustomizer,
	},
	{
		path: "/withdraw-funds",
		component: WithdrawFunds,
	},
	{
		path: "/video-chat/:callId",
		component: VideoChat,
	},
	{
		path: "/refer",
		component: Refer,
	},
	{
		path: "/settings",
		component: Settings,
	},
	{ path: "/upgrade-plan", component: UpgradePlan },
	{
		path: "/stripe/account/refresh",
		component: StripeRefresh,
	},
	{
		path: "/stripe/account/return",
		component: StripeReturn,
	},
];

export default routes;
