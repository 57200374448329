import React, { Fragment } from "react";
import Skeleton from "../../../components/Skeleton/Skeleton";
import styles from "../messages.module.css";
// import { useDispatch, useSelector } from "react-redux";

const skeleton = () => {
    //   const authState = useSelector((state) => state.auth);
    return (
        <Fragment>
            <main className={`${styles["chat-page"]}`}>
                <div className="container-fluid">
                    <div className="row">
                        <div
                            className="col-md-4 p-0 "
                            style={{ height: "90vh" }}
                        >
                            <div className={`${styles["chat-sidebar"]}`}>
                                <nav
                                    className={`${styles["chat-sidebar-nav"]} d-flex align-items-center gap-2`}
                                >
                                    <div className="profile-icon me-2">
                                        <img
                                            className="profile-pic-sm"
                                            src="/img/profile-image.png"
                                            alt="Profile"
                                        />
                                    </div>

                                    <Fragment>
                                        <h4 className="font-weight-bolder text-capitalize m-0 flex-grow-1">
                                            Username
                                            <Fragment>
                                                <small className="ms-2 mb-0 notification d-inline">
                                                    R
                                                </small>
                                            </Fragment>
                                        </h4>
                                    </Fragment>

                                    <div className="d-flex">
                                        <span className="material-icons pointer">
                                            group_add
                                        </span>
                                    </div>

                                    <div className="d-flex">
                                        <span className="material-icons pointer">
                                            more_horiz
                                        </span>
                                    </div>
                                </nav>
                                <section>
                                    <div
                                        className={`${styles["chat-search"]} nav-height d-flex align-items-center`}
                                    >
                                        <form>
                                            <input
                                                type="text"
                                                className="form-control w-100"
                                                placeholder="Search Chat..."
                                            />
                                        </form>
                                    </div>
                                    <ul
                                        className={`${styles["chat-contacts"]} m-0`}
                                    >
                                        <li
                                            className={`${styles["chat-contact-one"]} `}
                                        >
                                            <Fragment>
                                                <div className="profile-icon mr-2">
                                                    <img
                                                        className="profile-pic-sm"
                                                        src="/img/group-chat.jfif"
                                                        alt="Profile"
                                                    />
                                                </div>
                                                <div className="w-100">
                                                    <div
                                                        className="d-flex align-items-center gap-2"
                                                        style={{
                                                            display: "flex",
                                                        }}
                                                    >
                                                        <div className="d-flex gap-2 flex-grow-1">
                                                            <h5 className="font-weight-bolder text-capitalize d-inline m-0 d-inline">
                                                                <Skeleton />
                                                            </h5>

                                                            <Fragment>
                                                                <Fragment>
                                                                    <small className="mr-2 notification d-inline"></small>
                                                                </Fragment>
                                                            </Fragment>
                                                        </div>
                                                        <div
                                                            className={`d-flex m-0`}
                                                        >
                                                            <span className="material-icons pointer">
                                                                more_horiz
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        //   className="contact-message"
                                                        className="d-flex align-items-center mt-1"
                                                    >
                                                        <small className="chat-last-msg text-secondary flex-grow-1">
                                                            <Skeleton />
                                                        </small>
                                                        <small className="last-msg-time text-uppercase text-secondary">
                                                            <Skeleton />
                                                        </small>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        </li>
                                    </ul>
                                </section>
                            </div>
                        </div>
                        <div
                            className="col-md-8 p-0"
                            style={{ height: "90vh" }}
                        >
                            <section
                                className={`${styles["chat-area"]} d-flex flex-column`}
                            >
                                <Fragment>
                                    <div className="no-chat-display">
                                        Chat Instantly
                                    </div>
                                </Fragment>
                            </section>
                        </div>
                    </div>
                </div>
            </main>
        </Fragment>
    );
};

export default skeleton;
