import React, { Fragment } from "react";

import styles from "./Footer.module.css";

const Footer = () => {
    return (
        <Fragment>
            <footer id={styles["footer"]} className="pt-5">
                <div className="container">
                    <div className="row w-100" style={{ marginBottom: "20px" }}>
                        <div
                            className="col-md-2"
                            style={{ marginBottom: "20px" }}
                        >
                            <div className="d-flex align-items-center" to="/">
                                <img
                                    alt=""
                                    src="/img/landing/logo.png"
                                    style={{ width: "50px" }}
                                />
                                <h3 className="notranslate">&nbsp;Trinity</h3>
                            </div>
                            <hr style={{ backgroundColor: "white" }}></hr>
                            <p>
                                Trinity helps you to connect with the right
                                people to achieve your dream
                            </p>
                        </div>
                        <div
                            className="col-md-9 row"
                            style={{ marginLeft: "15px", marginBottom: "20px" }}
                        >
                            <div id={styles["vertical"]} className="col-md-4">
                                <button className={`btn btn-dark ${styles.bt}`}>
                                    Join As Freelancer
                                </button>
                                <div
                                    className="d-flex flex-column align-items-left justify-content-md-start"
                                    style={{
                                        marginLeft: "40px",
                                        marginTop: "20px",
                                    }}
                                >
                                    <a
                                        className={styles["footerLink"]}
                                        href="/vision"
                                    >
                                        -Our Vision
                                    </a>
                                    <a
                                        className={styles["footerLink"]}
                                        href="/goals"
                                    >
                                        -Goals
                                    </a>
                                    {/* <a className={styles["footerLink"]}>-Our Developer</a> */}
                                    <a
                                        className={styles["footerLink"]}
                                        href="/impressum"
                                    >
                                        -Impressum
                                    </a>
                                </div>
                            </div>
                            <div
                                className="col-md-4 "
                                style={{ marginBottom: "20px" }}
                            >
                                <button className={`btn btn-dark ${styles.bt}`}>
                                    Get In Touch
                                </button>
                                <div
                                    className="d-flex flex-column align-items-left justify-content-md-start"
                                    style={{
                                        marginLeft: "30px",
                                        marginTop: "20px",
                                    }}
                                >
                                    <a className={styles["footerLink"]}>
                                        Contact Form
                                    </a>
                                    <a
                                        className={styles["footerLink"]}
                                        href="mailto: neoxdev.info@gmail.com"
                                    >
                                        Mail : neoxdev.info@gmail.com
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-4 mx-auto">
                                <button
                                    className={`btn btn-dark ${styles.bt1}`}
                                >
                                    Terms
                                </button>
                                <div
                                    className="d-flex flex-column align-items-left justify-content-md-start"
                                    style={{
                                        marginLeft: "30px",
                                        marginTop: "20px",
                                    }}
                                >
                                    <a
                                        className={styles["footerLink"]}
                                        href="/terms-of-service"
                                    >
                                        Terms of Service
                                    </a>
                                    <a
                                        className={styles["footerLink"]}
                                        href="/terms-of-payment"
                                    >
                                        Terms of Payment
                                    </a>
                                    <a
                                        className={styles["footerLink"]}
                                        href="/copyright"
                                    >
                                        Copyright Terms
                                    </a>
                                    <a
                                        className={styles["footerLink"]}
                                        href="/privacy-policy"
                                    >
                                        Privacy Policy
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`d-flex ${styles["footer-logo"]}`}>
                        <div>
                            <p style={{ display: "contents" }}>Follow Us: </p>
                            <a
                                className={styles["footerLink"]}
                                href="https://www.instagram.com/neoxentertainment/"
                            >
                                <img
                                    className={`${styles["footer-logo-icons"]}`}
                                    alt=""
                                    src="/img/landing/instagram.png"
                                ></img>
                            </a>
                            <a
                                className={styles["footerLink"]}
                                href="https://www.facebook.com/fabian.grundmann.35/"
                            >
                                <img
                                    className={`${styles["footer-logo-icons"]}`}
                                    alt=""
                                    src="/img/landing/facebook.png"
                                ></img>
                            </a>
                            <a
                                className={styles["footerLink"]}
                                href="https://www.linkedin.com/in/fabian-grundmann-88312a196/"
                            >
                                <img
                                    className={`${styles["footer-logo-icons"]}`}
                                    alt=""
                                    src="/img/landing/linkdin.png"
                                ></img>
                            </a>
                            <a
                                className={styles["footerLink"]}
                                href="https://www.youtube.com/channel/UCc4CB2UU4L44Ee8ZsSnsa7g"
                            >
                                <img
                                    className={`${styles["footer-logo-icons"]}`}
                                    alt=""
                                    src="/img/landing/youtube.png"
                                ></img>
                            </a>
                            <a
                                className={styles["footerLink"]}
                                href="https://www.neoxentertainment.com/"
                            >
                                <img
                                    className={`${styles["footer-logo-icons"]}`}
                                    style={{ borderRadius: "100%" }}
                                    alt=""
                                    src="/img/landing/website.png"
                                ></img>
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </Fragment>
    );
};

export default Footer;
