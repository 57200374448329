import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
	getActiveCountries,
	getTimezones,
	getActiveCurrencies,
} from "../../actions/profile";
import { createOrUpdateProfile } from "../../actions/profile";
import { useHistory } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";

const SetupProfile = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const [disable, setDisable] = useState(false);
	const [countries, setCountries] = useState([]);
	const [currencies, setCurrencies] = useState([]);
	const [timezones, setTimezones] = useState([]);
	const [formData, setFormData] = useState({
		country: "",
		timezone: "",
		currency: "",
	});

	const loadInitData = async () => {
		const res1 = await dispatch(getActiveCountries());
		const res2 = await dispatch(getTimezones());
		const res3 = await dispatch(getActiveCurrencies());

		setCountries(
			res1.data.map((country) => {
				return {
					key: country._id,
					text: country.name,
					value: country._id,
				};
			})
		);
		setTimezones(
			res2.data.map((timezone, i) => {
				return { key: i, text: timezone, value: timezone };
			})
		);
		setCurrencies(
			res3.data.map((currency) => {
				return {
					key: currency._id,
					text: currency.name,
					value: currency._id,
				};
			})
		);
	};

	useEffect(() => {
		loadInitData();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { country, timezone, currency } = formData;

	const onChangeDropdownValue = (e, data) => {
		setFormData({ ...formData, [data.name]: data.value });
	};

	const formStep1 = (
		<div key={1}>
			<h4>
				<b>Setup Profile</b>
			</h4>
			<hr></hr>
			<div className="mb-3">
				<label className="form-label">Where Are You From?</label>
				<Dropdown
					className="sementic-dropdown dark-input dropdown-select"
					placeholder="Select Country"
					fluid
					name="country"
					search
					selection
					options={countries}
					onChange={onChangeDropdownValue}
					value={country}
					required
				/>
			</div>
			<div className="mb-3">
				<label className="form-label">
					What's Your Local Currency?
				</label>
				<Dropdown
					placeholder="Select Currency"
					className="sementic-dropdown dark-input dropdown-select"
					fluid
					name="currency"
					search
					selection
					options={currencies}
					onChange={onChangeDropdownValue}
					defaultValue={currency}
					required
				/>
			</div>
			<div className="mb-3">
				<label className="form-label">What's Your Timezone?</label>
				<Dropdown
					className="sementic-dropdown dark-input dropdown-select"
					placeholder="Select Timezone"
					fluid
					name="timezone"
					search
					selection
					options={timezones}
					onChange={onChangeDropdownValue}
					defaultValue={timezone.toString()}
					required
				/>
			</div>
			<div className="text-end">
				<button
					className="btn btn-primary-gradient mt-3"
					onClick={(e) => onFormSubmit(e)}
				>
					Let's Go{" "}
					{disable ? (
						<img src="/img/loader.gif" alt="loader" width="25px" />
					) : (
						""
					)}
				</button>
			</div>
		</div>
	);

	const onFormSubmit = async (e) => {
		e.preventDefault();
		setDisable(true);

		var object = {
			jsonData: JSON.stringify({
				country,
				timezone,
				currency,
			}),
		};

		const result = await dispatch(createOrUpdateProfile(object));

		if (result) {
			setDisable(false);
			return history.replace("/dashboard");
		} else {
			setDisable(false);
		}
	};

	return (
		<Fragment>
			<div className="container" id="setup-profile-page">
				<form onSubmit={(e) => onFormSubmit(e)}>
					<div className="row">
						<div
							className="col-md-6 text-center welcome-section d-flex align-items-center p-5 p-md-3 mt-5 my-md-5"
							style={{
								// backgroundImage:
								//     "linear-gradient(to right, #f85032d2, #e73727e8),url('/img/bg-2.jpg')",
								backgroundImage:
									"-webkit-gradient(linear,left top,right top,from(#f85032d2),to(#e73727e8)),url('/img/bg-2.jpg')",
							}}
						>
							<div>
								<h4 className="mb-4">Welcome To</h4>
								<h2 className="mb-3">NeoxEntertainment</h2>
								<p className="mb-4  mx-sm-5">
									You are successfully registered! Please
									complete your profile before using the
									portal... :
								</p>
							</div>
						</div>
						<div
							className="col-md-6 light-bg p-5 mb-5 my-md-5"
							id="setup-profile-form"
						>
							<Fragment>{formStep1}</Fragment>
						</div>
					</div>
				</form>
			</div>
		</Fragment>
	);
};

export default SetupProfile;
