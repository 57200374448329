import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../actions/alert";
import { addEducation } from "./../../../actions/profile";

const AddEducation = ({ toggleMode, reloadProfile }) => {
    const dispatch = useDispatch();
    const [disable, setDisable] = useState(false);
    const [formData, setFormData] = useState({
        location: "",
        school: "",
        degree: "",
        from: "",
        to: "",
        current: false,
        description: "",
    });

    const { location, school, degree, from, to, current, description } =
        formData;

    const onChangeValue = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onChangeCheckbox = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.checked });
    };

    const onFormSubmit = async (e) => {
        e.preventDefault();
        setDisable(true);
        const response = await dispatch(addEducation(formData));
        if (response.status) {
            dispatch(setAlert(response.msg, "success"));

            reloadProfile();
            toggleMode(false);
        }
        setDisable(false);
    };

    return (
        <Fragment>
            <div className="section-body px-3">
                <form onSubmit={(e) => onFormSubmit(e)}>
                    <div className="mb-2">
                        <label className="form-label">Degree</label>
                        <input
                            type="text"
                            className="dark-input"
                            name="degree"
                            placeholder="Degree"
                            value={degree}
                            onChange={(e) => onChangeValue(e)}
                            required
                        />
                    </div>
                    <div className="mb-2">
                        <div className="row">
                            <div className="col-md-6">
                                <label className="form-label">Location</label>
                                <input
                                    type="text"
                                    className="dark-input"
                                    name="location"
                                    placeholder="Location"
                                    value={location}
                                    onChange={(e) => onChangeValue(e)}
                                    required
                                />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">School</label>
                                <input
                                    type="text"
                                    className="dark-input"
                                    name="school"
                                    placeholder="School"
                                    value={school}
                                    onChange={(e) => onChangeValue(e)}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mb-2">
                        <div className="row">
                            <div className="col-md-6">
                                <label className="form-label">From Date</label>
                                <input
                                    type="date"
                                    className="dark-input"
                                    name="from"
                                    value={from}
                                    onChange={(e) => onChangeValue(e)}
                                    required
                                />
                            </div>
                            {formData.current ? (
                                <div className="col-md-6"></div>
                            ) : (
                                <div className="col-md-6">
                                    <label className="form-label">
                                        To Date
                                    </label>
                                    <input
                                        type="date"
                                        className="dark-input"
                                        name="to"
                                        value={to}
                                        onChange={(e) => onChangeValue(e)}
                                        required
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="mb-2">
                        <label className="m-0">
                            <input
                                type="checkbox"
                                name="current"
                                value={current}
                                onChange={(e) => onChangeCheckbox(e)}
                            />{" "}
                            I am pursuing it
                        </label>
                    </div>
                    <div className="mb-2">
                        <label className="form-label">Description</label>
                        <textarea
                            className="dark-input"
                            name="description"
                            rows="5"
                            placeholder="Describe your work experience"
                            value={description}
                            onChange={(e) => onChangeValue(e)}
                        ></textarea>
                    </div>
                    <div className="section-options mb-3">
                        <button
                            disabled={disable}
                            type="submit"
                            className="btn-save me-2"
                        >
                            Save{" "}
                            {disable ? (
                                <img
                                    src="/img/loader.gif"
                                    alt="loader"
                                    width="25px"
                                />
                            ) : (
                                ""
                            )}
                        </button>
                        <button
                            className="btn-cancel"
                            onClick={() => toggleMode(false)}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
            {/* <form onSubmit={(e) => onFormSubmit(e)} className="px-md-5">
				<div className="form-group">
					<div className="row">
						<div className="col">
							<label>Degree</label>
							<input
								type="text"
								className="form-control"
								name="degree"
								placeholder="Degree"
								value={degree}
								onChange={(e) => onChangeValue(e)}
								required
							/>
						</div>
					</div>
				</div>
				<div className="form-group">
					<div className="row">
						<div className="col">
							<label>Location</label>
							<input
								type="text"
								className="form-control"
								name="location"
								placeholder="Location"
								value={location}
								onChange={(e) => onChangeValue(e)}
								required
							/>
						</div>
						<div className="col">
							<label>School</label>
							<input
								type="text"
								className="form-control"
								name="school"
								placeholder="School"
								value={school}
								onChange={(e) => onChangeValue(e)}
								required
							/>
						</div>
					</div>
				</div>
				<div className="form-group">
					<div className="row">
						<div className="col">
							<label>From Date</label>
							<input
								type="date"
								className="form-control"
								name="from"
								value={from}
								onChange={(e) => onChangeValue(e)}
								required
							/>
						</div>
						{formData.current ? (
							<div className="col"></div>
						) : (
							<div className="col">
								<label>To Date</label>
								<input
									type="date"
									className="form-control"
									name="to"
									value={to}
									onChange={(e) => onChangeValue(e)}
									required
								/>
							</div>
						)}
					</div>
				</div>
				<div className="form-group">
					<label>
						<input
							type="checkbox"
							name="current"
							value={current}
							onChange={(e) => onChangeCheckbox(e)}
						/>{" "}
						I am pursuing it
					</label>
				</div>
				<div className="form-group">
					<label>Description</label>
					<textarea
						className="form-control"
						name="description"
						rows="5"
						placeholder="Describe your work experience"
						value={description}
						onChange={(e) => onChangeValue(e)}
					></textarea>
				</div>
				<div className="form-group">
					<button className="btn btn-link" onClick={toggle}>
						Cancel
					</button>
					&nbsp;
					<input
						type="submit"
						className="btn btn-primary-gradient"
						value="Save"
					/>
				</div>
			</form> */}
        </Fragment>
    );
};

export default AddEducation;
