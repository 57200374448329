import {
    AUTH_USER,
    AUTH_USER_FAIL,
    DISABLE_TUTORIAL,
    GET_PROFILE,
    LOGIN_SUCCESS,
    LOGOUT,
} from "../actions/types";

const initialState = {
    loading: true,
    authenticated: false,
    token: localStorage.getItem("auth-token"),
    user: null,
};

const auth = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case AUTH_USER:
            return {
                ...state,
                loading: false,
                authenticated: true,
                user: payload,
            };
        case AUTH_USER_FAIL:
            return {
                ...state,
                loading: false,
                authenticated: false,
            };
        case LOGIN_SUCCESS:
            localStorage.setItem("auth-token", payload.token);
            return {
                ...state,
                loading: false,
                authenticated: true,
                token: payload.token,
                user: payload.user,
            };
        case DISABLE_TUTORIAL:
            return {
                ...state,
                user: { ...state.user, tutorial: false },
            };
        case GET_PROFILE:
            return { ...state, user: { ...state.user, profile: payload } };
        case LOGOUT:
            localStorage.removeItem("auth-token");
            return {
                ...state,
                loading: false,
                authenticated: false,
                token: null,
                user: null,
            };
        default:
            return state;
    }
};

export default auth;
