import { Dialog } from "@material-ui/core";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { getApiEndpoint } from "../../../utils/getApiEndpoint";
import {
    CustomizedDialogContent,
    CustomizedDialogTitle,
} from "../CustomizedModal/CustomizedModal";

const ViewPortfolioItemModal = ({ isOpen, onClose, portfolio }) => {
    return (
        <Dialog
            onClose={() => onClose(false)}
            open={isOpen}
            maxWidth="md"
            fullWidth={true}
        >
            <CustomizedDialogTitle>
                <h3>{portfolio.title}</h3>
            </CustomizedDialogTitle>
            <CustomizedDialogContent>
                <div className="row">
                    <div className="col-md-8">
                        <Carousel>
                            {portfolio.files.map((file, index) => {
                                return (
                                    <div key={index}>
                                        <img
                                            src={getApiEndpoint() + file}
                                            alt="gallery"
                                            className="w-100"
                                        />
                                    </div>
                                );
                            })}
                        </Carousel>
                    </div>
                    <div className="col-md-4">
                        {portfolio.link && (
                            <a
                                href={portfolio.link}
                                target="_blank"
                                rel="noreferrer"
                                className="btn btn-primary-gradient w-100 mb-3"
                            >
                                Portfolio Link
                            </a>
                        )}
                        <h6>Description:</h6>
                        <p>{portfolio.description}</p>
                    </div>
                </div>
            </CustomizedDialogContent>
        </Dialog>
    );
};

export default ViewPortfolioItemModal;
