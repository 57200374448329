import { CLEAR_ERROR, ON_ERROR, ON_FORBIDDEN } from "./types";

export const error = (err) => (dispatch) => {
    if (err.response.status === 500) {
        dispatch({
            type: ON_ERROR,
        });
    }
    if (err.response.status === 403) {
        dispatch({
            type: ON_FORBIDDEN,
        });
    }
};

export const clearError = () => (dispatch) => {
    dispatch({
        type: CLEAR_ERROR,
    });
};
