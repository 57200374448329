import { getApiEndpoint } from "../utils/getApiEndpoint";
import { requestWithToken } from "../utils/httpRequest";
import { GET_PROFILE, GET_PROFILE_IMAGE } from "./types";
import { error } from "./error";

// Fetch user profile picture
export const fetchCurrentUserProfilePic = () => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"GET",
			`${getApiEndpoint()}/profile/me/pic`
		);

		if (response.data.data) {
			dispatch({
				type: GET_PROFILE_IMAGE,
				payload: response.data.data,
			});
		}

		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};

// Get Logged In User Profile
export const fetchCurrentUserProfile = () => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"GET",
			`${getApiEndpoint()}/profile/me`
		);

		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};

// Fetch Selected User Profile
export const fetchSelectedUserProfile = (user_id) => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"GET",
			`${getApiEndpoint()}/profile/user/${user_id}`
		);

		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};

// Get Logged In User Wallet
export const fetchCurrentUserWallet = () => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"GET",
			`${getApiEndpoint()}/profile/me/wallet`
		);

		return new Promise((resolve, reject) => resolve(response.data));
	} catch (err) {
		dispatch(error());
		return new Promise((resolve, reject) => reject(err.response.data));
	}
};

// Create or Update Profile
export const createOrUpdateProfile = (formData) => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"POST",
			`${getApiEndpoint()}/profile`,
			formData
		);
		console.log(response);

		dispatch({
			type: GET_PROFILE,
			payload: response.data.data,
		});

		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};

// Update Profile bio
export const setBio = (about) => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"POST",
			`${getApiEndpoint()}/profile/bio`,
			{
				bio: about,
			}
		);
		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};

// Add new experience
export const addExperience = (data) => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"PUT",
			`${getApiEndpoint()}/profile/experience`,
			data
		);
		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};

//Add Portfolio
export const addFolio = (data) => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"PUT",
			`${getApiEndpoint()}/profile/portfolio`,
			data
		);
		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};

//Add Education
export const addEducation = (data) => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"PUT",
			`${getApiEndpoint()}/profile/education`,
			data
		);
		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};

//Edit Education
export const editEducation = (id, data) => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"PUT",
			`${getApiEndpoint()}/profile/education/${id}`,
			data
		);
		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};
// Delete Experience
export const deleteEducation = (id) => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"DELETE",
			`${getApiEndpoint()}/profile/education/${id}`
		);
		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};
//Edit Experience
export const editExperience = (id, data) => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"PUT",
			`${getApiEndpoint()}/profile/experience/${id}`,
			data
		);
		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};
// Delete Experience
export const deleteExperience = (id) => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"DELETE",
			`${getApiEndpoint()}/profile/experience/${id}`
		);
		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};
//Edit Folio
export const editFolio = (id, data) => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"PUT",
			`${getApiEndpoint()}/profile/portfolio/${id}`,
			data
		);
		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};
// Delete Folio
export const deletePortfolio = (id) => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"DELETE",
			`${getApiEndpoint()}/profile/portfolio/${id}`
		);
		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};
// Get Review
export const getReview = (id) => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"GET",
			`${getApiEndpoint()}/profilereview/${id}`
		);
		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};

// Add Profile Review
export const addProfileReview =
	(reviewedTo, projectId, review, reviewAs, rating) => async (dispatch) => {
		const body = {
			reviewedTo,
			projectId,
			review,
			reviewAs,
			rating,
		};

		try {
			const response = await requestWithToken(
				"POST",
				`${getApiEndpoint()}/profilereview`,
				body
			);
			return response.data;
		} catch (err) {
			dispatch(error(err));
			return err.response.data;
		}
	};

// get all skills created by admin
export const getAdminApprovedSkills = () => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"GET",
			`${getApiEndpoint()}/skills`
		);
		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};

// Save user profile skills
export const setSkills = (body) => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"POST",
			`${getApiEndpoint()}/profile/skills`,
			body
		);

		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};

// Get all countries from DB
export const getActiveCountries = () => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"GET",
			`${getApiEndpoint()}/country`
		);

		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};

// Get all currencies from DB
export const getActiveCurrencies = () => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"GET",
			`${getApiEndpoint()}/currency`
		);

		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};

// Get all timezones from API
export const getTimezones = () => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"GET",
			`${getApiEndpoint()}/country/timezones`
		);
		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};

// Get all Customer profiles
export const getCustomerProfiles = () => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"GET",
			`${getApiEndpoint()}/profile/customer`
		);

		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};

// Get profile by id
