import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BiDownload } from "react-icons/bi";
import { formatTime } from "../../../utils/formatTime";
import styles from "./MilestoneDeliveryListItem.module.css";
import { downloadFile } from "../../../actions/file";
import { setAlert } from "../../../actions/alert";
import fileDownload from "js-file-download";

const MilestoneDeliveryListItem = ({ delivery }) => {
	const dispatch = useDispatch();

	const authState = useSelector((state) => state.auth);

	const onDownloadAttachment = async (name, url) => {
		const response = await dispatch(downloadFile({ url }));
		if (response.status === 0) {
			dispatch(setAlert(response.msg, "danger"));
		} else {
			// download(response, attachment.name);
			fileDownload(response, name);
		}
	};

	return (
		<Fragment>
			<div
				className={`${styles["milestone-delivery"]} light-bg p-3 mb-3`}
			>
				<div className="d-flex">
					<h6 className="flex-grow-1 mb-0">
						{delivery.title}{" "}
						{delivery.workUrl && (
							<Fragment>
								(
								<a
									href={delivery.workUrl}
									target="_blank"
									rel="noreferrer"
									className="color-primary"
								>
									Open Work Link
								</a>
								)
							</Fragment>
						)}
					</h6>
					<p
						className={`${
							styles["status"]
						}  p-1 rounded d-inline-block ${
							styles[delivery.status]
						}`}
					>
						{delivery.status.replace("-", " ")}
					</p>
				</div>
				<small className="text-muted">
					@{" "}
					{formatTime(
						delivery.createdAt,
						authState.user.profile.timezone,
						"DD MMM YYYY, hh:mm A"
					)}
				</small>
				<div
					className={`${styles["description"]} text-muted mt-2`}
					dangerouslySetInnerHTML={{
						__html: delivery.description,
					}}
				></div>
				{delivery.attachments && delivery.attachments.length > 0 && (
					<>
						<br />
						Delivery Attachments:
						{delivery.attachments.map((attachment) => {
							return (
								<div
									className="d-flex align-items-center p-0"
									style={{
										background: "transparent",
									}}
								>
									<span className="flex-grow-1 text-muted">
										{attachment.name}
									</span>
									<button
										className="btn p-0"
										onClick={() =>
											onDownloadAttachment(
												attachment.name,
												attachment.url
											)
										}
									>
										<BiDownload />
									</button>
								</div>
							);
						})}
					</>
				)}
				{delivery.status === "rejected" && delivery.rejectReason && (
					<Fragment>
						<hr className="my-3"></hr>
						<p className="m-0">Reject Reason:</p>
						<div
							className={`${styles["description"]} text-muted`}
							dangerouslySetInnerHTML={{
								__html: delivery.rejectReason,
							}}
						></div>
					</Fragment>
				)}
			</div>
		</Fragment>
	);
};

export default MilestoneDeliveryListItem;
