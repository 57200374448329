import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setAlert } from "../../../actions/alert";
import { sendMilestonePaymentRequestAction } from "../../../actions/milestone";
import {
	fetchProjectAgreements,
	hireDeveloperAction,
	markProjectCompleteAction,
} from "../../../actions/project";
import { formatTime } from "../../../utils/formatTime";
import ReviewModal from "../../Modals/ReviewModal/ReviewModal";
import SignAgreementsModal from "../../Modals/SignAgreementsModal/SignAgreementsModal";
import ChatAttachmentItem from "../ChatAttachmentItem/ChatAttachmentItem";

import styles from "./messageItem.module.css";

const MessageItem = ({ activeChat, ...props }) => {
	const dispatch = useDispatch();

	const authState = useSelector((state) => state.auth);

	const [isLoading, setIsLoading] = useState(false);
	const [showSignAgreementModal, setShowSignAgreementModal] = useState(false);
	const [showReviewModal, setShowReviewModal] = useState(false);
	const [projectAgreements, setProjectAgreements] = useState([]);
	const [hireRequestBody, setHireRequestBody] = useState({});
	const [projectId, setProjectId] = useState("");
	const [reviewedTo, setReviewedTo] = useState("");

	const hireDeveloper = async (
		chatId,
		msgId,
		action,
		actionData,
		signedAgreements
	) => {
		const requestBody = {
			chatId,
			msgId: msgId,
			action: action,
			actionData: actionData,
			signedAgreements,
		};
		const response = await dispatch(hireDeveloperAction(requestBody));
		if (response.status) {
			dispatch(setAlert(response.msg, "success"));
		} else {
			dispatch(setAlert(response.msg, "danger"));
		}
	};

	const onMsgAction = async (type, action, chatId, msg) => {
		setIsLoading(true);

		let requestBody;
		let response;

		switch (type) {
			case "hire":
				requestBody = {
					chatId,
					msgId: msg._id,
					action: action,
					actionData: msg.action.actionData,
				};

				if (action === "accepted") {
					const projectAgreementsResponse = await dispatch(
						fetchProjectAgreements(
							msg?.action?.actionData?.projectId
						)
					);
					if (
						projectAgreementsResponse.status &&
						projectAgreementsResponse.data.length > 0
					) {
						setProjectAgreements(projectAgreementsResponse.data);
						setHireRequestBody(requestBody);
						setShowSignAgreementModal(true);
						break;
					}
				}

				await hireDeveloper(
					chatId,
					msg._id,
					action,
					msg.action.actionData
				);

				break;
			case "payment":
				requestBody = {
					userId: authState.user._id,
					chatId,
					msgId: msg._id,
					action: action,
					actionData: msg.action.actionData,
				};
				response = await dispatch(
					sendMilestonePaymentRequestAction(requestBody)
				);
				if (response.status) {
					dispatch(setAlert(response.msg, "success"));
				} else {
					dispatch(setAlert(response.msg, "danger"));
				}
				setIsLoading(false);
				break;
			case "complete":
				requestBody = {
					// userId: authState.user._id,
					chatId,
					msg: msg,
					action: action,
					// actionData: msg.action.actionData,
				};
				response = await dispatch(
					markProjectCompleteAction(
						msg.action.actionData.projectId,
						requestBody
					)
				);
				if (response.status) {
					dispatch(setAlert(response.msg, "success"));
					setProjectId(msg.action.actionData.projectId);
					setReviewedTo(msg.user?._id);
					setShowReviewModal(true);
				} else {
					dispatch(setAlert(response.msg, "danger"));
				}
				// await socketState.socket.emit(
				//     "mark-project-complete-action-taken",
				//     {
				//         msg: msg,
				//         chatId: chatId,
				//         authId: authState.user._id,
				//         action: action,
				//     }
				// );
				setIsLoading(false);

				break;
			default:
				setIsLoading(false);

				break;
		}

		setIsLoading(false);
	};

	return (
		<Fragment>
			{props.msg.user?._id === authState.user._id ? (
				<Fragment>
					<div className="d-flex flex-column">
						<div className={`${styles["sender-chat-bubble"]}`}>
							<p className="m-0">
								{props.msg.msgType === "message" && (
									<span
										dangerouslySetInnerHTML={{
											__html: props.msg.message,
										}}
									></span>
								)}
								{props.msg.msgType === "attachment" && (
									<ChatAttachmentItem
										attachment={props.msg.attachment}
									/>
								)}
								{props.msg.msgType &&
									props.msg.msgType !== "message" &&
									props.msg.msgType !== "meeting" &&
									props.msg.msgType !== "attachment" && (
										<Fragment>
											{props.msg.message}
											<span
												className={`${styles["action-status"]}`}
											>
												&nbsp; -{" "}
												{props.msg.action.actionTaken}
											</span>
										</Fragment>
									)}
							</p>

							<div className={`${styles["chat-action"]}`}>
								{props.msg.msgType === "meeting" && (
									<Fragment>
										<Link
											to={`/video-chat/${props.msg.action.actionData.roomId}`}
											className={`btn ${styles["btn-chat-primary"]}`}
										>
											Join Meeting
										</Link>
									</Fragment>
								)}
							</div>

							<div className="text-end">
								<small className={`${styles["msg-time"]}`}>
									{formatTime(
										props.msg.createdAt,
										authState.user.profile.timezone
									)}
								</small>
							</div>
						</div>

						{props.index === 0 && (
							<small className="received-name mx-2 ms-auto">
								{props.msg.user
									? props.msg.user.name
									: props.msg.botName}
							</small>
						)}

						{props.index !== 0 &&
							activeChat.messages[props.index].user?._id !==
								activeChat.messages[props.index - 1].user
									?._id && (
								<small className="received-name mx-2 ms-auto">
									{props.msg.user
										? props.msg.user.name
										: props.msg.botName}
								</small>
							)}
					</div>
				</Fragment>
			) : (
				<Fragment>
					<div className="d-flex flex-column">
						<div className={`${styles["chat-bubble"]}`}>
							<p className="m-0">
								{props.msg.msgType === "message" && (
									<span
										dangerouslySetInnerHTML={{
											__html: props.msg.message,
										}}
									></span>
								)}
								{props.msg.msgType === "attachment" && (
									<ChatAttachmentItem
										attachment={props.msg.attachment}
									/>
								)}
								{props.msg.msgType &&
									props.msg.msgType !== "message" &&
									props.msg.msgType !== "meeting" &&
									props.msg.msgType !== "attachment" && (
										<Fragment>
											{props.msg.message}
											<span
												className={`${styles["action-status"]}`}
											>
												&nbsp; -{" "}
												{props.msg.action.actionTaken}
											</span>
										</Fragment>
									)}
							</p>

							<div className={`${styles["chat-action"]}`}>
								{(props.msg.msgType === "hire" ||
									props.msg.msgType === "payment" ||
									props.msg.msgType === "complete") &&
								props.msg.action.actionTaken === "pending" ? (
									<Fragment>
										<button
											className={`btn ${styles["btn-chat-primary"]} me-1`}
											disabled={isLoading}
											onClick={() =>
												onMsgAction(
													props.msg.msgType,
													"accepted",
													activeChat._id,
													props.msg
												)
											}
										>
											Accept
										</button>
										<button
											className={`btn ${styles["btn-chat-secondary"]}`}
											disabled={isLoading}
											onClick={() =>
												onMsgAction(
													props.msg.msgType,
													"rejected",
													activeChat._id,
													props.msg
												)
											}
										>
											Reject
										</button>
									</Fragment>
								) : props.msg.msgType === "meeting" ? (
									<Fragment>
										<Link
											to={`/video-chat/${props.msg.action.actionData.roomId}`}
											className={`btn ${styles["btn-chat-primary"]}`}
										>
											Join Meeting
										</Link>
									</Fragment>
								) : (
									<Fragment></Fragment>
								)}
								{isLoading && (
									<img
										src="/img/loader.gif"
										alt="Loading"
										className={`web-loader ${styles["webloader"]} ms-2`}
									/>
								)}
							</div>
							<div className="text-end">
								<small className={`${styles["msg-time"]}`}>
									{formatTime(
										props.msg.createdAt,
										authState.user.profile.timezone
									)}
								</small>
							</div>
						</div>

						{props.index === 0 && (
							<small className="received-name mx-2">
								{props.msg.user
									? props.msg.user.name
									: props.msg.botName}
							</small>
						)}

						{props.index !== 0 &&
							activeChat.messages[props.index].user?._id !==
								activeChat.messages[props.index - 1].user
									?._id && (
								<small className="received-name mx-2">
									{props.msg.user
										? props.msg.user.name
										: props.msg.botName}
								</small>
							)}
					</div>
				</Fragment>
			)}

			<SignAgreementsModal
				isOpen={showSignAgreementModal}
				onClose={setShowSignAgreementModal}
				projectAgreements={projectAgreements}
				onAccept={async (signedAgreements) =>
					await hireDeveloper(
						hireRequestBody.chatId,
						hireRequestBody.msgId,
						hireRequestBody.action,
						hireRequestBody.actionData,
						signedAgreements
					)
				}
			/>

			<ReviewModal
				isOpen={showReviewModal}
				onClose={setShowReviewModal}
				projectId={projectId}
				reviewedTo={reviewedTo}
				reviewAs="developer"
			/>
		</Fragment>
	);
};

export default MessageItem;
