import { CLEAR_ERROR, ON_ERROR, ON_FORBIDDEN } from "../actions/types";

const initialState = {
    isError: false,
    isForbidden: false,
};

const error = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
        case ON_ERROR:
            return {
                ...state,
                isError: true,
            };
        case ON_FORBIDDEN:
            return {
                ...state,
                isForbidden: true,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                isError: false,
                isForbidden: false,
            };
        default:
            return state;
    }
};

export default error;
