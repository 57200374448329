import React, { Fragment, useState } from "react";

import parse from "html-react-parser";

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value + 1); // update the state to force render
}

const DefaultInput = ({
    field,
    onChangeValue,
    sp,
    required,
    nested = false,
    formData,
}) => {
    const forceUpdate = useForceUpdate();

    const onValue = (e) => {
        if (sp !== null && sp !== undefined && sp !== {}) {
            onChangeValue(e, sp.dataName);
            forceUpdate();
        } else {
            onChangeValue(e);
        }
    };

    return (
        <Fragment>
            <div className="form-group">
                {nested ? (
                    <h6 className="mt-3 mb-2">{field.fieldName}</h6>
                ) : (
                    <h3 className="my-3">{field.fieldName}</h3>
                )}
                {field.fieldDesc && field.fieldDesc !== "" ? (
                    <div className="text-muted mb-3">
                        {parse(field.fieldDesc)}
                    </div>
                ) : (
                    ""
                )}
                <input
                    autoComplete="off"
                    type={field.fieldType}
                    name={field.dataName}
                    value={formData[field.dataName]}
                    onChange={(e) => onValue(e)}
                    placeholder="Type Here..."
                    className="dark-input rounded form-control"
                    required={required}
                    style={{
                        padding: "7px",
                        border: "0px",
                    }}
                />
            </div>
        </Fragment>
    );
};

export default DefaultInput;
