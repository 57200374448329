import React, { Fragment, useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";

import styles from "./ProfileOverview.module.css";
import { getApiEndpoint } from "../../../utils/getApiEndpoint";
import { ProfileContext } from "../../../contexts/ProfileContext";

const ProfileOverview = (props) => {
    const profileContext = useContext(ProfileContext);

    const [localtime, setlocaltime] = useState("");

    const authState = useSelector((state) => state.auth);

    useEffect(() => {
        const time = new Date().toLocaleString("en-US", {
            timeZone: profileContext.profile.timezone,
            timeStyle: "short",
        });

        setlocaltime(time);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const profileRating = () => {
        const starRating = [];
        for (let i = 1; i < 6; i++) {
            if (profileContext.profile.profileRating >= i) {
                starRating.push(
                    <span key={i} className="material-icons">
                        star
                    </span>
                );
            } else {
                starRating.push(
                    <span key={i} className="material-icons">
                        star_border
                    </span>
                );
            }
        }
        return starRating;
    };

    return (
        <Fragment>
            <section
                className={`${styles["profile-section"]} light-bg p-4 my-lg-3 mb-3 text-center`}
            >
                <div className={`${styles["profile-icon"]}`}>
                    {profileContext.profile.profileImgUrl &&
                    profileContext.profile.profileImgUrl !== "" ? (
                        <Fragment>
                            <img
                                className="profile-pic-xl"
                                src={
                                    getApiEndpoint() +
                                    profileContext.profile.profileImgUrl
                                }
                                alt="Profile"
                            />
                        </Fragment>
                    ) : (
                        <Fragment>
                            <img
                                className="profile-pic-xl"
                                src="/img/profile-image.png"
                                alt="Profile"
                            />
                        </Fragment>
                    )}
                    {/* <img
						className="profile-pic-xl"
						src="/img/profile-image.png"
						alt="Profile"
					/> */}
                    {authState.user !== null ? (
                        <Fragment>
                            {profileContext.profile.user._id ===
                            authState.user._id ? (
                                <Fragment>
                                    <div
                                        className={`${styles["profile-edit-btn"]} d-inline`}
                                        onClick={props.toggleMode}
                                    >
                                        <span className="material-icons">
                                            create
                                        </span>
                                    </div>
                                </Fragment>
                            ) : (
                                ""
                            )}
                        </Fragment>
                    ) : (
                        ""
                    )}
                </div>
                <h5 className="text-capitalize mt-4">
                    {profileContext.profile.user.name}
                </h5>
                {authState.user !== null ? (
                    <Fragment>
                        {authState.user.userType.toLowerCase() ===
                        "recruiter" ? (
                            <Fragment>
                                <small className="ml-2 mb-0 notification d-inline px-2">
                                    Recruiter
                                </small>
                            </Fragment>
                        ) : (
                            ""
                        )}
                    </Fragment>
                ) : (
                    ""
                )}
                <div className={`${styles["profile-rating"]} mt-2`}>
                    <div
                        className={`${styles["stars"]} d-flex justify-content-center`}
                    >
                        {profileRating()}
                    </div>
                </div>
                <div className={`${styles["profile-tagline"]} mt-1`}>
                    {profileContext.profile.tagline}
                </div>

                <div className={`${styles["profile-details"]} mt-4`}>
                    {profileContext.profile.hourlyRate.amount
                        ? profileContext.profile.hourlyRate.amount !== 0 && (
                              <div className={`${styles["detail-one"]} mt-1`}>
                                  <p>
                                      {profileContext.profile.hourlyRate
                                          .currency +
                                          " " +
                                          profileContext.profile.hourlyRate
                                              .amount}{" "}
                                      per hour
                                  </p>
                              </div>
                          )
                        : ""}
                    <div className={`${styles["detail-one"]} mt-1`}>
                        <p>0 Projects Worked</p>
                    </div>
                    <div className={`${styles["detail-one"]} mt-1`}>
                        <p>From {profileContext.profile.country.name}</p>
                    </div>
                    <div className={`${styles["detail-one"]} mt-1`}>
                        <p>
                            {localtime} ({profileContext.profile.timezone})
                        </p>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default ProfileOverview;
