import { getApiEndpoint } from "../utils/getApiEndpoint";
import { requestWithToken } from "../utils/httpRequest";
import { error } from "./error";

// Schedule New Meeting
export const scheduleNewMeeting = (body) => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "POST",
            `${getApiEndpoint()}/scheduled-meeting`,
            body
        );

        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Fetch Scheduled Meeting For User
export const fetchScheduledMeetings = () => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/scheduled-meeting`
        );

        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Update meeting action for invited user
export const updateMeetingInvitationAction = (body) => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "PUT",
            `${getApiEndpoint()}/scheduled-meeting/action`,
            body
        );

        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Add meeting to google calendar
export const addMeetingToGoogleCalendar = (formData) => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "POST",
            `${getApiEndpoint()}/calendar/google`,
            formData
        );

        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};
