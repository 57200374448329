import React, { Fragment } from "react";

import parse from "html-react-parser";

const Radio = ({
    field,
    onChangeOptions,
    sp,
    required,
    nested = false,
    formData,
}) => {
    const onOption = (e) => {
        if (sp !== null && sp !== undefined && sp !== {}) {
            onChangeOptions(e, field.dataName, sp.dataName);
        } else {
            onChangeOptions(e, field.dataName);
        }
    };

    return (
        <Fragment>
            <div className="form-group">
                {nested ? (
                    <h6 className="mt-3 mb-2">{field.fieldName}</h6>
                ) : (
                    <h3 className="my-3">{field.fieldName}</h3>
                )}
                {field.fieldDesc && field.fieldDesc !== "" ? (
                    <div className="text-muted mb-3">
                        {parse(field.fieldDesc)}
                    </div>
                ) : (
                    ""
                )}
                {field.fieldOptions.length !== 0 ? (
                    <Fragment>
                        <div className="">
                            {field.fieldOptions.map((op) => {
                                return (
                                    <Fragment key={op._id}>
                                        <div className="radio d-flex">
                                            <label
                                                className="radio-one d-flex align-items-center p-1 m-0"
                                                htmlFor={op._id}
                                            >
                                                <input
                                                    id={op._id}
                                                    type="radio"
                                                    name={field.dataName}
                                                    value={JSON.stringify(op)}
                                                    onChange={(e) =>
                                                        onOption(e)
                                                    }
                                                    required={required}
                                                    checked={
                                                        formData[field.dataName]
                                                            ._id === op._id
                                                    }
                                                />
                                                <span className="radio-custom me-2"></span>
                                                <p className="ml-3 mb-0">
                                                    {op.optionText}
                                                </p>
                                            </label>
                                        </div>
                                    </Fragment>
                                );
                            })}
                        </div>
                    </Fragment>
                ) : (
                    ""
                )}
            </div>
        </Fragment>
    );
};

export default Radio;
