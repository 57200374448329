import styles from "./Tooltip.module.css";

const Tooltip = ({ children }) => {
    return <div className={`${styles["tooltip-container"]}`}>{children}</div>;
};

export const SelectMilestoneTooltip = () => {
    return (
        <Tooltip>
            Select milestones in which you are interested.
            <br />
            You can select all milestones as well to bid on full project.
        </Tooltip>
    );
};
