import { getApiEndpoint } from "../../utils/getApiEndpoint";

const AttachmentPreviewIcon = ({ name, url, width }) => {
	if (
		name.includes(".jpg") ||
		name.includes(".png") ||
		name.includes(".jpeg")
	) {
		return (
			<img
				src={
					url.includes("blob:http://")
						? url
						: `${getApiEndpoint() + url}`
				}
				alt="preview"
				width={width}
			/>
		);
	} else if (name.includes(".pdf")) {
		return <img src="/icons/pdf.png" alt="icon" width={width} />;
	} else if (name.includes(".doc") || name.includes(".docx")) {
		return <img src="/icons/doc.png" alt="icon" width={width} />;
	} else if (name.includes(".xls") || name.includes(".xlsx")) {
		return <img src="/icons/xls.png" alt="icon" width={width} />;
	} else if (name.includes(".ppt") || name.includes(".pptx")) {
		return <img src="/icons/ppt.png" alt="icon" width={width} />;
	} else {
		<img src="/icons/file.png" alt="icon" width={width} />;
	}
};

export default AttachmentPreviewIcon;
