import { getApiEndpoint } from "../utils/getApiEndpoint";
import { requestWithToken } from "../utils/httpRequest";
import { error } from "./error";

// Apply Referral Code
export const applyReferralCode = (body) => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "POST",
            `${getApiEndpoint()}/referral`,
            body
        );

        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Fetch My Referrals
export const fetchMyReferrals = () => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/referral/me`
        );

        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Fetch My Active Referral
export const fetchMyActiveReferral = () => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/referral/me/active`
        );

        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};
