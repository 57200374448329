import React, { Fragment, useEffect, useState } from "react";

import styles from "./skeleton.module.css";

const Skeleton = (props) => {
    const [height, setHeight] = useState("20px");
    const [width, setWidth] = useState("100%");
    const [radius, setRadius] = useState("5px");
    const [margin, setMargin] = useState("5px");

    useEffect(() => {
        if (props.height) {
            setHeight(props.height);
        }
        if (props.shape === "circle") {
            setRadius("50%");
            setHeight(props.height);
            setWidth(props.width);
        }
        if (props.margin) {
            setMargin(props.margin);
        }
        if (props.width) {
            setWidth(props.width);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <div
                className={`${styles["skeleton"]} mx-auto`}
                style={{
                    height: `${height}`,
                    width: `${width}`,
                    borderRadius: `${radius}`,
                    margin: `${margin}`,
                }}
            >
                <span
                    style={{ width: `150px ` }}
                    className={`${styles["shine"]} `}
                ></span>
            </div>
        </Fragment>
    );
};

export default Skeleton;
