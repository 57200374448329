import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FaRegStar, FaStar, FaStarHalfAlt } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import { saveBidRating } from "../../actions/bid";
import { getApiEndpoint } from "../../utils/getApiEndpoint";
import HireModal from "../Modals/HireModal/HireModal";
import ReviewModal from "../Modals/ReviewModal/ReviewModal";
import styles from "./bidListItem.module.css";
import EllipsisParagraph from "../utils/EllipsisParagraph/EllipsisParagraph";
import MilestoneBidsTermsMismatchModal from "../Modals/MilestoneBidsTermsMismatchModal/MilestoneBidsTermsMismatchModal";

const BidListItem = ({
	bid,
	projectOwnerId,
	customerFees,
	activeReferral,
	className,
}) => {
	const dispatch = useDispatch();

	const authState = useSelector((state) => state.auth);
	const socketState = useSelector((state) => state.socket);

	const [loaders, setLoaders] = useState({
		bidRatingLoading: false,
	});
	const [rating] = useState(0);
	const [bidCharges, setBidCharges] = useState(0);
	const [discountedBidCharges, setDiscountedBidCharges] = useState(0);
	const [textAreaBidId, setTextAreaBidId] = useState(null);
	const [initiatingMsg, setInitiatingMsg] = useState("");
	const [bidRating, setBidRating] = useState(bid.bidRating || -1);
	const [hireDevModalOpen, setHireDevModalOpen] = useState(false);
	const [milestoneBidMismatchOpen, setMilestoneBidMismatch] = useState(false);

	useEffect(() => {
		// Calculate bid charges to owner
		setBidCharges((bid.quote.amount * customerFees) / 100);

		// Calculate discount bid charges for the customer depending on referral benefits
		if (
			activeReferral &&
			activeReferral.referralBenefits.discountedHires > 0
		) {
			const discount = activeReferral.referralBenefits.discount;
			if (discount.type === "percentage") {
				setDiscountedBidCharges(
					(bid.quote.amount *
						((customerFees * (100 - discount.value)) / 100)) /
						100
				);
			}
		}

		// eslint-disable-next-line  react-hooks/exhaustive-deps
	}, [bid]);

	const onIntiateChatWithUser = (e, bidUserId) => {
		e.preventDefault();

		socketState.socket.emit("send-message", {
			msgData: {
				msg: initiatingMsg,
				userId: authState.user._id,
				otherUserId: bidUserId,
			},
		});
		setInitiatingMsg("");
		setTextAreaBidId(null);
	};

	const onSaveBidRating = async (rating) => {
		setLoaders({ ...loaders, bidRatingLoading: true });

		const response = await dispatch(
			saveBidRating({ bidId: bid._id, projectId: bid.project, rating })
		);
		if (response.status) {
			dispatch(setAlert(response.msg, "success"));
			setBidRating(rating);
			bid.bidRating = rating;
		} else {
			dispatch(setAlert(response.msg, "danger"));
		}

		setLoaders({ ...loaders, bidRatingLoading: false });
	};

	const onHireDeveloper = async () => {
		if (bid.milestones.length > 0) {
			if (bid.quote.amount !== bid.milestones[0].price.amount) {
				setMilestoneBidMismatch(true);
				return;
			}

			if (
				bid.deliveredIn.value !==
					bid.milestones[0].estimatedDuration.value ||
				bid.deliveredIn.scope !==
					bid.milestones[0].estimatedDuration.scope
			) {
				setMilestoneBidMismatch(true);
				return;
			}
		}

		setHireDevModalOpen(true);
	};

	return (
		<Fragment>
			<li className={`${className} bid-one p-4 mb-3`}>
				<div className="bid-user-info d-flex align-items-center">
					<img
						className="profile-pic-md me-3"
						src={
							bid.bidOwner.profileImgUrl
								? getApiEndpoint() + bid.bidOwner.profileImgUrl
								: "/img/profile-image.png"
						}
						alt="Bid User"
					/>
					<div className="bid-user me-3 flex-grow-1">
						<Link to={`/profile/${bid.user._id}`}>
							<h5>{bid.user.name}</h5>
						</Link>
						<small className="text-muted">
							{[...Array(Math.floor(rating))].map(
								(rat, index) => {
									return (
										<Fragment key={index}>
											<IconContext.Provider
												value={{
													color: "red",
												}}
											>
												<FaStar
													className="star justify-content-end align-items-end"
													size={20}
													style={{
														paddingBottom: "4px",
													}}
												/>
											</IconContext.Provider>
										</Fragment>
									);
								}
							)}
							{rating - Math.floor(rating) > 0 ? (
								<>
									<FaStarHalfAlt
										className="star justify-content-end align-items-end"
										size={20}
										style={{
											paddingBottom: "4px",
										}}
									/>
								</>
							) : (
								""
							)}
							{[...Array(Math.floor(5 - rating))].map(
								(rat, index) => {
									return (
										<Fragment key={index}>
											<FaRegStar
												className="star justify-content-end align-items-end"
												size={20}
												style={{
													paddingBottom: "4px",
												}}
											/>
										</Fragment>
									);
								}
							)}{" "}
							({rating} reviews)
						</small>
					</div>
					<div className="bid-price">
						<h5 className="job-rate">
							{discountedBidCharges > 0 ? (
								<Fragment>
									<span className="text-decoration-line-through text-small text-muted fw-lighter me-2">
										$ {bid.quote.amount + bidCharges}
									</span>{" "}
									${" "}
									{bid.quote.amount +
										bidCharges -
										discountedBidCharges}
								</Fragment>
							) : (
								<Fragment>
									$ {bid.quote.amount + bidCharges}
								</Fragment>
							)}
						</h5>
					</div>
				</div>
				<div className="bid-duration mt-3">
					<small className="m-0 text-muted bid-durationh5">
						Duration: {bid.deliveredIn.value}{" "}
						{bid.deliveredIn.scope}
					</small>
					{bid.milestones.length > 0 && (
						<>
							<small className="text-muted">
								&nbsp;&nbsp;|&nbsp;&nbsp;
							</small>
							<small className="m-0 text-muted bid-durationh5">
								Milestone:{" "}
								{bid.milestones.map((milestone, index) => {
									return (
										<span
											key={index}
											className="text-muted"
										>
											{index !== 0 && ", "}
											{milestone.title}
										</span>
									);
								})}
							</small>
						</>
					)}
				</div>
				<div className="bid-description">
					<EllipsisParagraph>{bid.description}</EllipsisParagraph>
				</div>
				{projectOwnerId === authState.user._id && (
					<Fragment>
						<div className="row mt-3">
							<div className="col-md-6">
								<div
									className={`d-flex ${styles["bid-rating-container"]}`}
								>
									{[...Array(5)].map((item, index) => {
										return (
											<div
												key={index}
												className={`${
													styles["bid-rating-item"]
												} ${
													bidRating >= index + 1 &&
													styles["selected"]
												}`}
												onMouseEnter={() => {
													setBidRating(index + 1);
												}}
												onMouseLeave={() => {
													setBidRating(
														bid.bidRating || -1
													);
												}}
												onClick={() => {
													onSaveBidRating(index + 1);
												}}
											>
												<span className="material-icons">
													done
												</span>
											</div>
										);
									})}
									{loaders.bidRatingLoading && (
										<img
											src="/img/loader.gif"
											width="20px"
											alt="loader"
										/>
									)}
								</div>
							</div>
							<div className="col-md-6">
								<div className="bid-options text-end">
									<button
										className="btn btn-primary-gradient outline me-2"
										onClick={(e) => {
											setTextAreaBidId(bid._id);
											setInitiatingMsg("");
										}}
										type="button"
									>
										Chat
									</button>

									{bid.status === "active" && (
										<div className="d-inline">
											<button
												type="button"
												className="btn btn-primary-gradient"
												onClick={onHireDeveloper}
											>
												Hire
											</button>
											<HireModal
												projectId={bid.project}
												bid={bid}
												hireType="developer"
												customerFee={customerFees}
												referral={activeReferral}
												isOpen={hireDevModalOpen}
												onClose={() =>
													setHireDevModalOpen(false)
												}
											/>
											<MilestoneBidsTermsMismatchModal
												isOpen={
													milestoneBidMismatchOpen
												}
												handleClose={() => {
													setMilestoneBidMismatch(
														false
													);
												}}
												handleSubmit={() => {
													setHireDevModalOpen(true);
												}}
												bid={bid}
												milestone={bid.milestones[0]}
											/>
										</div>
									)}
									{bid.status === "completed" ||
										(bid.status === "reviewed" && (
											<ReviewModal
												reviewedTo={bid.user._id}
												projectId={bid.project}
												user="customer"
											/>
										))}
								</div>
							</div>
						</div>
						{textAreaBidId === bid._id && (
							<>
								<div
									className={`${styles["chat-msg-options"]} mt-3`}
								>
									<hr></hr>
									<form
										className="d-flex gap-3"
										onSubmit={(e) =>
											onIntiateChatWithUser(
												e,
												bid.user._id
											)
										}
									>
										<input
											type="text"
											className="dark-input"
											value={initiatingMsg}
											onChange={(e) =>
												setInitiatingMsg(e.target.value)
											}
											placeholder="Type Your Message..."
											autoComplete="off"
										></input>

										<button
											className={`${styles["btn-send-msg"]}`}
											type="submit"
										>
											<span
												className={`material-icons text-white`}
											>
												send
											</span>
										</button>
									</form>
								</div>
							</>
						)}
					</Fragment>
				)}
			</li>
		</Fragment>
	);
};

BidListItem.propTypes = {
	className: PropTypes.string,
	bid: PropTypes.object.isRequired,
	projectOwnerId: PropTypes.string.isRequired,
	customerFees: PropTypes.number.isRequired,
	activeReferral: PropTypes.object,
};

BidListItem.defaultProps = {
	className: "light-bg",
	activeReferral: null,
};

export default BidListItem;
