import React, { Fragment, useEffect, useState } from "react";
import useStateRef from "react-usestateref";
import AgoraRTC from "agora-rtc-sdk-ng";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import useDynamicRefs from "use-dynamic-refs";

import styles from "./VideoChat.module.css";
import OverallVideoChat from "../../components/VideoChat/Overall";
import FocusVideoChat from "../../components/VideoChat/Focus/Focus";
import { fetchSelectedUserProfile } from "../../actions/profile";
import { generateToken } from "../../actions/videochat";

const VideoChat = () => {
    const [getRef, setRef] = useDynamicRefs();
    const dispatch = useDispatch();
    const history = useHistory();

    const { callId } = useParams();

    const [rtc, setRtc] = useState({
        client: null,
        screenClient: null,
        localAudioTrack: null,
        localVideoTrack: null,
        localScreenTrack: null,
    });

    const [remoteUsers, setRemoteUsers, remoteUsersRef] = useStateRef([]);
    const [videoEnabled, setVideoEnabled] = useState(true);
    const [audioEnabled, setAudioEnabled] = useState(true);
    const [screenEnabled, setScreenEnabled] = useState(false);
    const [cameras, setCameras] = useState([]);
    const [selectedCamera, setSelectedCamera] = useState(null);
    const [microphones, setMicrophones] = useState([]);
    const [selectedMicrophone, setSelectedMicrophone] = useState([]);
    const [gridViewSettings, setGridViewSettings] = useState({
        view: "overall",
        focused: null,
    });

    useEffect(() => {
        const getDevices = async () => {
            const cameraDevices = await AgoraRTC.getCameras();
            console.log(cameraDevices);
            setCameras(cameraDevices);
            setSelectedCamera(cameraDevices[0]);
            const microphoneDevices = await AgoraRTC.getMicrophones();
            console.log(microphoneDevices);
            setMicrophones(microphoneDevices);
            setSelectedMicrophone(microphoneDevices[0]);
        };

        getDevices();
        // startCall();
    }, []);

    useEffect(() => {
        // if (cameras.length === 0) {
        //     alert("No camera device detected!");
        // }

        // if (microphones.length === 0) {
        //     alert("No microphone device detected!");
        // }

        const startCall = async () => {
            if (cameras.length === 0) {
                setRtc({
                    client: AgoraRTC.createClient({
                        mode: "rtc",
                        codec: "vp8",
                    }),
                    localAudioTrack:
                        await AgoraRTC.createMicrophoneAudioTrack(),
                });
            } else if (microphones.length === 0) {
                setRtc({
                    client: AgoraRTC.createClient({
                        mode: "rtc",
                        codec: "vp8",
                    }),
                    localVideoTrack: await AgoraRTC.createCameraVideoTrack({
                        encoderConfig: "480p_1",
                    }),
                });
            } else {
                setRtc({
                    client: AgoraRTC.createClient({
                        mode: "rtc",
                        codec: "vp8",
                    }),
                    localVideoTrack: await AgoraRTC.createCameraVideoTrack({
                        encoderConfig: "480p_1",
                    }),
                    localAudioTrack:
                        await AgoraRTC.createMicrophoneAudioTrack(),
                });
            }
        };

        if (microphones.length > 0) {
            startCall();
            console.log(rtc);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cameras, microphones]);

    useEffect(() => {
        const initClient = async () => {
            console.log(rtc);
            const options = await dispatch(generateToken(callId));

            await rtc.client.join(
                options.appId,
                callId,
                options.token,
                options.uid
            );

            rtc.client.on("user-published", async (user, mediaType) => {
                await rtc.client.subscribe(user, mediaType);

                if (mediaType === "video") {
                }

                if (mediaType === "audio") {
                    const remoteAudioTrack = user.audioTrack;
                    remoteAudioTrack.play();
                }

                const isScreenShare = user.uid
                    .toString()
                    .includes("screenshare");

                const userDetails = await dispatch(
                    fetchSelectedUserProfile(
                        isScreenShare
                            ? user.uid.toString().slice(0, -11)
                            : user.uid.toString()
                    )
                );

                user.isScreenShare = isScreenShare;
                user.userDetails = userDetails.data;

                const remoteUserIndex = remoteUsersRef.current.findIndex(
                    (remoteUser) => remoteUser.uid === user.uid
                );
                if (remoteUserIndex !== -1) {
                    setRemoteUsers(
                        remoteUsersRef.current.splice(remoteUserIndex, 1, user)
                    );
                } else {
                    setRemoteUsers([...remoteUsersRef.current, user]);
                }
            });

            rtc.client.on("user-unpublished", (user, mediaType) => {
                if (mediaType === "video") {
                    user.videoTrack?.stop();
                }

                if (mediaType === "audio") {
                    user.audioTrack?.stop();
                }

                const remoteUserIndex = remoteUsersRef.current.findIndex(
                    (remoteUser) => remoteUser.uid === user.uid
                );
                setRemoteUsers(
                    remoteUsersRef.current.splice(remoteUserIndex, 1, user)
                );
            });

            rtc.client.on("user-left", (user) => {
                setRemoteUsers(
                    remoteUsersRef.current.filter(
                        (remoteUser) => remoteUser.uid !== user.uid
                    )
                );
            });

            if (rtc.localVideoTrack) {
                await rtc.client.publish([
                    rtc.localAudioTrack,
                    rtc.localVideoTrack,
                ]);
            } else {
                await rtc.client.publish([rtc.localAudioTrack]);
            }
        };

        if (rtc.client) {
            initClient();
        }

        // eslint-disable-next-line
    }, [rtc.client]);

    const toggleGridMode = (userId = null) => {
        remoteUsers.map((remoteUser) => remoteUser.videoTrack?.stop());

        if (gridViewSettings.focused !== userId) {
            setGridViewSettings({ view: "focus", focused: userId });
        } else {
            if (gridViewSettings.view === "overall") {
                setGridViewSettings({ view: "focus", focused: userId });
            } else if (gridViewSettings.view === "focus") {
                setGridViewSettings({ view: "overall", focused: null });
            }
        }
    };

    const onToggleVideo = async () => {
        if (
            rtc.client?.localTracks.some(
                (track) => track.trackMediaType === "video"
            )
        ) {
            rtc.localVideoTrack?.stop();
            setVideoEnabled(false);
            await rtc.client?.unpublish([rtc.localVideoTrack]);
        } else {
            setVideoEnabled(true);
            const localVideoRef = getRef("localVideo");
            rtc.localVideoTrack?.play(localVideoRef.current);
            await rtc.client?.publish([rtc.localVideoTrack]);
        }
    };

    const onToggleAudio = async () => {
        if (
            rtc.client?.localTracks.some(
                (track) => track.trackMediaType === "audio"
            )
        ) {
            setAudioEnabled(false);
            await rtc.client?.unpublish([rtc.localAudioTrack]);
        } else {
            setAudioEnabled(true);
            await rtc.client?.publish([rtc.localAudioTrack]);
        }
    };

    const onToggleScreenShare = async () => {
        if (!screenEnabled) {
            const options = await dispatch(
                generateToken(callId, "screenshare")
            );

            rtc.screenClient = AgoraRTC.createClient({
                mode: "rtc",
                codec: "vp8",
            });
            rtc.localScreenTrack = await AgoraRTC.createScreenVideoTrack();

            await rtc.screenClient?.join(
                options.appId,
                callId,
                options.token,
                options.uid
            );

            await rtc.screenClient?.publish([rtc.localScreenTrack]);
            setScreenEnabled(true);
        } else {
            rtc.localScreenTrack.close();
            await rtc.screenClient.leave();
            rtc.screenClient.removeAllListeners();

            setScreenEnabled(false);
        }
    };

    const onLeave = async () => {
        if (rtc) {
            rtc.localAudioTrack?.close();
            rtc.localVideoTrack?.close();
            rtc.localScreenTrack?.close();

            await rtc.client?.leave();
            await rtc.screenClient?.leave();

            rtc.client.removeAllListeners();
            rtc.screenClient?.removeAllListeners();
        }

        history.push("/messages");
    };

    const onToggleFullScreen = () => {
        const videoCallContainer = getRef("VideoCallContainer");
        videoCallContainer.current.requestFullscreen();
    };

    const onChangeCamera = (camera) => {
        setSelectedCamera(camera);
        rtc.localVideoTrack?.setDevice(camera.deviceId);
    };

    const onChangeMicrophone = (microphone) => {
        setSelectedMicrophone(microphone);
        rtc.localAudioTrack?.setDevice(microphone.deviceId);
    };

    const renderGridView = () => {
        switch (gridViewSettings.view) {
            case "overall":
                return (
                    <OverallVideoChat
                        rtc={rtc}
                        remoteUsers={remoteUsers}
                        toggleGridMode={toggleGridMode}
                        videoEnabled={videoEnabled}
                        audioEnabled={audioEnabled}
                    />
                );
            case "focus":
                return (
                    <FocusVideoChat
                        rtc={rtc}
                        remoteUsers={remoteUsers}
                        toggleGridMode={toggleGridMode}
                        focused={gridViewSettings.focused}
                        videoEnabled={videoEnabled}
                        audioEnabled={audioEnabled}
                    />
                );
            default:
                break;
        }
    };

    return (
        <Fragment>
            <div
                ref={setRef("VideoCallContainer")}
                className={`${styles["video-chat-page"]}`}
            >
                <main className={`${styles["video-chat"]} p-4 m-0`}>
                    {renderGridView()}
                </main>
                <div
                    className={`${styles["video-call-options"]} d-flex justify-content-center align-items-center`}
                >
                    <div className="options-left">
                        <div className="d-inline-block dropup">
                            <button
                                className={`btn ${styles["btn-hollow"]}`}
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <span className="material-icons mt-2">
                                    more_vert
                                </span>
                            </button>
                            <ul className="dropdown-menu">
                                <li>
                                    <h6 className="dropdown-header">
                                        More Options
                                    </h6>
                                </li>
                                <li
                                    className="dropdown-item"
                                    onClick={() => onToggleFullScreen()}
                                >
                                    Fullscreen Mode
                                </li>
                            </ul>
                        </div>
                        <div className="d-inline-block me-4">&nbsp;</div>
                        <button
                            className={`${styles["btn-hollow"]} ${
                                screenEnabled ? styles["active"] : ""
                            } btn`}
                            onClick={() => onToggleScreenShare()}
                        >
                            <span className="material-icons mt-2">
                                present_to_all
                            </span>
                        </button>
                    </div>
                    <button
                        className={`${styles["call-disconnect"]} btn`}
                        onClick={() => onLeave()}
                    >
                        <span className="material-icons mt-2">call_end</span>
                    </button>
                    <div className="options-right">
                        {rtc.client ? (
                            <Fragment>
                                {rtc.localVideoTrack ? (
                                    <Fragment>
                                        <div className="position-relative d-inline-block dropup">
                                            <button
                                                className={`${
                                                    styles["btn-hollow"]
                                                } ${
                                                    !videoEnabled
                                                        ? styles["active"]
                                                        : ""
                                                } btn`}
                                                onClick={() => onToggleVideo()}
                                            >
                                                <span className="material-icons mt-2">
                                                    videocam_off
                                                </span>
                                            </button>
                                            <button
                                                className={`btn ${styles["btn-hollow"]} ${styles["more-options"]}`}
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                <span className="material-icons">
                                                    expand_more
                                                </span>
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li>
                                                    <h6 className="dropdown-header">
                                                        Select Camera
                                                    </h6>
                                                </li>
                                                {cameras.map(
                                                    (camera, index) => {
                                                        return (
                                                            <li
                                                                key={index}
                                                                className="dropdown-item"
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    checked={
                                                                        camera.deviceId ===
                                                                        selectedCamera.deviceId
                                                                    }
                                                                    onChange={() =>
                                                                        onChangeCamera(
                                                                            camera
                                                                        )
                                                                    }
                                                                />{" "}
                                                                {camera.label}
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                        </div>
                                    </Fragment>
                                ) : (
                                    ""
                                )}
                                <div className="d-inline-block me-4">
                                    &nbsp;
                                </div>
                                {rtc.localAudioTrack ? (
                                    <Fragment>
                                        <div className="position-relative d-inline-block dropup">
                                            <button
                                                className={`${
                                                    styles["btn-hollow"]
                                                } ${
                                                    !audioEnabled
                                                        ? styles["active"]
                                                        : ""
                                                } btn`}
                                                onClick={() => onToggleAudio()}
                                            >
                                                <span className="material-icons mt-2">
                                                    mic_off
                                                </span>
                                            </button>
                                            <button
                                                className={`btn ${styles["btn-hollow"]} ${styles["more-options"]}`}
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                <span className="material-icons">
                                                    expand_more
                                                </span>
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li>
                                                    <h6 className="dropdown-header">
                                                        Select Microphone
                                                    </h6>
                                                </li>
                                                {microphones.map(
                                                    (microphone, index) => {
                                                        return (
                                                            <li
                                                                key={index}
                                                                className="dropdown-item"
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    checked={
                                                                        microphone.deviceId ===
                                                                        selectedMicrophone.deviceId
                                                                    }
                                                                    onChange={() =>
                                                                        onChangeMicrophone(
                                                                            microphone
                                                                        )
                                                                    }
                                                                />{" "}
                                                                {
                                                                    microphone.label
                                                                }
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                        </div>
                                    </Fragment>
                                ) : (
                                    ""
                                )}
                            </Fragment>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default VideoChat;
