import { withStyles } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        paddingBottom: 0,
        background: "#0c0c0c",
        color: "#c5c5c5",
        border: "1px solid red",
        borderBottom: "none",
        borderRadius: "5px 5px 0px 0px",
    },
    actions: {
        borderTop: "1px solid #000000",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const CustomizedDialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            {children}
        </MuiDialogTitle>
    );
});

const CustomizedDialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        background: "#0c0c0c",
        color: "#c5c5c5",
        border: "1px solid red",
        borderTop: "none",
        borderRadius: "0px 0px 5px 5px",
    },
}))(MuiDialogContent);

export { CustomizedDialogTitle, CustomizedDialogContent };
