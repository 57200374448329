import React, { Fragment, useState } from "react";

import { addExperience } from "./../../../actions/profile";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../actions/alert";

const AddExperience = ({ toggleMode, reloadProfile }) => {
    const dispatch = useDispatch();
    const [disable, setDisable] = useState(false);
    const [formData, setFormData] = useState({
        title: "",
        company: "",
        from: "",
        to: "",
        current: false,
        description: "",
    });

    const { title, company, from, to, current, description } = formData;
    const onChangeValue = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onChangeCheckbox = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.checked });
    };

    const onFormSubmit = async (e) => {
        e.preventDefault();
        setDisable(true);
        const response = await dispatch(addExperience(formData));
        if (response) {
            dispatch(setAlert(response.msg, "success"));

            reloadProfile();
            toggleMode(false);
        }
        setDisable(false);
    };

    return (
        <Fragment>
            <div className="section-body px-3">
                <form onSubmit={(e) => onFormSubmit(e)}>
                    <div className="mb-2">
                        <div className="row">
                            <div className="col-md-6">
                                <label className="form-label">Title</label>
                                <input
                                    type="text"
                                    className="dark-input"
                                    name="title"
                                    placeholder="Title"
                                    value={title}
                                    onChange={(e) => onChangeValue(e)}
                                    required
                                />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Company</label>
                                <input
                                    type="text"
                                    className="dark-input"
                                    name="company"
                                    placeholder="Company"
                                    value={company}
                                    onChange={(e) => onChangeValue(e)}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mb-2">
                        <div className="row">
                            <div className="col-md-6">
                                <label className="form-label">From Date</label>
                                <input
                                    type="date"
                                    className="dark-input"
                                    name="from"
                                    value={from}
                                    onChange={(e) => onChangeValue(e)}
                                    required
                                />
                            </div>
                            {formData.current ? (
                                <div className="col-md-6"></div>
                            ) : (
                                <div className="col-md-6">
                                    <label className="form-label">
                                        To Date
                                    </label>

                                    <input
                                        type="date"
                                        className="dark-input"
                                        name="to"
                                        value={to}
                                        onChange={(e) => onChangeValue(e)}
                                        required
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="form-group mb-2">
                        <label className="m-0">
                            <input
                                type="checkbox"
                                name="current"
                                checked={current}
                                onChange={(e) => onChangeCheckbox(e)}
                            />{" "}
                            I am currently working here
                        </label>
                    </div>
                    <div className="mb-2">
                        <label className="form-label">Description</label>
                        <textarea
                            className="dark-input"
                            name="description"
                            rows="5"
                            placeholder="Describe your work experience"
                            value={description}
                            onChange={(e) => onChangeValue(e)}
                        ></textarea>
                    </div>
                    <div className="section-options mb-3">
                        <button
                            disabled={disable}
                            type="submit"
                            className="btn-save me-2"
                        >
                            Save{" "}
                            {disable ? (
                                <img
                                    src="/img/loader.gif"
                                    alt="loader"
                                    width="25px"
                                />
                            ) : (
                                ""
                            )}
                        </button>
                        <button
                            className="btn-cancel"
                            onClick={() => toggleMode(false)}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </Fragment>
    );
};

export default AddExperience;
