import React, { Fragment, useState } from "react";
import { Dialog } from "@material-ui/core";
import { setAlert } from "../../../actions/alert";
import { buyPlan } from "./../../../actions/plan";
import { useDispatch } from "react-redux";
import { createPaypalPayment } from "../../../actions/paypal";
import {
    CustomizedDialogContent,
    CustomizedDialogTitle,
} from "../CustomizedModal/CustomizedModal";
import LoadingButton from "../../utils/LoadingButton/LoadingButton";

const GetPlan = ({ profile, plan, refreshPlanPage }) => {
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [loading, setLoading] = useState(false);

    const toggleDialog = () => {
        profile.currentPlan && setIsActive(true);
        setOpen(!open);
    };

    const onBuyPlan = async (e, planId, paymentMode) => {
        setLoading(true);
        switch (paymentMode) {
            case "wallet":
                const walletResponse = await dispatch(
                    buyPlan(planId, paymentMode)
                );
                if (walletResponse.status) {
                    refreshPlanPage();
                    toggleDialog();
                    dispatch(setAlert(walletResponse.msg, "success"));
                } else {
                    dispatch(setAlert(walletResponse.msg, "danger"));
                }
                break;
            case "paypal":
                await dispatch(
                    createPaypalPayment({
                        transactionType: "planPurchase",
                        amount: plan.price.amount,
                        redirectURL: "upgrade-plan",
                    })
                );
                break;
            default:
                break;
        }
        setLoading(false);
    };

    // const onPaypal = async (e, packId) => {
    //     setPaymentMode("paypal");
    //     // const today = dayjs(new Date()).format("DD/MM/YYYY");
    //     if (!profile) {
    //         return setAlert("Profile not found", "danger");
    //     }

    //     if (profile.currentPlan) {
    //         dayjs.extend(isSameOrBefore);
    //         // dayjs().isSameOrBefore(dayjs(profile.currentPlan.endDate));
    //         const isBefore = dayjs().isSameOrBefore(
    //             dayjs(profile.currentPlan.endDate)
    //         );

    //         if (profile.currentPlan.plan !== null && isBefore) {
    //             setIsActive(true);
    //         } else {
    //             setLoading(true);
    //             const paypalObj = await buyPlanPaypal(packId, paymentMode);
    //             if (paypalObj) {
    //                 for (let i = 0; i < paypalObj.links.length; i++) {
    //                     if (paypalObj.links[i].rel === "approval_url") {
    //                         // return res.redirect(paypalObj.links[i].href);
    //                         window.location.href = paypalObj.links[i].href;
    //                     }
    //                 }
    //             } else {
    //                 setLoading(false);
    //                 setAlert("Something went wrong!", "danger");
    //             }
    //             toggleDialog();
    //             // setAlert("by paypal", "success");
    //         }
    //     }
    // };

    // const onBuyPlan = async (e, packId) => {
    //   e.preventDefault();
    //   if (paymentMode === "wallet") {
    //     const paymentMsg = await buyPlan(packId, paymentMode);

    //     if (paymentMsg === "success") {
    //       setAlert("Plan Upgraded Successfully", "success");
    //       toggleDialog();
    //     }
    //   } else if (paymentMode === "paypal") {
    //     setLoading(true);
    //     const paypalObj = await buyPlanPaypal(packId, paymentMode);

    //     if (paypalObj) {
    //       for (let i = 0; i < paypalObj.links.length; i++) {
    //         if (paypalObj.links[i].rel === "approval_url") {
    //           // return res.redirect(paypalObj.links[i].href);
    //           window.location.href = paypalObj.links[i].href;
    //         }
    //       }
    //     } else {
    //       setLoading(false);

    //       setAlert("Something went wrong!", "danger");
    //     }
    //     toggleDialog();
    //   }
    // };

    return (
        <Fragment>
            <div className="d-inline w-100">
                <button
                    className="btn btn-primary-gradient outline w-100"
                    onClick={toggleDialog}
                >
                    {plan && <>Get Plan for $ {plan.price.amount}</>}
                </button>

                <Dialog
                    open={open}
                    onClose={toggleDialog}
                    maxWidth="sm"
                    fullWidth={true}
                >
                    <CustomizedDialogTitle>
                        <div className="d-flex justify-content-between align-items-center">
                            <h3>Get {plan.name}</h3>
                            <h5>
                                {plan.price.currency} {plan.price.amount}
                            </h5>
                        </div>
                    </CustomizedDialogTitle>
                    <CustomizedDialogContent>
                        <div className="text-center">
                            {isActive ? (
                                <Fragment>
                                    <p className="mb-3">
                                        A plan is already active in your
                                        Profile. Do you still want to change the
                                        ongoing plan?
                                    </p>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <table className="table table-borderless">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p className="text-start mb-0">
                                                        Active Projects:
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="text-end mb-0">
                                                        {plan.projectsCount}
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="text-start mb-0">
                                                        Bids:
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="text-end mb-0">
                                                        {plan.bidsCount}
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="text-start mb-0">
                                                        Validity:
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="text-end mb-0">
                                                        {plan.duration.value}{" "}
                                                        {plan.duration.scope}
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Fragment>
                            )}
                        </div>
                        <div className="mt-3">
                            {isActive ? (
                                <Fragment>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <LoadingButton
                                                isLoading={loading}
                                                text="Pay with Wallet"
                                                className="btn btn-primary-gradient outline w-100"
                                                onClick={(e) =>
                                                    onBuyPlan(
                                                        e,
                                                        plan._id,
                                                        "wallet"
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <button
                                                type="submit"
                                                onClick={() => toggleDialog()}
                                                className="btn btn-primary-gradient w-100"
                                            >
                                                No
                                            </button>
                                        </div>
                                    </div>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <LoadingButton
                                                isLoading={loading}
                                                text="Pay with Wallet"
                                                className="btn btn-primary-gradient w-100"
                                                onClick={(e) =>
                                                    onBuyPlan(
                                                        e,
                                                        plan._id,
                                                        "wallet"
                                                    )
                                                }
                                            />
                                        </div>
                                        {/* <div className="col-md-4">
                                            <button
                                                className="btn btn-primary-gradient outline w-100"
                                                onClick={(e) =>
                                                    onBuyPlan(
                                                        e,
                                                        plan._id,
                                                        "paypal"
                                                    )
                                                }
                                            >
                                                Pay with Paypal
                                            </button>
                                        </div> */}
                                        {/* <div className="col-md-4">
                                            <button
                                                className="btn btn-primary-gradient outline w-100"
                                                // onClick={(e) => onPaypal(e, plan._id)}
                                            >
                                                Pay with Stripe
                                            </button>
                                        </div> */}
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    </CustomizedDialogContent>
                </Dialog>
            </div>
        </Fragment>
    );
};

export default GetPlan;
