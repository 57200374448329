import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Import styling
import styles from "./newProjectsContainer.module.css";

const NewProjectsContainer = () => {
    const projectState = useSelector((state) => state.project);

    return (
        <Fragment>
            <div className={`${styles["new-projects-wrapper"]}`}>
                {projectState.newProjects.map((project, index) => {
                    return (
                        <Link
                            key={index}
                            to={`/project/${project.id}`}
                            className={`${styles["new-project-item"]}`}
                        >
                            <h6>{project.title}</h6>
                            <hr className="my-2" />
                            <p className="text-muted">{project.description}</p>
                        </Link>
                    );
                })}
            </div>
        </Fragment>
    );
};

export default NewProjectsContainer;
