import React, { Fragment, useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "semantic-ui-react";

import { createOrUpdateProfile } from "./../../../actions/profile";
import { getActiveCountries, getTimezones } from "./../../../actions/countries";
import styles from "./profileEdit.module.css";
import { setAlert } from "../../../actions/alert";
import { ProfileContext } from "../../../contexts/ProfileContext";
import LoadingButton from "../../utils/LoadingButton/LoadingButton";
import ProfileOverviewSkeleton from "../ProfileOverviewSkeleton/ProfileOverviewSkeleton";
import { getActiveCurrencies } from "../../../actions/currency";

const ProfileEdit = ({ toggleMode, reloadProfile }) => {
    const profileContext = useContext(ProfileContext);
    const dispatch = useDispatch();

    const authState = useSelector((state) => state.auth);

    const [isUpdating, setIsUpdating] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [countryOptions, setCountryOptions] = useState([]);
    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [timezones, setTimezones] = useState([]);

    const [file, setFile] = useState();
    const [formData, setFormData] = useState({
        country: profileContext.profile.country,
        currency: profileContext.profile.currency,
        timezone: profileContext.profile.timezone,
        hourlyRate: profileContext.profile.hourlyRate.amount,
        hourlyCurrency: profileContext.profile.hourlyRate.currency,
        tagline: profileContext.profile.tagline,
        profileType: "",
    });

    const { country, currency, timezone, hourlyRate, tagline } = formData;

    useEffect(() => {
        loadInitData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // load view init data
    const loadInitData = async () => {
        setFormData({
            ...formData,
            country: profileContext.profile.country._id,
        });

        const countries = await dispatch(getActiveCountries());
        const currencies = await dispatch(getActiveCurrencies());
        const countriesArr = [];
        countries.data.map((country) => {
            const obj = {
                key: country._id,
                text: country.name,
                value: country._id,
            };
            countriesArr.push(obj);
            return null;
        });
        setCountryOptions(countriesArr);

        const currencyArr = [];
        currencies.data.map((currency) => {
            const obj = {
                key: currency._id,
                text: currency.name,
                value: currency._id,
            };
            currencyArr.push(obj);
            return null;
        });
        setCurrencyOptions(currencyArr);

        // setCountries(await getActiveCountries());
        const resTimezones = await dispatch(getTimezones());
        setTimezones(
            resTimezones.data.map((timezone, i) => {
                return { key: i, text: timezone, value: timezone };
            })
        );
        setIsLoading(false);
    };

    if (authState.user !== null) {
        formData.profileType = authState.user.userType.toLowerCase();
    }

    const updateProfile = async (e) => {
        e.preventDefault();
        setIsUpdating(true);

        const data = new FormData();
        data.append("jsonData", JSON.stringify(formData));
        if (file) data.append("file", file);

        const response = await dispatch(createOrUpdateProfile(data));

        if (response.status) {
            reloadProfile();
            toggleMode(false);
        } else {
            dispatch(setAlert(response.msg, "danger"));
        }

        setIsUpdating(false);
    };

    const onChangeValue = (e) => {
        if (e.target.min && e.target.value < e.target.min) {
            setFormData({ ...formData, [e.target.name]: e.target.min });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const fileSelectHandler = (e) => {
        setFile(e.target.files[0]);
    };

    const onChangeDropdownValue = (e, data) => {
        setFormData({ ...formData, [data.name]: data.value });
    };

    const upload = () => {
        document.getElementById("selectImg").click();
    };

    return (
        <Fragment>
            {!isLoading ? (
                <Fragment>
                    <Fragment>
                        <section
                            className={`${styles["profile-section"]} light-bg p-4 my-lg-3 mb-3`}
                        >
                            <form onSubmit={(e) => updateProfile(e)}>
                                <div
                                    className={`${styles["profile-icon"]} text-center`}
                                    onClick={upload}
                                >
                                    {file ? (
                                        <Fragment>
                                            <img
                                                src={URL.createObjectURL(file)}
                                                alt="Profile"
                                                className="profile-pic-xl"
                                            />
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            {profileContext.profile
                                                .profileImgUrl !== "" ? (
                                                <Fragment>
                                                    <img
                                                        className="profile-pic-xl"
                                                        src={
                                                            "https://newtrinityapi.newlimetech.com" +
                                                            profileContext
                                                                .profile
                                                                .profileImgUrl
                                                        }
                                                        alt="Profile"
                                                    />
                                                </Fragment>
                                            ) : (
                                                <Fragment>
                                                    <img
                                                        className="profile-pic-xl"
                                                        src="/img/profile-image.png"
                                                        alt="Profile"
                                                    />
                                                </Fragment>
                                            )}
                                        </Fragment>
                                    )}

                                    <div
                                        className={styles["edit-overlay"]}
                                    ></div>
                                    <div className={styles["edit-icon"]}>
                                        <span className="material-icons">
                                            create
                                        </span>
                                    </div>
                                    <input
                                        type="file"
                                        hidden
                                        name="profileImg"
                                        onChange={fileSelectHandler}
                                        id="selectImg"
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="form-label">
                                        Tagline
                                    </label>
                                    <input
                                        type="text"
                                        className="dark-input"
                                        name="tagline"
                                        placeholder="Tagline"
                                        value={tagline}
                                        onChange={(e) => onChangeValue(e)}
                                    ></input>
                                </div>
                                <div className="mb-2">
                                    <label className="form-label">
                                        Hourly Rate
                                    </label>
                                    <div className="input-group flex-nowrap">
                                        <span className="input-group-text borders-right-radius-none d-inline-block">
                                            $
                                        </span>
                                        <input
                                            type="number"
                                            className="dark-input borders-left-radius-none flex-grow-1"
                                            name="hourlyRate"
                                            placeholder="Hourly Rate"
                                            value={hourlyRate}
                                            min="0"
                                            onChange={(e) => onChangeValue(e)}
                                        />
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <label className="form-label">
                                        Country
                                    </label>
                                    <Dropdown
                                        className="sementic-dropdown dark-input dropdown-select"
                                        placeholder="Select Country"
                                        fluid
                                        name="country"
                                        search
                                        selection
                                        options={countryOptions}
                                        onChange={onChangeDropdownValue}
                                        value={country}
                                        required
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="form-label">
                                        Currency
                                    </label>
                                    <Dropdown
                                        placeholder="Select Currency"
                                        className="sementic-dropdown dark-input dropdown-select"
                                        fluid
                                        name="currency"
                                        search
                                        selection
                                        options={currencyOptions}
                                        onChange={onChangeDropdownValue}
                                        defaultValue={currency}
                                        required
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="form-label">
                                        Timezone
                                    </label>
                                    <Dropdown
                                        className="sementic-dropdown dark-input dropdown-select"
                                        placeholder="Select Timezone"
                                        fluid
                                        name="timezone"
                                        search
                                        selection
                                        options={timezones}
                                        onChange={onChangeDropdownValue}
                                        defaultValue={timezone.toString()}
                                        required
                                    />
                                </div>
                                <div className="mt-3 ">
                                    <LoadingButton
                                        text="Save"
                                        className="btn-save"
                                        isLoading={isUpdating}
                                    />
                                    <input
                                        type="button"
                                        className="btn-cancel ms-2"
                                        value="Cancel"
                                        onClick={() => toggleMode(false)}
                                    ></input>
                                </div>
                            </form>
                        </section>
                    </Fragment>
                </Fragment>
            ) : (
                <ProfileOverviewSkeleton />
            )}
        </Fragment>
    );
};

export default ProfileEdit;
