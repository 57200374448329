import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import GoogleLogin from "react-google-login";

import { setAlert } from "../../actions/alert";
import { register, socialLogin } from "../../actions/auth";
import styles from "./register.module.css";
import LoadingButton from "../../components/utils/LoadingButton/LoadingButton";

const Register = () => {
    const topLoaderState = useSelector((state) => state.topLoader);

    const [isLoading, setIsLoading] = useState(false);
    const [showPasswordField, setShowPasswordField] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password: "",
        password2: "",
        userType: "customer",
        refferalId: "",
    });

    const { name, email, password, password2, userType, refferalId } = formData;

    const history = useHistory();
    const dispatch = useDispatch();

    // useEffect(() => {
    //     loadUser();
    // }, []);

    // const loadUser = async () => {
    //     dispatch(toggleTopLoader(true));
    //     const res = await dispatch(authUser());

    //     if (res.status == 1) {
    //         //redirect login
    //         dispatch(toggleTopLoader(false));
    //         history.push("/dashboard");
    //     } else {
    //         dispatch(toggleTopLoader(false));
    //     }
    // };

    const onChangeValue = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const onFormSubmit = async (e) => {
        e.preventDefault();

        if (password !== password2) {
            dispatch(setAlert("Passwords do not match", "danger"));
        } else {
            setIsLoading(true);
            const res = await dispatch(
                register(name, email, password, userType, refferalId)
            );
            if (res.status === 1) {
                setIsLoading(false);
                dispatch(setAlert(res.msg, "success"));
                history.push("/login");
            } else {
                setIsLoading(false);
                dispatch(setAlert(res.msg, "danger"));
            }
        }
    };

    const responseGoogle = async (response, resetForm) => {
        try {
            response.preventDefault();
        } catch (err) {}

        setIsLoading(true);

        const requestBody = {
            email: formData.email,
            fullName: formData.name,
            password: formData.password,
            userType: "customer",
        };

        if (resetForm) {
            setFormData({
                ...formData,
                email: response.profileObj.email,
                fullName: response.profileObj.name,
            });

            requestBody["email"] = response.profileObj.email;
            requestBody["fullName"] = response.profileObj.name;
        }

        const res = await dispatch(socialLogin(requestBody));
        if (res.status) {
            if (res.data.user.profile !== null) {
                history.replace("/dashboard");
            } else {
                history.replace("/setupprofile");
            }
        } else {
            if (res.msg === "PASSWORD_REQUIRED") {
                setShowPasswordField(true);
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
        }

        setIsLoading(false);
    };

    // const handletoggle = () => {
    //     setIsRefer(!isRefer);
    // };

    return (
        <Fragment>
            {!topLoaderState.visible ? (
                <div className="container d-flex justify-content-center py-5">
                    <div className="light-bg my-5 px-5 py-4" id="signup-box">
                        {!showPasswordField ? (
                            <Fragment>
                                <h4 className="text-center">
                                    Register Account
                                </h4>
                                <hr></hr>
                                <form onSubmit={(e) => onFormSubmit(e)}>
                                    <div className="mb-3">
                                        <input
                                            type="text"
                                            className="dark-input"
                                            name="name"
                                            placeholder="Name"
                                            value={name}
                                            onChange={(e) => onChangeValue(e)}
                                            required
                                        ></input>
                                    </div>
                                    <div className="mb-3">
                                        <input
                                            type="email"
                                            className="dark-input"
                                            name="email"
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => onChangeValue(e)}
                                            required
                                        ></input>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-6">
                                            <input
                                                type="password"
                                                className="dark-input"
                                                name="password"
                                                placeholder="Password"
                                                value={password}
                                                onChange={(e) =>
                                                    onChangeValue(e)
                                                }
                                                required
                                            ></input>
                                        </div>
                                        <div className="col-md-6">
                                            <input
                                                type="password"
                                                className="dark-input"
                                                name="password2"
                                                placeholder="Confirm Password"
                                                value={password2}
                                                onChange={(e) =>
                                                    onChangeValue(e)
                                                }
                                                required
                                            ></input>
                                        </div>
                                    </div>
                                    {/* <div className="form-group d-flex mb-2 align-items-center">
                                        <input
                                            type="checkbox"
                                            className="me-2"
                                            onChange={(e) => handletoggle(e)}
                                        ></input>
                                        <label>Referral Code</label>
                                    </div>
                                    {isRefer && (
                                        <div className="form-group mb-4">
                                            <label>Referral Id</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="refferalId"
                                                placeholder="Refferal Code"
                                                value={refferalId}
                                                onChange={(e) =>
                                                    onChangeValue(e)
                                                }
                                            ></input>
                                        </div>
                                    )} */}
                                    <div className="mb-2">
                                        <LoadingButton
                                            text="Register"
                                            isLoading={isLoading}
                                        />
                                    </div>
                                    <div className={styles["hr-sect"]}>OR</div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            {/* <button
                                        type="button"
                                        className="btn btn-light"
                                    >
                                        <p style={{ color: "#000" }}>
                                            <img
                                                src="/img/google.png"
                                                width="15%"
                                                height="12%"
                                                style={{ marginRight: "15px" }}
                                            />
                                            Sign up with Google
                                        </p>
                                    </button> */}
                                            <GoogleLogin
                                                clientId={
                                                    process.env
                                                        .REACT_APP_GOOGLE_CLIENT_ID
                                                }
                                                buttonText="Signup With Google"
                                                onSuccess={(e) =>
                                                    responseGoogle(e, true)
                                                }
                                                className="w-100 text-muted"
                                            />
                                        </div>
                                        {/* <div className="col-6">
                                    <button
                                        type="button"
                                        className="btn btn-light"
                                    >
                                        <p style={{ color: "#000" }}>
                                            <img
                                                src="/img/facebook.png"
                                                width="14%"
                                                style={{
                                                    marginRight: "15px",
                                                }}
                                            />
                                            Sign up with Facebook
                                        </p>
                                    </button>
                                </div> */}
                                    </div>
                                </form>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <h4 className="text-center">
                                    Set Password For Your Account
                                </h4>
                                <hr></hr>
                                <form
                                    onSubmit={(e) => {
                                        responseGoogle(e, false);
                                    }}
                                >
                                    <div className="mb-3">
                                        <input
                                            type="password"
                                            className="dark-input"
                                            name="password"
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => onChangeValue(e)}
                                            required
                                        ></input>
                                    </div>
                                    <div className="mb-4">
                                        <input
                                            type="password"
                                            className="dark-input"
                                            name="password2"
                                            placeholder="Confirm Password"
                                            value={password2}
                                            onChange={(e) => onChangeValue(e)}
                                            required
                                        ></input>
                                    </div>
                                    <div className="mb-2">
                                        <button
                                            disabled={isLoading}
                                            type="submit"
                                            className={
                                                "btn btn-primary-gradient form-control"
                                            }
                                        >
                                            Save{" "}
                                            {isLoading ? (
                                                <img
                                                    src="/img/loader.gif"
                                                    alt="loader"
                                                    width="25px"
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </Fragment>
                        )}
                    </div>
                </div>
            ) : (
                <Fragment></Fragment>
            )}
        </Fragment>
    );
};

export default Register;
