import React, { Fragment, useEffect, useState } from "react";

import { seo } from "../../utils/seo";
import ProjectListItem from "../../components/ProjectListItem/ProjectListItem";
import { useDispatch } from "react-redux";
import { fetchMyProjects } from "../../actions/project";

const MyProjects = () => {
    const [loading, setLoading] = useState(true);
    const [activeProjects, setActiveProjects] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        seo({ title: "Myproject" });
        loadInit();

        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, []);

    const loadInit = async () => {
        const response = await dispatch(fetchMyProjects());
        if (response.status) {
            setActiveProjects(response.data);
        }

        setLoading(false);
    };

    if (loading) return <div>Loading...</div>;

    return (
        <Fragment>
            <main className="container">
                <div className="row">
                    <div className="col-lg-12 px-lg-2 m-0">
                        <div className="m-3 d-flex">
                            <h3 className="text-capitalize">My Projects</h3>
                        </div>

                        <section className="job-results my-lg-3 mb-3">
                            {activeProjects == null ? (
                                ""
                            ) : activeProjects.length ? (
                                activeProjects.map((project, index) => {
                                    return (
                                        <ProjectListItem
                                            key={index}
                                            project={project}
                                        />
                                    );
                                })
                            ) : (
                                <>
                                    <h4 className="text-center py-5">
                                        No Projects Found
                                    </h4>
                                </>
                            )}
                        </section>
                    </div>
                </div>
            </main>
        </Fragment>
    );
};

// MyProjects.propTypes = {
//   getActiveProjects: PropTypes.func.isRequired,
//   getUpcomingMilestones: PropTypes.func.isRequired,
//   getPlanDetails: PropTypes.func.isRequired,
//   activeprojectsdata: PropTypes.array,
// };

// const mapStateToProps = (state) => ({
//   activeprojectsdata: state.dashboard.activeprojects,
//   dashboard: state.dashboard,
// });

export default MyProjects;
