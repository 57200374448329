import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { fetchScheduledMeetings } from "../../actions/scheduledMeeting";
import ScheduledMeetingListItem from "../../components/ScheduledMeetingListItem/ScheduledMeetingListItem";

const ScheduledMeetings = () => {
    const dispatch = useDispatch();

    const [scheduledMeetings, setScheduledMeetings] = useState([]);

    useEffect(() => {
        fetchMeetings();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchMeetings = async () => {
        const response = await dispatch(fetchScheduledMeetings());
        if (response.status) {
            setScheduledMeetings(response.data);
        }
    };

    return (
        <Fragment>
            <div className="container">
                <div className="m-3 d-flex">
                    <h3 className="text-capitalize">Scheduled Meetings</h3>
                </div>
                <hr />
                <div className="my-lg-3 mb-3">
                    {scheduledMeetings.length !== 0 ? (
                        <Fragment>
                            {scheduledMeetings.map((meeting, index) => {
                                return (
                                    <ScheduledMeetingListItem
                                        key={index}
                                        meeting={meeting}
                                        reloadMeetingsData={fetchMeetings}
                                    />
                                    // <ProjectListItem
                                    //     key={index}
                                    //     project={project.project}
                                    // />
                                );
                            })}
                        </Fragment>
                    ) : (
                        <Fragment>
                            <h4 className="text-center text-muted py-5">
                                No Scheduled Meetings Found!
                            </h4>
                        </Fragment>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default ScheduledMeetings;
