// import download from "downloadjs";
import fileDownload from "js-file-download";
import { Fragment, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../actions/alert";
import {
	deleteProjectAttachment,
	downloadProjectAttachment,
} from "../../actions/project";
import { ProjectControlCenterContext } from "../../contexts/ProjectControlCenterContext";
import { getApiEndpoint } from "../../utils/getApiEndpoint";
import styles from "./ProjectAttachmentItem.module.css";

const ProjectAttachmentItem = ({
	projectId,
	attachment,
	refreshAttachments,
}) => {
	const dispatch = useDispatch();
	const projectControlCenterContext = useContext(ProjectControlCenterContext);

	const authState = useSelector((state) => state.auth);

	const isCustomer =
		projectControlCenterContext?.project?.user?._id === authState.user?._id;

	const onDeleteAttachment = async (url) => {
		const response = await dispatch(
			deleteProjectAttachment(projectId, { url })
		);
		if (response.status) {
			dispatch(setAlert(response.msg, "success"));
			projectControlCenterContext.fetchProjectDetails();
			refreshAttachments();
		} else {
			dispatch(setAlert(response.msg, "danger"));
		}
	};

	const onDownloadAttachment = async (url) => {
		const response = await dispatch(
			downloadProjectAttachment(projectId, { url })
		);
		if (response.status === 0) {
			dispatch(setAlert(response.msg, "danger"));
		} else {
			// download(response, attachment.name);
			fileDownload(response, attachment.name);
		}
	};

	return (
		<Fragment>
			<div className={`col-md-4 ${styles["attachment-item"]}`}>
				{attachment.name.includes(".jpg") ||
				attachment.name.includes(".png") ||
				attachment.name.includes(".jpeg") ? (
					<div className="text-center position-relative overflow-hidden">
						<img
							src={`${getApiEndpoint() + attachment.url}`}
							alt="preview"
							className="w-100 mb-2"
						/>
						<p className="text-muted m-0">{attachment.name}</p>
						<div className={`${styles["attachment-actions"]}`}>
							{isCustomer && (
								<span
									className="material-icons me-2"
									onClick={() =>
										onDeleteAttachment(attachment.url)
									}
								>
									delete
								</span>
							)}
							<span
								className="material-icons"
								onClick={() =>
									onDownloadAttachment(attachment.url)
								}
							>
								file_download
							</span>
						</div>
					</div>
				) : attachment.name.includes(".pdf") ? (
					<div
						className={`d-flex flex-column text-center position-relative h-100`}
					>
						<div
							className={`d-flex align-items-center justify-content-center flex-grow-1 ${styles["item"]} ${styles["pdf"]} mb-2`}
						>
							<img src="/icons/pdf.png" alt="icon" />
						</div>
						<p className="text-muted m-0">{attachment.name}</p>
						<div className={`${styles["attachment-actions"]}`}>
							{isCustomer && (
								<span
									className="material-icons me-2"
									onClick={() =>
										onDeleteAttachment(attachment.url)
									}
								>
									delete
								</span>
							)}
							<span
								className="material-icons"
								onClick={() =>
									onDownloadAttachment(attachment.url)
								}
							>
								file_download
							</span>
						</div>
					</div>
				) : attachment.name.includes(".doc") ||
				  attachment.name.includes(".docx") ? (
					<div
						className={`d-flex flex-column text-center position-relative h-100`}
					>
						<div
							className={`d-flex align-items-center justify-content-center flex-grow-1 ${styles["item"]} mb-2`}
						>
							<img src="/icons/doc.png" alt="icon" />
						</div>
						<p className="text-muted m-0">{attachment.name}</p>
						<div className={`${styles["attachment-actions"]}`}>
							{isCustomer && (
								<span
									className="material-icons me-2"
									onClick={() =>
										onDeleteAttachment(attachment.url)
									}
								>
									delete
								</span>
							)}
							<span
								className="material-icons"
								onClick={() =>
									onDownloadAttachment(attachment.url)
								}
							>
								file_download
							</span>
						</div>
					</div>
				) : attachment.name.includes(".xls") ||
				  attachment.name.includes(".xlsx") ? (
					<div
						className={`d-flex flex-column text-center position-relative h-100`}
					>
						<div
							className={`d-flex align-items-center justify-content-center flex-grow-1 ${styles["item"]} mb-2`}
						>
							<img src="/icons/xls.png" alt="icon" />
						</div>
						<p className="text-muted m-0">{attachment.name}</p>
						<div className={`${styles["attachment-actions"]}`}>
							{isCustomer && (
								<span
									className="material-icons me-2"
									onClick={() =>
										onDeleteAttachment(attachment.url)
									}
								>
									delete
								</span>
							)}
							<span
								className="material-icons"
								onClick={() =>
									onDownloadAttachment(attachment.url)
								}
							>
								file_download
							</span>
						</div>
					</div>
				) : attachment.name.includes(".ppt") ||
				  attachment.name.includes(".pptx") ? (
					<div
						className={`d-flex flex-column text-center position-relative h-100`}
					>
						<div
							className={`d-flex align-items-center justify-content-center flex-grow-1 ${styles["item"]} mb-2`}
						>
							<img src="/icons/ppt.png" alt="icon" />
						</div>
						<p className="text-muted m-0">{attachment.name}</p>
						<div className={`${styles["attachment-actions"]}`}>
							{isCustomer && (
								<span
									className="material-icons me-2"
									onClick={() =>
										onDeleteAttachment(attachment.url)
									}
								>
									delete
								</span>
							)}
							<span
								className="material-icons"
								onClick={() =>
									onDownloadAttachment(attachment.url)
								}
							>
								file_download
							</span>
						</div>
					</div>
				) : (
					<div
						className={`d-flex flex-column text-center position-relative h-100`}
					>
						<div
							className={`d-flex align-items-center justify-content-center flex-grow-1 ${styles["item"]} mb-2`}
						>
							<img src="/icons/file.png" alt="icon" />
						</div>
						<p className="text-muted m-0">{attachment.name}</p>
						<div className={`${styles["attachment-actions"]}`}>
							{isCustomer && (
								<span
									className="material-icons me-2"
									onClick={() =>
										onDeleteAttachment(attachment.url)
									}
								>
									delete
								</span>
							)}
							<span
								className="material-icons"
								onClick={() =>
									onDownloadAttachment(attachment.url)
								}
							>
								file_download
							</span>
						</div>
					</div>
				)}
			</div>
		</Fragment>
	);
};

export default ProjectAttachmentItem;
