import { Dialog } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../actions/alert";
import { changeProjectStatus } from "../../../actions/project";
import {
    CustomizedDialogContent,
    CustomizedDialogTitle,
} from "../CustomizedModal/CustomizedModal";
import styles from "./ChangeProjectStatusModal.module.css";

const ChangeProjectStatusModal = ({
    isOpen,
    onClose,
    projectId,
    currentStatus,
}) => {
    const dispatch = useDispatch();

    const handleStatusChange = async (status) => {
        const response = await dispatch(changeProjectStatus(projectId, status));
        if (response.status) {
            dispatch(setAlert(response.msg, "success"));
            onClose();
        } else {
            dispatch(setAlert(response.msg, "danger"));
        }
    };

    return (
        <Dialog
            onClose={() => onClose(false)}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
            maxWidth="sm"
            fullWidth={true}
        >
            <CustomizedDialogTitle id="customized-dialog-title">
                <h3 className="modal-title text-accent">
                    Change Project Status
                </h3>
            </CustomizedDialogTitle>
            <CustomizedDialogContent>
                <ul className={`${styles["project-status-list"]} p-0`}>
                    <li
                        className={`${styles["list-item"]} d-flex align-items-center mb-2`}
                        onClick={() => handleStatusChange("draft")}
                    >
                        <div className="flex-grow-1">
                            <h5
                                className={`${styles["title"]} ${styles["draft"]} mb-0`}
                            >
                                Draft
                            </h5>
                            <p className="text-muted">
                                Hide your project from listing in the portal to
                                make changes.
                            </p>
                        </div>
                        {currentStatus === "draft" && (
                            <span className="material-icons color-primary">
                                task_alt
                            </span>
                        )}
                    </li>
                    <li
                        className={`${styles["list-item"]} d-flex align-items-center mb-2`}
                        onClick={() => handleStatusChange("active")}
                    >
                        <div className="flex-grow-1">
                            <h5
                                className={`${styles["title"]} ${styles["active"]} mb-0`}
                            >
                                Active
                            </h5>
                            <p className="text-muted">
                                List your project on portal for accepting bids
                                from developers.
                            </p>
                        </div>
                        {currentStatus === "active" && (
                            <span className="material-icons color-primary">
                                task_alt
                            </span>
                        )}
                    </li>
                    <li
                        className={`${styles["list-item"]} d-flex align-items-center mb-2`}
                        onClick={() => handleStatusChange("closed")}
                    >
                        <div className="flex-grow-1">
                            <h5
                                className={`${styles["title"]} ${styles["closed"]} mb-0`}
                            >
                                Closed
                            </h5>
                            <p className="text-muted">
                                Show project only to the users who are linked
                                with your project e.g. hired developers etc. new
                                developers are unable to bid.
                            </p>
                        </div>
                        {currentStatus === "closed" && (
                            <span className="material-icons color-primary">
                                task_alt
                            </span>
                        )}
                    </li>
                </ul>
            </CustomizedDialogContent>
        </Dialog>
    );
};

export default ChangeProjectStatusModal;
