import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../actions/alert";
import { fetchHiredBidsOnProject } from "../../../actions/bid";
import { saveNewMilestone, updateMilestone } from "../../../actions/milestone";
import { getActiveSkills } from "../../../actions/skills";
import LoadingButton from "../../utils/LoadingButton/LoadingButton";

const AddEditMilestone = ({
	isOpen,
	handleClose,
	milestone,
	handleSubmit,
	projectId,
	projectOwnerId,
}) => {
	const dispatch = useDispatch();

	const authState = useSelector((state) => state.auth);

	const [saving, setSaving] = useState(false);
	const [activeSkills, setActiveSkills] = useState([]);
	const [hiredUsers, setHiredUsers] = useState([]);
	const [formData, setFormData] = useState({
		title: milestone ? milestone.title : "",
		amount: milestone ? milestone.price.amount : "",
		duration: milestone ? milestone.estimatedDuration.value : "",
		scope: milestone ? milestone.estimatedDuration.scope : "weeks",
		skill: milestone ? milestone.skill : "",
		hiredUser: milestone ? milestone.hiredUser?._id : null,
		description: milestone ? milestone.description : "",
		currency: "USD",
	});

	const { title, amount, duration, scope, skill, hiredUser, description } =
		formData;

	useEffect(() => {
		if (isOpen) {
			fetchDevelopers();
			fetchActiveSkills();
			if (!milestone) {
				setFormData({
					title: "",
					amount: "",
					duration: "",
					scope: "weeks",
					skill: "",
					hiredUser: null,
					description: "",
					currency: "USD",
				});
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	const fetchDevelopers = async () => {
		const response = await dispatch(fetchHiredBidsOnProject(projectId));
		console.log(response);
		if (response.status) {
			setHiredUsers(response.data);
		}
	};

	const fetchActiveSkills = async () => {
		const response = await dispatch(getActiveSkills());
		if (response.status) {
			setActiveSkills(response.data);
		}
	};

	const onChangeValue = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onsubmit = async (e) => {
		e.preventDefault();
		setSaving(true);

		const milestoneData = {
			title,
			skill: skill,
			price: {
				amount: parseInt(amount),
				currency: "USD",
			},
			estimatedDuration: {
				scope,
				value: parseInt(duration),
			},
			hiredUser,
			description,
		};
		if (projectOwnerId !== authState.user._id) {
			milestoneData.hiredUser = authState.user._id;
		}
		milestoneData.projectId = projectId;
		milestoneData.userId = authState.user._id;
		if (milestone) {
			milestoneData.milestoneId = milestone._id;
			const response = await dispatch(
				updateMilestone(milestone._id, milestoneData)
			);
			if (response.status) {
				dispatch(setAlert(response.msg, "success"));
				handleClose();
			} else {
				dispatch(setAlert(response.msg, "danger"));
			}
			// socketState.socket.emit("milestone-edit", milestoneData);
		} else {
			const response = await dispatch(saveNewMilestone(milestoneData));
			if (response.status) {
				dispatch(setAlert(response.msg, "success"));
				handleClose();
			} else {
				dispatch(setAlert(response.msg, "danger"));
			}
			// socketState.socket.emit("milestone-add", milestoneData);
		}

		setSaving(false);
	};

	return (
		<Fragment>
			<form onSubmit={(e) => onsubmit(e)}>
				<div className="row mb-3">
					<div className="col-md-12 pr-md-1">
						<label className="form-label">
							Title <small className="text-danger">*</small>
						</label>
						<input
							autoComplete="off"
							type="text"
							name="title"
							placeholder="Enter milestone title"
							required
							value={title}
							onChange={(e) => onChangeValue(e)}
							className="dark-input"
						/>
					</div>
				</div>
				<div className="row mb-3">
					<div className="col-md-6 zmd-1">
						<label className="form-label">
							Price <small className="text-danger">*</small>
						</label>
						<input
							type="number"
							name="amount"
							placeholder="Enter milestone price"
							required
							value={amount}
							onChange={(e) => onChangeValue(e)}
							className="dark-input"
						/>
					</div>
					<div className="col-md-6 pl-md-1">
						<label className="form-label">
							Estimated Duration{" "}
							<small className="text-danger">*</small>
						</label>
						<div className="input-group">
							<input
								type="text"
								name="duration"
								value={duration}
								className={`dark-input flex-grow-1 borders-right-radius-none`}
								style={{ width: "0px" }}
								placeholder="Estimated Time"
								required
								onChange={(e) => onChangeValue(e)}
							/>
							<select
								name="scope"
								className={`dark-input flex-grow-1 borders-left-radius-none`}
								style={{ width: "0px" }}
								defaultValue="weeks"
								onChange={(e) => onChangeValue(e)}
							>
								<option value="days">Days</option>
								<option value="weeks">Weeks</option>
								<option value="months">Months</option>
							</select>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<div className="mb-3">
							<label className="form-label">
								Skill <small className="text-danger">*</small>
							</label>
							<div>
								<select
									className={`dark-input focus-none dark-select`}
									name="skill"
									onChange={(e) => onChangeValue(e)}
									value={skill._id}
									required
								>
									<option value="">Select Skill</option>
									{activeSkills.length !== 0 && (
										<Fragment>
											{activeSkills.map(
												(skill, index) => {
													return (
														<Fragment key={index}>
															<option
																value={
																	skill._id
																}
															>
																{skill.name}
															</option>
														</Fragment>
													);
												}
											)}
										</Fragment>
									)}
								</select>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						{authState.user !== null && (
							<Fragment>
								{(projectOwnerId === authState.user._id ||
									authState.user.userType ===
										"recruiter") && (
									<Fragment>
										<div className="mb-3">
											<label className="form-label">
												Assign Developer
											</label>
											<div>
												<select
													className={`dark-input focus-none dark-select`}
													name="hiredUser"
													onChange={(e) =>
														onChangeValue(e)
													}
													value={hiredUser}
												>
													<option value="">
														Select Developer
													</option>
													{hiredUsers.length !==
														0 && (
														<Fragment>
															{hiredUsers.map(
																(
																	user,
																	index
																) => {
																	return (
																		<Fragment
																			key={
																				index
																			}
																		>
																			<option
																				value={
																					user
																						.user
																						._id
																				}
																				// selected={
																				//     user.user._id.toString() ===
																				//     hiredUser.toString()
																				// }
																			>
																				{
																					user
																						.user
																						.name
																				}
																			</option>
																		</Fragment>
																	);
																}
															)}
														</Fragment>
													)}
												</select>
											</div>
										</div>
									</Fragment>
								)}
							</Fragment>
						)}
					</div>
				</div>
				<div className="mb-3">
					<label className="form-label">
						Description <small className="text-danger">*</small>
					</label>
					<textarea
						name="description"
						required
						value={description}
						onChange={(e) => onChangeValue(e)}
						placeholder="Describe milestone"
						rows="5"
						className={`dark-input`}
					></textarea>
				</div>
				<div className="text-end mt-4 mb-2">
					<button
						type="button"
						onClick={() => handleClose(false)}
						className={`btn btn-primary-gradient outline me-2`}
					>
						Cancel
					</button>
					<LoadingButton
						text="Save"
						isLoading={saving}
						className="btn btn-primary-gradient"
						style={{ color: "#fff" }}
					/>
				</div>
			</form>
		</Fragment>
	);
};

export default AddEditMilestone;
