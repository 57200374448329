import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./ChatPanel.module.css";
// importing custom hook
import useWindowDimensions from "./../../utils/windowDimensionHook";

import MessageItem from "./../Messages/MessageItem/MessageItem";

import { getAllChat, removeMsgNotification } from "../../actions/chat";
import { setAlert } from "../../actions/alert";
import { getBotResponse, sendMsgBot } from "../../actions/chatBot";
import {
    setPanelState,
    addChat,
    setPanelMsg,
    deleteLastChat,
    setMinimisedState,
    closeChat,
} from "../../actions/chatPanel";
import { getApiEndpoint } from "../../utils/getApiEndpoint";

export const ChatPanel = (props) => {
    const dispatch = useDispatch();

    const auth = useSelector((state) => state.auth);
    const chatsState = useSelector((state) => state.chat);
    const socket = useSelector((state) => state.socket.socket);
    const chatPanelOpen = useSelector((state) => state.chatPanel.chatPanelOpen);
    const activeChats = useSelector((state) => state.chatPanel.activeChats);
    const panelLoading = useSelector((state) => state.chat.loading);

    const [notifications] = useState([]);
    const { width } = useWindowDimensions();

    const chatBot = useSelector((state) => state.chatBot.chat);

    const [maxActiveChats, setMaxActiveChats] = useState(null);

    const activeChatsByWidth = () => {
        if (width > 1100) {
            setMaxActiveChats(3);
        } else if (width > 900 && width <= 1100) {
            setMaxActiveChats(2);
            if (activeChats.length > 2) {
                deleteLastChat();
            }
        } else if (width > 600 && width <= 900) {
            setMaxActiveChats(1);
            if (activeChats.length > 1) {
                deleteLastChat();
            }
        }
    };

    useEffect(() => {
        activeChatsByWidth();

        // eslint-disable-next-line
    }, [width]);

    const openPanel = async () => {
        dispatch(setPanelState());
        await dispatch(getAllChat());
    };

    const enterRoom = async (chat) => {
        if (socket != null) {
            if (
                !chatsState.activeRoom ||
                chatsState.activeRoom._id !== chat._id
            ) {
                if (chat._id !== "chatbot") {
                    await socket.emit("join-chatroom", chat._id);
                    await socket.emit("delete-notifications", {
                        userId: auth.user._id,
                        chatId: chat._id,
                    });
                    dispatch(removeMsgNotification(chat._id));
                }
            }
        } else {
            setAlert("Server Error", "danger");
        }
    };

    const onAddChat = (chat, maxActiveChats) => {
        enterRoom(chat);
        dispatch(addChat(chat, maxActiveChats));
    };

    const sendMsg = (chatId, msg, userId, users) => {
        if (socket != null) {
            socket.emit("send-message", {
                msgData: {
                    chatId: chatId,
                    msg: msg,
                    userId: userId,
                    users: users,
                },
                msgType: {
                    type: "message",
                },
            });
        } else {
            setAlert("Cannot send Messages", "danger");
        }
    };

    const onFormSubmit = async (e, chat) => {
        e.preventDefault();
        const event = {
            target: {
                value: "",
            },
        };
        if (!chat.msg) {
            setAlert("Please type a message", "danger");
        } else {
            if (chat._id !== "chatbot") {
                sendMsg(chat._id, chat.msg, auth.user._id, chat.users);
                dispatch(setPanelMsg(event, chat._id));
            } else {
                dispatch(sendMsgBot(chat.msg, auth.user));
                dispatch(getBotResponse(chat.msg));
                dispatch(setPanelMsg(event, chat._id));
            }
        }
    };

    return (
        <Fragment>
            <Fragment>
                <div className={`${styles["bottom-chat-area"]}`}>
                    <div className={`chat-panel ${styles["chat-panel"]}`}>
                        <div
                            className={`chat-panel-head  ${styles["chat-panel-head"]}`}
                            onClick={() => openPanel()}
                        >
                            Chat
                            <span className="ml-2">
                                {chatsState.msgNotification.length !== 0 && (
                                    <span className="d-inline-block text-black ms-1">
                                        ({chatsState.msgNotification.length})
                                    </span>
                                )}
                            </span>
                            {/* <span>width-{width}</span> */}
                        </div>
                        {chatPanelOpen ? (
                            <Fragment>
                                <div
                                    className={`chat-panel-body ${styles["chat-panel-body"]}`}
                                >
                                    {!panelLoading ? (
                                        <Fragment>
                                            <ul
                                                className={`m-0 ps-0 ${styles["chat-contacts"]}`}
                                            >
                                                <Fragment>
                                                    <li
                                                        onClick={() =>
                                                            dispatch(
                                                                addChat(
                                                                    chatBot,
                                                                    maxActiveChats
                                                                )
                                                            )
                                                        }
                                                        className={`d-flex aling-items-center gap-2 ${styles["chat-contact-one"]}`}
                                                    >
                                                        <div className="d-flex aling-items-center gap-2 profile-icon mr-2">
                                                            <img
                                                                className="profile-pic-sm"
                                                                src="/img/profile-image.png"
                                                                alt="Profile"
                                                            />
                                                        </div>
                                                        <div className="contact-detail">
                                                            <div
                                                                className={`chat-name  ${styles["chat-name"]}`}
                                                            >
                                                                <h5 className="font-weight-bolder text-capitalize d-inline m-0 d-inline">
                                                                    Chatbot
                                                                </h5>
                                                            </div>
                                                            <small className="chat-last-msg text-secondary">
                                                                {
                                                                    chatBot
                                                                        .messages[0]
                                                                        .message
                                                                }
                                                            </small>
                                                        </div>
                                                    </li>
                                                    {chatsState.chats.length !==
                                                    0
                                                        ? chatsState.chats.map(
                                                              (chat) => {
                                                                  return (
                                                                      <Fragment
                                                                          key={
                                                                              chat._id
                                                                          }
                                                                      >
                                                                          <li
                                                                              onClick={() =>
                                                                                  onAddChat(
                                                                                      chat,
                                                                                      maxActiveChats
                                                                                  )
                                                                              }
                                                                              className={`d-flex aling-items-center gap-2 ${styles["chat-contact-one"]}`}
                                                                          >
                                                                              {chat.groupName ? (
                                                                                  <Fragment>
                                                                                      <div className="d-flex aling-items-center gap-2 profile-icon mr-2">
                                                                                          <img
                                                                                              className="profile-pic-sm"
                                                                                              src="/img/group-chat.jfif"
                                                                                              alt="Profile"
                                                                                          />
                                                                                      </div>
                                                                                      <div className="contact-detail">
                                                                                          <div
                                                                                              className={`chat-name ${styles["chat-name"]}`}
                                                                                          >
                                                                                              <h5 className="font-weight-bolder text-capitalize d-inline m-0 d-inline">
                                                                                                  {
                                                                                                      chat.groupName
                                                                                                  }
                                                                                              </h5>
                                                                                          </div>
                                                                                          {notifications.length >
                                                                                          0 ? (
                                                                                              <Fragment>
                                                                                                  {notifications.some(
                                                                                                      (
                                                                                                          noti
                                                                                                      ) =>
                                                                                                          noti.chatId ===
                                                                                                          chat._id
                                                                                                  ) ? (
                                                                                                      <Fragment>
                                                                                                          <small className="mr-2 notification d-inline">
                                                                                                              {
                                                                                                                  notifications.filter(
                                                                                                                      (
                                                                                                                          noti
                                                                                                                      ) =>
                                                                                                                          noti.chatId ===
                                                                                                                          chat._id
                                                                                                                  )
                                                                                                                      .length
                                                                                                              }
                                                                                                          </small>
                                                                                                      </Fragment>
                                                                                                  ) : (
                                                                                                      ""
                                                                                                  )}
                                                                                              </Fragment>
                                                                                          ) : (
                                                                                              ""
                                                                                          )}
                                                                                          {/* <small className="chat-last-msg text-secondary">
                                                                                              Last
                                                                                              message
                                                                                              ...
                                                                                          </small> */}
                                                                                      </div>
                                                                                  </Fragment>
                                                                              ) : (
                                                                                  <Fragment>
                                                                                      {chat.users.map(
                                                                                          (
                                                                                              user
                                                                                          ) => {
                                                                                              return (
                                                                                                  <Fragment
                                                                                                      key={
                                                                                                          user._id
                                                                                                      }
                                                                                                  >
                                                                                                      {user._id !==
                                                                                                      auth
                                                                                                          .user
                                                                                                          ._id ? (
                                                                                                          <Fragment>
                                                                                                              <div className="d-flex aling-items-center gap-2 profile-icon mr-2">
                                                                                                                  <img
                                                                                                                      className="profile-pic-sm"
                                                                                                                      src={
                                                                                                                          user
                                                                                                                              .userProfile
                                                                                                                              .profileImgUrl
                                                                                                                              ? getApiEndpoint() +
                                                                                                                                user
                                                                                                                                    .userProfile
                                                                                                                                    .profileImgUrl
                                                                                                                              : "/img/profile-image.png"
                                                                                                                      }
                                                                                                                      alt="Profile"
                                                                                                                  />
                                                                                                              </div>
                                                                                                              <div className="contact-detail flex-grow-1">
                                                                                                                  <div
                                                                                                                      className={`chat-name ${styles["chat-name"]} d-flex`}
                                                                                                                  >
                                                                                                                      <h5 className="font-weight-bolder text-capitalize d-inline m-0 d-inline flex-grow-1">
                                                                                                                          {
                                                                                                                              user.name
                                                                                                                          }
                                                                                                                      </h5>
                                                                                                                      {user.userType.toLowerCase() ===
                                                                                                                      "recruiter" ? (
                                                                                                                          <Fragment>
                                                                                                                              <small className="ml-2 mb-0 notification d-inline">
                                                                                                                                  R
                                                                                                                              </small>
                                                                                                                          </Fragment>
                                                                                                                      ) : (
                                                                                                                          ""
                                                                                                                      )}
                                                                                                                      {chatsState
                                                                                                                          .msgNotification
                                                                                                                          .length >
                                                                                                                          0 && (
                                                                                                                          <Fragment>
                                                                                                                              {chatsState.msgNotification.some(
                                                                                                                                  (
                                                                                                                                      noti
                                                                                                                                  ) =>
                                                                                                                                      noti.chatId ===
                                                                                                                                      chat._id
                                                                                                                              ) && (
                                                                                                                                  <Fragment>
                                                                                                                                      <small className="mr-2 notification d-inline">
                                                                                                                                          {
                                                                                                                                              chatsState.msgNotification.filter(
                                                                                                                                                  (
                                                                                                                                                      noti
                                                                                                                                                  ) =>
                                                                                                                                                      noti.chatId ===
                                                                                                                                                      chat._id
                                                                                                                                              )
                                                                                                                                                  .length
                                                                                                                                          }
                                                                                                                                      </small>
                                                                                                                                  </Fragment>
                                                                                                                              )}
                                                                                                                          </Fragment>
                                                                                                                      )}
                                                                                                                  </div>
                                                                                                                  {/* <small className="chat-last-msg text-secondary">
                                                                                                                      Last
                                                                                                                      message
                                                                                                                      ...
                                                                                                                  </small> */}
                                                                                                              </div>
                                                                                                          </Fragment>
                                                                                                      ) : (
                                                                                                          ""
                                                                                                      )}
                                                                                                  </Fragment>
                                                                                              );
                                                                                          }
                                                                                      )}
                                                                                  </Fragment>
                                                                              )}
                                                                          </li>
                                                                      </Fragment>
                                                                  );
                                                              }
                                                          )
                                                        : "Loading..."}
                                                </Fragment>
                                            </ul>
                                        </Fragment>
                                    ) : (
                                        <div className="chat-loading">
                                            "Loading..."
                                        </div>
                                    )}
                                </div>
                            </Fragment>
                        ) : (
                            ""
                        )}
                    </div>

                    {activeChats.length !== 0 ? (
                        <div className={`open-chats ${styles["open-chats"]}`}>
                            <Fragment>
                                {activeChats.map((activeChat) => {
                                    return (
                                        <Fragment key={activeChat._id}>
                                            <div
                                                className={`chat-panel  ${styles["chat-panel"]}`}
                                            >
                                                {/* new head */}
                                                {activeChat.groupName ? (
                                                    <Fragment>
                                                        <div
                                                            className={`chat-panel-head d-flex align-items-center ${styles["chat-panel-head"]}`}
                                                        >
                                                            <div className="profile-icon mr-2">
                                                                <img
                                                                    className="profile-pic-xs"
                                                                    src="/img/group-chat.jfif"
                                                                    alt="Profile"
                                                                />
                                                            </div>
                                                            <div
                                                                className={`chat-name d-flex flex-grow-1 ${styles["chat-name"]}`}
                                                            >
                                                                <div
                                                                    className={`font-weight-bolder text-capitalize m-0 ${styles["black"]} `}
                                                                >
                                                                    {
                                                                        activeChat.groupName
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="head-option d-flex align-items-center">
                                                                <div
                                                                    className="head-option pointer mx-2"
                                                                    onClick={() =>
                                                                        dispatch(
                                                                            setMinimisedState(
                                                                                activeChat._id
                                                                            )
                                                                        )
                                                                    }
                                                                >
                                                                    _
                                                                </div>
                                                                <div
                                                                    className="head-option pointer mx-2"
                                                                    onClick={() =>
                                                                        dispatch(
                                                                            closeChat(
                                                                                activeChat._id
                                                                            )
                                                                        )
                                                                    }
                                                                >
                                                                    x
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                ) : (
                                                    <Fragment>
                                                        {activeChat.users.map(
                                                            (user) => {
                                                                return (
                                                                    <Fragment
                                                                        key={
                                                                            user._id
                                                                        }
                                                                    >
                                                                        {user._id !==
                                                                        auth
                                                                            .user
                                                                            ._id ? (
                                                                            <Fragment>
                                                                                <div
                                                                                    className={`chat-panel-head d-flex align-items-center ${styles["chat-panel-head"]}`}
                                                                                >
                                                                                    <div className="profile-icon mr-2">
                                                                                        <img
                                                                                            className="profile-pic-xs me-2"
                                                                                            src={
                                                                                                user
                                                                                                    ?.userProfile
                                                                                                    ?.profileImgUrl
                                                                                                    ? getApiEndpoint() +
                                                                                                      user
                                                                                                          .userProfile
                                                                                                          .profileImgUrl
                                                                                                    : "/img/profile-image.png"
                                                                                            }
                                                                                            alt="Profile"
                                                                                        />
                                                                                    </div>
                                                                                    <div
                                                                                        className={`chat-name d-flex flex-grow-1 ${styles["chat-name"]}`}
                                                                                    >
                                                                                        <div
                                                                                            className={`font-weight-bolder text-capitalize m-0 ${styles["black"]} `}
                                                                                        >
                                                                                            {
                                                                                                user.name
                                                                                            }
                                                                                        </div>
                                                                                        {user.userType.toLowerCase() ===
                                                                                        "recruiter" ? (
                                                                                            <Fragment>
                                                                                                <small
                                                                                                    className={`ml-2 mb-0 recruiter ${styles["recruiter"]}`}
                                                                                                >
                                                                                                    R
                                                                                                </small>
                                                                                            </Fragment>
                                                                                        ) : (
                                                                                            ""
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="head-option d-flex align-items-center">
                                                                                        <div
                                                                                            className="head-option mx-2 pointer"
                                                                                            onClick={() =>
                                                                                                dispatch(
                                                                                                    setMinimisedState(
                                                                                                        activeChat._id
                                                                                                    )
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            _
                                                                                        </div>
                                                                                        <div
                                                                                            className="head-option mx-2 pointer"
                                                                                            onClick={() =>
                                                                                                dispatch(
                                                                                                    closeChat(
                                                                                                        activeChat._id
                                                                                                    )
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            x
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Fragment>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </Fragment>
                                                                );
                                                            }
                                                        )}
                                                    </Fragment>
                                                )}
                                                {/* new head */}
                                                {!activeChat.isMinimised ? (
                                                    <Fragment>
                                                        <div
                                                            className={`chat-body ${styles["chat-body"]}`}
                                                        >
                                                            {/* new chat container */}
                                                            <div
                                                                className={`chat-container d-flex flex-column-reverse ${styles["chat-container"]}`}
                                                            >
                                                                {activeChat.messages.map(
                                                                    (
                                                                        msg,
                                                                        i
                                                                    ) => {
                                                                        return (
                                                                            <Fragment
                                                                                key={
                                                                                    i
                                                                                }
                                                                            >
                                                                                <MessageItem
                                                                                    msg={
                                                                                        msg
                                                                                    }
                                                                                    index={
                                                                                        i
                                                                                    }
                                                                                    activeChat={
                                                                                        activeChat
                                                                                    }
                                                                                />
                                                                            </Fragment>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                            {/* new chat container */}
                                                            {/* new chat msg */}
                                                            <div
                                                                className={`chat-msg-input ${styles["chat-msg-input"]}`}
                                                            >
                                                                <form
                                                                    className="d-flex"
                                                                    onSubmit={(
                                                                        e
                                                                    ) =>
                                                                        onFormSubmit(
                                                                            e,
                                                                            activeChat
                                                                        )
                                                                    }
                                                                >
                                                                    <input
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            dispatch(
                                                                                setPanelMsg(
                                                                                    e,
                                                                                    activeChat._id
                                                                                )
                                                                            )
                                                                        }
                                                                        type="text"
                                                                        className="form-control flex-grow-1 text-white"
                                                                        placeholder="Type Your Message..."
                                                                        name="msg"
                                                                        value={
                                                                            activeChat.msg
                                                                        }
                                                                        autoComplete="off"
                                                                    ></input>
                                                                    <button
                                                                        className={`ml-2 btn-send-msg ${styles["btn-send-msg"]}`}
                                                                        type="submit"
                                                                    >
                                                                        <span
                                                                            className={`material-icons ${styles["icon"]}`}
                                                                            style={{
                                                                                color: "white",
                                                                            }}
                                                                        >
                                                                            send
                                                                        </span>
                                                                        {/* <span>
																			<img
																				style={{
																					width: "20px",
																					padding: "0rem 0.5rem",
																				}}
																				src="/img/right-arrow.png"
																				alt="send"
																			/>
																		</span> */}
                                                                    </button>
                                                                </form>
                                                            </div>
                                                            {/* new chat msg */}
                                                        </div>
                                                    </Fragment>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </Fragment>
                                    );
                                })}
                            </Fragment>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </Fragment>
            {/* ) : (
				""
			)} */}
        </Fragment>
    );
};
