import React, { Fragment } from "react";
import Skeleton from "../../Skeleton/Skeleton";

const AboutSkeleton = () => {
    return (
        <Fragment>
            <div className="section-body">
                <Skeleton height="50px" />
            </div>
        </Fragment>
    );
};

export default AboutSkeleton;
