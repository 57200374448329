import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import CustomTourProvider from "./components/CustomTourProvider/CustomTourProvider";
import store from "./store";

// Import global styling
import "semantic-ui-css/semantic.min.css";
import "./styles.css";
import MainLayout from "./layouts";

ReactDOM.render(
    <Provider store={store}>
        <CustomTourProvider>
            <BrowserRouter>
                <MainLayout />
                {/* <Switch>
          {getRoutes(appRoutes)}
          {getRoutes(authRoutes)}
        </Switch> */}
            </BrowserRouter>
        </CustomTourProvider>
    </Provider>,
    document.getElementById("root")
);
