import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import styles from "./loggedOutNavbar.module.css";

const LoggedOutNavbar = () => {
    return (
        <Fragment>
            <header id={styles["landing-nav"]}>
                <nav className={`${styles.navbar} navbar navbar-expand-lg`}>
                    <div className="container-fluid d-flex align-items-center">
                        <Link
                            className="d-flex navbar-brandd-flex align-items-center"
                            to="/"
                        >
                            <img
                                src="/img/landing/logo.png"
                                alt=""
                                style={{ width: "14%" }}
                            />
                            <span
                                className={`notranslate ${styles["navbar-logo-title"]}`}
                            >
                                &nbsp;Trinity
                            </span>
                        </Link>

                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="material-icons">menu</span>
                        </button>
                        <div
                            className="collapse navbar-collapse"
                            id="navbarSupportedContent"
                        >
                            <ul className={`${styles["navbar-nav"]} ms-auto`}>
                                <li
                                    className={`${styles["navbar-navitem"]} nav-item active`}
                                >
                                    <Link
                                        className="nav-link"
                                        to={{
                                            pathname: "/login",
                                            state: {
                                                from: window.location.pathname,
                                            },
                                        }}
                                    >
                                        Login
                                    </Link>
                                </li>
                                <li
                                    className={`${styles["navbar-navitem"]} nav-item active`}
                                >
                                    <Link className="nav-link" to="/register">
                                        Register
                                    </Link>
                                </li>
                                <li
                                    className={`${styles["navbar-navitem"]} nav-item active`}
                                >
                                    <Link
                                        className="nav-link"
                                        to="/post-project"
                                    >
                                        Post Project
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </Fragment>
    );
};

export default LoggedOutNavbar;
