import React from "react";
import Skeleton from "../../Skeleton/Skeleton";

const ReviewsSkeleton = () => {
    return (
        <div className="reviews-members pt-4 pb-4">
            <div className="media">
                <div className="d-flex">
                    <a href="#" className="me-3">
                        <Skeleton shape="circle" height="60px" width="60px" />
                    </a>
                    <div className="d-flex flex-column">
                        <h6>
                            <a href="#">
                                <Skeleton />
                            </a>
                        </h6>
                        <div>
                            <Skeleton />
                        </div>
                    </div>
                </div>

                <div className="media-body">
                    <div className="reviews-members-header">
                        <h6 className="mt-3">
                            <Skeleton />
                        </h6>
                        <p className="text-gray">
                            <Skeleton />
                        </p>
                    </div>
                    <div className="reviews-members-body">
                        <p>
                            <Skeleton />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReviewsSkeleton;
