import { getApiEndpoint } from "../utils/getApiEndpoint";
import { requestWithToken } from "../utils/httpRequest";
import { joinRoom, leaveRoom } from "./../utils/clientEvents";
import { setAlert } from "./alert";
import { error } from "./error";
import {
    ADD_NEW_MESSAGE,
    GET_ALL_CHAT,
    SET_ACTIVE_CHAT,
    CLEAR_ACTIVE_ROOM,
    NEW_MESSAGE_ROOMS,
    UPDATE_ACTION_TAKEN,
    UPDATE_CHAT,
    ON_ERROR,
    NEW_CHAT_GROUP_CREATED,
    INITIATE_CHAT_WITH_USER,
    GET_ALL_MSG_NOTIFICATION,
    NEW_MSG_NOTIFICATION,
    REMOVE_MSG_NOTIFICATION,
    LOAD_CHATROOM_MESSAGES,
} from "./types";

// Get all chats of current user
export const getAllChat = () => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/chat`
        );

        dispatch({
            type: GET_ALL_CHAT,
            payload: response.data.data,
        });

        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Get messages for a chatroom by chatroom id
export const getChatroomMessages = (chatroomId) => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/chat/chatroom/${chatroomId}/messages`
        );

        dispatch({
            type: LOAD_CHATROOM_MESSAGES,
            payload: response.data.data,
        });

        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Set Active Chat room
export const setActiveChat = (chat) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_ACTIVE_CHAT,
            payload: chat,
        });
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Add new msg in the message array of the activeRoom
export const newMsg = (msg) => async (dispatch) => {
    try {
        dispatch({
            type: ADD_NEW_MESSAGE,
            payload: msg,
        });
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

// Add new msg in the message array of the chatroom
export const newMsgRoom = (chatId, msg) => async (dispatch) => {
    try {
        dispatch({
            type: NEW_MESSAGE_ROOMS,
            payload: { chatId, msg },
        });
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

// Updating the action taken by the user on chat action
export const updateAction = (data) => async (dispatch, getState) => {
    try {
        const activeRoom = getState().chat.activeRoom;
        const panelChats = getState().chatPanel.activeChats;

        // Update active room in messages page
        if (activeRoom?._id === data.chatId) {
            activeRoom.messages.find(
                (msg) => msg._id.toString() === data.msgId.toString()
            ).action.actionTaken = data.action;

            dispatch({
                type: UPDATE_ACTION_TAKEN,
            });
        }

        // Update chat panel message
        if (panelChats.length !== 0) {
            if (panelChats.some((chat) => chat._id === data.chatId)) {
                dispatch({
                    type: UPDATE_CHAT,
                });
            }
        }
        if (activeRoom !== null) {
        }
    } catch (err) {
        console.log(err);
        dispatch(setAlert(err.message, "danger"));
    }
};

// Create New Chat-group
export const newChatGroup = (formData) => async (dispatch, getState) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    //const body = JSON.stringify(formData);

    try {
        const res = await requestWithToken(
            "POST",
            `${getApiEndpoint()}/chat/group`,
            formData,
            config
        );

        if (res.status === 200) {
            const socket = getState().socket.socket;

            joinRoom(socket, res.data._id);
            dispatch({
                type: NEW_CHAT_GROUP_CREATED,
                payload: res.data,
            });
            dispatch(
                setAlert(
                    `New Group - ${formData.groupName} is Created`,
                    "success"
                )
            );
        }
    } catch (err) {
        const errors = err.response.data.errors;

        if (errors) {
            dispatch(setAlert(errors[0].msg, "danger"));
        }
    }
};

// Upload File In Chat
export const uploadFile = (files) => async (dispatch) => {
    try {
        const res = await requestWithToken(
            "POST",
            `${getApiEndpoint()}/chat/files`,
            files
        );

        console.log(res.data);

        return res.data;
    } catch (err) {
        console.log(err.response.data);
        dispatch(error(err));
        return err.response.data;
    }
};

// Add new msg notification
export const newMsgNotification = (data) => async (dispatch) => {
    try {
        dispatch({
            type: NEW_MSG_NOTIFICATION,
            payload: data,
        });
    } catch (err) {
        const errors = err.response.data.errors;

        if (errors) {
            dispatch(setAlert(errors[0].msg, "danger"));
        }
    }
};

export const intiateChatWithUser = (chatroom) => (dispatch) => {
    dispatch({
        type: INITIATE_CHAT_WITH_USER,
        payload: chatroom,
    });
};

// Get all notification of the current user
export const getAllMsgNotification = () => async (dispatch) => {
    try {
        const res = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/chat/msg-notification`
        );

        dispatch({
            type: GET_ALL_MSG_NOTIFICATION,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: ON_ERROR,
            payload: null,
        });
        return err.response.data;
    }
};

// Get all notification of the current user
export const removeMsgNotification = (chatId) => async (dispatch) => {
    try {
        dispatch({
            type: REMOVE_MSG_NOTIFICATION,
            payload: chatId,
        });
    } catch (err) {
        dispatch({
            type: ON_ERROR,
            payload: null,
        });
        return err.response.data;
    }
};

export const clearActiveRoom = () => async (dispatch, getState) => {
    const activeRoom = getState().chat.activeRoom;
    const socket = getState().socket.socket;

    if (activeRoom) {
        leaveRoom(socket, activeRoom._id);
    }

    dispatch({
        type: CLEAR_ACTIVE_ROOM,
    });
};

// Upload chat attachments
export const uploadChatAttachment = (chatId, formData) => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "POST",
            `${getApiEndpoint()}/chat/${chatId}/attachment`,
            formData
        );

        return response.data;
    } catch (err) {
        dispatch(error(err));
        return err.response.data;
    }
};

// Download chat attachments
export const downloadChatAttachment =
    (chatId, formData) => async (dispatch) => {
        try {
            const response = await requestWithToken(
                "POST",
                `${getApiEndpoint()}/chat/${chatId}/attachment/download`,
                formData
            );

            return response.data;
        } catch (err) {
            dispatch(error(err));
            return err.response.data;
        }
    };
