export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const SHOW_TOPLOADER = "SHOW_TOPLOADER";
export const HIDE_TOPLOADER = "HIDE_TOPLOADER";

export const AUTH_USER = "AUTH_USER";
export const AUTH_USER_FAIL = "AUTH_USER_FAIL";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const NEW_CHAT_GROUP_CREATED = "NEW_CHAT_GROUP_CREATED";
export const INITIATE_CHAT_WITH_USER = "INITIATE_CHAT_WITH_USER";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const DISABLE_TUTORIAL = "DISABLE_TUTORIAL";

export const SOCKET_CONNECTION = "SOCKET_CONNECTION";
export const SOCKET_DISCONNECT = "SOCKET_DISCONNECT";
export const SOCKET_ERROR = "SOCKET_ERROR";

export const GET_ALL_CHAT = "GET_ALL_CHAT";
export const GET_ACTIVE_CHAT = "GET_ACTIVE_CHAT";
export const SET_ACTIVE_CHAT = "SET_ACTIVE_CHAT";
export const CLEAR_ACTIVE_ROOM = "CLEAR_ACTIVE_ROOM";
export const LOAD_CHATROOM_MESSAGES = "LOAD_CHATROOM_MESSAGES";
export const ADD_NEW_MESSAGE = "ADD_NEW_MESSAGE";
export const NEW_MESSAGE_ROOMS = "NEW_MESSAGE_ROOMS";
export const UPDATE_ACTION_TAKEN = "UPDATE_ACTION_TAKEN";
export const GET_ALL_MSG_NOTIFICATION = "GET_ALL_MSG_NOTIFICATION";
export const NEW_MSG_NOTIFICATION = "NEW_MSG_NOTIFICATION";

export const UPDATE_UNSEEN_NOTIFICATION_COUNT =
    "UPDATE_UNSEEN_NOTIFICATION_COUNT";

export const SET_PANEL_STATE = "SET_PANEL_STATE";
export const UPDATE_CHAT = "UPDATE_CHAT";
export const SET_PANEL_MSG = "SET_PANEL_MSG";
export const EMPTY_PANEL_CHAT = "EMPTY_PANEL_CHAT";
export const NEW_PANEL_MSG = "NEW_PANEL_MSG";

export const GET_MILESTONES = "GET_MILESTONES";
export const GET_PROJECT = "GET_PROJECT";
export const GET_PROJECTS = "GET_PROJECTS";
export const PROJECT_ERROR = "PROJECT_ERROR";
export const CLEAR_PROJECT = "CLEAR_PROJECT";

export const NEW_PROJECT_NOTIFICATION = "NEW_PROJECT_NOTIFICATION";
export const REMOVE_PROJECT_NOTIFICATION = "REMOVE_PROJECT_NOTIFICATION";

export const CREATE_GAME_CUSTOMIZER = "CREATE_GAME_CUSTOMIZER";
export const CLEAR_GAME_CUSTOMIZER = "CLEAR_GAME_CUSTOMIZER";

export const GET_AGREEMENTS = "GET_AGREEMENTS";
export const AGREEMENTS_ERROR = "AGREEMENTS_ERROR";

export const ON_ERROR = "ON_ERROR";
export const ON_FORBIDDEN = "ON_FORBIDDEN";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const GET_BOT_RESPONSE = "GET_BOT_RESPONSE";
export const GETTING_RESPONSE = "GETTING_RESPONSE";
export const UPDATE_BOT_PANEL = "UPDATE_BOT_PANEL";
export const BOT_ERROR = "BOT_ERROR";

export const REMOVE_MSG_NOTIFICATION = "REMOVE_MSG_NOTIFICATION";

export const REACTTOUR_STEPS = "REACTTOUR_STEPS";
export const TOGGLE_REACTTOUR = "TOGGLE_REACTTOUR";

export const ADD_REALTIME_EVENT = "ADD_REALTIME_EVENT";
export const CLEAR_REALTIME_EVENT = "CLEAR_REALTIME_EVENT";

export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_IMAGE = "GET_PROFILE_IMAGE";
