import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { hireDeveloper } from "../../../actions/project";
import { setAlert } from "../../../actions/alert";
import LoadingButton from "../../utils/LoadingButton/LoadingButton";
import { Dialog } from "@material-ui/core";
import {
    CustomizedDialogContent,
    CustomizedDialogTitle,
} from "../CustomizedModal/CustomizedModal";

const HireModal = (props) => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const bidCharges = props.bid.quote.amount * (props.customerFee / 100);
    const referralDiscount = props?.referral?.referralBenefits?.discount;
    let discountedBidCharges = 0;

    if (referralDiscount) {
        switch (referralDiscount.type) {
            case "percentage":
                discountedBidCharges =
                    (bidCharges * (100 - referralDiscount.value)) / 100;
                break;
            default:
                break;
        }
    }

    const onPayWithWallet = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const requestBody = {
            bidId: props.bid._id,
            projectId: props.projectId,
            type: props.hireType,
        };

        const response = await dispatch(hireDeveloper(requestBody));
        if (response.status) {
            dispatch(setAlert(response.msg, "success"));
            props.onClose(false);
        } else {
            dispatch(setAlert(response.msg, "danger"));
        }

        setIsLoading(false);
    };

    return (
        <Fragment>
            <Dialog
                onClose={() => props.onClose(false)}
                open={props.isOpen}
                maxWidth="sm"
                fullWidth={true}
            >
                <CustomizedDialogTitle>
                    <h3>Hire Developer</h3>
                    <p>
                        Hiring a freelancer requires you to Deposit Bid Amount
                        on Portal.
                    </p>
                </CustomizedDialogTitle>
                <CustomizedDialogContent>
                    <form onSubmit={(e) => onPayWithWallet(e)}>
                        <table className="table table-borderless">
                            <tbody>
                                <tr>
                                    <td className="text-start">Bid Amount</td>
                                    <td className="text-end">
                                        $ {props.bid.quote.amount}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-start">
                                        + Customer Charges ({props.customerFee}
                                        %)
                                    </td>
                                    <td className="text-end">$ {bidCharges}</td>
                                </tr>
                                {props.referral &&
                                    props.referral.referralBenefits
                                        .discountedHires > 0 && (
                                        <tr>
                                            <td className="text-start">
                                                - Referral Code Disc. (
                                                {100 - referralDiscount.value}
                                                {referralDiscount.type ===
                                                "percentage"
                                                    ? "%"
                                                    : "/-"}
                                                )
                                            </td>
                                            <td className="text-end">
                                                $ {discountedBidCharges}
                                            </td>
                                        </tr>
                                    )}
                                <tr>
                                    <td className="text-start">Total</td>
                                    <td className="text-end">
                                        ${" "}
                                        {props.bid.quote.amount +
                                            bidCharges -
                                            discountedBidCharges}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="text-end">
                            <LoadingButton
                                text="Pay With Wallet"
                                className="btn btn-primary-gradient"
                                isLoading={isLoading}
                            />
                        </div>
                    </form>
                </CustomizedDialogContent>
            </Dialog>
        </Fragment>
    );
};

export default HireModal;
