import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "semantic-ui-react";

import { getActiveSkills } from "../../../actions/skills";
import { setAlert } from "../../../actions/alert";

const InitialForm = (props) => {
    const formRef = useRef();
    const dispatch = useDispatch();

    const authState = useSelector((state) => state.auth);

    const [activeSkills, setActiveSkills] = useState([]);

    useEffect(() => {
        loadInitData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadInitData = async () => {
        const response = await dispatch(getActiveSkills());
        const skillsArray = [];
        response.data.map((skill) => {
            const obj = {
                key: skill._id,
                text: skill.name,
                value: skill._id,
            };
            skillsArray.push(obj);
            return null;
        });
        setActiveSkills(skillsArray);
    };

    const { title, description, skills, amount } = props.formData;

    const onChangeDropdownValue = (e, data) => {
        props.setFormData({ ...props.formData, [data.name]: data.value });
    };

    return (
        <Fragment>
            <form
                ref={formRef}
                onSubmit={(e) => {
                    e.preventDefault();
                    if (props.addonOptions.length > 0) {
                        props.onNext();
                    } else {
                        props.onSubmit(e);
                    }
                }}
            >
                <span data-tour="step-postproject-1">
                    <h6 className="text-center">
                        Step 1: Tell us about your Project
                    </h6>
                    <div className="mb-3">
                        <label className="form-label">Project title</label>
                        <input
                            type="text"
                            className="dark-input"
                            name="title"
                            value={title}
                            placeholder="Project title"
                            onChange={(e) => props.onChangeCallback(e)}
                            autoComplete="off"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">
                            Project Description
                        </label>
                        <textarea
                            className="dark-input"
                            placeholder="Your project description"
                            rows="5"
                            name="description"
                            value={description}
                            onChange={(e) => props.onChangeCallback(e)}
                            autoComplete="off"
                            required
                        ></textarea>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">
                            Select required skills
                        </label>
                        <Dropdown
                            placeholder="Select Skills"
                            className="sementic-dropdown dark-input dropdown-select mt-2 mb-3"
                            name="skills"
                            multiple
                            search
                            selection
                            options={activeSkills}
                            onChange={onChangeDropdownValue}
                            value={skills}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Project Budget</label>
                        <div className="input-group mb-3 ">
                            <span className="input-group-text borders-right-radius-none">
                                $
                            </span>
                            <input
                                type="number"
                                className="dark-input borders-left-radius-none"
                                style={{ flexGrow: "1", width: "auto" }}
                                placeholder="Project budget"
                                name="amount"
                                value={amount}
                                min="0"
                                onChange={(e) => props.onChangeCallback(e)}
                                autoComplete="off"
                                required
                            />
                        </div>
                    </div>
                </span>
                <div className="text-end mb-3">
                    {props.addonOptions.length !== 0 ? (
                        <span data-tour="step-postproject-2">
                            <button
                                className="btn btn-primary-gradient"
                                type="button"
                                onClick={() => {
                                    if (!formRef.current.checkValidity()) {
                                        formRef.current.reportValidity();
                                        return;
                                    }

                                    if (skills.length === 0) {
                                        dispatch(
                                            setAlert(
                                                "Skills is required!",
                                                "danger"
                                            )
                                        );
                                        return;
                                    }

                                    if (amount < 1) {
                                        dispatch(
                                            setAlert(
                                                "Amount cannot be less than 1!",
                                                "danger"
                                            )
                                        );
                                        return;
                                    }

                                    props.onNext();
                                }}
                            >
                                Next
                            </button>
                        </span>
                    ) : (
                        <span data-tour="step-postproject-2">
                            <button
                                type="submit"
                                className="btn btn-primary-gradient"
                                disabled={
                                    !authState.authenticated || props.loading
                                }
                            >
                                Post Free{" "}
                                {props.loading ? (
                                    <img
                                        src="/img/loader.gif"
                                        alt="loader"
                                        width="25px"
                                    />
                                ) : (
                                    ""
                                )}
                            </button>
                        </span>
                    )}
                </div>
            </form>
        </Fragment>
    );
};

export default InitialForm;
