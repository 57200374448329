import { EditorState } from "draft-js";
import React, { Fragment, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { getFrontendPublicURL } from "../../utils/getApiEndpoint";

const WYSIWYGEditor = ({ onChange }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [toolbar] = useState({
        options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "textAlign",
            "link",
            "history",
        ],
        inline: {
            options: ["bold", "italic", "underline", "strikethrough"],
            bold: {
                icon: `${getFrontendPublicURL()}/img/wysiwyg-icons/bold.png`,
                className: "wysiwyg-input",
            },
            italic: {
                icon: `${getFrontendPublicURL()}/img/wysiwyg-icons/italic-font.png`,
                className: "wysiwyg-input",
            },
            underline: {
                icon: `${getFrontendPublicURL()}/img/wysiwyg-icons/underline.png`,
                className: "wysiwyg-input",
            },
            strikethrough: {
                icon: `${getFrontendPublicURL()}/img/wysiwyg-icons/strikethrough-font-variant.png`,
                className: "wysiwyg-input",
            },
        },
        blockType: {
            className: "wysiwyg-input",
            dropdownClassName: "wysiwyg-dropdown-item",
        },
        fontSize: {
            className: "wysiwyg-input",
            dropdownClassName: "wysiwyg-dropdown-item",
        },
        fontFamily: {
            className: "wysiwyg-input",
            dropdownClassName: "wysiwyg-dropdown-item",
        },
        list: {
            options: ["unordered", "ordered"],
            unordered: {
                icon: `${getFrontendPublicURL()}/img/wysiwyg-icons/list.png`,
                className: "wysiwyg-input",
            },
            ordered: {
                icon: `${getFrontendPublicURL()}/img/wysiwyg-icons/ordered-list.png`,
                className: "wysiwyg-input",
            },
        },
        textAlign: {
            left: {
                icon: `${getFrontendPublicURL()}/img/wysiwyg-icons/left-align.png`,
                className: "wysiwyg-input",
            },
            center: {
                icon: `${getFrontendPublicURL()}/img/wysiwyg-icons/center-align.png`,
                className: "wysiwyg-input",
            },
            right: {
                icon: `${getFrontendPublicURL()}/img/wysiwyg-icons/right-align.png`,
                className: "wysiwyg-input",
            },
            justify: {
                icon: `${getFrontendPublicURL()}/img/wysiwyg-icons/justification.png`,
                className: "wysiwyg-input",
            },
        },
        colorPicker: {
            icon: `${getFrontendPublicURL()}/img/wysiwyg-icons/color-picker.png`,
            className: "wysiwyg-input",
        },
        link: {
            link: {
                icon: `${getFrontendPublicURL()}/img/wysiwyg-icons/link.png`,
                className: "wysiwyg-input",
            },
            unlink: {
                icon: `${getFrontendPublicURL()}/img/wysiwyg-icons/unlink.png`,
                className: "wysiwyg-input",
            },
        },
        remove: {
            icon: `${getFrontendPublicURL()}/img/wysiwyg-icons/eraser.png`,
            className: "wysiwyg-input",
        },
        history: {
            undo: {
                icon: `${getFrontendPublicURL()}/img/wysiwyg-icons/undo-circular-arrow.png`,
                className: "wysiwyg-input",
            },
            redo: {
                icon: `${getFrontendPublicURL()}/img/wysiwyg-icons/redo-arrow-symbol.png`,
                className: "wysiwyg-input",
            },
        },
    });

    return (
        <Fragment>
            <Editor
                editorState={editorState}
                toolbarClassName="wysiwyg-toolbar"
                editorClassName="dark-input wysiwyg-editor"
                placeholder="Type comment..."
                toolbar={toolbar}
                onEditorStateChange={(state) => {
                    setEditorState(state);
                    onChange(state);
                }}
            />
        </Fragment>
    );
};

export default WYSIWYGEditor;
