import React, { Fragment, useContext } from "react";
import { useSelector } from "react-redux";

import { ProfileContext } from "../../../contexts/ProfileContext";

import styles from "./skillDisplay.module.css";

const SkillDisplay = (props) => {
    const profileContext = useContext(ProfileContext);

    const authState = useSelector((state) => state.auth);

    return (
        <Fragment>
            <Fragment>
                <section
                    className={`${styles["skills-section"]} light-bg p-4 my-lg-3 mb-3 text-center`}
                >
                    <h5>Skills</h5>
                    <div className={`${styles["all-skills"]} dark-bg p-2 mt-2`}>
                        {profileContext.profile.skills.length > 0 ? (
                            <ul
                                className={`${styles["job-skills"]} d-flex flex-wrap p-0 mb-0`}
                            >
                                {profileContext.profile.skills.map((skill) => {
                                    return (
                                        <Fragment key={skill._id}>
                                            <li
                                                className={
                                                    styles["badge-hollow"]
                                                }
                                            >
                                                {skill.name}
                                            </li>
                                        </Fragment>
                                    );
                                })}
                            </ul>
                        ) : (
                            <p className="text-muted">No skills added!</p>
                        )}
                    </div>
                    {authState.user !== null ? (
                        <Fragment>
                            {profileContext.profile.user._id ===
                            authState.user._id ? (
                                <Fragment>
                                    <div className="d-flex mt-3">
                                        <button
                                            className="btn-save flex-grow-1"
                                            onClick={props.toggleMode}
                                        >
                                            Add Skills
                                        </button>
                                    </div>
                                </Fragment>
                            ) : (
                                ""
                            )}
                        </Fragment>
                    ) : (
                        ""
                    )}
                </section>
            </Fragment>
            {/* )} */}
        </Fragment>
    );
};

export default SkillDisplay;
