const steps = {
    dashboard: [
        {
            selector: '[data-tour="step-dashboard-1"]',
            content: "Here you can see your monthly stats for bids and revenue",
        },
        {
            selector: '[data-tour="step-dashboard-2"]',
            content: "You can access your active projects quickly from here",
        },
        {
            selector: '[data-tour="step-dashboard-3"]',
            content: "This section shows you how much you used your plan.",
        },
        {
            selector: '[data-tour="step-dashboard-4"]',
            content: "From here you can quickly deposit amount in your wallet",
        },
        {
            selector: '[data-tour="step-navbar-1"]',
            content: "Click to view Projects",
        },
    ],
    navigationOne: [
        {
            selector: '[data-tour="step-navbar-2"]',
            content: "Click here to browse projects.",
        },
    ],
    navigationTwo: [
        {
            selector: '[data-tour="step-navbar-5"]',
            content: "Click here To checkout Profile.",
        },
    ],
    browseProjectSteps: [
        {
            selector: '[data-tour="step-browseProject-2"]',
            content:
                "You can find latest projects here depending on your skills",
        },
        {
            selector: '[data-tour="step-browseProject-1"]',
            content: "Filter the projects from the long list of projects",
        },
        {
            selector: '[data-tour="step-navbar-4"]',
            content: "Click to go to Profile",
        },
    ],

    postProjectSteps1: [
        {
            selector: '[data-tour="step-postproject-1"]',
            content: "You can fill up this form to post a new project.",
        },
        {
            selector: '[data-tour="step-postproject-2"]',
            content: "Click on next to continue.",
        },
    ],
    postProjectSteps2: [
        {
            selector: '[data-tour="step-postproject-1"]',
            content:
                "We also offer some additional addons for your project which can help you to operate on the platform.",
        },
    ],

    profileSteps: [
        {
            selector: '[data-tour="step-profile-1"]',
            content: "You can edit your basic details here",
        },
        {
            selector: '[data-tour="step-profile-2"]',
            content: "You can edit skills for your profile here",
        },
        {
            selector: '[data-tour="step-profile-3"]',
            content: "Here you can add details about yourself",
        },
        {
            selector: '[data-tour="step-profile-4"]',
            content: "Here you can add/edit your portfolio",
        },
        {
            selector: '[data-tour="step-profile-5"]',
            content: "Here you can add/edit your education",
        },
        {
            selector: '[data-tour="step-profile-6"]',
            content: "Here you can add/edit your experience",
        },
        {
            selector: '[data-tour="step-navbar-3"]',
            content: "Here you can add/edit your experience",
        },
    ],
};

export const getSteps = (query) => {
    return steps[query];
};
