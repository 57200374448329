import React, {
	createContext,
	Fragment,
	useContext,
	useEffect,
	useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";

import { logout } from "../../actions/auth";
import CustomTourProvider from "../CustomTourProvider/CustomTourProvider";
import { reacttourUpdate, toggleDirectTutorial } from "../../actions/reacttour";

// Import Styling
import styles from "./loggedInNavbar.module.css";
import { getSteps } from "../../utils/tourSteps";
import { fetchCurrentUserProfilePic } from "../../actions/profile";
import { getApiEndpoint } from "../../utils/getApiEndpoint";
import { useTour } from "@reactour/tour";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import {
	getAllNotifications,
	getNewNotificationCount,
	notificationsSeen,
} from "../../actions/notification";
import {
	newRecommendedProject,
	removeSocketEventListener,
} from "../../utils/clientEvents";
import ReviewModal from "../Modals/ReviewModal/ReviewModal";

const disableBody = () => {
	document.body.style.overflow = "hidden";
};
const enableBody = () => {
	document.body.style.overflow = "auto";
};
const LoggedInNavbar = (props) => {
	const tourSteps = useSelector((state) => state.reacttour.steps);
	return (
		<CustomTourProvider
			disableBody={disableBody}
			enableBody={enableBody}
			steps={tourSteps}
		>
			<Main {...props} />
		</CustomTourProvider>
	);
};

const NavbarContext = createContext();

const Main = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const { setIsOpen } = useTour();

	const tourState = useSelector((state) => state.reacttour);
	const authState = useSelector((state) => state.auth);
	const profileState = useSelector((state) => state.profile);
	const socketState = useSelector((state) => state.socket);
	const msgNotification = useSelector((state) => state.chat.msgNotification);
	const notificationState = useSelector((state) => state.notification);

	const [notifications, setNotifications] = useState(null);
	const [recommendedProjectsCount, setRecommendedProjectsCount] = useState(0);
	const [showReviewModal, setShowReviewModal] = useState(false);
	const [pendingReviewData, setPendingReviewData] = useState({
		projectId: "",
		reviewedTo: "",
	});

	useEffect(() => {
		dispatch(fetchCurrentUserProfilePic());
		dispatch(getNewNotificationCount());

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		// Realtime page events
		if (
			socketState.socket &&
			location.pathname !== "/recommended-projects"
		) {
			newRecommendedProject(socketState.socket, (data) => {
				setRecommendedProjectsCount((prev) => prev + 1);
			});

			return () => {
				removeSocketEventListener(
					socketState.socket,
					"new-project-notification"
				);
			};
		} else if (
			socketState.socket &&
			location.pathname === "/recommended-projects"
		) {
			setRecommendedProjectsCount(0);
			return () => {
				removeSocketEventListener(
					socketState.socket,
					"new-project-notification"
				);
			};
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socketState.socket, location.pathname]);

	const updateSteps = () => {
		if (
			authState.user &&
			(authState.user.tutorial || tourState.tutorials)
		) {
			const steps = getSteps("navigationOne");
			dispatch(reacttourUpdate(steps));
		}
	};

	const updateSteps2 = () => {
		if (
			authState.user &&
			(authState.user.tutorial || tourState.tutorials)
		) {
			const steps = getSteps("navigationTwo");
			dispatch(reacttourUpdate(steps));
		}
	};

	const fetchNotifactions = async () => {
		setNotifications(null);

		const response = await dispatch(getAllNotifications());
		if (response.status) {
			setNotifications(response.data);
			dispatch(notificationsSeen());
		}
	};

	const loadTutorial = () => {
		history.replace("/dashboard");
		dispatch(toggleDirectTutorial(true));
		setIsOpen(true);
	};

	const logOutUser = () => {
		if (socketState.socket !== null) {
			socketState.socket.disconnect(true);
		}
		dispatch(logout());
		history.replace("/");
	};

	const navbarContextData = {
		pendingReviewModal: {
			showReviewModal,
			setShowReviewModal,
			pendingReviewData,
			setPendingReviewData,
		},
	};

	return (
		<Fragment>
			<NavbarContext.Provider value={navbarContextData}>
				<nav
					className={`${styles["my-navbar"]} navbar navbar-expand-md navbar-dark nav-height py-0 px-3`}
				>
					<div className="container-lg p-0">
						<Link
							className={`d-flex align-items-center`}
							to="/dashboard"
						>
							<img
								src="/img/landing/logo.png"
								alt=""
								style={{ width: "18%" }}
							/>
							<span
								className={`notranslate ${styles["navbar-logo-title"]}`}
							>
								&nbsp;Trinity
							</span>
						</Link>
						<button
							className="navbar-toggler"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#navbarSupportedContent"
							aria-controls="navbarSupportedContent"
							aria-expanded="false"
							aria-label="Toggle navigation"
						>
							<span className="material-icons">menu</span>
						</button>
						<Navbar.Collapse>
							<Nav className="align-items-center ms-auto">
								<Nav.Item>
									<Link to="/dashboard" className="nav-link">
										Dashboard
									</Link>
								</Nav.Item>
								{/* <Dropdown as={ButtonGroup}>
                                <Button variant="success">Messages</Button>
                                <Dropdown.Toggle split />
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">
                                        Action
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">
                                        Another action
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                        Something else
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown> */}
								<NavDropdown
									title={
										<span>
											Messages
											{msgNotification.length > 0 ? (
												<span className="d-inline-block notification ms-2">
													{msgNotification.length}
												</span>
											) : (
												""
											)}
										</span>
									}
								>
									<NavDropdown.Item>
										<Link to="/messages">Messages</Link>
									</NavDropdown.Item>
									<NavDropdown.Item>
										<Link to="/scheduled-meetings">
											Scheduled Meetings
										</Link>
									</NavDropdown.Item>
								</NavDropdown>
								<NavDropdown
									title={
										<span data-tour="step-navbar-1">
											Projects{" "}
											<span className="ms-1 d-inline-block">
												{recommendedProjectsCount >
													0 && (
													<small
														className={`${styles["count"]}`}
													>
														{
															recommendedProjectsCount
														}
													</small>
												)}
											</span>
										</span>
									}
									onClick={updateSteps}
									autoClose={
										tourState.tutorials
											? "inside"
											: "default"
									}
								>
									<NavDropdown.Item>
										<Link to="/my-projects">
											My Projects
										</Link>
									</NavDropdown.Item>
									<NavDropdown.Item>
										<Link to="/recommended-projects">
											Recommended Projects{" "}
											<span className="ms-1 d-inline-block">
												{recommendedProjectsCount >
													0 && (
													<small
														className={`${styles["count"]}`}
													>
														{
															recommendedProjectsCount
														}
													</small>
												)}
											</span>
										</Link>
									</NavDropdown.Item>
									<NavDropdown.Item>
										<Link to="/browse-projects">
											<span
												className="text-dark"
												data-tour="step-navbar-2"
											>
												Browse All Projects
											</span>
										</Link>
									</NavDropdown.Item>
									<NavDropdown.Item>
										<Link to="/bids-submitted">
											<span
												className="text-dark"
												data-tour="step-navbar-2"
											>
												Bids Submitted
											</span>
										</Link>
									</NavDropdown.Item>
								</NavDropdown>
								<Nav.Item>
									<Link to="/game-forms" className="nav-link">
										Game Customizer
									</Link>
								</Nav.Item>
								<NavDropdown
									className="no-dropdown-toggle notification-dropdown"
									title={
										<div
											className="profile-icon position-relative"
											onClick={fetchNotifactions}
										>
											<span className="material-icons">
												notifications
											</span>
											<span
												className={`${styles["notification-count"]}`}
											>
												{
													notificationState.unseenNotificationCount
												}
											</span>
										</div>
									}
								>
									<ul
										className={`${styles["notification-dropdown"]} p-2`}
									>
										<ul
											className="nav nav-pills d-flex"
											id="pills-tab"
											role="tablist"
										>
											<li
												className="nav-item flex-grow-1"
												role="presentation"
											>
												<button
													className="nav-link notification-tab active w-100"
													id="pills-new-tab"
													data-bs-toggle="pill"
													data-bs-target="#pills-new"
													type="button"
													role="tab"
													aria-controls="pills-new"
													aria-selected="true"
												>
													New
												</button>
											</li>
											<li
												className="nav-item flex-grow-1"
												role="presentation"
											>
												<button
													className="nav-link notification-tab w-100"
													id="pills-all-tab"
													data-bs-toggle="pill"
													data-bs-target="#pills-all"
													type="button"
													role="tab"
													aria-controls="pills-all"
													aria-selected="false"
												>
													All
												</button>
											</li>
										</ul>
										<div
											className="tab-content"
											id="pills-tabContent"
										>
											<div
												className="tab-pane fade show active"
												id="pills-new"
												role="tabpanel"
												aria-labelledby="pills-new-tab"
											>
												{!notifications ? (
													<p className="py-5 text-center text-muted">
														Loading...
													</p>
												) : (
													<>
														{notifications?.new
															.length > 0 ? (
															<>
																{notifications.new.map(
																	(
																		notification
																	) => (
																		<NotificationItem
																			key={
																				notification._id
																			}
																			notification={
																				notification
																			}
																		/>
																	)
																)}
															</>
														) : (
															<p className="py-5 text-center text-muted">
																No
																notifications!
															</p>
														)}
													</>
												)}
											</div>
											<div
												className="tab-pane fade"
												id="pills-all"
												role="tabpanel"
												aria-labelledby="pills-all-tab"
											>
												{!notifications ? (
													<p className="py-5 text-center text-muted">
														Loading...
													</p>
												) : (
													<>
														{notifications?.all
															.length > 0 ? (
															<>
																{notifications.all.map(
																	(
																		notification
																	) => (
																		<NotificationItem
																			key={
																				notification._id
																			}
																			notification={
																				notification
																			}
																		/>
																	)
																)}
															</>
														) : (
															<p className="py-5 text-center text-muted">
																No
																notifications!
															</p>
														)}
													</>
												)}
											</div>
										</div>
									</ul>
								</NavDropdown>
								<NavDropdown
									className="no-dropdown-toggle"
									title={
										<div className="profile-icon">
											<span data-tour="step-navbar-4">
												{profileState.profileImgUrl ===
												"" ? (
													<img
														className="profile-pic-sm"
														src="/img/profile-image.png"
														alt="Profile"
													/>
												) : (
													<img
														className="profile-pic-sm"
														src={
															getApiEndpoint() +
															profileState.profileImgUrl
														}
														alt="Profile"
													/>
												)}
											</span>
										</div>
									}
									onClick={updateSteps2}
									autoClose={
										tourState.tutorials
											? "inside"
											: "default"
									}
								>
									<NavDropdown.Item>
										<Link to="/transactions">
											<div className="d-flex justify-content-between align-items-center">
												<span className="material-icons text-dark">
													account_balance_wallet
												</span>
												<small className="text-right"></small>
											</div>
										</Link>
									</NavDropdown.Item>
									<NavDropdown.Item>
										{authState.user !== null && (
											<Link
												to={`/profile/${authState.user._id}`}
											>
												<span
													className="text-dark"
													data-tour="step-navbar-5"
												>
													Profile
												</span>
											</Link>
										)}
									</NavDropdown.Item>
									<NavDropdown.Item>
										<Link to="/withdraw-funds">
											Withdraw Funds
										</Link>
									</NavDropdown.Item>
									<NavDropdown.Item>
										<Link to="/upgrade-plan">
											Upgrade Plan
										</Link>
									</NavDropdown.Item>
									<NavDropdown.Item>
										<Link to="/refer">Refer A Friend</Link>
									</NavDropdown.Item>
									<NavDropdown.Item>
										<Link to="/settings">Settings</Link>
									</NavDropdown.Item>
									<NavDropdown.Item>
										<Link to="#" onClick={loadTutorial}>
											Tutorial
										</Link>
									</NavDropdown.Item>
									<NavDropdown.Item>
										<Link to="#" onClick={logOutUser}>
											Logout
										</Link>
									</NavDropdown.Item>
								</NavDropdown>
								<Nav.Item>
									<Link
										className="btn btn-primary-gradient"
										to="/post-project"
									>
										<span data-tour="step-navbar-3">
											Post Project
										</span>
									</Link>
								</Nav.Item>
							</Nav>
						</Navbar.Collapse>
					</div>
				</nav>
			</NavbarContext.Provider>

			<ReviewModal
				isOpen={navbarContextData.pendingReviewModal.showReviewModal}
				onClose={
					navbarContextData.pendingReviewModal.setShowReviewModal
				}
				projectId={pendingReviewData.projectId}
				reviewedTo={pendingReviewData.reviewedTo}
				reviewAs="developer"
			/>
		</Fragment>
	);
};

const NotificationItem = ({ notification }) => {
	const history = useHistory();
	const navbarContext = useContext(NavbarContext);

	const handleClick = (link) => {
		if (link.startsWith("#")) {
			if (link.startsWith("#pendingReview")) {
				console.log(link);
				const splitLink = link.split("?");
				const params = splitLink[1].split("&");
				const projectId = params[0].split("projectId=")[1];
				const reviewedTo = params[1].split("reviewedTo=")[1];

				console.log(projectId, reviewedTo);

				navbarContext.pendingReviewModal.setPendingReviewData({
					projectId,
					reviewedTo,
				});
				navbarContext.pendingReviewModal.setShowReviewModal(true);
			}
		} else {
			history.push(link);
		}
	};

	return (
		<li className={`${styles["notification-item"]}`}>
			{/* <Link to={`${notification.link}`}> */}
			<div
				className={`dropdown-item ${styles["dropdown-item"]}`}
				onClick={() => handleClick(notification.link)}
			>
				<div className="d-flex">
					<div className="d-flex flex-column">
						<div className="d-flex justify-content-between">
							<h6
								className="text-white mb-0"
								style={{
									color: "rgba(0,0,0,.87)",
								}}
							>
								{notification.title}
							</h6>
						</div>
						<small
							className="text-muted text-break mb-1"
							style={{
								whiteSpace: "break-spaces",
								maxWidth: "30ch",
								color: "rgba(0,0,0,.87)",
							}}
						>
							{notification.description}
						</small>
					</div>
				</div>
			</div>
			{/* </Link> */}
		</li>
	);
};

export default LoggedInNavbar;
