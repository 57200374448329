import React, { Fragment } from 'react';
import styles from './CTA.module.css';
import { Link } from 'react-router-dom';

const CTA = () => {
    return (
        <Fragment>
            <section
                id={styles['call-to-action-section']}
                className='py-5 px-3'
            >
                <div className='container'>
                    <h2 className='section-title mb-4'>
                        Start Your Project Today
                    </h2>
                    <div className='actions text-center'>
                        <Link to='/game-customizer'>
                            <button className={`${styles.pushable} me-3 mb-3`}>
                                <span className={styles.shadow}></span>
                                <span className={styles.edge}></span>
                                <span className={styles.front}>
                                    Configurate Experience
                                </span>
                            </button>
                        </Link>

                        {/* <button className="btn btn-primary-gradient px-4 mr-3">
							Configure Game
						</button> */}
                        <Link to='/register'>
                            <button
                                className={`${styles.pushable} ${styles.transparent}`}
                            >
                                <span className={styles.shadow}></span>
                                <span className={styles.edge}></span>
                                <span className={styles.front}>
                                    Earn Money As Freelancer
                                </span>
                            </button>
                            {/* <button className="btn btn-second px-4">
								Earn Money As Freelancer
							</button> */}
                        </Link>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default CTA;
