import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../components/LoggedInNavbar/LoggedInNavbar";
import AlertsContainer from "../../components/AlertsContainer/AlertsContainer";
import { ChatPanel } from "../../components/ChatPanel/ChatPanel";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import TopLoader from "../../components/TopLoader/TopLoader";
import { clearError } from "../../actions/error";
import NewProjectsContainer from "../../components/NewProjectsContainer/NewProjectsContainer";
import { Switch } from "react-router-dom";
import appRoutes from "../../routes/app";
import { getRoutes } from "../../utils/getRoutes";
import { getAllMsgNotification } from "../../actions/chat";
import { openSocketConnection } from "../../actions/socket";
import { useLocation, useHistory } from "react-router-dom";

const App = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const authState = useSelector((state) => state.auth);
	const errorState = useSelector((state) => state.error);

	const [ShowChatPanel, setShowChatPanel] = useState(true);

	useEffect(() => {
		dispatch(clearError());

		dispatch(getAllMsgNotification());
		dispatch(openSocketConnection(localStorage.getItem("auth-token")));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// If profile not found
		if (!authState.user.profile) {
			history.replace("/setupprofile");
		}

		// If profile found and user go to setup profile
		if (authState.user.profile && location.pathname === "/setupprofile") {
			history.replace("/dashboard");
		}

		if (
			location.pathname === "/messages" ||
			location.pathname.includes("/game-customizer/") ||
			location.pathname === "/setupprofile"
		) {
			setShowChatPanel(false);
		} else {
			setShowChatPanel(true);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	return (
		<Fragment>
			<TopLoader />
			<Navbar />
			{ShowChatPanel ? <ChatPanel /> : ""}
			<AlertsContainer />
			{!errorState.isError && !errorState.isForbidden ? (
				// Routes
				<Switch>{getRoutes(appRoutes)}</Switch>
			) : (
				<ErrorComponent />
			)}
			<NewProjectsContainer />
		</Fragment>
	);
};

export default App;
