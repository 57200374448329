import React, { useState } from "react";
import { Dialog } from "@material-ui/core";
import {
    CustomizedDialogContent,
    CustomizedDialogTitle,
} from "../CustomizedModal/CustomizedModal";
import LoadingButton from "../../utils/LoadingButton/LoadingButton";
import { useFormik } from "formik";
import { milestoneDeliveryAction } from "../../../actions/milestone";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../actions/alert";

const RejectMilestoneDeliveryModal = ({
    isOpen,
    onClose,
    milestoneId,
    deliveryId,
}) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const rejectMilestoneDelivery = useFormik({
        initialValues: {
            action: "rejected",
            rejectReason: "",
        },
        onSubmit: async (values) => {
            setLoading(true);
            const response = await dispatch(
                milestoneDeliveryAction(milestoneId, deliveryId, values)
            );
            if (response.status) {
                dispatch(setAlert(response.msg, "success"));
                onClose();
            } else {
                dispatch(setAlert(response.msg, "danger"));
            }
            setLoading(false);
        },
    });

    return (
        <Dialog
            onClose={() => onClose(false)}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
            maxWidth="sm"
            fullWidth={true}
        >
            <CustomizedDialogTitle id="customized-dialog-title">
                <h3 className="modal-title text-accent">
                    Reject Milestone Delivery
                </h3>
            </CustomizedDialogTitle>
            <CustomizedDialogContent>
                <form onSubmit={rejectMilestoneDelivery.handleSubmit}>
                    <div className="mb-3">
                        <label className="form-label">
                            Reject Reason{" "}
                            <small className="text-danger">*</small>
                        </label>
                        <textarea
                            name="rejectReason"
                            value={rejectMilestoneDelivery.values.rejectReason}
                            onChange={rejectMilestoneDelivery.handleChange}
                            className="dark-input"
                            placeholder="Reject Reason"
                            rows="3"
                            required
                        ></textarea>
                    </div>
                    <div>
                        <LoadingButton isLoading={loading} text="Submit" />
                    </div>
                </form>
            </CustomizedDialogContent>
        </Dialog>
    );
};

export default RejectMilestoneDeliveryModal;
