import { getApiEndpoint } from "../utils/getApiEndpoint";
import { requestWithToken } from "../utils/httpRequest";
import { error } from "./error";

// Download file
export const downloadFile = (formData) => async (dispatch) => {
	try {
		const response = await requestWithToken(
			"POST",
			`${getApiEndpoint()}/file/download`,
			formData,
			{ responseType: "blob" }
		);

		return response.data;
	} catch (err) {
		dispatch(error(err));
		return err.response.data;
	}
};
