import { getApiEndpoint } from "../utils/getApiEndpoint";
import { requestWithToken } from "../utils/httpRequest";

import { setAlert } from "./alert";

// Save New Payout
export const setNewPayout =
    (title, payoutType, payoutDetails) => async (dispatch, getState) => {
        try {
            const body = { title, payoutType, payoutDetails };
            const res = await requestWithToken(
                "POST",
                `${getApiEndpoint()}/payout-methods`,
                body
            );

            return res.data;
        } catch (err) {
            const errors = err.response.data;

            if (errors) {
                dispatch(setAlert(errors.msg, "danger"));
            }
        }
    };

// Get all payout methods
export const getPayoutMethods = () => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "GET",
            `${getApiEndpoint()}/payout-methods`
        );

        return response.data;
    } catch (err) {
        const errors = err.response.data;

        if (errors) {
            dispatch(setAlert(errors.msg, "danger"));
        }
    }
};

// Delete payout method
export const deletePayoutMethod = (id) => async (dispatch) => {
    try {
        const response = await requestWithToken(
            "DELETE",
            `${getApiEndpoint()}/payout-methods/` + id
        );

        return response.data;
    } catch (err) {
        const errors = err.response.data;

        if (errors) {
            dispatch(setAlert(errors.msg, "danger"));
        }
    }
};
