import { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHiredBidsOnProject } from "../../actions/bid";
import { ProjectControlCenterContext } from "../../contexts/ProjectControlCenterContext";
import HiredDeveloperListItem from "../HiredDeveloperListItem/HiredDeveloperListItem";
import styles from "./ProjectControlCenter.module.css";

const DevelopersTab = ({ projectId }) => {
    const dispatch = useDispatch();

    const projectControlCenterContext = useContext(ProjectControlCenterContext);
    const authState = useSelector((state) => state.auth);

    const [loading, setLoading] = useState(true);
    const [developers, setDevelopers] = useState([]);

    useEffect(() => {
        const fetchHiredDevelopers = async () => {
            const response = await dispatch(fetchHiredBidsOnProject(projectId));
            if (response.status) {
                setDevelopers(response.data);
            }

            setLoading(false);
        };

        if (
            projectControlCenterContext.project?.user?._id ===
            authState.user._id
        ) {
            fetchHiredDevelopers();
        } else {
            projectControlCenterContext.onChangeTab(0);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);

    return (
        <Fragment>
            <h4>Hired Developers</h4>
            <hr></hr>
            {!loading ? (
                <Fragment>
                    {developers.length > 0 ? (
                        <ul className={`${styles["bid-list"]} p-0`}>
                            {developers.map((developer) => {
                                return (
                                    <HiredDeveloperListItem
                                        developer={developer}
                                    />
                                );
                            })}
                        </ul>
                    ) : (
                        <h5 className="text-center text-muted mt-5">
                            No Hired Developers!
                        </h5>
                    )}
                </Fragment>
            ) : (
                <h5 className="text-center text-muted mt-5">
                    Loading Developers...
                </h5>
            )}
        </Fragment>
    );
};

export default DevelopersTab;
