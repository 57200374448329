import React, { useState } from "react";
import { Dialog } from "@material-ui/core";
import {
    CustomizedDialogContent,
    CustomizedDialogTitle,
} from "../CustomizedModal/CustomizedModal";
import LoadingButton from "../../utils/LoadingButton/LoadingButton";
import { useDispatch } from "react-redux";
import { milestoneDeliveryAction } from "../../../actions/milestone";
import { setAlert } from "../../../actions/alert";

const AcceptMilestoneDeliveryModal = ({
    isOpen,
    onClose,
    milestoneId,
    deliveryId,
}) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const onAcceptDelivery = async () => {
        setLoading(true);
        const response = await dispatch(
            milestoneDeliveryAction(milestoneId, deliveryId, {
                action: "accepted",
            })
        );
        if (response.status) {
            dispatch(setAlert(response.msg, "success"));
            onClose();
        } else {
            dispatch(setAlert(response.msg, "danger"));
        }
        setLoading(false);
    };

    return (
        <Dialog
            onClose={() => onClose(false)}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
            maxWidth="sm"
            fullWidth={true}
        >
            <CustomizedDialogTitle id="customized-dialog-title">
                <h3 className="modal-title text-accent">
                    Accept Milestone Delivery
                </h3>
            </CustomizedDialogTitle>
            <CustomizedDialogContent>
                <p className="text-center">
                    Are you sure to accept this delivery. Accepting delivery
                    will close the milestone and release all payments to
                    developer.
                </p>
                <div className="d-flex">
                    <button
                        className="btn btn-primary-gradient outline flex-grow-1 me-3"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <LoadingButton
                        loading={loading}
                        text="Accept"
                        className="btn btn-primary-gradient flex-grow-1"
                        onClick={onAcceptDelivery}
                    />
                </div>
            </CustomizedDialogContent>
        </Dialog>
    );
};

export default AcceptMilestoneDeliveryModal;
