import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { removeMsgNotification, setActiveChat } from "../../../actions/chat";

import styles from "./ChatList.module.css";
import { getApiEndpoint } from "../../../utils/getApiEndpoint";
import { setAlert } from "../../../actions/alert";

const ChatList = () => {
    const dispatch = useDispatch();

    const authState = useSelector((state) => state.auth);
    const chatState = useSelector((state) => state.chat);
    const socketState = useSelector((state) => state.socket);
    const notifications = useSelector((state) => state.chat.msgNotification);
    const chatBot = useSelector((state) => state.chatBot.chat);

    const [chatList] = useState(chatState.chats);

    const enterRoom = async (chat) => {
        if (socketState.socket != null) {
            if (
                !chatState.activeRoom ||
                chatState.activeRoom._id !== chat._id
            ) {
                if (chat._id !== "chatbot") {
                    await socketState.socket.emit("join-chatroom", chat._id);
                    await socketState.socket.emit("delete-notifications", {
                        userId: authState.user._id,
                        chatId: chat._id,
                    });
                    dispatch(removeMsgNotification(chat._id));
                }
                await dispatch(setActiveChat(chat));
            }
        } else {
            dispatch(setAlert("Server Error", "danger"));
        }
    };

    return (
        <>
            <section>
                {/* <div className={`${styles["chat-search"]} nav-height`}>
                    <form>
                        <input
                            type="text"
                            className="form-control w-100"
                            placeholder="Search Chat..."
                            onChange={(e) => onSearchChat(e)}
                        />
                    </form>
                </div> */}
                <hr></hr>
                <ul className={`${styles["chat-contacts"]} m-0`}>
                    <li
                        onClick={() => enterRoom(chatBot)}
                        className={`d-flex aling-items-center gap-2 ${styles["chat-contact-one"]}`}
                    >
                        <div className="d-flex aling-items-center gap-2 profile-icon mr-2">
                            <img
                                className="profile-pic-sm"
                                src="/img/profile-image.png"
                                alt="Profile"
                            />
                        </div>
                        <div className="contact-detail">
                            <div
                                className={`chat-name  ${styles["chat-name"]}`}
                            >
                                <h5 className="font-weight-bolder text-capitalize d-inline m-0 d-inline">
                                    Chatbot
                                </h5>
                            </div>
                            <small className="chat-last-msg text-secondary">
                                {chatBot.messages[0].message}
                            </small>
                        </div>
                    </li>
                    {chatList.length !== 0 ? (
                        chatList.map((chat, chatIndex) => {
                            return (
                                <Fragment key={chatIndex}>
                                    <li
                                        onClick={() => enterRoom(chat)}
                                        className={`${styles["chat-contact-one"]} `}
                                    >
                                        {chat.groupName ? (
                                            <Fragment>
                                                <div className="profile-icon mr-2">
                                                    <img
                                                        className="profile-pic-sm"
                                                        src={
                                                            chat
                                                                ? getApiEndpoint()
                                                                : "/img/group-chat.jfif"
                                                        }
                                                        alt="Profile"
                                                    />
                                                </div>
                                                <div className="w-100">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <div className="d-flex gap-2 flex-grow-1">
                                                            <h5 className="font-weight-bolder text-capitalize d-inline m-0 d-inline">
                                                                {chat.groupName}
                                                            </h5>
                                                            {notifications.length >
                                                            0 ? (
                                                                <Fragment>
                                                                    {notifications.some(
                                                                        (
                                                                            noti
                                                                        ) =>
                                                                            noti.chatId ===
                                                                            chat._id
                                                                    ) ? (
                                                                        <Fragment>
                                                                            <small className="mr-2 notification d-inline">
                                                                                {
                                                                                    notifications.filter(
                                                                                        (
                                                                                            noti
                                                                                        ) =>
                                                                                            noti.chatId ===
                                                                                            chat._id
                                                                                    )
                                                                                        .length
                                                                                }
                                                                            </small>
                                                                        </Fragment>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </Fragment>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div
                                                            className={`d-flex m-0`}
                                                        >
                                                            <span className="material-icons pointer">
                                                                more_horiz
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        //   className="contact-message"
                                                        className="d-flex align-items-center mt-1"
                                                    >
                                                        <small className="chat-last-msg text-secondary flex-grow-1">
                                                            {chat.messages
                                                                .length > 0
                                                                ? chat
                                                                      .messages[0]
                                                                      .message
                                                                : ""}
                                                        </small>
                                                        <small className="last-msg-time text-uppercase text-secondary">
                                                            {chat.messages
                                                                .length > 0
                                                                ? chat
                                                                      .messages[0]
                                                                      .createdAt
                                                                : ""}
                                                        </small>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                {chat.users.length !== 0 ? (
                                                    <>
                                                        {chat.users.map(
                                                            (
                                                                user,
                                                                userIndex
                                                            ) => {
                                                                return (
                                                                    <Fragment
                                                                        key={
                                                                            userIndex
                                                                        }
                                                                    >
                                                                        {user._id !==
                                                                        authState
                                                                            .user
                                                                            ._id ? (
                                                                            <Fragment>
                                                                                <div className="profile-icon mr-2">
                                                                                    <img
                                                                                        className="profile-pic-sm"
                                                                                        src={
                                                                                            user
                                                                                                .userProfile
                                                                                                .profileImgUrl
                                                                                                ? getApiEndpoint() +
                                                                                                  user
                                                                                                      .userProfile
                                                                                                      .profileImgUrl
                                                                                                : "/img/profile-image.png"
                                                                                        }
                                                                                        alt="Profile"
                                                                                    />
                                                                                </div>
                                                                                <div className="w-100">
                                                                                    <div className="d-flex align-items-center gap-2">
                                                                                        <div
                                                                                            className={`d-flex gap-1 flex-grow-1`}
                                                                                        >
                                                                                            <div className="d-flex">
                                                                                                <h5 className="font-weight-bolder text-capitalize d-inline m-0 d-inline">
                                                                                                    {
                                                                                                        user.name
                                                                                                    }
                                                                                                </h5>
                                                                                                {user.userType.toLowerCase() ===
                                                                                                "recruiter" ? (
                                                                                                    <Fragment>
                                                                                                        <small className="ml-2 mb-0 notification d-inline">
                                                                                                            R
                                                                                                        </small>
                                                                                                    </Fragment>
                                                                                                ) : (
                                                                                                    ""
                                                                                                )}
                                                                                            </div>
                                                                                            {notifications.length >
                                                                                            0 ? (
                                                                                                <Fragment>
                                                                                                    {notifications.some(
                                                                                                        (
                                                                                                            noti
                                                                                                        ) =>
                                                                                                            noti.chatId ===
                                                                                                            chat._id
                                                                                                    ) ? (
                                                                                                        <Fragment>
                                                                                                            <small className="mr-2 notification d-inline">
                                                                                                                {
                                                                                                                    notifications.filter(
                                                                                                                        (
                                                                                                                            noti
                                                                                                                        ) =>
                                                                                                                            noti.chatId ===
                                                                                                                            chat._id
                                                                                                                    )
                                                                                                                        .length
                                                                                                                }
                                                                                                            </small>
                                                                                                        </Fragment>
                                                                                                    ) : (
                                                                                                        ""
                                                                                                    )}
                                                                                                </Fragment>
                                                                                            ) : (
                                                                                                ""
                                                                                            )}
                                                                                        </div>
                                                                                        <div className="d-flex m-0">
                                                                                            <span className="material-icons pointer">
                                                                                                more_horiz
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <div
                                                                                        //   className={`${styles["contact-message"]}`}
                                                                                        className="d-flex align-items-center mt-1"
                                                                                    >
                                                                                        <small className="chat-last-msg text-secondary flex-grow-1">
                                                                                            {chat
                                                                                                .messages
                                                                                                .length >
                                                                                            0
                                                                                                ? chat.messages[0].message.substring(
                                                                                                      0,
                                                                                                      12
                                                                                                  ) +
                                                                                                  "..."
                                                                                                : ""}
                                                                                        </small>
                                                                                        <small className="last-msg-time text-uppercase text-secondary">
                                                                                            {chat
                                                                                                .messages
                                                                                                .length >
                                                                                            0
                                                                                                ? new Date(
                                                                                                      chat.messages[0].createdAt
                                                                                                  ).getHours() +
                                                                                                  ":" +
                                                                                                  new Date(
                                                                                                      chat.messages[0].createdAt
                                                                                                  ).getMinutes()
                                                                                                : ""}
                                                                                        </small>
                                                                                    </div> */}
                                                                                </div>
                                                                            </Fragment>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </Fragment>
                                                                );
                                                            }
                                                        )}
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </Fragment>
                                        )}
                                    </li>
                                </Fragment>
                            );
                        })
                    ) : (
                        <Fragment>
                            {/* <div className="no-chats">
								Start a Conversation to Chat
							</div> */}
                        </Fragment>
                    )}
                </ul>
            </section>
        </>
    );
};

export default ChatList;
