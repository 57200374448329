import { GET_PROFILE_IMAGE } from "../actions/types";

const initialState = {
    profileImgUrl: "",
    timezone: "",
};

const profile = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_PROFILE_IMAGE:
            return { ...state, profileImgUrl: payload.profileImgUrl };
        default:
            return state;
    }
};

export default profile;
