import React, { useState, Fragment } from 'react';
// import { postNewsletter } from '../../../actions/newsletter';
// import Popover from '@material-ui/core/Popover';
// import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import styles from './Newsletter.module.css';

const Newsletter = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const changeName = (e) => {
        setName(e.target.value);
    };
    const changeEmail = (e) => {
        setEmail(e.target.value);
    };

    // const formSubmit = async (e) => {
    //     e.preventDefault();
    //     const result = await postNewsletter(name, email);
    //     if (result.statusCode == 200) {
    //         alert('Subscribed Successfully');
    //     } else {
    //         alert("Couldn't Subscribe");
    //     }
    // };
    return (
        <Fragment>
            <section className='pt-5'>
                <div
                    style={{ background: '#df371c' }}
                    className='extra-padding'
                >
                    <h2 className='text-center section-title mb-4'>
                        JOIN OUR NEWSLETTER
                    </h2>
                    <div className='container'>
                        <form
                            // onSubmit={(e) => formSubmit(e)}
                            className='ps-0 pe-0'
                        >
                            <div className={`d-flex ${styles['sub-head']}`}>
                                <input
                                    type='text'
                                    className={`form-control ${styles['input-border']} mb-3`}
                                    placeholder='Your Name'
                                    autoComplete='off'
                                    value={name}
                                    required
                                    onChange={(e) => changeName(e)}
                                />
                                <input
                                    type='email'
                                    className={`form-control ${styles['input-border']} mb-3`}
                                    placeholder='Your Email'
                                    value={email}
                                    required
                                    onChange={(e) => changeEmail(e)}
                                />
                                <button
                                    type='submit'
                                    className={`btn btn-dark mb-3 px-5 pb-2 ${styles['sub']}`}
                                >
                                    Subscribe
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default Newsletter;
