import { setAlert } from "./alert";

import {
    UPDATE_CHAT,
    SET_PANEL_STATE,
    EMPTY_PANEL_CHAT,
    SET_PANEL_MSG,
} from "./../actions/types";
import { error } from "./error";
import { requestWithToken } from "../utils/httpRequest";
import { getApiEndpoint } from "../utils/getApiEndpoint";
import moment from "moment";

export const setPanelState = () => (dispatch, getState) => {
    dispatch({
        type: SET_PANEL_STATE,
        payload: !getState().chatPanel.chatPanelOpen,
    });
};

export const addChat = (chat, maxChats) => async (dispatch, getState) => {
    const activeChats = getState().chatPanel.activeChats;
    chat.isMinimised = false;
    chat.msg = "";
    chat.messages =
        chat._id === "chatbot"
            ? [
                  {
                      user: {
                          name: "chatbot",
                          userType: "chatbot",
                          _id: "chatbot_id",
                      },
                      message: "How can i help you?",
                      msgType: "message",
                      action: {
                          actionType: "message",
                          actionData: {},
                          actionTakenType: "",
                      },
                      createdAt: moment(),
                  },
              ]
            : [];

    if (chat._id !== "chatbot") {
        try {
            const response = await requestWithToken(
                "GET",
                `${getApiEndpoint()}/chat/chatroom/${chat._id}/messages`
            );

            chat.messages = response.data.data;
        } catch (err) {
            dispatch(error(err));
            return err.response.data;
        }
    }

    try {
        if (activeChats.length === maxChats) {
            if (!activeChats.some((chatEl) => chatEl._id === chat._id)) {
                activeChats.push(chat);
                activeChats.splice(0, 1);
                dispatch({
                    type: UPDATE_CHAT,
                });
            }
        } else {
            if (!activeChats.some((chatEl) => chatEl._id === chat._id)) {
                activeChats.push(chat);
                dispatch({
                    type: UPDATE_CHAT,
                });
            }
        }
    } catch (err) {
        dispatch(setAlert("Error in Opening Chat", "danger"));
    }
};

export const emptyPanelChats = () => (dispatch, getState) => {
    try {
        dispatch({
            type: EMPTY_PANEL_CHAT,
        });
    } catch (err) {
        setAlert("Server Error", "danger");
    }
};

export const deleteLastChat = () => (dispatch, getState) => {
    const activeChats = getState().chatPanel.activeChats;

    try {
        activeChats.splice(-1, 1);
        dispatch({
            type: UPDATE_CHAT,
        });
    } catch (err) {
        dispatch(setAlert("Error in Opening Chat", "danger"));
    }
};

export const setPanelMsg = (e, chatId) => (dispatch, getState) => {
    // const activeChats = getState().chatPanel.activeChats;

    try {
        // activeChats.find(
        //   (chatEl) => chatEl._id.toString() === chatId.toString()
        // ).msg = e.target.value;

        dispatch({
            type: SET_PANEL_MSG,
            payload: { msg: e.target.value, chatId },
        });
    } catch (err) {
        dispatch(setAlert("Cannot send Messages"));
    }
};

export const setMinimisedState = (chatId) => (dispatch, getState) => {
    const activeChats = getState().chatPanel.activeChats;

    try {
        activeChats.some((chatEl) => {
            if (chatEl._id === chatId) {
                if (chatEl.isMinimised) {
                    chatEl.isMinimised = false;
                } else {
                    chatEl.isMinimised = true;
                }
            }
            return null;
        });

        dispatch({
            type: UPDATE_CHAT,
        });
    } catch (err) {
        dispatch(setAlert("Cannot Minimise", "danger"));
    }
};

export const closeChat = (chatId) => (dispatch, getState) => {
    const activeChats = getState().chatPanel.activeChats;

    try {
        const delIndex = activeChats.indexOf(
            activeChats.find((chatEl) => chatEl._id === chatId)
        );

        activeChats.splice(delIndex, 1);

        dispatch({
            type: UPDATE_CHAT,
        });
    } catch (err) {
        dispatch(setAlert("Cannot Minimise", "danger"));
    }
};

// Add new msg in the Panel Chat Room
export const newPanelMsg = (chatId, msg) => async (dispatch, getState) => {
    try {
        getState()
            .chatPanel.activeChats.find(
                (el) => el._id.toString() === chatId.toString()
            )
            .messages.unshift(msg);

        dispatch({
            type: UPDATE_CHAT,
        });
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
