import React, { useState } from "react";
import { Dialog } from "@material-ui/core";
import {
    CustomizedDialogContent,
    CustomizedDialogTitle,
} from "../CustomizedModal/CustomizedModal";
import { useFormik } from "formik";
import LoadingButton from "../../utils/LoadingButton/LoadingButton";
import { useDispatch } from "react-redux";
import { startMilestoneRevision } from "../../../actions/milestone";
import { setAlert } from "../../../actions/alert";

const ContinueMilestoneWorkModal = ({ isOpen, onClose, milestoneId }) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const continueMilestoneFormik = useFormik({
        initialValues: {
            duration: "",
            scope: "weeks",
        },
        onSubmit: async (values) => {
            setLoading(true);
            const response = await dispatch(
                startMilestoneRevision(milestoneId, values)
            );
            if (response.status) {
                onClose();
                dispatch(setAlert(response.msg, "success"));
            } else {
                dispatch(setAlert(response.msg, "danger"));
            }
            setLoading(false);
        },
    });

    return (
        <Dialog
            onClose={() => onClose(false)}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
            maxWidth="sm"
            fullWidth={true}
        >
            <CustomizedDialogTitle id="customized-dialog-title">
                <h3 className="modal-title text-accent">
                    Continue Milestone Work
                </h3>
            </CustomizedDialogTitle>
            <CustomizedDialogContent>
                <form onSubmit={continueMilestoneFormik.handleSubmit}>
                    <p className="text-muted text-center">
                        Start work on the revisions. Please add some duration
                        for the revisions below.
                    </p>
                    <div className="mb-3">
                        <label className="form-label">Estimated Duration</label>
                        <div className="input-group">
                            <input
                                type="text"
                                name="duration"
                                value={continueMilestoneFormik.values.duration}
                                className={`dark-input flex-grow-1 borders-right-radius-none`}
                                style={{ width: "0px" }}
                                placeholder="Estimated Time"
                                required
                                onChange={continueMilestoneFormik.handleChange}
                            />
                            <select
                                name="scope"
                                className={`dark-input flex-grow-1 borders-left-radius-none`}
                                style={{ width: "0px" }}
                                value={continueMilestoneFormik.values.scope}
                                onChange={continueMilestoneFormik.handleChange}
                                required
                            >
                                <option value="days">Days</option>
                                <option value="weeks">Weeks</option>
                                <option value="months">Months</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <LoadingButton isLoading={loading} text="Submit" />
                    </div>
                </form>
            </CustomizedDialogContent>
        </Dialog>
    );
};

export default ContinueMilestoneWorkModal;
