import React, { Fragment } from "react";
import Skeleton from "../../Skeleton/Skeleton";

const EducationSkeleton = () => {
    return (
        <Fragment>
            <div className="section-el dark-bg p-3 mb-2">
                <div className="d-flex justify-content-between">
                    <h3 className="mb-3">
                        <Skeleton />
                    </h3>
                </div>
                <div className="el-info mt-2">
                    <div className="row">
                        <div className="col-sm-4 mb-1">
                            <p className="font-weight-bolder mb-1 mb-sm-0">
                                <Skeleton />
                            </p>
                        </div>
                        <div className="col-sm-8 mb-1 mb-sm-0">
                            <p>
                                <Skeleton />
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 mb-1">
                            <p className="font-weight-bolder mb-1 mb-sm-0">
                                <Skeleton />
                            </p>
                        </div>
                        <div className="col-sm-8 mb-1 mb-sm-0">
                            <p>
                                <Skeleton />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default EducationSkeleton;
