import React, { Fragment } from "react";
import styles from "./Vision.module.css";
const Vision = () => {
    return (
        <Fragment>
            <div style={{ paddingTop: "7rem", paddingBottom: "11rem" }}>
                <div
                    className="container"
                    style={{ color: "#fff", paddingLeft: "40px" }}
                >
                    <h1 className={styles["heading"]}>Vision</h1>
                    <h3>Last Updated: August 2021</h3>

                    <p>
                        My vision is to shine a positive light again on games
                        since playing them is not a waste of time but can bring
                        tremendous value to peoples' lives, including creating
                        jobs, passive sources of income with blockchain games,
                        and more. He is currently also trying to bring people
                        back together with virtual fairs and similar events. as
                        well as much more.
                    </p>
                    <p>
                        But the actual reason why I created this website and
                        built up a Network of Developers is because I also made
                        some bad experiences with bad and unreliable developers
                        before and I know how hard it can be to find the right
                        people and to plan the project in the right way
                    </p>
                    <p>
                        That's why I now connect people to realize the dream of
                        their own game
                    </p>
                </div>
            </div>
        </Fragment>
    );
};

export default Vision;
