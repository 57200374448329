import React, { Fragment, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../actions/alert";
import { deleteEducation } from "../../../actions/profile";

import { ProfileContext } from "../../../contexts/ProfileContext";
import EditEducation from "../EditEducation/EditEducation";
import styles from "./EducationList.module.css";

const EducationList = () => {
	const dispatch = useDispatch();

	const profileContext = useContext(ProfileContext);

	const authState = useSelector((state) => state.auth);

	const [educationview, setEducationView] = useState();
	const [edit, setEdit] = useState(false);
	const createClick = (edu, change) => {
		setEdit(change);
		setEducationView(
			<EditEducation edu={edu} callBackCancel={createClick} />
		);
	};

	const onDeleteEdu = async (expId) => {
		const response = await dispatch(deleteEducation(expId));

		if (response.status) {
			dispatch(setAlert(response.msg, "success"));
			profileContext.setProfile({
				...profileContext.profile,
				educations: response.data.educations,
			});
		} else {
			dispatch(setAlert(response.msg, "danger"));
		}
	};

	return profileContext.profile.educations.length === 0 ? (
		<Fragment>
			<div className="text-center text-muted">No Education Added</div>
		</Fragment>
	) : (
		<Fragment>
			<ul className="list-type-none mb-0 p-0">
				{edit ? (
					<>{educationview}</>
				) : (
					<>
						{profileContext.profile.educations.map((edu) => {
							return (
								<Fragment key={edu._id}>
									<li className="section-el dark-bg p-3 mb-2">
										<div className="d-flex justify-content-between">
											<h3 className="mb-3">
												{edu.school},{edu.location}
											</h3>
											{profileContext.profile.user._id.toString() ===
												authState.user._id.toString() && (
												<div>
													<button
														className={`${styles["heading-toggle"]} me-2`}
														onClick={(e) =>
															createClick(
																edu,
																true
															)
														}
													>
														<span className="material-icons">
															create
														</span>
													</button>
													<button
														className={`${styles["heading-toggle"]}`}
														onClick={(e) =>
															onDeleteEdu(edu._id)
														}
													>
														<span className="material-icons">
															delete
														</span>
													</button>
												</div>
											)}
										</div>
										<small className="text-muted">
											{edu.from.split("T")[0]} -{" "}
											{!edu.current
												? edu.to.split("T")[0]
												: "Current"}
										</small>
										<div className="el-info mt-2">
											<div className="row">
												<div className="col-sm-4 mb-1">
													<p className="font-weight-bolder mb-1 mb-sm-0">
														Degree
													</p>
												</div>
												<div className="col-sm-8 mb-1 mb-sm-0">
													<p>{edu.degree}</p>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-4 mb-1">
													<p className="font-weight-bolder mb-1 mb-sm-0">
														Description
													</p>
												</div>
												<div className="col-sm-8 mb-1 mb-sm-0">
													<p>{edu.description}</p>
												</div>
											</div>
										</div>
									</li>
									{/* <div className="container px-5">
							<div className="row mb-3">
								<div className="col">
									<h3>{edu.degree}</h3>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col">
									<span className="font-weight-bold">School: </span>
									{edu.school},{edu.location}
								</div>
							</div>
							<div className="row mb-3">
								<div className="col">
									{edu.from} - {!edu.current ? edu.to : "Current"}
								</div>
							</div>
							<div className="row mb-3">
								<div className="col">
									<span className="font-weight-bold">Description: </span>
									{edu.description}
								</div>
							</div>
						</div> */}
								</Fragment>
							);
						})}
					</>
				)}
			</ul>
		</Fragment>
	);
};

export default EducationList;
